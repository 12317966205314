import { IGetPods } from "../../../interface/get-pod-interface";
import config from "../../../config";
import axios from "axios";
import { IWallet } from "../wallet/wallet";

enum PaymentType {
  CHECKOUT = "CHECKOUT"
}

interface IcreateCheckoutDotPaymentLink {
  type: string;
  total:number;
  wallet:IWallet;
  link:string;
  id:string;
  createdAt:string;
  updatedAt:string;
  status:string
}

export async function createCheckoutDotComPaymentLink(
  accessToken: string,
  total: number
): Promise<IcreateCheckoutDotPaymentLink> {
  const url = new URL(
    `/billing/payment`,
    config.api.billing_endpoint
  );
  const data = {
    type: PaymentType.CHECKOUT,
    total: total
  };
  const response = await axios.post(url.href, data, {
    withCredentials: false,
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  });
  return response.data;
}
