import _ from "lodash";

export interface IConfig {
  api: {
    endpoint: string;
    billing_endpoint: string;
    stamp_endpoint: string;
    download_gateway:string;
    website_gateway:string;
  };
  listPodNumber: number;
  uploadItemListenerSleepTime: number;
  sentry: {
    dsn: string;
  };
  billing: {
    overviewToFixIndex: number;
  };
  auth0: {
    url: string;
    realm: string;
    client: string;
  };
  env: string;
}

const config: IConfig = {
  api: {
    endpoint: process.env.REACT_APP_API_ENDPOINT || "https://api.dev.dedrive.io",
    billing_endpoint: process.env.REACT_APP_API_BILLING_ENDPOINT || "https://api.dev.dedrive.io",
    stamp_endpoint: process.env.REACT_APP_STAMP_API_ENDPOINT || "https://api.dev.dedrive.io",
    download_gateway: process.env.REACT_APP_DOWNLOAD_GATEWAY || "https://dev.gateway.dedrive.io",
    website_gateway: process.env.REACT_APP_WEBSITE_GATEWAY || ".pod.gateway.dev.dedrive.io"
  },
  listPodNumber: _.toNumber(process.env.REACT_APP_LIST_POD_NUMBER) || 5, // 20 pod per page
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || "",
  },
  uploadItemListenerSleepTime: _.toNumber(process.env.REACT_APP_UPLOAD_ITEM_LISTENER_SLEEP_TIME) || 3000,
  billing: {
    overviewToFixIndex: _.toNumber(process.env.REACT_APP_OVERVIEW_TO_FIX_INDEX) || 2, // cause max : e^-8
  },
  auth0: {
    url: process.env.REACT_APP_AUTH0_URL || "https://auth.v2.degital.io",
    realm: process.env.REACT_APP_AUTH0_REALM || "test_dedrive",
    client: process.env.REACT_APP_AUTH0_CLIENT || "dedrive-portal",
  },
  env: process.env.REACT_APP_ENV || "develop",
};

export default config;
