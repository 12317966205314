import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { authActions } from "../store/auth-slice";
import { Iuser } from "../interface/get-user.interface";
import { useSelector } from "react-redux";
import { getUserWallet } from "../api/billing/wallet/wallet";
import { createCheckoutDotComPaymentLink } from "../api/billing/payment/checkout-dot-com";
import { createCoingatePaymentLink } from "../api/billing/payment/coingate";
import { getTransactionRecords } from "../api/billing/transaction/getTransactionRecords";
import { Slider } from "@mui/material";
import EDNSICON from "../Static/ednsicon.png";
import config from "../config";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

import toast, { Toaster } from "react-hot-toast";
import { ethers } from "ethers";
import { claimEdnsReward } from "../api/billing/edns/claimReward";
import getWalletAddress from "../util/web3";
import getWeb3WalletAddress from "../util/web3";
import Coingate from "../Static/coingate.svg";

interface IserviceCost {
  provider: {
    btfs: number;
    bzz: number;
    ipfs: number;
    total: number;
  };
  requestTime: number;
  total: number;
}
const Overview = () => {
  const [clip, setClip] = useState(false);
  const [balance, setBalance] = useState<number>(0);
  const user: Iuser = useSelector(authActions.login).payload.auth.user;
  const [monthSpent, setMonthSpent] = useState(0);
  const accessToken = useSelector(authActions.login).payload.auth.accessToken;
  const [refill, setRefill] = useState(false);
  const [refillAmount, SetRefillAmount] = useState(10);
  const [refillLoading, setRefillLoading] = useState(false);
  const [costOverView, setCostOverView] = useState<IserviceCost | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const providerOptions = {
  //   // walletconnect: {
  //   //   package: WalletConnectProvider,
  //   //   options: {
  //   //     infuraId: "e6f7ae043bf941658aa7e91826ecaff5",
  //   //   },
  //   // },
  //
  //   binancechainwallet: {
  //     package: true,
  //   },
  //   coinbasewallet: {
  //     package: CoinbaseWalletSDK,
  //     options: {
  //       appName: 'Alex Wong',
  //       infuraId: "e6f7ae043bf941658aa7e91826ecaff5",
  //     },
  //   },
  // };
  //
  // const web3Modal = new Web3Modal({
  //   network: "mainnet",
  //   providerOptions,// optional    -> Uncomment for debug webpack.config
  //   cacheProvider: false, // optional
  //   // required
  // });
  const handleChange = (event: Event, newValue: number | number[]) => {
    // console.log(newValue);
    const val = newValue as number;
    SetRefillAmount(val);
  };

  const refillAmountList = [
    {
      value: 10,
    },
    {
      value: 50,
    },
    {
      value: 100,
    },
    {
      value: 200,
    },
    {
      value: 500,
    },
    {
      value: 1000,
    },
  ];
  const getWalletAddress = async () => {
    try {
      const wallet = await getUserWallet(accessToken);
      setBalance(wallet.balance);
      setIsFetching(false);
    } catch (e) {
      console.error(e);
    }
  };

  const getDate = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const startDate = new Date();
    const todate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);

    return `${startDate.getDate()} ${months[startDate.getMonth()]} - ${todate.getDate()} ${months[todate.getMonth()]}`;
  };
  const getTransaction = async (_accessToken: string) => {
    try {
      const _transactionRecords = await getTransactionRecords(_accessToken);
      let outrecord = _transactionRecords.filter((x) => x.type == "EXPENSE");
      outrecord = outrecord.filter((item) => {
        const date = new Date(item.createdAt);
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);

        return date >= startDate;
      });

      const result = outrecord.reduce((accumulator, current) => {
        return accumulator + current.total;
      }, 0);

      const btfsCost = outrecord
        .filter((x) => {
          return x.product?.name.includes("BTFS");
        })
        .reduce((accumulator, current) => {
          return accumulator + current.total;
        }, 0);
      const ipfsCost = outrecord
        .filter((x) => {
          return x.product?.name.includes("IPFS");
        })
        .reduce((accumulator, current) => {
          return accumulator + current.total;
        }, 0);
      const bzzCost = outrecord
        .filter((x) => {
          return x.product?.name.includes("BZZ");
        })
        .reduce((accumulator, current) => {
          return accumulator + current.total;
        }, 0);
      const requestCost = outrecord
        .filter((x) => {
          return x.product?.name.includes("Request");
        })
        .reduce((accumulator, current) => {
          return accumulator + current.total;
        }, 0);
      // console.log({ requestCost });
      const providerCost = btfsCost + bzzCost + ipfsCost;
      const cost: IserviceCost = {
        provider: {
          btfs: btfsCost,
          bzz: bzzCost,
          ipfs: ipfsCost,
          total: providerCost,
        },
        requestTime: requestCost,
        total: providerCost + requestCost,
      };

      setCostOverView(cost);
      setMonthSpent(result);
    } catch (error) {
      console.error(error);
    }
  };
  async function ednsUserClaimReward() {
    //Disconnect all wallet

    // web3Modal.clearCachedProvider()

    try {
      // await window.updateWeb3Modal({ show: true });
      // setIsModalOpen(true);
      // const instance = await web3Modal.connect();
      // const provider = new ethers.providers.Web3Provider(instance);
      // const signer = provider.getSigner(0)
      // const address = await signer.getAddress()
      const address = await getWeb3WalletAddress();
      const domainCount = await claimEdnsReward(accessToken, address);
      await getWalletAddress();

      toast.success(`You have ${domainCount.domainCount} EDNS Domains and have been claimed successfully. 1 USD added to your wallet.`, {
        style: {
          background: "var(--bg-sec)",
          color: "var(--title-white)",
        },
      });
    } catch (e) {
      if ((e as Error).message !== "") {
        toast.error((e as Error).message, {
          style: {
            background: "var(--bg-sec)",
            color: "var(--title-white)",
          },
        });
      }
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      const hitbox = document.querySelector(".web3modal-modal-hitbox");
      const modalContainer = document.querySelector(".web3modal-modal-lightbox") as HTMLDivElement;
      const modal = document.querySelector("#WEB3_CONNECT_MODAL_ID");
      modalContainer.style.zIndex = "999";
      // listen hitbox click event
      hitbox?.addEventListener("click", async () => {
        await window.updateWeb3Modal({ show: false });
        setIsModalOpen(false);
        modal?.remove();
      });
      return () => {
        hitbox?.removeEventListener("click", async () => {
          await window.updateWeb3Modal({ show: false });
          setIsModalOpen(false);
          modal?.remove();
        });
      };
    }
  }, [isModalOpen]);

  useEffect(() => {
    getTransaction(accessToken);
  }, [accessToken]);

  useEffect(() => {
    getWalletAddress();
  }, [balance]);
  return (
    <div className="pb-mobile md:pb-10">
      {/* <Toaster></Toaster> */}
      <div data-show={refill} onClick={() => setRefill(false)} className="create-bucket-wrapper fixed left-0 top-0 z-[999] h-full w-full bg-black/10 backdrop-blur-[2px]"></div>
      <div data-show={refill} className="create-bucket-con topup create-border-gray bg-color z-[999] max-h-[85vh] overflow-y-auto rounded-3xl border-2 p-6 md:rounded-3xl md:p-6">
        <div
          className="text-main absolute right-6 top-6 grid cursor-pointer place-items-center rounded-full bg-[#e1e7e9] text-lg font-medium dark:bg-gray-800/70"
          onClick={() => setRefill(false)}
        >
          <Icon icon="basil:cross-outline" className="h-auto w-8 text-[#7c7e84] dark:text-gray-500" />
        </div>
        {/* <div className="bg-blue mb-6 grid aspect-square w-[64px] place-items-center rounded-full">
          <Icon icon="material-symbols:wallet" className="h-auto w-8 text-white" />
        </div> */}
        <h1 className="title-white text-xl font-bold">Top up Wallet</h1>
        <div className="billing-new-option title-white billing-option-border-color mt-8 rounded-xl border-2 p-8 pt-6 pb-5 shadow-sm hover:shadow-lg hover:shadow-blue-100/80 dark:shadow-none md:mt-6">
          <p className="mt-3 text-2xl font-semibold">Amount : ${refillAmount}</p>
          <div className="mt-1">
            <Slider value={refillAmount} defaultValue={refillAmountList[0].value} step={null} marks={refillAmountList} max={1000} onChange={handleChange} />
          </div>
        </div>
        <div className="justify mt-6 grid w-full grid-cols-2 gap-2">
          <button
            onClick={() => SetRefillAmount(10)}
            className="billing-option-border-color group/billing-option title-white grid h-[50px] w-full cursor-pointer place-items-center rounded-lg border-2 p-1 font-semibold focus-within:bg-[#25c0a3]"
          >
            <div className="grid h-full w-full place-items-center rounded-[4px] group-hover/billing-option:bg-[#25c0a3]">$10</div>
          </button>
          <button
            onClick={() => SetRefillAmount(50)}
            className="billing-option-border-color group/billing-option title-white grid h-[50px] w-full cursor-pointer place-items-center rounded-lg border-2 p-1 font-semibold focus-within:bg-[#25c0a3]"
          >
            <div className="grid h-full w-full place-items-center rounded-[4px] group-hover/billing-option:bg-[#25c0a3]">$50</div>
          </button>
          <button
            onClick={() => SetRefillAmount(100)}
            className="billing-option-border-color group/billing-option title-white grid h-[50px] w-full cursor-pointer place-items-center rounded-lg border-2 p-1 font-semibold focus-within:bg-[#25c0a3]"
          >
            <div className="grid h-full w-full place-items-center rounded-[4px] group-hover/billing-option:bg-[#25c0a3]">$100</div>
          </button>
          <button
            onClick={() => SetRefillAmount(200)}
            className="billing-option-border-color group/billing-option title-white grid h-[50px] w-full cursor-pointer place-items-center rounded-lg border-2 p-1 font-semibold focus-within:bg-[#25c0a3]"
          >
            <div className="grid h-full w-full place-items-center rounded-[4px] group-hover/billing-option:bg-[#25c0a3]">$200</div>
          </button>
          <button
            onClick={() => SetRefillAmount(500)}
            className="billing-option-border-color group/billing-option title-white grid h-[50px] w-full cursor-pointer place-items-center rounded-lg border-2 p-1 font-semibold focus-within:bg-[#25c0a3]"
          >
            <div className="grid h-full w-full place-items-center rounded-[4px] group-hover/billing-option:bg-[#25c0a3]">$500</div>
          </button>
          <button
            onClick={() => SetRefillAmount(1000)}
            className="billing-option-border-color group/billing-option title-white grid h-[50px] w-full cursor-pointer place-items-center rounded-lg border-2 p-1 font-semibold focus-within:bg-[#25c0a3]"
          >
            <div className="grid h-full w-full place-items-center rounded-[4px] group-hover/billing-option:bg-[#25c0a3]">$1,000</div>
          </button>
        </div>
        <button
          onClick={async () => {
            setRefillLoading(true);
            setRefill(false);
            const paymentDetails = await createCheckoutDotComPaymentLink(accessToken, refillAmount);
            window.open(paymentDetails.link);
            setRefillLoading(false);
          }}
          className="btn-billing-pay relative mt-5 w-full rounded-lg bg-[#25c0a3] py-4 text-base font-bold text-white hover:bg-[#44b6a1]"
        >
          Checkout
          <Icon className="btn-billing-pay-icon h-6 w-auto" icon="material-symbols:arrow-right-alt-rounded" />
        </button>
        <button
          onClick={async () => {
            setRefillLoading(true);
            setRefill(false);
            const paymentDetails = await createCoingatePaymentLink(accessToken, refillAmount);
            window.open(paymentDetails.link);
            setRefillLoading(false);
          }}
          className="btn-billing-pay relative mt-5 w-full rounded-lg bg-black py-4 text-base font-semibold text-white hover:bg-[#0c0c0c]"
        >
          <img src={Coingate} className="mx-auto w-[50%]" />
        </button>
      </div>

      <div className="bg-main main-box-shadow mt-6 rounded-2xl">
        <div className="border-gray flex flex-col flex-wrap items-start justify-between gap-4 border-b p-6 text-base font-semibold md:h-[75px] md:flex-row md:items-center md:p-0 md:px-6">
          <p className="title-white text-lg font-semibold">DeDrive Summary</p>

          <div className="flex w-full flex-col gap-4 md:w-auto md:flex-row md:gap-4">
            {/* {config.env === "develop" && (

              <button
                className="btn-billing-pay relative flex w-full items-center justify-center gap-4 rounded-md bg-[#884afc] p-6 py-3 text-base font-semibold text-white hover:bg-[#226ddd] dark:bg-[#5c1feb] md:w-fit md:py-2"
                onClick={async () => {
                  await ednsUserClaimReward();
                }}
              >
                <img src={EDNSICON} className="h-auto w-5" />
                {refillLoading ? <div>Loading</div> : "EDNS Reward"}
              </button>

            )} */}

            <button
              className="bg-blue btn-billing-pay relative flex w-full items-center justify-center gap-4 rounded-md p-6 py-3 text-base font-semibold text-white hover:bg-[#3ea794] md:w-fit md:py-2"
              onClick={async () => {
                setRefill(true);
              }}
            >
              <Icon icon="material-symbols:wallet" className="h-auto w-5" />
              {refillLoading ? <div>Loading</div> : "Top Up"}
            </button>
          </div>
        </div>
        <div className="flex w-full flex-col flex-wrap overflow-hidden rounded-xl py-6 md:flex-row">
          <div className="flex min-w-[350px] max-w-full flex-1 flex-col justify-between overflow-hidden p-8 py-6">
            <h3 className="text-lg text-[#aaabaf]">This Month Spending ({getDate()})</h3>
            {isFetching ? (
              <div className="gradient h-[48px] w-full rounded-md"></div>
            ) : (
              <h1 className="title-white truncate text-4xl md:text-5xl">{!isFetching && costOverView?.total.toFixed(config.billing.overviewToFixIndex)} USD</h1>
            )}
          </div>
          <div className="border-gray flex min-w-[350px] max-w-full flex-1 flex-col justify-between overflow-hidden border-l p-8 py-6">
            <h3 className=" text-lg text-[#aaabaf]">Wallet Balance</h3>
            <div className="title-white mt-4 truncate text-4xl md:text-5xl">
              {isFetching ? (
                <div className="gradient h-[48px] w-full rounded-md"></div>
              ) : (
                <h1 className="title-white truncate text-4xl md:text-5xl">{!isFetching && balance.toFixed(config.billing.overviewToFixIndex)} USD</h1>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bucket-info-border-color bg-main main-box-shadow mt-8 rounded-2xl font-medium">
        <div className="border-gray flex flex-wrap items-center justify-between gap-4 border-b p-6 font-semibold md:h-[75px] md:p-0 md:px-6">
          <p className="title-white text-lg font-semibold">DeDrive Service Billing</p>
        </div>
        <div className="bucket-info-border-color bucket-info-text-color flex border-b p-2 py-4 text-xs text-[#8a898c] 2xl:text-sm">
          <span className="max-w-[50%] flex-[1] p-4 py-2 font-medium">Service Name</span>
          <span className="border-gray max-w-[50%] flex-[1] border-l p-4 py-2 pl-4 font-medium">Amount</span>
        </div>
        <div className="bucket-info-border-color flex border-b p-6 py-8 text-xs text-[#8a898c] 2xl:text-[13px]">
          <div onClick={() => setClip(!clip)} className="flex flex-[14] cursor-pointer items-center gap-2">
            <span>Pod Provider</span>
            <Icon icon="akar-icons:chevron-down" className={`${clip ? "rotate-180" : "rotate-0"}`} />
          </div>
          <span className="flex-[1]">{!costOverView ? "Loading" : costOverView.provider.total.toFixed(config.billing.overviewToFixIndex)}</span>
        </div>
        <div data-clip={clip} className="billing-clip-con overflow-hidden">
          <div className="bucket-info-border-color flex border-b p-6 py-8 text-xs text-[#8a898c] 2xl:text-[13px]">
            <span className="flex-[14] pl-4">BTFS</span>
            <span className="ml-[-1rem] flex-[1] text-end">{!costOverView ? "Loading" : costOverView.provider.btfs.toFixed(config.billing.overviewToFixIndex)}</span>
          </div>
          <div className="bucket-info-border-color flex border-b p-6 py-8 text-xs text-[#8a898c] 2xl:text-[13px]">
            <span className="flex-[14] pl-4">IPFS</span>
            <span className="ml-[-1rem] flex-[1] text-end">{!costOverView ? "Loading" : costOverView.provider.ipfs.toFixed(config.billing.overviewToFixIndex)}</span>
          </div>
          <div className="bucket-info-border-color flex border-b p-6 py-8 text-xs text-[#8a898c] 2xl:text-[13px]">
            <span className="flex-[14] pl-4">BZZ</span>
            <span className="ml-[-1rem] flex-[1] text-end">{!costOverView ? "Loading" : costOverView.provider.bzz.toFixed(config.billing.overviewToFixIndex)}</span>
          </div>
        </div>
        <div className="bucket-info-border-color flex border-b p-6 py-8 text-xs text-[#8a898c] 2xl:text-[13px]">
          <span className="flex-[14]">Request Time</span>
          <span className="flex-[1] text-end">{!costOverView ? "Loading" : costOverView.requestTime.toFixed(config.billing.overviewToFixIndex)}</span>
        </div>
        <div className="bucket-info-text-color title-white flex items-center p-6 text-sm font-semibold 2xl:text-base ">
          <span className="flex-[14] text-sm 2xl:text-base">Total ({getDate()})</span>
          <span className="flex-[1] text-end 2xl:text-base">{!costOverView ? "Loading" : costOverView.total.toFixed(config.billing.overviewToFixIndex)}</span>
        </div>
      </div>
    </div>
  );
};

export default Overview;
