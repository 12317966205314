import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  // const [theme, setTheme] = useState(localStorage.getItem("theme"));
  // useEffect(() => {
  //   if (theme === null) {
  //     localStorage.setItem("theme", "dark");
  //     setTheme("dark");
  //   }
  // });
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <div className={`content-con grid place-items-center`}>
      <div className="mx-auto flex h-fit max-w-[85vw] flex-col items-center">
        <p className="title-white mt-[-10vw] text-[calc(100px+12vw)] font-extrabold leading-none">404</p>
        <p className="title-white text-center text-4xl font-bold">We couldn't connect the page.</p>
        <p className="title-white mt-10 text-center">This page was not found. You may have mistyped the address or the page may have moved.</p>
        <Link to="/" className="text-main mt-8 hover:underline">
          Back to home page
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
