import { Icon } from "@iconify/react";
import useWindowDimensions from "../Hook/useWindowDimensions";
import { isImage, isVideo, isText, isExcel, isCss, isCompress } from "../Hook/isType";
import { useDispatch, useSelector } from "react-redux";
import { fileActions } from "../store/file-slice";
import { useEffect, useState } from "react";
import { getItemsByUid } from "../api/get-item";
import { authActions } from "../store/auth-slice";
import { podActions } from "../store/pod-info-slice";
interface Props {
  Uid?: string;
  name: string;
  type: string;
  lastModified: string;
  size: string;
  status: string;
  location: string;
  handleClickFolder?: (folder: string, location: string) => Promise<void>;
  setLocation?: React.Dispatch<React.SetStateAction<string>>;
  setBreadCumb?: (folder: string, location: string) => any[];
  accessToken?: string;
  unCheckAction: (uid: string) => void;
  index: number;
  length: number;
  selectFile: (uid: string | null) => void;
  clicked: boolean;
  updateToList: (uid: string, action: boolean) => void;
  handleDisplayMode: boolean;
}

const File = (props: Props) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [checked, setChecked] = useState(false);
  // const preview = (info: { name: string; type: string; location: string; lastModified: string; size: string }) => {
  //   dispatch(fileActions.preview(info));
  // };
  const user = useSelector(authActions.login).payload.user;
  const pod = useSelector(podActions.currentPod).payload.pod;
  const getFileByUid = async (uid: string) => {
    const res = await getItemsByUid(`${props.accessToken}`, `${user?.sub}`, uid);
    console.log(res.data[pod.pod.PrimaryProvider.toLowerCase()]);
    return res.data[pod.pod.PrimaryProvider.toLowerCase()];
  };

  const formatFileSize = (bytes: number, decimalPoint: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleCheck = (e: any) => {
    const checkAll = document.querySelector("#checkAll") as HTMLInputElement;
    let isCheckAll = true;
    document.querySelectorAll("#fileCheck").forEach((e) => {
      const check = e as HTMLInputElement;
      if (!check.checked) {
        isCheckAll = false;
      }
    });
    checkAll.checked = isCheckAll;
    if (!e.target.checked) {
      props.unCheckAction(props.Uid!);
    }
    props.selectFile(null);
    props.updateToList(props.Uid!, e.target.checked);
  };

  const convertDate = (date: string) => {
    const dateArr = date.split("T")[0].split("-");
    const month = dateArr[1];
    const day = dateArr[2];
    const year = dateArr[0];
    const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthName[parseInt(month) - 1] + " " + day + ", " + year;
  };

  return (
    <div
      id="itemRow"
      onClick={(event) => {
        switch (event.detail) {
          case 1: {
            if ((event.target as any).nodeName.toUpperCase() !== "INPUT") {
              props.selectFile(props.Uid!);
            }
            // put all file information to redux
            // preview({
            //   name: props.name,
            //   type: props.type,
            //   location: props.location,
            //   lastModified: convertDate(props.lastModified),
            //   size: props.size,
            // });

            break;
          }
          case 2: {
            // preview({
            //   name: props.name,
            //   type: props.type,
            //   location: props.location,
            //   lastModified: convertDate(props.lastModified),
            //   size: props.size,
            // });
            props.type === "Folder" && props.handleClickFolder && props.handleClickFolder(props.name, props.location);
            props.setLocation && props.setLocation(`${props.location}/${props.name}`);
            props.type === "Folder" && props.setBreadCumb && props.setBreadCumb(props.name, props.location);
            // props.type !== "Folder" &&
            // props.downloadFile &&
            // props.downloadFile(props.accessToken, props.Uid, props.name);
            // Show Deatils of Item
            break;
          }
          default: {
            break;
          }
        }
      }}
      data-click={props.clicked || checked}
      className={`bucket-info-text-color bucket-item item border-gray text-color group flex h-[75px] select-none gap-2 px-6 py-4 text-[13px] font-semibold md:gap-4 md:text-sm ${
        !props.handleDisplayMode ? ` relative aspect-[1/1] !h-auto flex-col rounded-lg border border-b !p-2` : props.length - 1 === props.index ? "border-b-0" : "border-b"
      }`}
    >
      <div
        className={`flex flex-[0.5] items-center text-[#888E99]${
          !props.handleDisplayMode ? " pointer-events-none absolute left-2 top-2 opacity-0 transition duration-200 group-hover:pointer-events-auto group-hover:opacity-100" : ""
        }`}
      >
        <input
          style={{ opacity: `${props.status !== "PENDING" ? 1 : 0}` }}
          data-index={props.index}
          type="checkbox"
          name={`checkBox-${props.Uid}`}
          onChange={handleCheck}
          defaultChecked={false}
          className={`checkBox-${props.Uid}`}
          id={props.status !== "PENDING" ? "fileCheck" : ""}
        />
      </div>

      <div
        className={`bucket-info-text-color name shrink-1 flex flex-[4] items-center gap-3 overflow-hidden 2xl:gap-6${
          !props.handleDisplayMode ? " flex-col !items-start justify-between !gap-2" : ""
        }`}
        id="ItemProp"
      >
        <div id="ItemProp" className={`${!props.handleDisplayMode ? "bg-sec grid h-full w-full place-items-center rounded-md bg-[#f6f8fa] dark:bg-[#181a1b]" : ""}`}>
          {props.type !== "Folder" && isText(props.name?.split(".")[props.name?.split(".").length - 1]) && (
            <Icon
              icon="ant-design:file-text-filled"
              className={`ml-[-4px] h-7 w-auto shrink-0 text-[#6099f0] dark:text-[#588EDF] 2xl:h-8${!props.handleDisplayMode ? " !ml-0 !h-12 md:!h-16" : ""}`}
              id="ItemProp"
            ></Icon>
          )}
          {props.type === "Folder" && (
            <Icon
              icon="bxs:folder"
              className={`ml-[-4px] h-7 w-auto shrink-0 text-[#fdc25d] dark:text-[#EFA72A] 2xl:h-8${!props.handleDisplayMode ? " !ml-0 !h-12 md:!h-16" : ""}`}
              id="ItemProp"
            ></Icon>
          )}
          {props.type !== "Folder" && isVideo(props.name?.split(".")[props.name?.split(".").length - 1]) && (
            <Icon
              icon="ic:baseline-video-file"
              className={`ml-[-4px] h-7 w-auto shrink-0 text-[#f06957] dark:text-[#D4594A] 2xl:h-8${!props.handleDisplayMode ? " !ml-0 !h-12 md:!h-16" : ""}`}
              id="ItemProp"
            ></Icon>
          )}
          {props.type !== "Folder" && isImage(props.name?.split(".")[props.name?.split(".").length - 1]) && (
            <Icon
              icon="ic:baseline-insert-photo"
              className={`ml-[-4px] h-7 w-auto shrink-0 text-[#f06957] dark:text-[#D4594A] 2xl:h-8${!props.handleDisplayMode ? " !ml-0 !h-12 md:!h-16" : ""}`}
              id="ItemProp"
            ></Icon>
          )}
          {props.type !== "Folder" && isExcel(props.name?.split(".")[props.name?.split(".").length - 1]) && (
            <Icon
              icon="vscode-icons:file-type-excel"
              className={`ml-[-4px] h-7 w-auto shrink-0 text-[#33c481] 2xl:h-8${!props.handleDisplayMode ? " !ml-0 !h-12 md:!h-16" : ""}`}
              id="ItemProp"
            ></Icon>
          )}
          {props.type !== "Folder" && isCss(props.name?.split(".")[props.name?.split(".").length - 1]) && (
            <Icon
              icon="ci:css3"
              className={`ml-[-4px] h-7 w-auto shrink-0 text-[#fddc46] dark:text-[#eece42] 2xl:h-8${!props.handleDisplayMode ? " !ml-0 !h-12 md:!h-16" : ""}`}
              id="ItemProp"
            ></Icon>
          )}
          {props.type !== "Folder" && isCompress(props.name?.split(".")[props.name?.split(".").length - 1]) && (
            <Icon
              icon="ri:folder-zip-fill"
              className={`ml-[-4px] h-7 w-auto shrink-0 text-[#eea942] 2xl:h-8${!props.handleDisplayMode ? " !ml-0 !h-12 md:!h-16" : ""}`}
              id="ItemProp"
            ></Icon>
          )}
        </div>
        <span className={`truncate${!props.handleDisplayMode ? " mb-1 w-full shrink-0 text-center" : ""}`} id="ItemProp">
          {props.name}
        </span>
      </div>
      {width > 768 && props.handleDisplayMode && (
        <>
          <div className="flex flex-[3] items-center gap-1" id="ItemProp">
            {props.type}
          </div>
          {/*<div className="flex flex-[3] items-center gap-1" id="ItemProp" >{convertDate(props.lastModified)}</div>*/}
          <div className="flex flex-[3] items-center gap-1" id="ItemProp">
            {new Date(props.lastModified).toLocaleString()}
          </div>
          <div className="flex flex-[2.5] items-center gap-1" id="ItemProp">
            {formatFileSize(parseInt(props.size), 2)}
          </div>
          <div className="flex flex-[2] items-center gap-1" id="ItemProp">
            {props.status}
          </div>
        </>
      )}
      {props.status === "PENDING" ? (
        <div
          className={`bucket-info-text-color name shrink-1 flex flex-[1] items-center gap-3 overflow-hidden 2xl:gap-6${
            !props.handleDisplayMode ? " flex-col !items-start justify-between !gap-2" : ""
          }`}
          id="ItemProp"
        ></div>
      ) : (
        <div
          className={`bucket-info-text-color name shrink-1 flex flex-[1] items-center gap-3 overflow-hidden 2xl:gap-6${
            !props.handleDisplayMode ? " flex-col !items-start justify-between !gap-2" : ""
          }`}
          id="ItemProp"
        >
          <div className="flex flex-row items-center">
            <Icon
              className={`h-6 w-6 cursor-pointer hover:bg-gray-400`}
              onClick={async (e) => {
                if (props.Uid) {
                  const cid: any = await getFileByUid(props.Uid);
                  navigator.clipboard.writeText(cid.cid);
                }
              }}
              icon="ic:baseline-copy-all"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default File;
