import grapesjs from "grapesjs";

export default function (editor: grapesjs.Editor, options: any) {
  const panels = editor.Panels;
  const command = editor.Commands;

  // Create a custom panel with a button
  const pageCounterPanel = document.createElement("div");
  const pageCounterButton = document.createElement("button");
  pageCounterButton.innerHTML = "Pages: 0";
  pageCounterPanel.appendChild(pageCounterButton);

  // Add a new panel section for the page counter
  panels.addPanel({
    id: "page-counter-panel",
    el: pageCounterPanel,
    visible: true,
    buttons: [],
  });

  // Create a custom command to update the page counter
  command.add("update-page-counter", {
    run: function (editor) {
      const wrapper = editor.getWrapper();
      const componentCount = wrapper.components().models.filter((c: any) => c.is("page")).length;
      const label = `Pages: ${componentCount}`;
      pageCounterButton.innerHTML = label;
    },
  });

  // Update the page counter whenever a new page is added or removed
  editor.on("component:add", (component: any) => {
    if (component.is("page")) {
      editor.runCommand("update-page-counter");
    }
  });

  editor.on("component:remove", (component: any) => {
    if (component.is("page")) {
      editor.runCommand("update-page-counter");
    }
  });
}
