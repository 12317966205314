import config from "../../../config";
import axios from "axios";

export interface IWallet {
  id: string;
  balance: number;
  createdAt: string;
  updatedAt: string;
}

export async function getUserWallet(accessToken: string): Promise<IWallet> {
  const url = new URL(`/billing/wallet`, config.api.billing_endpoint);
  const response = await axios.get(url.href, {
    withCredentials: false,
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
