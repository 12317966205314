export const depthToSize = (value: number) => {
  const sizeOrg = (Math.pow(2, value) * 4096).toString().split("");
  if (sizeOrg.length >= 13) {
    const length = sizeOrg.length - 12;
    let indexCount = 0;
    let gbString = "";
    for (let i = 0; i < length; i++) {
      gbString = gbString.concat(sizeOrg[indexCount]);
      indexCount++;
    }
    gbString = gbString.concat(`.${sizeOrg[indexCount]}${sizeOrg[indexCount + 1]}${sizeOrg[indexCount + 2]}`);

    const gb = +gbString;
    return `${gb.toFixed(2)} TB`;
  } else {
    const length = sizeOrg.length - 9;
    let indexCount = 0;
    let tbString = "";
    for (let i = 0; i < length; i++) {
      tbString = tbString.concat(sizeOrg[indexCount]);
      indexCount++;
    }
    tbString = tbString.concat(`.${sizeOrg[indexCount]}${sizeOrg[indexCount + 1]}${sizeOrg[indexCount + 2]}`);
    const tb = +tbString;
    return `${tb.toFixed(2)} GB`;
  }
};
