import { createSlice } from "@reduxjs/toolkit";
import { IStamp } from "../interface/create-pod.interface";

export interface IPod {
  pod: {
    ID: string;
    Name: string;
    PrimaryProvider: string;
    Providers: string[];
    PublicAccessible: boolean;
    Type: string;
    Address: string;
    Owner: string;
    CreatedAt: string;
    UpdatedAt: string;
    BzzStamp: {
      DefaultIndex: number;
      Stamps: { value: IStamp; label: string }[];
    } | null;
  };
}

const initialState: IPod = {
  pod: {
    ID: "",
    Name: "",
    PrimaryProvider: "",
    Providers: [],
    PublicAccessible: false,
    Type: "",
    Address: "",
    Owner: "",
    CreatedAt: "",
    UpdatedAt: "",
    BzzStamp: null,
  },
};

const podInfoSlice = createSlice({
  name: "podInfo",
  initialState,
  reducers: {
    currentPod(state: IPod, action) {
      state.pod = {
        ID: action.payload._id,
        Name: action.payload.name,
        PrimaryProvider: action.payload.primaryProvider,
        Providers: action.payload.providers,
        PublicAccessible: action.payload.publicAccessible,
        Type: action.payload.type,
        Address: action.payload.address,
        Owner: action.payload.owner,
        CreatedAt: action.payload.createdAt,
        UpdatedAt: action.payload.updatedAt,
        BzzStamp: action.payload.bzzStamp,
      };
    },
  },
});

export const podActions = podInfoSlice.actions;
export default podInfoSlice;
