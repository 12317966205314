import NavLargeTitleLinkList from "./NavContent/NavImg/NavLargeTitleLinkList.png";
import NavListOverflow from "./NavContent/NavImg/NavListOverflow.png";
import NavLogoLinksInlineCollapse from "./NavContent/NavImg/NavLogoLinksInlineCollapse.png";
import NavLogoTitlesLinksCentered from "./NavContent/NavImg/NavLogoTitlesLinksCentered.png";
import NavMinimalSignUp from "./NavContent/NavImg/NavMinimalSignUp.png";
import NavTitleLinkList from "./NavContent/NavImg/NavTitleLinkList.png";

const Nav = [
  {
    id: "Footers-large-title-link-list",
    category: "Nav",
    label: "<h3>Large Title Link List</h3>",
    media: `<img src="${NavLargeTitleLinkList}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .db {
                    display: block;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .b {
                    font-weight: bold;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .black {
                    color: #000;
                }
                
                .gray {
                    color: #777;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pb3 {
                    padding-bottom: 1rem;
                }
                
                .mr3 {
                    margin-right: 1rem;
                }
                
                .mb3 {
                    margin-bottom: 1rem;
                }
                
                .tc {
                    text-align: center;
                }
                
                .f1 {
                    font-size: 3rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) {
                    .pa4-ns {
                        padding: 2rem;
                    }
                
                    .mb4-ns {
                        margin-bottom: 2rem;
                    }
                
                    .f-headline-ns {
                        font-size: 6rem;
                    }
                
                    .f5-ns {
                        font-size: 1rem;
                    }
                }
                </style>
                <nav class="pa3 pa4-ns">
                <a class="link dim black b f1 f-headline-ns tc db mb3 mb4-ns" href="#" title="Home">Site Name</a>
                <div class="tc pb3">
                    <a class="link dim gray f6 f5-ns dib mr3" href="#" title="Home">Home</a>
                    <a class="link dim gray f6 f5-ns dib mr3" href="#" title="About">About</a>
                    <a class="link dim gray f6 f5-ns dib mr3" href="#" title="Store">Store</a>
                    <a class="link dim gray f6 f5-ns dib" href="#" title="Contact">Contact</a>
                </div>
                </nav>
            `,
  },
  {
    id: "Footers-list-overflow",
    category: "Nav",
    label: "<h3>List Overflow</h3>",
    media: `<img src="${NavListOverflow}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .dib {
                    display: inline-block;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .overflow-x-auto {
                    overflow-x: auto;
                }
                
                .gray {
                    color: #777;
                }
                
                .pv4 {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }
                
                .ph3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                .mr3 {
                    margin-right: 1rem;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .nowrap {
                    white-space: nowrap;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) {
                    .ph5-ns {
                        padding-left: 4rem;
                        padding-right: 4rem;
                    }
                
                    .f4-ns {
                        font-size: 1.25rem;
                    }
                }
                            
                </style>
                <nav class="ph3 ph5-ns pv4">
                <div class="nowrap overflow-x-auto">
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 1">Link 1</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 2">Link 2</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 3">Link 3</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 4">Link 4</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 5">Link 5</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 6">Link 6</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 7">Link 7</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 8">Link 8</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 9">Link 9</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 10">Link 10</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 11">Link 11</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 12">Link 12</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 13">Link 13</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 14">Link 14</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 15">Link 15</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 16">Link 16</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 17">Link 17</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 18">Link 18</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 19">Link 19</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 20">Link 20</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 21">Link 21</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 22">Link 22</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 23">Link 23</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 24">Link 24</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 25">Link 25</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 26">Link 26</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 27">Link 27</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 28">Link 28</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 29">Link 29</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 30">Link 30</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 31">Link 31</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 32">Link 32</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 33">Link 33</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 34">Link 34</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 35">Link 35</a>
                    <a class="link dim gray f5 f4-ns dib mr3" href="#" title="Link 36">Link 36</a>
                </div>
                </nav>
            `,
  },
  {
    id: "NavLogo-links-inline-collapse",
    category: "Nav",
    label: "<h3>Logo Links Inline Collapse</h3>",
    media: `<img src="${NavLogoLinksInlineCollapse}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .border-box {
                    box-sizing: border-box;
                }
                
                
                .db {
                    display: block;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .h2 {
                    height: 2rem;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .w2 {
                    width: 2rem;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .dark-gray {
                    color: #4d4d4f;
                }
                
                .mid-gray {
                    color: #555;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .mr3 {
                    margin-right: 1rem;
                }
                
                .mb2 {
                    margin-bottom: .5rem;
                }
                
                .tc {
                    text-align: center;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .v-mid {
                    vertical-align: middle;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 60em) {
                    .dt-l {
                        display: table;
                    }
                
                    .dtc-l {
                        display: table-cell;
                    }
                
                    .w-25-l {
                        width: 25%;
                    }
                
                    .w-75-l {
                        width: 75%;
                    }
                
                    .ph5-l {
                        padding-left: 4rem;
                        padding-right: 4rem;
                    }
                
                    .mr4-l {
                        margin-right: 2rem;
                    }
                
                    .mb0-l {
                        margin-bottom: 0;
                    }
                
                    .tl-l {
                        text-align: left;
                    }
                
                    .tr-l {
                        text-align: right;
                    }
                
                    .f5-l {
                        font-size: 1rem;
                    }
                }                
                </style>
                <nav class="db dt-l w-100 border-box pa3 ph5-l">
                <a class="db dtc-l v-mid mid-gray link dim w-100 w-25-l tc tl-l mb2 mb0-l" href="#" title="Home">
                    <img src="http://tachyons.io/img/logo.jpg" class="dib w2 h2 br-100" alt="Site Name">
                </a>
                <div class="db dtc-l v-mid w-100 w-75-l tc tr-l">
                    <a class="link dim dark-gray f6 f5-l dib mr3 mr4-l" href="#" title="Home">Home</a>
                    <a class="link dim dark-gray f6 f5-l dib mr3 mr4-l" href="#" title="How it Works">How it Works</a>
                    <a class="link dim dark-gray f6 f5-l dib mr3 mr4-l" href="#" title="Blog">Blog</a>
                    <a class="link dim dark-gray f6 f5-l dib mr3 mr4-l" href="#" title="Press">Press</a>
                    <a class="link dim dark-gray f6 f5-l dib" href="#" title="Contact">Contact</a>
                </div>
                </nav>
            `,
  },
  {
    id: "NavLogo-titles-links-centered",
    category: "Nav",
    label: "<h3>Titles Links Centered</h3>",
    media: `<img src="${NavLogoTitlesLinksCentered}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bt {
                    border-top-style: solid;
                    border-top-width: 1px;
                }
                
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--black {
                    border-color: #000;
                }
                
                .br-100 {
                    border-radius: 100%;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .avenir {
                    font-family: 'avenir next', avenir, sans-serif;
                }
                
                .baskerville {
                    font-family: baskerville, serif;
                }
                
                .i {
                    font-style: italic;
                }
                
                .fw1 {
                    font-weight: 100;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .h2 {
                    height: 2rem;
                }
                
                .tracked {
                    letter-spacing: .1em;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .mw7 {
                    max-width: 48rem;
                }
                
                .w2 {
                    width: 2rem;
                }
                
                .black-80 {
                    color: rgba(0, 0, 0, .8);
                }
                
                .white {
                    color: #fff;
                }
                
                .bg-black-80 {
                    background-color: rgba(0, 0, 0, .8);
                }
                
                .bg-white {
                    background-color: #fff;
                }
                
                .hover-bg-light-yellow:hover {
                    background-color: #fbf1a9;
                }
                
                .hover-bg-light-yellow:focus {
                    background-color: #fbf1a9;
                }
                
                .hover-bg-light-pink:hover {
                    background-color: #ffa3d7;
                }
                
                .hover-bg-light-pink:focus {
                    background-color: #ffa3d7;
                }
                
                .hover-bg-light-green:hover {
                    background-color: #9eebcf;
                }
                
                .hover-bg-light-green:focus {
                    background-color: #9eebcf;
                }
                
                .hover-bg-light-blue:hover {
                    background-color: #76c4e2;
                }
                
                .hover-bg-light-blue:focus {
                    background-color: #76c4e2;
                }
                
                .hover-bg-lightest-blue:hover {
                    background-color: #cdecff;
                }
                
                .hover-bg-lightest-blue:focus {
                    background-color: #cdecff;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pv4 {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }
                
                .mb0 {
                    margin-bottom: 0;
                }
                
                .mt2 {
                    margin-top: .5rem;
                }
                
                .mt4 {
                    margin-top: 2rem;
                }
                
                .tc {
                    text-align: center;
                }
                
                .ttu {
                    text-transform: uppercase;
                }
                
                .f1 {
                    font-size: 3rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                .bg-animate, .bg-animate:hover, .bg-animate:focus {
                    transition: background-color .15s ease-in-out;
                }
                
                @media screen and (min-width: 60em) {
                    .ph4-l {
                        padding-left: 2rem;
                        padding-right: 2rem;
                    }
                
                    .f5-l {
                        font-size: 1rem;
                    }
                }       
                </style>
                <header class="bg-white black-80 tc pv4 avenir">
                <a href="" class="bg-black-80 ba b--black dib pa3 w2 h2 br-100">
                    <svg class="white" data-icon="skull" viewBox="0 0 32 32" style="fill:currentcolor"><title>skull icon</title><path d="M16 0 C6 0 2 4 2 14 L2 22 L6 24 L6 30 L26 30 L26 24 L30 22 L30 14 C30 4 26 0 16 0 M9 12 A4.5 4.5 0 0 1 9 21 A4.5 4.5 0 0 1 9 12 M23 12 A4.5 4.5 0 0 1 23 21 A4.5 4.5 0 0 1 23 12"></path></svg>
                </a>
                <h1 class="mt2 mb0 baskerville i fw1 f1">Title</h1>
                <h2 class="mt2 mb0 f6 fw4 ttu tracked">Your amazing subtitle</h2>
                <nav class="bt bb tc mw7 center mt4">
                    <a class="f6 f5-l link bg-animate black-80 hover-bg-lightest-blue dib pa3 ph4-l" href="/">Home</a>
                    <a class="f6 f5-l link bg-animate black-80 hover-bg-light-green dib pa3 ph4-l" href="/portfolio">Portfolio</a>
                    <a class="f6 f5-l link bg-animate black-80 hover-bg-light-blue dib pa3 ph4-l" href="/shop">Shop</a>
                    <a class="f6 f5-l link bg-animate black-80 hover-bg-light-pink dib pa3 ph4-l" href="/about">About</a>
                    <a class="f6 f5-l link bg-animate black-80 hover-bg-light-yellow dib pa3 ph4-l" href="/contact">Contact</a>
                </nav>
                </header>
            `,
  },
  {
    id: "Nav-minimal-signUp",
    category: "Nav",
    label: "<h3>Minimal SignUp</h3>",
    media: `<img src="${NavMinimalSignUp}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--white-20 {
                    border-color: rgba(255, 255, 255, .2);
                }
                
                .b--white-10 {
                    border-color: rgba(255, 255, 255, .1);
                }
                
                .br-pill {
                    border-radius: 9999px;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .flex {
                    display: flex;
                }
                
                .items-center {
                    align-items: center;
                }
                
                .justify-between {
                    justify-content: space-between;
                }
                
                .h1 {
                    height: 1rem;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .w1 {
                    width: 1rem;
                }
                
                .white-70 {
                    color: rgba(255, 255, 255, .7);
                }
                
                .white {
                    color: #fff;
                }
                
                .hover-black:hover {
                    color: #000;
                }
                
                .hover-black:focus {
                    color: #000;
                }
                
                .hover-white:hover {
                    color: #fff;
                }
                
                .hover-white:focus {
                    color: #fff;
                }
                
                .hover-bg-white:hover {
                    background-color: #fff;
                }
                
                .hover-bg-white:focus {
                    background-color: #fff;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pv2 {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }
                
                .ph4 {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
                
                .mr3 {
                    margin-right: 1rem;
                }
                
                .no-underline {
                    text-decoration: none;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                .bg-animate, .bg-animate:hover, .bg-animate:focus {
                    transition: background-color .15s ease-in-out;
                }
                
                @media screen and (min-width: 30em) {
                    .mr4-ns {
                        margin-right: 2rem;
                    }
                }
                
                </style>
                <nav class="flex justify-between bb b--white-10">
                <a class="link white-70 hover-white no-underline flex items-center pa3" href="" style>
                    <svg
                    class="dib h1 w1"
                    data-icon="grid"
                    viewBox="0 0 32 32"
                    style="fill:currentcolor">
                    <title>Super Normal Icon Mark</title>
                    <path d="M2 2 L10 2 L10 10 L2 10z M12 2 L20 2 L20 10 L12 10z M22 2 L30 2 L30 10 L22 10z M2 12 L10 12 L10 20 L2 20z M12 12 L20 12 L20 20 L12 20z M22 12 L30 12 L30 20 L22 20z M2 22 L10 22 L10 30 L2 30z M12 22 L20 22 L20 30 L12 30z M22 22 L30 22 L30 30 L22 30z">
                    </path>
                    </svg>
                </a>
                <div class="flex-grow pa3 flex items-center">
                    <a class="f6 link dib white dim mr3 mr4-ns" href="#0">About</a>
                    <a class="f6 link dib white dim mr3 mr4-ns" href="#0">Sign In</a>
                    <a class="f6 dib white bg-animate hover-bg-white hover-black no-underline pv2 ph4 br-pill ba b--white-20" href="#0">Sign Up</a>
                </div>
                </nav>
                
            `,
  },
  {
    id: "Nav-title-link-list",
    category: "Nav",
    label: "<h3>Title Link List</h3>",
    media: `<img src="${NavTitleLinkList}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .dib {
                    display: inline-block;
                }
                
                .b {
                    font-weight: bold;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .black {
                    color: #000;
                }
                
                .gray {
                    color: #777;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .mr3 {
                    margin-right: 1rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) {
                    .pa4-ns {
                        padding: 2rem;
                    }
                
                    .f5-ns {
                        font-size: 1rem;
                    }
                }
                </style>
                <nav class="pa3 pa4-ns">
                <a class="link dim black b f6 f5-ns dib mr3" href="#" title="Home">Site Name</a>
                <a class="link dim gray    f6 f5-ns dib mr3" href="#" title="Home">Home</a>
                <a class="link dim gray    f6 f5-ns dib mr3" href="#" title="About">About</a>
                <a class="link dim gray    f6 f5-ns dib mr3" href="#" title="Store">Store</a>
                <a class="link dim gray    f6 f5-ns dib" href="#" title="Contact">Contact</a>
                </nav>

                
            `,
  },
];

export default Nav;
