import CollectionsAlbums from "./CollectionsContent/CollectionsImg/CollectionsAlbums.png";
// import CollectionsMovies from "./CollectionsContent/CollectionsImg/CollectionsMovies.png";
import CollectionsPosters from "./CollectionsContent/CollectionsImg/CollectionsPosters.png";
import CollectionsSquareTitleSubtitle from "./CollectionsContent/CollectionsImg/CollectionsSquareTitleSubtitle.png";
import CollectionsVinyl from "./CollectionsContent/CollectionsImg/CollectionsVinyl.png";

const Collections = [
  {
    id: "Collections-albums",
    category: "Collections",
    label: "<h3>Albums</h3>",
    media: `<img src="${CollectionsAlbums}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .outline {
                    outline: 1px solid;
                }
                
                .cf:before, .cf:after {
                    content: " ";
                    display: table;
                }
                
                .cf:after {
                    clear: both;
                }
                
                .cf {
                    *zoom: 1;
                }
                
                .db {
                    display: block;
                }
                
                .fl {
                    float: left;
                    _display: inline;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .w-50 {
                    width: 50%;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .black-10 {
                    color: rgba(0, 0, 0, .1);
                }
                
                .black {
                    color: #000;
                }
                
                .gray {
                    color: #777;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .ml0 {
                    margin-left: 0;
                }
                
                .mt2 {
                    margin-top: .5rem;
                }
                
                .mv0 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                .tc {
                    text-align: center;
                }
                
                .ttu {
                    text-transform: uppercase;
                }
                
                .f3 {
                    font-size: 1.5rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .truncate {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .clip {
                    position: fixed !important;
                    _position: absolute !important;
                    clip: rect(1px 1px 1px 1px);
                    clip: rect(1px, 1px, 1px, 1px);
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) and (max-width: 60em) {
                    .w-25-m {
                        width: 25%;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .w-20-l {
                        width: 20%;
                    }
                }
                </style>
                <article>
                <h2 class="f3 fw4 pa3 mv0">Albums</h2>
                <div class="cf pa2">
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/blonde/id1146195596?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is4.mzstatic.com/image/thumb/Music62/v4/93/8f/75/938f7536-0188-f9ba-4585-0a77ceaebf0a/source/400x40000bb.png" alt="Frank Ocean Blonde Album Cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">Blonde</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Frank Ocean</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/jeffery/id1146718343?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is1.mzstatic.com/image/thumb/Music71/v4/c8/2d/b1/c82db1cd-9dc5-d7cb-2a34-735cf47bb809/source/400x40000bb.png" alt="Young Thug - Jeffery Album Cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100 ttu">Jeffery</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Young Thug</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/untitled-unmastered./id1089846273?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is5.mzstatic.com/image/thumb/Music49/v4/1b/36/43/1b3643c6-e6a3-41bc-7f6d-7c2b64b5d60b/source/400x40000bb.png" alt="Kendrick Lamar untitled unmastered. Album Cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">untitled umastered.</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Kendrick Lamar</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/99.9/id1092026376?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is4.mzstatic.com/image/thumb/Music49/v4/e9/4c/2d/e94c2d5f-bdb0-c565-4cc2-f9dfcf7f0b87/source/400x40000bb.png" alt="Kaytranada 99%" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">99%</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Kaytranada</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/aa/id1067924003?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is3.mzstatic.com/image/thumb/Music49/v4/b6/b0/a1/b6b0a1dd-998d-9786-ca2f-87470be15250/source/400x40000bb.png" alt="Baauer Aa - Album Cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">Aa</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Baauer</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/99-cents/id1054734475?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is3.mzstatic.com/image/thumb/Music62/v4/fa/ae/a6/faaea65f-0819-bb5d-afaa-4f5e84015204/source/400x40000bb.png" alt="Santigold 99 cents - Album cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">99 cents</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Santigold</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/a-moon-shaped-pool/id1111577743?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is2.mzstatic.com/image/thumb/Music18/v4/34/03/34/34033451-12e2-2d0b-c100-11a390922a01/source/400x40000bb.png" alt="A Moon Shaped Pool - Album cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">A Moon Shaped Pool</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Radiohead</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/the-life-of-pablo/id1123231885?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is3.mzstatic.com/image/thumb/Music20/v4/c0/98/d0/c098d05b-7bcc-0ea3-0213-0f69992fda65/source/400x40000bb.png" alt="The Life of Pablo - Album Cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">The Life of Pablo</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Kanye West</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/coloring-book/id1113239004?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is2.mzstatic.com/image/thumb/Music18/v4/5a/42/0f/5a420f73-6490-abc9-bdcc-3001d5c4e9fc/source/400x40000bb.png" alt="Coloring Book - Album cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">Coloring Book</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Chance the Rapper</dd>
                        </dl>
                    </a>
                    </div>
                    <div class="fl w-50 w-25-m w-20-l pa2">
                    <a href="https://geo.itunes.apple.com/us/album/everybody-looking/id1135576036?at=1l3vqFJ&mt=1&app=music" class="db link dim tc">
                        <img src="http://is4.mzstatic.com/image/thumb/Music30/v4/9b/2e/e1/9b2ee13e-35fd-0cc1-d203-e65b4beafc7f/source/400x40000bb.png" alt="Everybody Looking - Album Cover" class="w-100 db outline black-10"/>
                        <dl class="mt2 f6 lh-copy">
                        <dt class="clip">Title</dt>
                        <dd class="ml0 black truncate w-100">Everybody Looking</dd>
                        <dt class="clip">Artist</dt>
                        <dd class="ml0 gray truncate w-100">Gucci Mane</dd>
                        </dl>
                    </a>
                    </div>
                </div>
                </article>
            `,
  },

  {
    id: "Collections-posters",
    category: "Collections",
    label: "<h3>Posters</h3>",
    media: `<img src="${CollectionsPosters}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .cf:before, .cf:after {
                    content: " ";
                    display: table;
                }
                
                .cf:after {
                    clear: both;
                }
                
                .cf {
                    *zoom: 1;
                }
                
                .db {
                    display: block;
                }
                
                .fl {
                    float: left;
                    _display: inline;
                }
                
                .w-50 {
                    width: 50%;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .black {
                    color: #000;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .pv2 {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }
                
                .ph2 {
                    padding-left: .5rem;
                    padding-right: .5rem;
                }
                
                .no-underline {
                    text-decoration: none;
                }
                
                .grow {
                    -moz-osx-font-smoothing: grayscale;
                    backface-visibility: hidden;
                    transform: translateZ(0);
                    transition: transform .25s ease-out;
                }
                
                .grow:hover, .grow:focus {
                    transform: scale(1.05);
                }
                
                .grow:active {
                    transform: scale(.9);
                }
                
                @media screen and (min-width: 30em) {
                    .w-25-ns {
                        width: 25%;
                    }
                
                    .w-50-ns {
                        width: 50%;
                    }
                }
                </style>
                <main class="cf pa2">
                <div class="fl w-100 w-50-ns ph2">
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/elevaters030211_960.jpg"></a>
                <a href="" class="no-underline pv2 grow db"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/elevaters.jpg"></a>
                <a href="#" class="no-underline pv2 grow db"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/pink-and-noseworthy-22.12.10-cargo_960.jpg"/></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/hw090911_960.jpg"></a>
                </div>
                <div class="fl w-50 w-25-ns ph2">
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/adam-stern-031209_960-2.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/hw-080411-cargo_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/hw18-240112-cc_960.jpg"> </a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/hw-residency-cargo_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/orchid-2-mnkr_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/O270711_960-2.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/adam-stern-020510_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/adam-stern-130610_960.jpg"></a>
                </div>
                <div class="fl w-50 w-25-ns ph2">
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/zach-hurd-101218_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/zh170311.4.cargo_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/hwspringtour-cargo_960-1.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/cc-shanee_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/ZachHurd-190111s_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/hw170211pie-cargo_960.jpg"></a>
                <a href="" class="pv2 grow db no-underline black"><img class="db w-100" src="https://s3-us-west-2.amazonaws.com/prnt/adam-stern-191110_960.jpg"></a>
                </div>
            </main>

            `,
  },
  {
    id: "Collections-square-title-subtitle",
    category: "Collections",
    label: "<h3>Square Title Subtitle</h3>",
    media: `<img src="${CollectionsSquareTitleSubtitle}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .aspect-ratio {
                    height: 0;
                    position: relative;
                }
                
                .aspect-ratio--1x1 {
                    padding-bottom: 100%;
                }
                
                .aspect-ratio--object {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                }
                
                .cover {
                    background-size: cover !important;
                }
                
                .bg-center {
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                
                .cf:before, .cf:after {
                    content: " ";
                    display: table;
                }
                
                .cf:after {
                    clear: both;
                }
                
                .cf {
                    *zoom: 1;
                }
                
                .db {
                    display: block;
                }
                
                .fl {
                    float: left;
                    _display: inline;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .black-90 {
                    color: rgba(0, 0, 0, .9);
                }
                
                .black-60 {
                    color: rgba(0, 0, 0, .6);
                }
                
                .pb3 {
                    padding-bottom: 1rem;
                }
                
                .ph2 {
                    padding-left: .5rem;
                    padding-right: .5rem;
                }
                
                .mb0 {
                    margin-bottom: 0;
                }
                
                .mt2 {
                    margin-top: .5rem;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                @media screen and (min-width: 30em) {
                    .w-25-ns {
                        width: 25%;
                    }
                
                    .pa2-ns {
                        padding: .5rem;
                    }
                
                    .ph0-ns {
                        padding-left: 0;
                        padding-right: 0;
                    }
                
                    .f4-ns {
                        font-size: 1.25rem;
                    }
                }
                
                @media screen and (min-width: 30em) and (max-width: 60em) {
                    .w-50-m {
                        width: 50%;
                    }
                }
                
                </style>
                <section class="cf w-100 pa2-ns">
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0006.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0002.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0003.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0004.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0007.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0008.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0009.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0010.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0011.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0012.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0013.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0014.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0015.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0016.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0017.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0018.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0019.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0020.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0021.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                <article class="fl w-100 w-50-m  w-25-ns pa2-ns">
                    <div class="aspect-ratio aspect-ratio--1x1">
                    <img style="background-image:url(http://mrmrs.github.io/images/0022.jpg);" 
                    class="db bg-center cover aspect-ratio--object" />
                    </div>
                    <a href="#0" class="ph2 ph0-ns pb3 link db">
                    <h3 class="f5 f4-ns mb0 black-90">Title of piece</h3>
                    <h3 class="f6 f5 fw4 mt2 black-60">Subtitle of piece</h3>
                    </a>
                </article>
                </section>
            `,
  },
  {
    id: "Collections-vinyl",
    category: "Collections",
    label: "<h3>Vinyl</h3>",
    media: `<img src="${CollectionsVinyl}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .aspect-ratio {
                    height: 0;
                    position: relative;
                }
                
                .aspect-ratio--1x1 {
                    padding-bottom: 100%;
                }
                
                .aspect-ratio--object {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                }
                
                .cover {
                    background-size: cover !important;
                }
                
                .bg-center {
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                
                .cf:before, .cf:after {
                    content: " ";
                    display: table;
                }
                
                .cf:after {
                    clear: both;
                }
                
                .cf {
                    *zoom: 1;
                }
                
                .db {
                    display: block;
                }
                
                .fl {
                    float: left;
                    _display: inline;
                }
                
                .w-50 {
                    width: 50%;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) {
                    .w-25-ns {
                        width: 25%;
                    }
                }
                
                @media screen and (min-width: 30em) and (max-width: 60em) {
                    .w-50-m {
                        width: 50%;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .w-25-l {
                        width: 25%;
                    }
                
                    .w-50-l {
                        width: 50%;
                    }
                }
                </style>
                <article class="cf">
                <div class="fl w-50 w-25-ns">
                    <a href="https://www.amazon.com/BEYONCÉ-Beyonce/dp/B00KCOMBJC/ref=sr_1_2_twi_lp__3?s=music&ie=UTF8&qid=1480422067&sr=1-2&keywords=beyonce&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Beyoncé" style="background-image:url(http://mrmrs.github.io/photos/beyonce.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-50 w-25-ns">
                    <a href="https://www.amazon.com/99-9-KAYTRANADA/dp/B01D9DBNX2/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480422105&sr=1-1&keywords=kaytranada+vinyl&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Kaytranada" style="background-image:url(http://mrmrs.github.io/photos/kaytranada.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-50 w-25-ns">
                    <a href="https://www.amazon.com/Woman-2LP-Set-Full-Album/dp/B01LX3E0ET/ref=sr_1_1?s=music&ie=UTF8&qid=1480422119&sr=1-1&keywords=justice&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Woman - Justice" style="background-image:url(http://mrmrs.github.io/photos/justice.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-50 w-25-ns">
                    <a href="https://www.amazon.com/Skin-Flume/dp/B01DD5N35W/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480422133&sr=1-1&keywords=flume&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Skin - Flume" style="background-image:url(http://mrmrs.github.io/photos/flume.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-50">
                    <a href="https://www.amazon.com/Seat-at-Table-Solange/dp/B01LXP7I5N/ref=sr_tnr_p_1_195429011_1_twi_lp__3?s=music&ie=UTF8&qid=1480422087&sr=1-1&keywords=solange+seat+at+the+table&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Seat at Table Solange" style="background-image:url(http://mrmrs.github.io/photos/solange.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-50 w-25-ns">
                    <a href="https://www.amazon.com/untitled-unmastered-LP-Kendrick-Lamar/dp/B01DET9BV2/ref=sr_1_3_twi_lp__3?s=music&ie=UTF8&qid=1480370912&sr=1-3&keywords=kendrick+lamar&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Untitled Unmastered - Kendrick Lamar" style="background-image:url(http://mrmrs.github.io/photos/untitledunmastered.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-100 w-25-ns">
                    <a href="https://www.amazon.com/Moon-Shaped-Pool-2-LP-Download/dp/B01FDF12UI/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480370971&sr=1-1&keywords=moon+shaped+pool&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Moon Shaped Pool 2" style="background-image:url(http://mrmrs.github.io/photos/moonshapedpool.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-50 w-25-ns">
                    <a href="https://www.amazon.com/Colour-Anything-2-LP/dp/B01F8674B8/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480371036&sr=1-1&keywords=color+in+anything&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Colour Anything 2" style="background-image:url(http://mrmrs.github.io/photos/colouranything.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-50 w-25-ns">
                    <a href="https://www.amazon.com/Good-Luck-Do-Your-Best/dp/B01C3IHINI/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480371123&sr=1-1&keywords=good+luck+gold+panda&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Good Luck Do Your Best" style="background-image:url(http://mrmrs.github.io/photos/goldpanda.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-100 w-50-m w-25-ns">
                    <a href="https://www.amazon.com/32-Levels-Clams-Casino/dp/B01GU83I4K/ref=sr_1_2_twi_lp__1?s=music&ie=UTF8&qid=1480371183&sr=1-2&keywords=clams+casino&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="32 Levels Clams Casino" style="background-image:url(http://mrmrs.github.io/photos/clamscasino.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                    <a href="https://www.amazon.com/Danny-Brown-Atrocity-Exhibition-Exclusive/dp/B01M9F0LSQ/ref=sr_1_2?ie=UTF8&qid=1480421198&sr=8-2&keywords=danny+brown+vinyl&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Danny Brown Atrocity Exhibition Exclusive" style="background-image:url(http://mrmrs.github.io/photos/dannybrown.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                <div class="fl w-100 w-50-m w-25-l">
                    <div class="fl w-100">
                    <a href="https://www.amazon.com/Human-Energy-MACHINEDRUM/dp/B01HC7UTBI/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480371226&sr=1-1&keywords=human+energy&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                        <img role="img" aria-label="Human Energy - Machinedrum" style="background-image:url(http://mrmrs.github.io/photos/humanenergy.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                    </div>
                    <div class="fl w-100">
                    <div class="fl w-50">
                        <a href="https://www.amazon.com/Moodymann-DJ-Kicks-DJ-KICKS/dp/B01AEOM6D0/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480371894&sr=1-1&keywords=dj+kicks+moodymann&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                        <img role="img" aria-label="Moodymann - DJ Kicks" style="background-image:url(http://mrmrs.github.io/photos/moodyman.jpg);" class="bg-center cover aspect-ratio--object"></img>
                        </a>
                    </div>
                    <div class="fl w-50">
                        <a href="https://www.amazon.com/Stranger-Things-Netflix-Original-Soundtrack/dp/B01KA4MVF2/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480423240&sr=1-1&keywords=stranger+things&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                        <img role="img" aria-label="Stranger Things Netflix Original Soundtrack" style="background-image:url(http://mrmrs.github.io/photos/strangerthings.jpg);" class="bg-center cover aspect-ratio--object"></img>
                        </a>
                    </div>
                    <div class="fl w-50">
                        <a href="https://www.amazon.com/How-Be-Human-Being-LP/dp/B01GQ7DIJA/ref=tmm_vnl_swatch_0?_encoding=UTF8&qid=1480421224&sr=8-1&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                        <img role="img" aria-label="How Be Human Being LP" style="background-image:url(http://mrmrs.github.io/photos/glassanimals.jpg);" class="bg-center cover aspect-ratio--object"></img>
                        </a>
                    </div>
                    <div class="fl w-50">
                        <a href="https://www.amazon.com/22-Million-Bon-Iver/dp/B01KBKVK2K/ref=sr_tnr_p_6_195212011_1_twi_lp__3?s=music&ie=UTF8&qid=1480422776&sr=1-6&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                        <img role="img" aria-label="22 Million - Bon Iver" style="background-image:url(http://mrmrs.github.io/photos/boniver.jpg);" class="bg-center cover aspect-ratio--object"></img>
                        </a>
                    </div>
                    </div>
                </div>
                <div class="fl w-100 w-50-l">
                    <a href="https://www.amazon.com/Malibu-Anderson-Paak/dp/B01BXNXBAS/ref=sr_1_1_twi_lp__3?s=music&ie=UTF8&qid=1480422041&sr=1-1&keywords=paak&tag=mrmrs01-20" class="db aspect-ratio aspect-ratio--1x1 dim">
                    <img role="img" aria-label="Malibu -  Anderson Paak" style="background-image:url(http://mrmrs.github.io/photos/paak.jpg);" class="bg-center cover aspect-ratio--object"></img>
                    </a>
                </div>
                </article>

            `,
  },
];


export default Collections;
