import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { getTransactionRecords, ITransactionRecord } from "../api/billing/transaction/getTransactionRecords";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import config from "../config";
import useWindowDimensions from "../Hook/useWindowDimensions";

const MonthBilling = () => {
  const [transactionRecords, setTransactionRecords] = useState<null | ITransactionRecord[]>(null);
  const accessToken: string = useSelector(authActions.login).payload.auth.accessToken;
  const { width, height } = useWindowDimensions();
  const [defaultRecordsNumber, setDefaultRecordsNumber] = useState<number>(height >= 1540 ? 15 : 10);
  const getTransaction = async (_accessToken: string) => {
    try {
      const _transactionRecords = await getTransactionRecords(_accessToken);
      setTransactionRecords(_transactionRecords);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    height > 1300 ? setDefaultRecordsNumber(15) : setDefaultRecordsNumber(10);
    if (transactionRecords && currentPageIndex > Math.ceil(transactionRecords.length / defaultRecordsNumber)) {
      setCurrentPageIndex(Math.ceil(transactionRecords.length / defaultRecordsNumber) - 1);
    }
  }, [height]);

  //Prevent overflow bug from Stamp.tsx
  useEffect(() => {
    const contentCon = document.querySelector(".content-con") as HTMLDivElement;
    contentCon.style.overflow = "auto";
  }, []);

  useEffect(() => {
    getTransaction(accessToken);
  }, [accessToken]);

  const convertDateTime = (datetime: string) => {
    const date = new Date(datetime);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    if (minute < 10) {
      return `${hour}:0${minute} ${day} ${month}, ${year}`;
    }
    return `${hour}:${minute} ${day} ${month}, ${year}`;
  };

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);

  // useEffect(() => {
  //   if (transactionRecords) {
  //     console.log(currentPageIndex, Math.ceil(transactionRecords.length / 10), defaultRecordsNumber);
  //   }
  // }, [transactionRecords, currentPageIndex]);

  const render_page_index = () => {
    const page_index = [];
    if (transactionRecords) {
      const totalPages = Math.ceil(transactionRecords.length / defaultRecordsNumber) + 1;
      for (
        let i = currentPageIndex >= 3 ? (currentPageIndex > totalPages - 3 ? (currentPageIndex === totalPages - 1 ? -4 : -3) : -2) : currentPageIndex === 1 ? 0 : -1;
        i < (currentPageIndex >= 3 ? (currentPageIndex > totalPages - 3 ? (currentPageIndex === totalPages - 1 ? 1 : 2) : 3) : currentPageIndex === 1 ? 5 : 4);
        i++
      ) {
        console.log(i);
        // if(currentPageIndex+i <= totalPages){

        page_index.push(
          <div
            className={`${
              currentPageIndex + i === currentPageIndex ? "text-gray-700 underline dark:text-white" : "text-black/20 dark:text-white/20"
            } flex h-6 w-[3rem] cursor-pointer items-center justify-center text-center select-none`}
            onClick={() => setCurrentPageIndex(currentPageIndex + i)}
          >
            {currentPageIndex + i}
          </div>,
        );
        // }
      }
    }
    return page_index;
  };

  return (
    <div className="mt-6 ml-[-1.5rem] flex h-fit w-screen flex-col gap-5 overflow-x-auto px-[1.5rem] pb-[calc(2rem+66px)] md:pb-10 md:ml-0 md:w-auto md:overflow-visible md:px-0">
      <div className="bg-main main-box-shadow min-w-[878px] rounded-xl ">
        <div className="flex items-center gap-4 p-4">
          <div className="billing-icon-bg-color billing-icon-color red aspect-square rounded-full p-2">
            <Icon icon="icon-park-outline:bill" className="h-6 w-auto" />
          </div>
          <span className="title-white text-sm">Summary</span>
        </div>
      </div>
      <div className="bg-main main-box-shadow min-w-[878px] shrink-0 overflow-hidden rounded-xl">
        <div className="flex items-center gap-4 p-4">
          <div className="billing-icon-bg-color billing-icon-color aspect-square rounded-full p-2">
            <Icon icon="fluent:payment-32-regular" className="h-6 w-auto" />
          </div>
          <span className="title-white text-sm">Purchase History</span>
        </div>
        <div className="con-white text-medium flex gap-4 p-5 text-xs text-[#888E99] 2xl:text-[13px]">
          <p className="flex-[1.5]">Id</p>
          <p className="flex-[1]">Plan</p>
          <p className="flex-[1.25]">Info</p>
          <p className="flex-[0.85]">Price</p>
          <p className="flex-[0.75]">Date</p>
        </div>
        {transactionRecords?.slice(defaultRecordsNumber * (currentPageIndex - 1), defaultRecordsNumber + defaultRecordsNumber * (currentPageIndex - 1)).map((record) => {
          return record.type === "TOPUP" ? (
            <div className="text-medium billing-hover-color flex items-center gap-4 p-5 py-6 text-xs text-[#888E99] 2xl:text-[13px]">
              <p className="title-white flex-[1.5]">{record.id}</p>
              <p className="flex-[1]">Recharge</p>
              <p className="flex-[1.25]">Recharge by {record.payment?.type} </p>
              <p className="flex-[0.85]" style={{ color: "green" }}>
                {" "}
                + {record.total}
              </p>
              <p className="flex-[0.75]">{convertDateTime(record.createdAt)}</p>
            </div>
          ) : (
            <>
              {record.type === "EXPENSE" ? (
                <div className="text-medium billing-hover-color flex items-center gap-4 p-5 py-6 text-xs text-[#888E99] 2xl:text-[13px]">
                  <p className="title-white flex-[1.5]">{record.id}</p>
                  <p className="flex-[1]">{record.product?.name}</p>
                  <p className="flex-[1.25]">{record.product?.description}</p>
                  <p className="billing-icon-red flex-[0.85] truncate"> - {record.total.toFixed(config.billing.overviewToFixIndex)}</p>
                  <p className="flex-[0.75]">{convertDateTime(record.createdAt)}</p>
                </div>
              ) : (
                <div className="text-medium billing-hover-color flex items-center gap-4 p-5 py-6 text-xs text-[#888E99] 2xl:text-[13px]">
                  <p className="title-white flex-[1.5]">{record.id}</p>
                  <p className="flex-[1]">EDNS Reward</p>
                  <p className="flex-[1.25]">EDNS Domain Owner free 10$</p>
                  <p className="flex-[0.85]" style={{ color: "green" }}>
                    {" "}
                    + {record.total}
                  </p>
                  <p className="flex-[0.75]">{convertDateTime(record.createdAt)}</p>
                </div>
              )}
            </>
          );
        })}

        {/*<div className="text-medium billing-hover-color flex gap-4 p-5 py-6 text-xs text-[#888E99] 2xl:text-[13px]">*/}
        {/*  <p className="flex-[1.5]">4f88a3be-405e-4e89-8c61-828708f00161</p>*/}
        {/*  <p className="flex-[1]">BzzNode 1year 2.20TB</p>*/}
        {/*  <p className="flex-[1.25]">Buy BzzNode(1year) for 1.10TB</p>*/}
        {/*  <p className="flex-[0.85]">0.6772358119424</p>*/}
        {/*  <p className="flex-[0.75]">December 15, 2022</p>*/}
        {/*</div>*/}
        {/*<div className="text-medium billing-hover-color flex gap-4 p-5 py-6 text-xs text-[#888E99] 2xl:text-[13px]">*/}
        {/*  <p className="flex-[1.5]">4f88a3be-405e-4e89-8c61-828708f00161</p>*/}
        {/*  <p className="flex-[1]">BzzNode 1year 2.20TB</p>*/}
        {/*  <p className="flex-[1.25]">Buy BzzNode(1year) for 1.10TB</p>*/}
        {/*  <p className="flex-[0.85]">0.6772358119424</p>*/}
        {/*  <p className="flex-[0.75]">December 15, 2022</p>*/}
        {/*</div>*/}
        {/*<div className="text-medium billing-hover-color flex gap-4 p-5 py-6 text-xs text-[#888E99] 2xl:text-[13px]">*/}
        {/*  <p className="flex-[1.5]">4f88a3be-405e-4e89-8c61-828708f00161</p>*/}
        {/*  <p className="flex-[1]">BzzNode 1year 2.20TB</p>*/}
        {/*  <p className="flex-[1.25]">Buy BzzNode(1year) for 1.10TB</p>*/}
        {/*  <p className="flex-[0.85]">0.6772358119424</p>*/}
        {/*  <p className="flex-[0.75]">December 15, 2022</p>*/}
        {/*</div>*/}
        {/*<div className="text-medium billing-hover-color flex gap-4 p-5 py-6 text-xs text-[#888E99] 2xl:text-[13px]">*/}
        {/*  <p className="flex-[1.5]">4f88a3be-405e-4e89-8c61-828708f00161</p>*/}
        {/*  <p className="flex-[1]">BzzNode 1year 2.20TB</p>*/}
        {/*  <p className="flex-[1.25]">Buy BzzNode(1year) for 1.10TB</p>*/}
        {/*  <p className="flex-[0.85]">0.6772358119424</p>*/}
        {/*  <p className="flex-[0.75]">December 15, 2022</p>*/}
        {/*</div>*/}
      </div>
      {transactionRecords && transactionRecords.length > 0 && (
        <div className="sticky left-[50%] mx-auto flex translate-x-[-50%] items-center gap-1">
          <div
            className={`title-white bg-main main-box-shadow border-gray grid aspect-square h-full place-items-center rounded-md dark:border px-2 `}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (currentPageIndex > 1) {
                setCurrentPageIndex(1);
              }
            }}
          >
            <Icon className="h-6 w-auto text-[#cccbce]" icon="material-symbols:keyboard-double-arrow-left-rounded" />
          </div>
          <div
            className={`title-white bg-main main-box-shadow border-gray grid aspect-square h-full place-items-center rounded-md dark:border px-2 `}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (currentPageIndex > 1) {
                setCurrentPageIndex(currentPageIndex - 1);
              }
            }}
          >
            <Icon className="h-6 w-auto text-[#cccbce]" icon="material-symbols:chevron-left-rounded" />
          </div>
          {render_page_index()}
          {/*<div className="title-white w-12 select-none text-center">{currentPageIndex + 1} </div>*/}
          <div
            className={`title-white bg-main border-gray main-box-shadow grid aspect-square h-full place-items-center rounded-md dark:border px-2`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (transactionRecords && currentPageIndex < Math.ceil(transactionRecords.length / defaultRecordsNumber)) {
                setCurrentPageIndex(currentPageIndex + 1);
              }
            }}
          >
            <Icon className="h-6 w-auto text-[#cccbce]" icon="material-symbols:chevron-right-rounded" />
          </div>
          <div
            className={`title-white bg-main border-gray main-box-shadow grid aspect-square h-full place-items-center rounded-md dark:border px-2`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (transactionRecords && currentPageIndex < Math.ceil(transactionRecords.length / defaultRecordsNumber)) {
                setCurrentPageIndex(Math.ceil(transactionRecords.length / defaultRecordsNumber));
              }
            }}
          >
            <Icon className="h-6 w-auto text-[#cccbce]" icon="material-symbols:keyboard-double-arrow-right-rounded" />
          </div>
        </div>
      )}

      {/* <div className="border-gray h-fit min-w-[345px] rounded-xl border p-5 px-6 shadow-lg">
            <p className="title-white pb-2 text-lg font-medium">Summary</p>
            <div className="border-b border-dashed py-5">
              <p className="text-[14px] font-medium text-[#9ea4af]">BzzNode</p>
              <div className="mt-1 flex justify-between">
                <div className="text-[#888E99]">
                  <span className="font-medium ">2.20TB</span>
                  <span className="ml-1 text-lg">x</span>
                  <span className="ml-1 font-medium">
                    <span className="text-black">1</span> Year
                  </span>
                </div>
                <p className="title-white truncate text-lg font-medium md:max-w-[145px]">
                  0.6772358119424
                </p>
              </div>
            </div>
            <div className="border-b border-dashed py-5">
              <p className="text-[14px] font-medium text-[#9ea4af]">BzzNode</p>
              <div className="mt-1 flex justify-between">
                <div className="text-[#888E99]">
                  <span className="font-medium ">2.20TB</span>
                  <span className="ml-1 text-lg">x</span>
                  <span className="ml-1 font-medium">
                    <span className="text-black">1</span> Year
                  </span>
                </div>
                <p className="title-white truncate text-lg font-medium md:max-w-[145px]">
                  0.6772358119424
                </p>
              </div>
            </div>
            <div className="border-b border-dashed py-5">
              <p className="text-[14px] font-medium text-[#9ea4af]">BzzNode</p>
              <div className="mt-1 flex justify-between">
                <div className="text-[#888E99]">
                  <span className="font-medium ">2.20TB</span>
                  <span className="ml-1 text-lg">x</span>
                  <span className="ml-1 font-medium">
                    <span className="text-black">1</span> Year
                  </span>
                </div>
                <p className="title-white truncate text-lg font-medium md:max-w-[145px]">
                  0.6772358119424
                </p>
              </div>
            </div>
            <div className="border-b border-b-gray-300 py-5">
              <p className="text-[14px] font-medium text-[#9ea4af]">BzzNode</p>
              <div className="mt-1 flex justify-between">
                <div className="text-[#888E99]">
                  <span className="font-medium ">2.20TB</span>
                  <span className="ml-1 text-lg">x</span>
                  <span className="ml-1 font-medium">
                    <span className="text-black">1</span> Year
                  </span>
                </div>
                <p className="title-white truncate text-lg font-medium md:max-w-[145px]">
                  0.6772358119424
                </p>
              </div>
            </div>
            <div className="py-3 pt-4 text-lg">
              <div className="mt-1 flex justify-between">
                <div className="title-white font-medium">Total</div>
                <p className="truncate font-semibold text-[#598de0] md:max-w-[200px]">
                  3.2252358119424
                </p>
              </div>
            </div>
          </div> */}
    </div>
  );
};

export default MonthBilling;
