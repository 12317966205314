import type grapesjs from 'grapesjs';
import { PluginOptions } from '.';
import { typeCustomCode } from './utils';

export default (editor: grapesjs.Editor, { blockCustomCode }: PluginOptions = {}) => {
  const { Blocks } = editor;

  blockCustomCode && Blocks.add(typeCustomCode, {
    label: 'Import Code',
    media: `
    <i class="fa fa-code" aria-hidden="true" style="width:100%;font-size:50px;"></i>
    `,
    category: 'Basic',
    activate: true,
    select: true,
    content: { type: typeCustomCode },
    ...blockCustomCode
  });
}