import axios from "axios";
import config from "../config";
import fs, { access } from "fs";
import path from "path";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
export async function downloadFilesPrivate(accessToken: string, uid: string, file: string,userId:string,filesize:number,callback?:(name:string,percentCompleted:number,filesize:number,fileName:string)=>void): Promise<any> {
  const url = new URL(`/v1/file/access`, config.api.endpoint);

  const cashe = await axios.post(url.href, { uid: uid }, { headers: { authorization: `Bearer ${accessToken}` } });
  const filename = file.split("/").pop();
  if (!filename) {
    throw new Error("File Name Error");
  }
  if (cashe) {
    const _url = new URL(`/v1/access`, config.api.download_gateway);
    const res = await axios
      .post(
        _url.href,
        { uid: uid },
        {
          responseType: "blob",
          onDownloadProgress:function(progressEvent) {
            const percentCompleted = progressEvent.total?Math.round((progressEvent.loaded * 100) / progressEvent.total ):Math.round( (progressEvent.loaded * 100) /filesize);
            if (callback) {
              callback(`Download-${uid}`, percentCompleted, progressEvent.total, filename);
            }
          }
        },
      )
      .then((response) => {
        window.analytics.track('Download Private File - Success',{
          cid:uid,
          userId:userId,
          date:new Date().toLocaleString()
        })
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });

    return res;
  }else{
    window.analytics.track('Download Private File - Failed of Grant Access Role',{
      cid:uid,
      userId:userId,
      error:"Can't grant the access role",
      date:new Date().toLocaleString()
    })
  }
}
