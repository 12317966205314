import CardsBasicTextCard from "./CardsContent/CardsImg/CardsBasicTextCard.png";
import CardsNewsCard from "./CardsContent/CardsImg/CardsNewsCard.png";
import CardsProductCard from "./CardsContent/CardsImg/CardsProductCard.png";
import CardsProfileCardTitleSubtitle from "./CardsContent/CardsImg/CardsProfileCardTitleSubtitle.png";
import CardsProfileCard from "./CardsContent/CardsImg/CardsProfileCard.png";
import CardsSuggestedProfile from "./CardsContent/CardsImg/CardsSuggestedProfile.png";

const Cards = [
  {
    id: "Cards-basic-text-card",
    category: "Cards",
    label: "<h3>Basic Text Card</h3>",
    media: `<img src="${CardsBasicTextCard}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bt {
                    border-top-style: solid;
                    border-top-width: 1px;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .mw5 {
                    max-width: 16rem;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pv2 {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }
                
                .ph3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                .mv0 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                .mv4 {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
                
                .f4 {
                    font-size: 1.25rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                @media screen and (min-width: 30em) {
                    .mw6-ns {
                        max-width: 32rem;
                    }
                
                    .f5-ns {
                        font-size: 1rem;
                    }
                }
                </style>
                <article class="center mw5 mw6-ns hidden ba mv4">
                <h1 class="f4 bg-near-black white mv0 pv2 ph3">Title of card</h1>
                <div class="pa3 bt">
                    <p class="f6 f5-ns lh-copy measure mv0">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                    tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                    vero eos et accusam et justo duo dolores et ea rebum.
                    </p>
                </div>
                </article>
            `,
  },
  {
    id: "Cards-news-card",
    category: "Cards",
    label: "<h3>News Card</h3>",
    media: `<img src="${CardsNewsCard}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .db {
                    display: block;
                }
                
                .tracked {
                    letter-spacing: .1em;
                }
                
                .lh-title {
                    line-height: 1.25;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .mw5 {
                    max-width: 16rem;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .gray {
                    color: #777;
                }
                
                .bg-white {
                    background-color: #fff;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pv2 {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }
                
                .ph3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                .mv4 {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
                
                .ttu {
                    text-transform: uppercase;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }                
                </style>
                <article class="bg-white center mw5 ba b--black-10 mv4">
                <div class="pv2 ph3">
                    <h1 class="f6 ttu tracked">Daily News Co.</h1>
                </div>
                <img src="http://tachyons.io/img/cat-720.jpg" class="w-100 db" alt="Closeup photo of a tabby cat yawning.">
                <div class="pa3">
                    <a href="#" class="link dim lh-title">15 things every cat owner should know</a>
                    <small class="gray db pv2">AMP - <time>6 hours ago</time></small>
                </div>
                </article>

            `,
  },
  {
    id: "Cards-product-card",
    category: "Cards",
    label: "<h3>Product Card</h3>",
    media: `<img src="${CardsProductCard}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .br2 {
                    border-radius: .25rem;
                }
                
                .br--top {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                
                .db {
                    display: block;
                }
                
                .dt {
                    display: table;
                }
                
                .dtc {
                    display: table-cell;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .mw5 {
                    max-width: 16rem;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .dark-gray {
                    color: #333;
                }
                
                .mid-gray {
                    color: #555;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .mt1 {
                    margin-top: .25rem;
                }
                
                .mt2 {
                    margin-top: .5rem;
                }
                
                .mv0 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                .mv4 {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
                
                .tr {
                    text-align: right;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                @media screen and (min-width: 30em) {
                    .pb3-ns {
                        padding-bottom: 1rem;
                    }
                
                    .ph3-ns {
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                
                    .f4-ns {
                        font-size: 1.25rem;
                    }
                }
                
                @media screen and (min-width: 30em) and (max-width: 60em) {
                    .w-50-m {
                        width: 50%;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .w-25-l {
                        width: 25%;
                    }
                }                                
                </style>
                <article class="br2 ba dark-gray b--black-10 mv4 w-100 w-50-m w-25-l mw5 center">
                <img src="http://placekitten.com/g/600/300" class="db w-100 br2 br--top" alt="Photo of a kitten looking menacing.">
                <div class="pa2 ph3-ns pb3-ns">
                    <div class="dt w-100 mt1">
                    <div class="dtc">
                        <h1 class="f5 f4-ns mv0">Cat</h1>
                    </div>
                    <div class="dtc tr">
                        <h2 class="f5 mv0">$1,000</h2>
                    </div>
                    </div>
                    <p class="f6 lh-copy measure mt2 mid-gray">
                    If it fits, i sits burrow under covers. Destroy couch leave hair everywhere,
                    and touch water with paw then recoil in horror.
                    </p>
                </div>
                </article>
            `,
  },
  {
    id: "Cards-profile-card-title-subtitle",
    category: "Cards",
    label: "<h3>Profile Card Title Subtitle</h3>",
    media: `<img src="${CardsProfileCardTitleSubtitle}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .b--black-05 {
                    border-color: rgba(0, 0, 0, .05);
                }
                
                .br3 {
                    border-radius: .5rem;
                }
                
                .br-100 {
                    border-radius: 100%;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .h4 {
                    height: 8rem;
                }
                
                .mw5 {
                    max-width: 16rem;
                }
                
                .w4 {
                    width: 8rem;
                }
                
                .gray {
                    color: #777;
                }
                
                .bg-white {
                    background-color: #fff;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .mb2 {
                    margin-bottom: .5rem;
                }
                
                .mt0 {
                    margin-top: 0;
                }
                
                .mv3 {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
                
                .tc {
                    text-align: center;
                }
                
                .f3 {
                    font-size: 1.5rem;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                @media screen and (min-width: 30em) {
                    .pa4-ns {
                        padding: 2rem;
                    }
                }
                
                </style>
                <article class="mw5 center bg-white br3 pa3 pa4-ns mv3 ba b--black-10">
                <div class="tc">
                    <img src="http://tachyons.io/img/avatar_1.jpg" class="br-100 h4 w4 dib ba b--black-05 pa2" title="Photo of a kitty staring at you">
                    <h1 class="f3 mb2">Mimi W.</h1>
                    <h2 class="f5 fw4 gray mt0">CCO (Chief Cat Officer)</h2>
                </div>
                </article>
            `,
  },
  {
    id: "Cards-profile-card",
    category: "Cards",
    label: "<h3>Profile Card</h3>",
    media: `<img src="${CardsProfileCard}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .br3 {
                    border-radius: .5rem;
                }
                
                .br-100 {
                    border-radius: 100%;
                }
                
                .bw1 {
                    border-width: .125rem;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .h3 {
                    height: 4rem;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .mw3 {
                    max-width: 4rem;
                }
                
                .mw5 {
                    max-width: 16rem;
                }
                
                .w3 {
                    width: 4rem;
                }
                
                .black-70 {
                    color: rgba(0, 0, 0, .7);
                }
                
                .bg-white {
                    background-color: #fff;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .mv3 {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
                
                .tc {
                    text-align: center;
                }
                
                .f4 {
                    font-size: 1.25rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                @media screen and (min-width: 30em) {
                    .pa4-ns {
                        padding: 2rem;
                    }
                }
                </style>
                <article class="mw5 center bg-white br3 pa3 pa4-ns mv3 ba b--black-10">
                <div class="tc">
                    <img src="http://tachyons.io/img/avatar_1.jpg" class="br-100 h3 w3 dib" title="Photo of a kitty staring at you">
                    <h1 class="f4">Mimi Whitehouse</h1>
                    <hr class="mw3 bb bw1 b--black-10">
                </div>
                <p class="lh-copy measure center f6 black-70">
                    Quite affectionate and outgoing.
                    She loves to get chin scratches and will
                    roll around on the floor waiting for you give her more of them.
                </p>
                </article>
            `,
  },
  {
    id: "Cards-suggested-profile",
    category: "Cards",
    label: "<h3>Suggested Profile</h3>",
    media: `<img src="${CardsSuggestedProfile}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bt {
                    border-top-style: solid;
                    border-top-width: 1px;
                }
                
                .b--black-20 {
                    border-color: rgba(0, 0, 0, .2);
                }
                
                .br1 {
                    border-radius: .125rem;
                }
                
                .br-100 {
                    border-radius: 100%;
                }
                
                .bw1 {
                    border-width: .125rem;
                }
                
                .top-1 {
                    top: 1rem;
                }
                
                .right-1 {
                    right: 1rem;
                }
                
                .db {
                    display: block;
                }
                
                .b {
                    font-weight: bold;
                }
                
                .h1 {
                    height: 1rem;
                }
                
                .lh-solid {
                    line-height: 1;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .mw5 {
                    max-width: 16rem;
                }
                
                .w1 {
                    width: 1rem;
                }
                
                .relative {
                    position: relative;
                }
                
                .absolute {
                    position: absolute;
                }
                
                .black-40 {
                    color: rgba(0, 0, 0, .4);
                }
                
                .gray {
                    color: #777;
                }
                
                .white {
                    color: #fff;
                }
                
                .dark-blue {
                    color: #00449e;
                }
                
                .bg-dark-blue {
                    background-color: #00449e;
                }
                
                .hover-blue:hover {
                    color: #357edd;
                }
                
                .hover-blue:focus {
                    color: #357edd;
                }
                
                .hover-bg-blue:hover {
                    background-color: #357edd;
                }
                
                .hover-bg-blue:focus {
                    background-color: #357edd;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pv1 {
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                }
                
                .ph3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                .mv1 {
                    margin-top: .25rem;
                    margin-bottom: .25rem;
                }
                
                .no-underline {
                    text-decoration: none;
                }
                
                .tc {
                    text-align: center;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                .hide-child .child {
                    opacity: 0;
                    transition: opacity .15s ease-in;
                }
                
                .hide-child:hover  .child, .hide-child:focus  .child, .hide-child:active .child {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .grow {
                    -moz-osx-font-smoothing: grayscale;
                    backface-visibility: hidden;
                    transform: translateZ(0);
                    transition: transform .25s ease-out;
                }
                
                .grow:hover, .grow:focus {
                    transform: scale(1.05);
                }
                
                .grow:active {
                    transform: scale(.9);
                }
                
                .bg-animate, .bg-animate:hover, .bg-animate:focus {
                    transition: background-color .15s ease-in-out;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                </style>
                <section class="tc pa3 pa5-ns">
                <article class="hide-child relative ba b--black-20 mw5 center">
                    <img src="" style="width:100%" class="db" alt="Photo of Jesse Grant" />
                    <div class="pa2 bt b--black-20">
                    <a class="f6 db link dark-blue hover-blue" href="#">Jesse Grant</a>
                    <p class="f6 gray mv1">5 mutual friends</p>
                    <a class="link tc ph3 pv1 db bg-animate bg-dark-blue hover-bg-blue white f6 br1" href="#">Add Friend</a>
                    </div>
                    <a class="child absolute top-1 right-1 ba bw1 black-40 grow no-underline br-100 w1 h1 pa2 lh-solid b" href="#">×</a>
                </article>
                </section>
            `,
  },
];

export default Cards;
