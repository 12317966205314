const Basic = [
  {
    id: "Basic-video-video",
    category: "Basic",
    label: "Video",
    resizable: "true",
    media: `<i class="fa fa-youtube-play" aria-hidden="true" style="width:100%;font-size:50px;"></i>`,
    content: `<video></video>`,
  },
  {
    id: "Basic-img-image",
    category: "Basic",
    label: "Image",
    media: `<i class="fa fa-picture-o" aria-hidden="true" style="width:100%;font-size:50px;"></i>`,
    content: `<img>`,
  },
  {
    id: "Basic-a-link",
    category: "Basic",
    label: "Link",
    media: `<i class="fa fa-link" aria-hidden="true" style="width:100%;font-size:50px;"></i>`,
    content: `<a style="cursor:pointer;color:blue;">Insert the text here</a>`,
  },
  {
    id: "Basic-p-h1-Section",
    category: "Basic",
    label: "Text section",
    media: `<i class="fa fa-text-height" aria-hidden="true" style="width:100%;font-size:50px;"></i>`,
    content: `<section class="bdg-sect"><h1>Insert title here</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p></section>`,
  },
  {
    id: "Basic-h1-block",
    category: "Basic",
    label: "Heading",
    media: `<i class="fa fa-header" aria-hidden="true" style="width:100%;font-size:50px;"></i>`,
    // content: {
    //   type: "text",
    //   content: "Insert your text here",
    //   style: {
    //     padding: "10px",
    //     "font-size": "20px",
    //     "font-weight": "bold",
    //   },
    // },
    content: `<h1 style="text-align:center;">Insert the text here</h1>`,
  },
  {
    id: "the-row-block",
    category: "Layout",
    label: "<h3>1 Column</h3>",
    media: `<svg style="width:100%;padding:15px;" xmlns='http://www.w3.org/2000/svg'fill='currentColor' class='bi bi-square' viewBox='0 0 16 16'>"+
  "<path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z'/>"+
"</svg>`,
    content: `
  
  <div class="row" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row">
    <div class="row-cell" data-gjs-draggable=".row"></div>
  </div>
  <style>
    .row {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      padding: 10px;
      min-height: 75px;
    }
    .row-cell {
      flex-grow: 1;
      flex-basis: 100%;
      padding: 5px;
    }
  </style>
`,
  },
  {
    id: "the-row-block-2",
    category: "Layout",
    label: "<h3>2 Columns</h3>",
    media: `<svg style="width:100%;padding:15px;" xmlns='http://www.w3.org/2000/svg' fill='currentColor' class='bi bi-layout-split' viewBox='0 0 16 16'>"+
"<path d='M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm8.5-1v12H14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H8.5zm-1 0H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h5.5V2z'/>"+
"</svg>`,
    content: `

<div class="row" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row">
<div class="row-cell" data-gjs-draggable=".row"></div>  
<div class="row-cell" data-gjs-draggable=".row"></div>
</div>

<style>
  .row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    padding: 10px;
    min-height: 75px;
  }
  .row-cell {
    flex-grow: 1;
    flex-basis: 100%;
    padding: 5px;
  }
</style>
`,
  },
  {
    id: "the-row-block-3",
    category: "Layout",
    label: "<h3>3 Columns</h3>",
    media: `<svg style="width:100%;padding:15px;" xmlns='http://www.w3.org/2000/svg' fill='currentColor' class='bi bi-grid-1x2' viewBox='0 0 16 16'>"+
"<path d='M6 1H1v14h5V1zm9 0h-5v5h5V1zm0 9v5h-5v-5h5zM0 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm9 0a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V1zm1 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-5z'/>"+
"</svg>`,
    content: `

<div class="row" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row">
<div class="row-cell" data-gjs-draggable=".row"></div>  
<div class="row-cell" data-gjs-draggable=".row"></div>
<div class="row-cell" data-gjs-draggable=".row"></div>
</div>
<style>
.row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  padding: 10px;
  min-height: 75px;
}
.row-cell {
  flex-grow: 1;
  flex-basis: 100%;
  padding: 5px;
}
</style>
`,
  },
];

export default Basic;
