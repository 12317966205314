import Web3Modal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { ethers } from "ethers";


const providerOptions = {
  binancechainwallet: {
    package: true,
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'Alex Wong',
      infuraId: "e6f7ae043bf941658aa7e91826ecaff5",
    },
  },
};

export default async function getWeb3WalletAddress():Promise<string>{
  try {
    const web3Modal = new Web3Modal({
      network: "mainnet",
      providerOptions,// optional    -> Uncomment for debug webpack.config
      cacheProvider: false, // optional
      // required
    });
    web3Modal.clearCachedProvider()
    await window.updateWeb3Modal({ show: true });
    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    const signer = provider.getSigner(0)
    const address = await signer.getAddress()
    return address
  }catch (e) {
    await window.updateWeb3Modal({ show: false });
    throw e
  }

}
