const isImage = (_type: string): boolean => {
  const type = _type.toLowerCase()
  if (
    type === "jpg" ||
    type === "jpeg" ||
    type === "png" ||
    type === "gif" ||
    type === "svg" ||
    type === "webp" ||
    type === "bmp" ||
    type === "ico" ||
    type === "tiff" ||
    type === "tif" ||
    type === "psd" ||
    type === "raw" ||
    type === "heif" ||
    type === "JPG"
  ) {
    return true;
  }
  return false;
};

const isVideo = (_type: string): boolean => {
  const type = _type.toLowerCase()
  if (
    type === "mp4" ||
    type === "webm" ||
    type === "mkv" ||
    type === "flv" ||
    type === "vob" ||
    type === "ogv" ||
    type === "ogg" ||
    type === "avi" ||
    type === "mov" ||
    type === "wmv" ||
    type === "yuv" ||
    type === "rm" ||
    type === "rmvb" ||
    type === "m4v" ||
    type === "3gp" ||
    type === "3g2" ||
    type === "mxf" ||
    type === "roq" ||
    type === "nsv" ||
    type === "flv" ||
    type === "f4v" ||
    type === "f4p" ||
    type === "f4a" ||
    type === "f4b"
  ) {
    return true;
  }
  return false;
};

const isCss = (type: string): boolean => {
  if (type === "css" || type === "scss" || type === "sass") {
    return true;
  }
  return false;
};

const isCompress = (type: string): boolean => {
  if (type === "zip" || type === "rar" || type === "7z" || type === "tar" || type === "gz" || type === "bz2") {
    return true;
  }
  return false;
};

const isText = (type: string): boolean => {
  if (type === "txt" || type === "doc" || type === "docx" || type === "ppt" || type === "pptx" || type === "pdf" || type === "html" || type === "htm" || type === "md") {
    return true;
  }
  return false;
};

const isExcel = (type: string): boolean => {
  if (type === "xls" || type === "xlsx" || type === "csv") {
    return true;
  }
  return false;
};

export { isImage, isExcel, isText, isVideo, isCss, isCompress };
