import axios from "axios";
import config from "../config";
import { ICreateItem, ICreateItemRes } from "../interface/create-item-interface";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { errorHandler } from "./presigned-url-multi";
export async function creatItem(accessToken: string, input: ICreateItem, userID: string): Promise<ICreateItemRes> {
  const url = new URL("/v1/http-api/items", config.api.endpoint);
  const response = await axios.post(
    url.href,

    input,

    {
      headers: {
        authorization: `Bearer ${accessToken}`,
        "user-id": `${userID}`,
      },
    },
  );
  errorHandler(response.data.status,response.data.messages[0],"creatItem",userID)
  return response.data;
}
