import { ReactKeycloakProvider } from "@react-keycloak/web";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import MonthBilling from "./Components/MonthBilling";
import Overview from "./Components/Overview";
import Stamp from "./Components/Stamp";
import "./index.scss";
import keycloak from "./keycloak";
import Billing from "./Page/Billing";
import BucketInfo from "./Page/BucketInfo";
import NotFound from "./Page/NotFound";
import Pod from "./Page/Pod";
import Setting from "./Page/Setting";
import Builder from "./Page/Builder";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import config from "./config";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
// import Segment from "react-segment-analytics";
import { Buffer } from "buffer";
import PreviewPage from "./Page/Preview";
import PreviewPageImage from "./Page/PreviewImage";

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
window.Buffer = window.Buffer || Buffer;

root.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/preview/video/:id" element={<PreviewPage />} />
          <Route path="/preview/image/:id" element={<PreviewPageImage />} />
          <Route path="/" element={<App />}>
            <Route path="*" element={<NotFound />} />
            <Route path="/pod" element={<Pod />} />
            <Route path="/pod/info/:podName/items" element={<BucketInfo type="Items" />} />
            <Route path="/pod/info/:podName/properties" element={<BucketInfo type="Properties" />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/billing" element={<Billing />}>
              <Route path="/billing" element={<Overview />} />
              <Route path="/billing/stamp/new" element={<Stamp />} />
              <Route path="/billing/stamp" element={<Stamp />} />
              <Route path="/billing/month" element={<MonthBilling />} />
            </Route>
            <Route path="/builder" element={<Builder />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </ReactKeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
