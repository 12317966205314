import ListsBlockItemDottedBorder from "./ListsContent/ListsImg/ListsBlockItemDottedBorder.png";
import ListsBorderSpaced from "./ListsContent/ListsImg/ListsBorderSpaced.png";
import ListsBorderTight from "./ListsContent/ListsImg/ListsBorderTight.png";
import ListsContactPhone from "./ListsContent/ListsImg/ListsContactPhone.png";
import ListsFollowerNotificationsRoundedSquareAvatar from "./ListsContent/ListsImg/ListsFollowerNotificationsRoundedSquareAvatar.png";
import ListsFollowerNotifications from "./ListsContent/ListsImg/ListsFollowerNotifications.png";
import ListsHashtags from "./ListsContent/ListsImg/ListsHashtags.png";
import ListsItemsImageTitlePrice from "./ListsContent/ListsImg/ListsItemsImageTitlePrice.png";
import ListsLargeLinksInline from "./ListsContent/ListsImg/ListsLargeLinksInline.png";
import ListsLinksAnimateColor from "./ListsContent/ListsImg/ListsLinksAnimateColor.png";
import ListsLinksInline from "./ListsContent/ListsImg/ListsLinksInline.png";
import ListsLinksWithBordersInline from "./ListsContent/ListsImg/ListsLinksWithBordersInline.png";
import ListsSlabStatLarge from "./ListsContent/ListsImg/ListsSlabStatLarge.png";
import ListsSlabStatSmall from "./ListsContent/ListsImg/ListsSlabStatSmall.png";
import ListsSlabStat from "./ListsContent/ListsImg/ListsSlabStat.png";
import ListsTitleText from "./ListsContent/ListsImg/ListsTitleText.png";

const Lists = [
  {
    id: "Lists-block-item-dotted-border",
    category: "Lists",
    label: "<h3>Block Item Dotted Border</h3>",
    media: `<img src="${ListsBlockItemDottedBorder}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .b--black-30 {
                    border-color: rgba(0, 0, 0, .3);
                }
                
                .b--dotted {
                    border-style: dotted;
                }
                
                .bt-0 {
                    border-top-width: 0;
                }
                
                .br-0 {
                    border-right-width: 0;
                }
                
                .bl-0 {
                    border-left-width: 0;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pl0 {
                    padding-left: 0;
                }
                
                .pv3 {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                </style>
                <div class="pa3 pa5-ns">
                <ul class="list pl0 measure center">
                    <li class="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">Orange</li>
                    <li class="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">Apple</li>
                    <li class="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">Peach</li>
                    <li class="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">Grape</li>
                    <li class="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">Grapefruit</li>
                    <li class="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">Kiwi</li>
                </ul>
                </div>
            `,
  },
  {
    id: "Lists-border-spaced",
    category: "Lists",
    label: "<h3>Border Spaced</h3>",
    media: `<img src="${ListsBorderSpaced}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--light-silver {
                    border-color: #aaa;
                }
                
                .br2 {
                    border-radius: .25rem;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .mw6 {
                    max-width: 32rem;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pl0 {
                    padding-left: 0;
                }
                
                .pv3 {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
                
                .ph3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                .ml0 {
                    margin-left: 0;
                }
                
                .f4 {
                    font-size: 1.25rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                
                </style>
                <article class="pa3 pa5-ns">
                <h1 class="f4 bold center mw6">Cats</h1>
                <ul class="list pl0 ml0 center mw6 ba b--light-silver br2">
                    <li class="ph3 pv3 bb b--light-silver">Mackeral Tabby</li>
                    <li class="ph3 pv3 bb b--light-silver">Burmese</li>
                    <li class="ph3 pv3 bb b--light-silver">Orange Tabby</li>
                    <li class="ph3 pv3 bb b--light-silver">Maine Coon</li>
                    <li class="ph3 pv3 bb b--light-silver">Siamese</li>
                    <li class="ph3 pv3 bb b--light-silver">Scottish Fold</li>
                    <li class="ph3 pv3">American Bobtail</li>
                </ul>
                </article>
            `,
  },
  {
    id: "Lists-border-tight",
    category: "Lists",
    label: "<h3>Border Tight</h3>",
    media: `<img src="${ListsBorderTight}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--light-silver {
                    border-color: #aaa;
                }
                
                .br3 {
                    border-radius: .5rem;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .mw5 {
                    max-width: 16rem;
                }
                
                .pl0 {
                    padding-left: 0;
                }
                
                .pv2 {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }
                
                .ph3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                .ml0 {
                    margin-left: 0;
                }
                
                .f4 {
                    font-size: 1.25rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                </style>
                <h1 class="f4 bold center mw5">Cats</h1>
                <ul class="list pl0 ml0 center mw5 ba b--light-silver br3">
                <li class="ph3 pv2 bb b--light-silver">Mackeral Tabby</li>
                <li class="ph3 pv2 bb b--light-silver">Burmese</li>
                <li class="ph3 pv2 bb b--light-silver">Maine Coon</li>
                <li class="ph3 pv2 bb b--light-silver">Orange Tabby</li>
                <li class="ph3 pv2 bb b--light-silver">Siamese</li>
                <li class="ph3 pv2 bb b--light-silver">Scottish Fold</li>
                <li class="ph3 pv2">American Bobtail</li>
                </ul>

            `,
  },
  {
    id: "Lists-contact-phone",
    category: "Lists",
    label: "<h3>Contact Phone</h3>",
    media: `<img src="${ListsContactPhone}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .br-100 {
                    border-radius: 100%;
                }
                
                .db {
                    display: block;
                }
                
                .flex {
                    display: flex;
                }
                
                .flex-auto {
                    flex: 1 1 auto;
                    min-width: 0;
                    min-height: 0;
                }
                
                .items-center {
                    align-items: center;
                }
                
                .h2 {
                    height: 2rem;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .w2 {
                    width: 2rem;
                }
                
                .black-70 {
                    color: rgba(0, 0, 0, .7);
                }
                
                .blue {
                    color: #357edd;
                }
                
                .hover-dark-gray:hover {
                    color: #333;
                }
                
                .hover-dark-gray:focus {
                    color: #333;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pl0 {
                    padding-left: 0;
                }
                
                .pl3 {
                    padding-left: 1rem;
                }
                
                .mt0 {
                    margin-top: 0;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                @media screen and (min-width: 30em) {
                    .h3-ns {
                        height: 4rem;
                    }
                
                    .w3-ns {
                        width: 4rem;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .ph0-l {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }                
                </style>
                <ul class="list pl0 mt0 measure center">
                <li
                    class="flex items-center lh-copy pa3 ph0-l bb b--black-10">
                    <img class="w2 h2 w3-ns h3-ns br-100" src="http://tachyons.io/img/avatar-mrmrs.jpg" />
                    <div class="pl3 flex-auto">
                        <span class="f6 db black-70">Mrmrs</span>
                        <span class="f6 db black-70">Medium Hexagon, LLC</span>
                    </div>
                    <div>
                        <a href="tel:" class="f6 link blue hover-dark-gray">+1 (999) 555-5555</a>
                    </div>
                </li>
                <li
                    class="flex items-center lh-copy pa3 ph0-l bb b--black-10">
                    <img class="w2 h2 w3-ns h3-ns br-100" src="http://tachyons.io/img/avatar-jxnblk.jpg" />
                    <div class="pl3 flex-auto">
                        <span class="f6 db black-70">Jxnblk</span>
                        <span class="f6 db black-70">Large Circle, Inc</span>
                    </div>
                    <div>
                        <a href="tel:" class="f6 link blue hover-dark-gray">+1 (999) 555-5555</a>
                    </div>
                </li>
                <li
                    class="flex items-center lh-copy pa3 ph0-l bb b--black-10">
                    <img class="w2 h2 w3-ns h3-ns br-100" src="http://tachyons.io/img/avatar-jasonli.jpg" />
                    <div class="pl3 flex-auto">
                        <span class="f6 db black-70">Jason Li</span>
                        <span class="f6 db black-70">Little Blue Square, Inc</span>
                    </div>
                    <div>
                        <a href="tel:" class="f6 link blue hover-dark-gray">+1 (999) 555-5555</a>
                    </div>
                </li>
                <li
                    class="flex items-center lh-copy pa3 ph0-l bb b--black-10">
                    <img class="w2 h2 w3-ns h3-ns br-100" src="http://tachyons.io/img/avatar-yavor.jpg" />
                    <div class="pl3 flex-auto">
                        <span class="f6 db black-70">Yavor</span>
                        <span class="f6 db black-70">Large Circle, Inc</span>
                    </div>
                    <div>
                        <a href="tel:" class="f6 link blue hover-dark-gray">+1 (999) 555-5555</a>
                    </div>
                </li>
                </ul>
            `,
  },
  {
    id: "Lists-follower-notifications-rounded-square-avatar",
    category: "Lists",
    label: "<h3>Follower Notifications Rounded Square Avatar</h3>",
    media: `<img src="${ListsFollowerNotificationsRoundedSquareAvatar}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .b--black-05 {
                    border-color: rgba(0, 0, 0, .05);
                }
                
                .br2 {
                    border-radius: .25rem;
                }
                
                .db {
                    display: block;
                }
                
                .dt {
                    display: table;
                }
                
                .dtc {
                    display: table-cell;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .fw6 {
                    font-weight: 600;
                }
                
                .button-reset::-moz-focus-inner {
                    border: 0;
                    padding: 0;
                }
                
                .h2 {
                    height: 2rem;
                }
                
                .lh-title {
                    line-height: 1.25;
                }
                
                .mw6 {
                    max-width: 32rem;
                }
                
                .w2 {
                    width: 2rem;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .black-60 {
                    color: rgba(0, 0, 0, .6);
                }
                
                .black {
                    color: #000;
                }
                
                .bg-white {
                    background-color: #fff;
                }
                
                .pl3 {
                    padding-left: 1rem;
                }
                
                .pb2 {
                    padding-bottom: .5rem;
                }
                
                .pv1 {
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                }
                
                .mb0 {
                    margin-bottom: 0;
                }
                
                .mt0 {
                    margin-top: 0;
                }
                
                .mt2 {
                    margin-top: .5rem;
                }
                
                .mv0 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                .tr {
                    text-align: right;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                .v-mid {
                    vertical-align: middle;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                .pointer:hover {
                    cursor: pointer;
                }
                
                @media screen and (min-width: 30em) {
                    .h3-ns {
                        height: 4rem;
                    }
                
                    .w3-ns {
                        width: 4rem;
                    }
                
                    .f5-ns {
                        font-size: 1rem;
                    }
                }                
                </style>
                <main class="mw6 center">
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/2.jpg" class="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Young Gatchell </h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@yg</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/3.jpg" class="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Arnoldo Degraff</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@99xx88randomhandle</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/4.jpg" class="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Deirdre Lachance</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@favfavfav</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/5.jpg" class="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Frederic Starner</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@hungryhippofanatic</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/6.jpg" class="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Cleveland Ridout</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@purethinking</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/7.jpg" class="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Leticia Fearon</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@retrofeels</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/10.jpg" class="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Ahmad Backer</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@ahmadBB</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/11.jpg" class="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Carlie Noakes</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@carnoakes99</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
            </main>
            `,
  },
  {
    id: "Lists-follower-notifications",
    category: "Lists",
    label: "<h3>Follower Notifications</h3>",
    media: `<img src="${ListsFollowerNotifications}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .b--black-05 {
                    border-color: rgba(0, 0, 0, .05);
                }
                
                .br-100 {
                    border-radius: 100%;
                }
                
                .db {
                    display: block;
                }
                
                .dt {
                    display: table;
                }
                
                .dtc {
                    display: table-cell;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .fw6 {
                    font-weight: 600;
                }
                
                .button-reset::-moz-focus-inner {
                    border: 0;
                    padding: 0;
                }
                
                .h2 {
                    height: 2rem;
                }
                
                .lh-title {
                    line-height: 1.25;
                }
                
                .mw6 {
                    max-width: 32rem;
                }
                
                .w2 {
                    width: 2rem;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .black-60 {
                    color: rgba(0, 0, 0, .6);
                }
                
                .black {
                    color: #000;
                }
                
                .bg-white {
                    background-color: #fff;
                }
                
                .pl3 {
                    padding-left: 1rem;
                }
                
                .pb2 {
                    padding-bottom: .5rem;
                }
                
                .pv1 {
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                }
                
                .mb0 {
                    margin-bottom: 0;
                }
                
                .mt0 {
                    margin-top: 0;
                }
                
                .mt2 {
                    margin-top: .5rem;
                }
                
                .mv0 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                .tr {
                    text-align: right;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                .v-mid {
                    vertical-align: middle;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                .pointer:hover {
                    cursor: pointer;
                }
                
                @media screen and (min-width: 30em) {
                    .h3-ns {
                        height: 4rem;
                    }
                
                    .w3-ns {
                        width: 4rem;
                    }
                
                    .f5-ns {
                        font-size: 1rem;
                    }
                }                
                </style>
                <main class="mw6 center">
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/2.jpg" class="ba b--black-10 db br-100 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Young Gatchell </h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@yg</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/3.jpg" class="ba b--black-10 db br-100 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Arnoldo Degraff</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@99xx88randomhandle</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/4.jpg" class="ba b--black-10 db br-100 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Deirdre Lachance</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@favfavfav</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/5.jpg" class="ba b--black-10 db br-100 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Frederic Starner</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@hungryhippofanatic</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/6.jpg" class="ba b--black-10 db br-100 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Cleveland Ridout</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@purethinking</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/7.jpg" class="ba b--black-10 db br-100 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Leticia Fearon</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@retrofeels</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/10.jpg" class="ba b--black-10 db br-100 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Ahmad Backer</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@ahmadBB</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
                <article class="dt w-100 bb b--black-05 pb2 mt2" href="#0">
                <div class="dtc w2 w3-ns v-mid">
                    <img src="http://mrmrs.github.io/photos/p/11.jpg" class="ba b--black-10 db br-100 w2 w3-ns h2 h3-ns"/>
                </div>
                <div class="dtc v-mid pl3">
                    <h1 class="f6 f5-ns fw6 lh-title black mv0">Carlie Noakes</h1>
                    <h2 class="f6 fw4 mt0 mb0 black-60">@carnoakes99</h2>
                </div>
                <div class="dtc v-mid">
                    <form class="w-100 tr">
                    <button class="f6 button-reset bg-white ba b--black-10 dim pointer pv1 black-60" type="submit">+ Follow</button>
                    </form>
                </div>
                </article>
            </main>

            `,
  },
  {
    id: "Lists-hashtags",
    category: "Lists",
    label: "<h3>Lists-hashtags</h3>",
    media: `<img src="${ListsHashtags}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .br2 {
                    border-radius: .25rem;
                }
                
                .db {
                    display: block;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .fw7 {
                    font-weight: 700;
                }
                
                .lh-title {
                    line-height: 1.25;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .mw6 {
                    max-width: 32rem;
                }
                
                .black-60 {
                    color: rgba(0, 0, 0, .6);
                }
                
                .blue {
                    color: #357edd;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .pa4 {
                    padding: 2rem;
                }
                
                .pl0 {
                    padding-left: 0;
                }
                
                .pv2 {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }
                
                .mr3 {
                    margin-right: 1rem;
                }
                
                .mb0 {
                    margin-bottom: 0;
                }
                
                .mt3 {
                    margin-top: 1rem;
                }
                
                .mv0 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                .no-underline {
                    text-decoration: none;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                .v-mid {
                    vertical-align: middle;
                }
                
                .underline-hover:hover, .underline-hover:focus {
                    text-decoration: underline;
                }
                
                @media screen and (min-width: 30em) {
                    .pa4-ns {
                        padding: 2rem;
                    }
                }                
                </style>
                <section class="bg-washed-blue pa2 pa4-ns">
                <article class="ba b--black-10 br2 bg-white pa4 mw6 center">
                    <div>
                    <h4 class="f5 fw4 black-60 dib v-mid mv0 mr3">Hashtags</h4>
                    <a href="" class="f6 no-underline underline-hover blue dib v-mid mb0">Edit</a>
                    </div>
                    <ul class="list f6 pl0 mt3 mb0">
                    <li class="pv2">
                        <a href="#" class="link blue lh-title">
                        <span class="fw7 underline-hover">#AmazingSerena</span>
                        <span class="db black-60">134.5k mentions</span>
                        </a>
                    </li>  
                    <li class="pv2">
                        <a href="#" class="link blue lh-title">
                        <span class="fw7 underline-hover">#womenintech</span>
                        <span class="db black-60">11k mentions</span>
                        </a>
                    </li>  
                    <li class="pv2">
                        <a href="#" class="link blue lh-title">
                        <span class="fw7 underline-hover">#SenWarrenRoastsWellsFargo</span>
                        <span class="db black-60">99k mentions</span>
                        </a>
                    </li>  
                    <li class="pv2">
                        <a href="#" class="link blue lh-title">
                        <span class="fw7 underline-hover">#blackgirlmagic</span>
                        <span class="db black-60">256.5k mentions</span>
                        </a>
                    </li>  
                    <li class="pv2">
                        <a href="#" class="link blue lh-title">
                        <span class="fw7 underline-hover">#becauseofthemwecan</span>
                        <span class="db black-60">4.1k mentions</span>
                        </a>
                    </li>  
                    <li class="pv2">
                        <a href="#" class="link blue lh-title">
                        <span class="fw7 underline-hover">#gillscottheron</span>
                        <span class="db black-60">20.11k mentions</span>
                        </a>
                    </li>  
                    <li class="pv2">
                        <a href="#" class="link blue lh-title">
                        <span class="fw7 underline-hover db">#trending</span>
                        <span class="db black-60">4.1k mentions</span>
                        </a>
                    </li>  
                    </ul>
                </article>
                </section>
            `,
  },
  {
    id: "Lists-items-image-title-price",
    category: "Lists",
    label: "<h3>Items Image Title Price</h3>",
    media: `<img src="${ListsItemsImageTitlePrice}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .db {
                    display: block;
                }
                
                .dt {
                    display: table;
                }
                
                .dtc {
                    display: table-cell;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .fw6 {
                    font-weight: 600;
                }
                
                .lh-title {
                    line-height: 1.25;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .mw6 {
                    max-width: 32rem;
                }
                
                .w3 {
                    width: 4rem;
                }
                
                .w-100 {
                    width: 100%;
                }
                
                .black-60 {
                    color: rgba(0, 0, 0, .6);
                }
                
                .black {
                    color: #000;
                }
                
                .blue {
                    color: #357edd;
                }
                
                .pl2 {
                    padding-left: .5rem;
                }
                
                .pb2 {
                    padding-bottom: .5rem;
                }
                
                .ml0 {
                    margin-left: 0;
                }
                
                .mb0 {
                    margin-bottom: 0;
                }
                
                .mt2 {
                    margin-top: .5rem;
                }
                
                .mv0 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                .clip {
                    position: fixed !important;
                    _position: absolute !important;
                    clip: rect(1px 1px 1px 1px);
                    clip: rect(1px, 1px, 1px, 1px);
                }
                
                .v-top {
                    vertical-align: top;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) {
                    .f5-ns {
                        font-size: 1rem;
                    }
                }
                
                </style>
                <main class="mw6 center">
                <article>
                  <a class="link dt w-100 bb b--black-10 pb2 mt2 dim blue" href="#0">
                    <div class="dtc w3">
                      <img src="http://mrmrs.github.io/images/0010.jpg" class="db w-100"/>
                    </div>
                    <div class="dtc v-top pl2">
                      <h1 class="f6 f5-ns fw6 lh-title black mv0">Grid Systems</h1>
                      <h2 class="f6 fw4 mt2 mb0 black-60">Josef Müller-Brockmann</h2>
                      <dl class="mt2 f6">
                        <dt class="clip">Price</dt>
                        <dd class="ml0">$75.00</dd>
                      </dl>
                    </div>
                  </a>
                </article>
                <article>
                  <a class="link dt w-100 bb b--black-10 pb2 mt2 dim blue" href="#0">
                    <div class="dtc w3">
                      <img src="http://mrmrs.github.io/images/0002.jpg" class="db w-100"/>
                    </div>
                    <div class="dtc v-top pl2">
                      <h1 class="f6 f5-ns fw6 lh-title black mv0">History of the Poster</h1>
                      <h2 class="f6 fw4 mt2 mb0 black-60">Josef Müller-Brockmann</h2>
                      <dl class="mt2 f6">
                        <dt class="clip">Price</dt>
                        <dd class="ml0">$15.00</dd>
                      </dl>
                    </div>
                  </a>
                </article>
                <article>
                  <a class="link dt w-100 bb b--black-10 pb2 mt2 dim blue" href="#0">
                    <div class="dtc w3">
                      <img src="http://mrmrs.github.io/images/0004.jpg" class="db w-100"/>
                    </div>
                    <div class="dtc v-top pl2">
                      <h1 class="f6 f5-ns fw6 lh-title black mv0">Graphic Design in IBM: Typography, Photography, and Illustration</h1>
                      <h2 class="f6 fw4 mt2 mb0 black-60">Josef Müller-Brockmann</h2>
                      <dl class="mt2 f6">
                        <dt class="clip">Price</dt>
                        <dd class="ml0">$15.00</dd>
                      </dl>
                    </div>
                  </a>
                </article>
                <article>
                  <a class="link dt w-100 bb b--black-10 pb2 mt2 dim blue" href="#0">
                    <div class="dtc w3">
                      <img src="http://mrmrs.github.io/images/0006.jpg" class="db w-100"/>
                    </div>
                    <div class="dtc v-top pl2">
                      <h1 class="f6 f5-ns fw6 lh-title black mv0">Fotoplakate: Von den Anfängen</h1>
                      <h2 class="f6 fw4 mt2 mb0 black-60">Josef Müller-Brockmann</h2>
                      <dl class="mt2 f6">
                        <dt class="clip">Price</dt>
                        <dd class="ml0">$15.00</dd>
                      </dl>
                    </div>
                  </a>
                </article>
                <article>
                  <a class="link dt w-100 bb b--black-10 pb2 mt2 dim blue" href="#0">
                    <div class="dtc w3">
                      <img src="http://mrmrs.github.io/images/0030.jpg" class="db w-100"/>
                    </div>
                    <div class="dtc v-top pl2">
                      <h1 class="f6 f5-ns fw6 lh-title black mv0">The Graphic Artist</h1>
                      <h2 class="f6 fw4 mt2 mb0 black-60">Josef Müller-Brockmann</h2>
                      <dl class="mt2 f6">
                        <dt class="clip">Price</dt>
                        <dd class="ml0">$15.00</dd>
                      </dl>
                    </div>
                  </a>
                </article>
                <article class="mt2">
                  <a class="link dt w-100 bb b--black-10 dim blue" href="#0">
                    <div class="dtc w3">
                      <img src="http://mrmrs.github.io/images/0010.jpg" class="db w-100"/>
                    </div>
                    <div class="dtc v-top pl2">
                      <h1 class="f6 f5-ns fw6 lh-title black mv0">A History of Visual Communication (Geschichte der visuellen Kommunikation)</h1>
                      <h2 class="f6 fw4 mt2 mb0 black-60">Josef Müller-Brockmann</h2>
                      <dl class="mt2 f6">
                        <dt class="clip">Price</dt>
                        <dd class="ml0">$15.00</dd>
                      </dl>
                    </div>
                  </a>
                </article>
              </main>
            `,
  },
  {
    id: "Lists-Large-Links-Inline",
    category: "Lists",
    label: "<h3>Large Links Inline</h3>",
    media: `<img src="${ListsLargeLinksInline}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .db {
                    display: block;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .b {
                    font-weight: bold;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .mid-gray {
                    color: #555;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .mr2 {
                    margin-right: .5rem;
                }
                
                .f4 {
                    font-size: 1.25rem;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                
                    .f2-ns {
                        font-size: 2.25rem;
                    }
                }
                </style>
                <ul class="list pa3 pa5-ns">
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Abyssinian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Aegean</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Arabian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Australian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Asian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Balinese</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Bambino</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Bengal</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Birman</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Bombay</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Brazilian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">British</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">British</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">British</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Burmese</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Burmilla</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">California</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Chantilly</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Chartreux</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Chausie</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Cheetoh</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Colorpoint</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Cornish</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Cymric</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Cyprus</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Devon</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Donskoy</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Dragon</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Egyptian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">European</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Exotic</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Foldex</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Cachorro</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">German</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Havana</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Highlander</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Himalayan</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Japanese</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Javanese</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Kurilian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Khao</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Korat</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Korean</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Korn</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Kurilian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Japan</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">LaPerm</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Lykoi</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Maine</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Manx</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Mekong</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Minskin</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Munchkin</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Nebelung</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Napoleon</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Norwegian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Ocicat</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Ojos</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Oregon</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Oriental</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Oriental</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Oriental</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">PerFold</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Persian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Persian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Peterbald</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Pixie</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Raas</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Ragamuffin</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Ragdoll</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Russian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Russian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Sam</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Savannah</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Scottish</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Selkirk</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Serengeti</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Serrade</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Siamese</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Siberian</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Singapura</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Snowshoe</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Sokoke</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Somali</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Sphynx</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Suphalak</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Thai</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Tonkinese</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Toyger</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Turkish</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">Turkish</a></li>
                <li class="dib mr2"><a href="#" class="f4 f2-ns b db pa2 link dim mid-gray">United</a></li>
                </ul>

            `,
  },
  {
    id: "Lists-links-animate-color",
    category: "Lists",
    label: "<h3>Links Animate Color</h3>",
    media: `<img src="${ListsLinksAnimateColor}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .dib {
                    display: inline-block;
                }
                
                .b {
                    font-weight: bold;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .black {
                    color: #000;
                }
                
                .hover-red:hover {
                    color: #ff4136;
                }
                
                .hover-red:focus {
                    color: #ff4136;
                }
                
                .hover-light-red:hover {
                    color: #ff725c;
                }
                
                .hover-light-red:focus {
                    color: #ff725c;
                }
                
                .hover-orange:hover {
                    color: #f48120;
                }
                
                .hover-orange:focus {
                    color: #f48120;
                }
                
                .hover-gold:hover {
                    color: #faad3f;
                }
                
                .hover-gold:focus {
                    color: #faad3f;
                }
                
                .hover-yellow:hover {
                    color: #ffd700;
                }
                
                .hover-yellow:focus {
                    color: #ffd700;
                }
                
                .hover-purple:hover {
                    color: #5e2ca5;
                }
                
                .hover-purple:focus {
                    color: #5e2ca5;
                }
                
                .hover-light-purple:hover {
                    color: #a463f2;
                }
                
                .hover-light-purple:focus {
                    color: #a463f2;
                }
                
                .hover-hot-pink:hover {
                    color: #ff41b4;
                }
                
                .hover-hot-pink:focus {
                    color: #ff41b4;
                }
                
                .hover-pink:hover {
                    color: #ff80cc;
                }
                
                .hover-pink:focus {
                    color: #ff80cc;
                }
                
                .hover-green:hover {
                    color: #19a974;
                }
                
                .hover-green:focus {
                    color: #19a974;
                }
                
                .hover-light-green:hover {
                    color: #9eebcf;
                }
                
                .hover-light-green:focus {
                    color: #9eebcf;
                }
                
                .hover-dark-blue:hover {
                    color: #00449e;
                }
                
                .hover-dark-blue:focus {
                    color: #00449e;
                }
                
                .hover-blue:hover {
                    color: #408bc9;
                }
                
                .hover-blue:focus {
                    color: #408bc9;
                }
                
                .hover-light-blue:hover {
                    color: #76c4e2;
                }
                
                .hover-light-blue:focus {
                    color: #76c4e2;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pv1 {
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                }
                
                .ph2 {
                    padding-left: .5rem;
                    padding-right: .5rem;
                }
                
                .no-underline {
                    text-decoration: none;
                }
                
                .f3 {
                    font-size: 1.5rem;
                }
                
                @media screen and (min-width: 30em) and (max-width: 60em) {
                    .pa4-m {
                        padding: 2rem;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .pa5-l {
                        padding: 4rem;
                    }
                }                
                </style>
                <section class="pa3 pa4-m pa5-l">
                <a class="f3 link hover-dark-blue b no-underline black dib ph2 pv1" href="http://mrmrs.github.io/gifolio">portfolio</a>
                <a class="f3 link b hover-blue no-underline black dib ph2 pv1" href="http://mrsjxn.com">music</a>
                <a class="f3 link b hover-light-blue no-underline black dib ph2 pv1" href="http://prnt.cc">print work</a>
                <a class="f3 link b hover-green no-underline black dib ph2 pv1" href="http://mrmrs.github.io">photography</a>
                <a class="f3 link b hover-light-green no-underline black dib ph2 pv1" href="http://mrmrs.github.io/writing">writing</a>
                <a class="f3 link b hover-yellow no-underline black dib ph2 pv1" href="http://cssstats.com">cssstats</a>
                <a class="f3 link b hover-gold no-underline black dib ph2 pv1" href="http://pesticide.io">pesticide</a>
                <a class="f3 link b hover-orange no-underline black dib ph2 pv1" href="http://gfffs.com">gifs</a>

                <a class="f3 link b hover-light-red no-underline black dib ph2 pv1" href="http://github.com/mrmrs">github</a>
                <a class="f3 link b hover-red no-underline black dib ph2 pv1" href="http://dribbble.com/mrmrs">dribbble</a>
                <a class="f3 link b hover-hot-pink no-underline black dib ph2 pv1" href="http://dribbble.com/mrmrs">medium</a>
                <a class="f3 link b hover-pink no-underline black dib ph2 pv1" href="http://instagram.com/mrmrs_">instagram</a>
                <a class="f3 link b hover-light-purple no-underline black dib ph2 pv1" href="http://unsplash.com/mrmrs">unsplash</a>
                <a class="f3 link b hover-purple no-underline black dib ph2 pv1" href="http://twitter.com/mrmrs_">twitter</a>
                </section>
            `,
  },
  {
    id: "Lists-links-inline",
    category: "Lists",
    label: "<h3>Links Inline</h3>",
    media: `<img src="${ListsLinksInline}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .db {
                    display: block;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .b {
                    font-weight: bold;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .mid-gray {
                    color: #555;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .pv4 {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }
                
                .ph3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                .mr2 {
                    margin-right: .5rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) {
                    .ph5-ns {
                        padding-left: 4rem;
                        padding-right: 4rem;
                    }
                
                    .f5-ns {
                        font-size: 1rem;
                    }
                }                
                </style>
                <ul class="list ph3 ph5-ns pv4">
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Abyssinian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Aegean</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Arabian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Australian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Asian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Balinese</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Bambino</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Bengal</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Birman</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Bombay</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Brazilian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">British</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">British</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">British</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Burmese</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Burmilla</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">California</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Chantilly</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Chartreux</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Chausie</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Cheetoh</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Colorpoint</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Cornish</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Cymric</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Cyprus</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Devon</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Donskoy</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Dragon</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Egyptian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">European</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Exotic</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Foldex</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Cachorro</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">German</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Havana</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Highlander</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Himalayan</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Japanese</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Javanese</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Kurilian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Khao</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Korat</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Korean</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Korn</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Kurilian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Japan</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">LaPerm</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Lykoi</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Maine</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Manx</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Mekong</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Minskin</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Munchkin</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Nebelung</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Napoleon</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Norwegian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Ocicat</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Ojos</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Oregon</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Oriental</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Oriental</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Oriental</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">PerFold</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Persian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Persian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Peterbald</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Pixie</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Raas</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Ragamuffin</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Ragdoll</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Russian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Russian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Sam</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Savannah</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Scottish</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Selkirk</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Serengeti</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Serrade</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Siamese</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Siberian</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Singapura</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Snowshoe</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Sokoke</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Somali</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Sphynx</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Suphalak</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Thai</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Tonkinese</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Toyger</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Turkish</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">Turkish</a></li>
                <li class="dib mr2"><a href="#" class="f6 f5-ns b db pa2 link dim mid-gray">United</a></li>
                </ul>

            `,
  },
  {
    id: "Lists-links-with-borders-inline",
    category: "Lists",
    label: "<h3>Links With Borders Inline</h3>",
    media: `<img src="${ListsLinksWithBordersInline}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .ba {
                    border-style: solid;
                    border-width: 1px;
                }
                
                .b--black-20 {
                    border-color: rgba(0, 0, 0, .2);
                }
                
                .db {
                    display: block;
                }
                
                .dib {
                    display: inline-block;
                }
                
                .b {
                    font-weight: bold;
                }
                
                .link {
                    text-decoration: none;
                    transition: color .15s ease-in;
                }
                
                .link:link, .link:visited {
                    transition: color .15s ease-in;
                }
                
                .link:hover {
                    transition: color .15s ease-in;
                }
                
                .link:active {
                    transition: color .15s ease-in;
                }
                
                .link:focus {
                    transition: color .15s ease-in;
                    outline: 1px dotted currentColor;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .dark-gray {
                    color: #4d4d4f;
                }
                
                .pa2 {
                    padding: .5rem;
                }
                
                .pv4 {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }
                
                .ph3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                
                .mr1 {
                    margin-right: .25rem;
                }
                
                .mb2 {
                    margin-bottom: .5rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .dim {
                    opacity: 1;
                    transition: opacity .15s ease-in;
                }
                
                .dim:hover, .dim:focus {
                    opacity: .5;
                    transition: opacity .15s ease-in;
                }
                
                .dim:active {
                    opacity: .8;
                    transition: opacity .15s ease-out;
                }
                
                @media screen and (min-width: 30em) {
                    .ph5-ns {
                        padding-left: 4rem;
                        padding-right: 4rem;
                    }
                
                    .f5-ns {
                        font-size: 1rem;
                    }
                }                
                </style>
                <ul class="list ph3 ph5-ns pv4">
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Abyssinian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Aegean</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Arabian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Australian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Asian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Balinese</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Bambino</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Bengal</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Birman</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Bombay</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Brazilian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">British</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">British</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">British</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Burmese</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Burmilla</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">California</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Chantilly</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Chartreux</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Chausie</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Cheetoh</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Colorpoint</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Cornish</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Cymric</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Cyprus</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Devon</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Donskoy</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Dragon</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Egyptian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">European</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Exotic</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Foldex</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Cachorro</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">German</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Havana</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Highlander</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Himalayan</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Japanese</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Javanese</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Kurilian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Khao</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Korat</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Korean</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Korn</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Kurilian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Japan</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">LaPerm</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Lykoi</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Maine</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Manx</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Mekong</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Minskin</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Munchkin</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Nebelung</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Napoleon</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Norwegian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Ocicat</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Ojos</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Oregon</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Oriental</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Oriental</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Oriental</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">PerFold</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Persian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Persian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Peterbald</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Pixie</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Raas</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Ragamuffin</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Ragdoll</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Russian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Russian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Sam</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Savannah</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Scottish</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Selkirk</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Serengeti</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Serrade</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Siamese</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Siberian</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Singapura</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Snowshoe</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Sokoke</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Somali</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Sphynx</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Suphalak</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Thai</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Tonkinese</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Toyger</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Turkish</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">Turkish</a></li>
                <li class="dib mr1 mb2"><a href="#" class="f6 f5-ns b db pa2 link dim dark-gray ba b--black-20">United</a></li>
                </ul>
            `,
  },
  {
    id: "Lists-slab-stat-large",
    category: "Lists",
    label: "<h3>Slab Stat Large</h3>",
    media: `<img src="${ListsSlabStatLarge}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .cf:before, .cf:after {
                    content: " ";
                    display: table;
                }
                
                .cf:after {
                    clear: both;
                }
                
                .cf {
                    *zoom: 1;
                }
                
                .db {
                    display: block;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .fw6 {
                    font-weight: 600;
                }
                
                .tracked {
                    letter-spacing: .1em;
                }
                
                .lh-title {
                    line-height: 1.25;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .ml0 {
                    margin-left: 0;
                }
                
                .ttu {
                    text-transform: uppercase;
                }
                
                .f2 {
                    font-size: 2.25rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .dib-l {
                        display: inline-block;
                    }
                
                    .w-auto-l {
                        width: auto;
                    }
                
                    .mr6-l {
                        margin-right: 8rem;
                    }
                
                    .f-subheadline-l {
                        font-size: 5rem;
                    }
                }                
                </style>
                <article class="pa3 pa5-ns" data-name="slab-stat-large">
                <h3 class="f6 ttu tracked">Today</h3>
                <div class="cf">
                    <dl class="db dib-l w-auto-l lh-title mr6-l">
                    <dd class="f6 fw4 ml0">Closed Issues</dd>
                    <dd class="f2 f-subheadline-l fw6 ml0">1,024</dd>
                    </dl>
                    <dl class="db dib-l w-auto-l lh-title mr6-l">
                    <dd class="f6 fw4 ml0">Open Issues</dd>
                    <dd class="f2 f-subheadline-l fw6 ml0">993</dd>
                    </dl>
                    <dl class="db dib-l w-auto-l lh-title mr6-l">
                    <dd class="f6 fw4 ml0">Next Release</dd>
                    <dd class="f2 f-subheadline-l fw6 ml0">10-22</dd>
                    </dl>
                    <dl class="db dib-l w-auto-l lh-title mr6-l">
                    <dd class="f6 fw4 ml0">Days Left</dd>
                    <dd class="f2 f-subheadline-l fw6 ml0">4</dd>
                    </dl>
                    <dl class="db dib-l w-auto-l lh-title mr6-l">
                    <dd class="f6 fw4 ml0">Favorite Cat</dd>
                    <dd class="f2 f-subheadline-l fw6 ml0">All of Them</dd>
                    </dl>
                    <dl class="db dib-l w-auto-l lh-title">
                    <dd class="f6 fw4 ml0">App Downloads</dd>
                    <dd class="f2 f-subheadline-l fw6 ml0">1,200</dd>
                    </dl>
                </div>
                </article>
            `,
  },
  {
    id: "Lists-slab-stat-small",
    category: "Lists",
    label: "<h3>Slab Stat Small</h3>",
    media: `<img src="${ListsSlabStatSmall}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .cf:before, .cf:after {
                    content: " ";
                    display: table;
                }
                
                .cf:after {
                    clear: both;
                }
                
                .cf {
                    *zoom: 1;
                }
                
                .fl {
                    float: left;
                    _display: inline;
                }
                
                .fw4 {
                    font-weight: 400;
                }
                
                .fw6 {
                    font-weight: 600;
                }
                
                .tracked {
                    letter-spacing: .1em;
                }
                
                .lh-title {
                    line-height: 1.25;
                }
                
                .w-50 {
                    width: 50%;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .ml0 {
                    margin-left: 0;
                }
                
                .ttu {
                    text-transform: uppercase;
                }
                
                .f3 {
                    font-size: 1.5rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .dib-l {
                        display: inline-block;
                    }
                
                    .fn-l {
                        float: none;
                    }
                
                    .w-auto-l {
                        width: auto;
                    }
                
                    .mr5-l {
                        margin-right: 4rem;
                    }
                }                           
                </style>
                <article class="pa3 pa5-ns" data-name="slab-stat-small">
                <h3 class="f6 ttu tracked">Today</h3>
                <div class="cf">
                    <dl class="fl fn-l w-50 dib-l w-auto-l lh-title mr5-l">
                    <dd class="f6 fw4 ml0">Closed Issues</dd>
                    <dd class="f3 fw6 ml0">1,024</dd>
                    </dl>
                    <dl class="fl fn-l w-50 dib-l w-auto-l lh-title mr5-l">
                    <dd class="f6 fw4 ml0">Open Issues</dd>
                    <dd class="f3 fw6 ml0">993</dd>
                    </dl>
                    <dl class="fl fn-l w-50 dib-l w-auto-l lh-title mr5-l">
                    <dd class="f6 fw4 ml0">Next Release</dd>
                    <dd class="f3 fw6 ml0">10-22</dd>
                    </dl>
                    <dl class="fl fn-l w-50 dib-l w-auto-l lh-title mr5-l">
                    <dd class="f6 fw4 ml0">Days Left</dd>
                    <dd class="f3 fw6 ml0">4</dd>
                    </dl>
                    <dl class="fl fn-l w-50 dib-l w-auto-l lh-title mr5-l">
                    <dd class="f6 fw4 ml0">Favorite Cat</dd>
                    <dd class="f3 fw6 ml0">All of Them</dd>
                    </dl>
                    <dl class="fl fn-l w-50 dib-l w-auto-l lh-title">
                    <dd class="f6 fw4 ml0">App Downloads</dd>
                    <dd class="f3 fw6 ml0">1,200</dd>
                    </dl>
                </div>
                </article>
            `,
  },
  {
    id: "Lists-slab-stat",
    category: "Lists",
    label: "<h3>Slab Stat</h3>",
    media: `<img src="${ListsSlabStat}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .dib {
                    display: inline-block;
                }
                
                .b {
                    font-weight: bold;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .ml0 {
                    margin-left: 0;
                }
                
                .mr5 {
                    margin-right: 4rem;
                }
                
                .f3 {
                    font-size: 1.5rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                
                    .f2-ns {
                        font-size: 2.25rem;
                    }
                
                    .f5-ns {
                        font-size: 1rem;
                    }
                }
                
                </style>
                <article class="pa3 pa5-ns" data-name="slab-stat">
                <h1>Today</h1>
                <dl class="dib mr5">
                    <dd class="f6 f5-ns b ml0">Closed Issues</dd>
                    <dd class="f3 f2-ns b ml0">1,024</dd>
                </dl>
                <dl class="dib mr5">
                    <dd class="f6 f5-ns b ml0">Open Issues</dd>
                    <dd class="f3 f2-ns b ml0">993</dd>
                </dl>
                <dl class="dib mr5">
                    <dd class="f6 f5-ns b ml0">Next Release</dd>
                    <dd class="f3 f2-ns b ml0">10-22</dd>
                </dl>
                <dl class="dib mr5">
                    <dd class="f6 f5-ns b ml0">Days Left</dd>
                    <dd class="f3 f2-ns b ml0">4</dd>
                </dl>
                <dl class="dib mr5">
                    <dd class="f6 f5-ns b ml0">Favorite Cat</dd>
                    <dd class="f3 f2-ns b ml0">All of Them</dd>
                </dl>
                <dl class="dib">
                    <dd class="f6 f5-ns b ml0">App Downloads</dd>
                    <dd class="f3 f2-ns b ml0">1,200</dd>
                </dl>
                </article>

            `,
  },
  {
    id: "Lists-title-text",
    category: "Lists",
    label: "<h3>Title Text</h3>",
    media: `<img src="${ListsTitleText}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .bb {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                
                .b--black-10 {
                    border-color: rgba(0, 0, 0, .1);
                }
                
                .db {
                    display: block;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .list {
                    list-style-type: none;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .pl0 {
                    padding-left: 0;
                }
                
                .mb1 {
                    margin-bottom: .25rem;
                }
                
                .f3 {
                    font-size: 1.5rem;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                @media screen and (min-width: 30em) {
                    .pa4-ns {
                        padding: 2rem;
                    }
                }
                
                </style>
                <ul class="list pl0">
                <li class="pa3 pa4-ns bb b--black-10">
                    <b class="db f3 mb1">alignment</b>
                    <span class="f5 db lh-copy measure">
                    The positioning of text within the page margins. Alignment can be flush left, flush right, justified, or centered. Flush left and flush right are sometimes referred to as left justified and right justified.</span>
                </li>
                <li class="pa3 pa4-ns bb b--black-10">
                    <b class="db f3 mb1">
                    ascender
                    </b>
                    <span class="f5 db lh-copy measure">
                    The part of lowercase letters (such as k, b, and d) that ascends above the
                    x-height of the other lowercase letters in a face.
                    </span>
                </li>
                <li class="pa3 pa4-ns bb b--black-10">
                    <b class="db f3 mb1">
                    baseline
                    </b>
                    <span class="f5 db lh-copy measure">
                    The imaginary line on which the majority of the characters in a typeface rest.
                    </span>
                </li>
                <li class="pa3 pa4-ns bb b--black-10">
                    <b class="db f3 mb1">
                    body text
                    </b>
                    <span class="f5 db lh-copy measure">
                    The paragraphs in a document that make up the bulk of its content. The body text should be set in an appropriate and easy-to-read face, typically at 10- or 12-point size.
                    </span>
                </li>
                <li class="pa3 pa4-ns bb b--black-10">
                    <b class="db f3 mb1">
                    boldface
                    </b>
                    <span class="f5 db lh-copy measure">
                    A typeface that has been enhanced by rendering it in darker, thicker strokes so that it will stand out on the page. Headlines that need emphasis should be boldface. Italics are preferable for emphasis in body text.
                    </span>
                </li>
                <li class="pa3 pa4-ns bb b--black-10">
                    <b class="db f3 mb1">
                    bullet
                    </b>
                    <span class="f5 db lh-copy measure">
                    A dot or other special character placed at the left of items in a list to show that they are individual, but related, points.
                    </span>
                </li>
                <li class="pa3 pa4-ns bb b--black-10">
                    <b class="db f3 mb1">
                cap height
                    </b>
                    <span class="f5 db lh-copy measure">
                    The height from the baseline to the top of the uppercase letters in a font. This may or may not be the same as the height of ascenders. Cap height is used in some systems to measure the type size.
                    </span>
                </li>
                <li class="pa3 pa4-ns">
                    <b class="db f3 mb1">
                    centered
                    </b>
                    <span class="f5 db lh-copy measure">
                    Text placed at an equal distance from the left and right margins. Headlines are often centered. It is generally not good to mix centered text with flush left or flush right text.
                    </span>
                </li>
                </ul>

            `,
  },
];

export default Lists;
