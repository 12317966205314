// import ArticlesFeature from "./ArticlesContent/ArticlesImg/ArticlesFeature.png";
import ArticlesHeadlineTitleText from "./ArticlesContent/ArticlesImg/ArticlesHeadlineTitleText.png";
import ArticlesLargeTitleText from "./ArticlesContent/ArticlesImg/ArticlesLargeTitleText.png";
import ArticlesLeftTitleTopBorder from "./ArticlesContent/ArticlesImg/ArticlesLeftTitleTopBorder.png";
import ArticlesLeftTitle from "./ArticlesContent/ArticlesImg/ArticlesLeftTitle.png";
import ArticlesPhotoEssay from "./ArticlesContent/ArticlesImg/ArticlesPhotoEssay.png";
import ArticlesSingleColumnLargeTitle from "./ArticlesContent/ArticlesImg/ArticlesSingleColumnLargeTitle.png";
import ArticlesTitleHighlightHeaderCover from "./ArticlesContent/ArticlesImg/ArticlesTitleHighlightHeaderCover.png";
import ArticlesTitleTextwithImage from "./ArticlesContent/ArticlesImg/ArticlesTitleTextwithImage.png";

const Articles = [
  // {
  //     id: "Articles-feature",
  //     category: "Articles",
  //     label: "<h3>Feature</h3>",
  //     media: `<img src="${ArticlesFeature}" width="100%">`,
  //     activate: false,
  //     content: `
  //
  //         <style>
  //         .cover {
  //             background-size: cover !important;
  //         }

  //         .dt {
  //             display: table;
  //         }

  //         .dtc {
  //             display: table-cell;
  //         }

  //         .athelas {
  //             font-family: athelas, georgia, serif;
  //         }

  //         .i {
  //             font-style: italic;
  //         }

  //         .fs-normal {
  //             font-style: normal;
  //         }

  //         .fw1 {
  //             font-weight: 100;
  //         }

  //         .vh-100 {
  //             height: 100vh;
  //         }

  //         .tracked {
  //             letter-spacing: .1em;
  //         }

  //         .lh-title {
  //             line-height: 1.25;
  //         }

  //         .lh-copy {
  //             line-height: 1.5;
  //         }

  //         .w-100 {
  //             width: 100%;
  //         }

  //         .white-70 {
  //             color: rgba(255, 255, 255, .7);
  //         }

  //         .white-60 {
  //             color: rgba(255, 255, 255, .6);
  //         }

  //         .white {
  //             color: #fff;
  //         }

  //         .bg-dark-gray {
  //             background-color: #333;
  //         }

  //         .pv5 {
  //             padding-top: 4rem;
  //             padding-bottom: 4rem;
  //         }

  //         .ph0 {
  //             padding-left: 0;
  //             padding-right: 0;
  //         }

  //         .ph2 {
  //             padding-left: .5rem;
  //             padding-right: .5rem;
  //         }

  //         .mb2 {
  //             margin-bottom: .5rem;
  //         }

  //         .mh0 {
  //             margin-left: 0;
  //             margin-right: 0;
  //         }

  //         .tc {
  //             text-align: center;
  //         }

  //         .ttu {
  //             text-transform: uppercase;
  //         }

  //         .f1 {
  //             font-size: 3rem;
  //         }

  //         .f4 {
  //             font-size: 1.25rem;
  //         }

  //         .f5 {
  //             font-size: 1rem;
  //         }

  //         .f6 {
  //             font-size: .875rem;
  //         }

  //         .measure {
  //             max-width: 30em;
  //         }

  //         .measure-wide {
  //             max-width: 34em;
  //         }

  //         .center {
  //             margin-right: auto;
  //             margin-left: auto;
  //         }

  //         .v-mid {
  //             vertical-align: middle;
  //         }

  //         @media screen and (min-width: 60em) {
  //             .f-headline-l {
  //                 font-size: 6rem;
  //             }
  //         }
  //         </style>
  //         <article class="athelas">
  //             <div class="vh-100 dt w-100 tc bg-dark-gray white cover" style="background:url(http://mrmrs.github.io/photos/u/009.jpg) no-repeat center;">
  //                 <div class="dtc v-mid">
  //                 <header class="white-70">
  //                     <h2 class="f6 fw1 ttu tracked mb2 lh-title">Issue Six</h2>
  //                     <h3 class="f6 fw1 lh-title">Summer MMXVI</h3>
  //                 </header>
  //                 <h1 class="f1 f-headline-l fw1 i white-60">The Chronicles</h1>
  //                 <blockquote class="ph0 mh0 measure f4 lh-copy center">
  //                     <p class="fw1 white-70">
  //                     It's the space you put between the notes that make the music.
  //                     </p>
  //                     <cite class="f6 ttu tracked fs-normal">Massimo Vignelli</cite>
  //                 </blockquote>
  //                 </div>
  //             </div>
  //             <div class="center measure-wide f5 pv5 lh-copy ph2">
  //                 <h1 class="f1 lh-title">The repercussion of ugliness is endless</h1>
  //                 <p>
  //                 The choice of paper size is one of the  first of any given work to be printed. There are two basic paper size systems in the world: the international A sizes, and the American sizes.
  //                 </p>
  //                 <p>
  //                 The international Standard paper sizes, called the A series, is based
  //                 on a golden rectangle, the divine proportion. It is extremely handsome
  //                 and practical as well. It is adopted by many countries around the world
  //                 and is based on the German DIN metric Standards. The United States uses
  //                 a basic letter size (8 1/2 x 11”) of ugly proportions, and results in
  //                 complete chaos with an endless amount of paper sizes. It is a
  //                 by-product of the culture of free enterprise, competition and waste.
  //                 Just another example of the misinterpretations of freedom.
  //                 </p>
  //                 <p>
  //                 These are the basic DIN sizes in mm. for : A0, 841x1189 - A1, 594x841 -
  //                 A2, 420x594 - A3, 297x420 - A4, 210x297 - A5, 148x210 - A6, 105 x148 -
  //                 A7, 74x 105 - A8, 52x74 - A9, 37x52 - A10, 26x37.
  //                 </p>
  //                 <p>
  //                 The A4 is the basic size for stationary. Two thirds of it is a square,
  //                 a nice economical happenstance resulting from the golden rectangle.
  //                 It is one of the reasons we tend to use as much
  //                 as possible the DIN sizes: proportions are always leading to other nice proportions.
  //                 </p>
  //                 <p>
  //                 This does not happen with the American basic size which leads to nothing. I counted 28 different standard sizes in USA!. The only reason we use it is because everybody in USA uses it, all stationary in USA is that size, so are manilla folders,  les and of ce equipment!
  //                 The repercussion of ugliness is endless.
  //                 </p>
  //             </div>
  //             </article>
  //     `
  // },
  {
    id: "Articles-headline-title-text",
    category: "Articles",
    label: "<h3>Headline Title Text</h3>",
    media: `<img src="${ArticlesHeadlineTitleText}" width="100%">`,
    activate: false,
    content: `
        
            <style>
            .lh-copy {
                line-height: 1.5;
            }
            
            .pa3 {
                padding: 1rem;
            }
            
            .f3 {
                font-size: 1.5rem;
            }
            
            .measure {
                max-width: 30em;
            }
            
            @media screen and (min-width: 30em) {
                .pa5-ns {
                    padding: 4rem;
                }
            }
            
            @media screen and (min-width: 30em) and (max-width: 60em) {
                .f1-m {
                    font-size: 3rem;
                }
            }
            
            @media screen and (min-width: 60em) {
                .f-headline-l {
                    font-size: 6rem;
                }
            }
            </style>
            <article class="pa3 pa5-ns">
            <h1 class="f3 f1-m f-headline-l">Title</h1>
            <p class="measure lh-copy">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
            <p class="measure lh-copy">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
            </article>
            
        `,
  },
  {
    id: "Article-Large-Title-Text",
    category: "Articles",
    label: "<h3>Large Title Text</h3>",
    media: `<img src="${ArticlesLargeTitleText}" width="100%">`,
    activate: false,
    content: `
        
            <style>
            .lh-copy {
                line-height: 1.5;
            }
            
            .pa3 {
                padding: 1rem;
            }
            
            .f3 {
                font-size: 1.5rem;
            }
            
            .measure {
                max-width: 30em;
            }
            
            @media screen and (min-width: 30em) {
                .pa5-ns {
                    padding: 4rem;
                }
            }
            
            @media screen and (min-width: 30em) and (max-width: 60em) {
                .f2-m {
                    font-size: 2.25rem;
                }
            }
            
            @media screen and (min-width: 60em) {
                .f1-l {
                    font-size: 3rem;
                }
            }
            </style>
            <article class="pa3 pa5-ns">
            <h1 class="f3 f2-m f1-l">Title</h1>
            <p class="measure lh-copy">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
            <p class="measure lh-copy">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
            </article>
            
        `,
  },
  {
    id: "Articles-left-title-top-border",
    category: "Articles",
    label: "<h3>Left Title Top Border</h3>",
    media: `<img src="${ArticlesLeftTitleTopBorder}" width="100%">`,
    activate: false,
    content: `
        
            <style>
            .bt {
                border-top-style: solid;
                border-top-width: 1px;
            }
            
            .bw2 {
                border-width: .25rem;
            }
            
            .cf:before, .cf:after {
                content: " ";
                display: table;
            }
            
            .cf:after {
                clear: both;
            }
            
            .cf {
                *zoom: 1;
            }
            
            .fn {
                float: none;
            }
            
            .fw9 {
                font-weight: 900;
            }
            
            .tracked {
                letter-spacing: .1em;
            }
            
            .lh-title {
                line-height: 1.25;
            }
            
            .lh-copy {
                line-height: 1.5;
            }
            
            .mid-gray {
                color: #555;
            }
            
            .gray {
                color: #777;
            }
            
            .pt3 {
                padding-top: 1rem;
            }
            
            .pv5 {
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
            
            .ph3 {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            
            .mb3 {
                margin-bottom: 1rem;
            }
            
            .mt0 {
                margin-top: 0;
            }
            
            .ttu {
                text-transform: uppercase;
            }
            
            .f2 {
                font-size: 2.25rem;
            }
            
            .f3 {
                font-size: 1.5rem;
            }
            
            .f5 {
                font-size: 1rem;
            }
            
            .f6 {
                font-size: .875rem;
            }
            
            .measure {
                max-width: 30em;
            }
            
            @media screen and (min-width: 30em) {
                .fl-ns {
                    float: left;
                    _display: inline;
                }
            
                .w-50-ns {
                    width: 50%;
                }
            
                .pr4-ns {
                    padding-right: 2rem;
                }
            
                .ph5-ns {
                    padding-left: 4rem;
                    padding-right: 4rem;
                }
            
                .mt0-ns {
                    margin-top: 0;
                }
            }
            </style>
            <article class="cf ph3 ph5-ns pv5">
            <header class="fn fl-ns w-50-ns pr4-ns">
                <h1 class="f2 lh-title fw9 mb3 mt0 pt3 bt bw2">
                On Typography
                </h1>
                <h2 class="f3 mid-gray lh-title">
                An excerpt from the Form of the Book by Jan Tschichold
                </h2>
                <time class="f6 ttu tracked gray">Sometime before 1967</time>
            </header>
            <div class="fn fl-ns w-50-ns">
                <p class="f5 lh-copy measure mt0-ns">
                TYPOGRAPHY, even when poorly executed, can never be taken for granted;
                nor is it ever accidental. Indeed, beauti- fully typeset pages are always
                the result of long experience. Now and then they even attain the rank of
                great artistic achievement. But the art of typesetting stands apart from
                ex- pressive artwork, because the appeal is not limited to a small
                circle. It is open to everyone's critical judgment, and nowhere does this
                judgment carry more weight. Typography that can- not be read by everybody
                is useless. Even for someone who constantly ponders matters of
                readability and legibility, it is difficult to determine whether
                something can be read with ease, but the average reader will rebel at
                once when the type is too small or otherwise irritates the eye; both are
                signs of a certain illegibility already.
                </p>
                <p class="f5 lh-copy measure">
                All typography consists of letters. These appear either in the form of a
                smoothly running sentence or as an assembly of lines, which may even have
                contrasting shapes. Good typog- raphy begins, and this is no minor
                matter, with the typeset- ting of a single line of text in a book or a
                newspaper. Using exactly the same typeface, it is possible to create either
                a pleasant line, easily read, or an onerous one. Spacing, if it is too wide
                or too compressed, will spoil almost any typeface.
                </p>
            </div>
            </article>

            
        `,
  },
  {
    id: "Articles-left-title",
    category: "Articles",
    label: "<h3>Left Title</h3>",
    media: `<img src="${ArticlesLeftTitle}" width="100%">`,
    activate: false,
    content: `
        
            <style>
            .cf:before, .cf:after {
                content: " ";
                display: table;
            }
            
            .cf:after {
                clear: both;
            }
            
            .cf {
                *zoom: 1;
            }
            
            .fn {
                float: none;
            }
            
            .tracked {
                letter-spacing: .1em;
            }
            
            .lh-title {
                line-height: 1.25;
            }
            
            .lh-copy {
                line-height: 1.5;
            }
            
            .gray {
                color: #777;
            }
            
            .pv5 {
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
            
            .ph3 {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            
            .mb3 {
                margin-bottom: 1rem;
            }
            
            .mt0 {
                margin-top: 0;
            }
            
            .mt4 {
                margin-top: 2rem;
            }
            
            .ttu {
                text-transform: uppercase;
            }
            
            .f6 {
                font-size: .875rem;
            }
            
            .measure {
                max-width: 30em;
            }
            
            @media screen and (min-width: 30em) {
                .fl-ns {
                    float: left;
                    _display: inline;
                }
            
                .w-50-ns {
                    width: 50%;
                }
            
                .pr4-ns {
                    padding-right: 2rem;
                }
            
                .ph5-ns {
                    padding-left: 4rem;
                    padding-right: 4rem;
                }
            
                .mt0-ns {
                    margin-top: 0;
                }
            }
            </style>
            <article class="cf ph3 ph5-ns pv5">
            <header class="fn fl-ns w-50-ns pr4-ns">
                <h1 class="mb3 mt0 lh-title">Clay in a Potter's Hand</h1>
                <time class="f6 ttu tracked gray">Jan Tschichold</time>
            </header>
            <div class="fn fl-ns w-50-ns">
                <p class="lh-copy measure mt4 mt0-ns">
                PERFECT typography is more a science than an art. Mastery of the trade is
                indispensable, but it isn't everything. Unerring taste, the hallmark of
                perfection, rests also upon a clear understanding of the laws of harmonious
                design. As a rule, impeccable taste springs partly from inborn sensitivity:
                from feeling. But feelings remain rather unproductive unless they can inspire a
                secure judgment. Feelings have to mature into knowledge about the consequences
                of formal decisions. For this reason, there are no born masters of typography,
                but self- education may lead in time to mastery.
                </p>
                <p class="lh-copy measure">
                It is wrong to say that there is no arguing about taste when it is good taste
                that is in question. We are not born with good taste, nor do we come into this
                world equipped with a real understanding of art. Merely to recognize who or
                what is represented in a picture has little to do with a real under- standing
                of art. Neither has an uninformed opinion about the proportions of Roman
                letters. In any case, arguing is senseless. He who wants to convince has to
                do a better job than others.
                </p>
            </div>
            </article>

            
        `,
  },
  {
    id: "Articles-photo-essay",
    category: "Articles",
    label: "<h3>Photo Essay</h3>",
    media: `<img src="${ArticlesPhotoEssay}" width="100%">`,
    activate: false,
    content: `
        
            <style>
            .cover {
                background-size: cover !important;
            }
            
            .bt {
                border-top-style: solid;
                border-top-width: 1px;
            }
            
            .b--black-10 {
                border-color: rgba(0, 0, 0, .1);
            }
            
            .cf:before, .cf:after {
                content: " ";
                display: table;
            }
            
            .cf:after {
                clear: both;
            }
            
            .cf {
                *zoom: 1;
            }
            
            .dn {
                display: none;
            }
            
            .db {
                display: block;
            }
            
            .dt {
                display: table;
            }
            
            .dtc {
                display: table-cell;
            }
            
            .fl {
                float: left;
                _display: inline;
            }
            
            .serif {
                font-family: georgia, times, serif;
            }
            
            .helvetica {
                font-family: 'helvetica neue', helvetica, sans-serif;
            }
            
            .fw6 {
                font-weight: 600;
            }
            
            .fw9 {
                font-weight: 900;
            }
            
            .vh-100 {
                height: 100vh;
            }
            
            .lh-title {
                line-height: 1.25;
            }
            
            .lh-copy {
                line-height: 1.5;
            }
            
            .mw6 {
                max-width: 32rem;
            }
            
            .w-50 {
                width: 50%;
            }
            
            .w-100 {
                width: 100%;
            }
            
            .black {
                color: #000;
            }
            
            .bg-light-pink {
                background-color: #ffa3d7;
            }
            
            .pl2 {
                padding-left: .5rem;
            }
            
            .pr1 {
                padding-right: .25rem;
            }
            
            .pb3 {
                padding-bottom: 1rem;
            }
            
            .pb5 {
                padding-bottom: 4rem;
            }
            
            .pv4 {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
            
            .ph3 {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            
            .f2 {
                font-size: 2.25rem;
            }
            
            .f4 {
                font-size: 1.25rem;
            }
            
            .f5 {
                font-size: 1rem;
            }
            
            .f6 {
                font-size: .875rem;
            }
            
            .measure {
                max-width: 30em;
            }
            
            .center {
                margin-right: auto;
                margin-left: auto;
            }
            
            .v-mid {
                vertical-align: middle;
            }
            
            @media screen and (min-width: 30em) {
                .fw4-ns {
                    font-weight: 400;
                }
            }
            
            @media screen and (min-width: 30em) and (max-width: 60em) {
                .ph4-m {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            
                .f3-m {
                    font-size: 1.5rem;
                }
            
                .f5-m {
                    font-size: 1rem;
                }
            }
            
            @media screen and (min-width: 60em) {
                .dt-l {
                    display: table;
                }
            
                .dtc-l {
                    display: table-cell;
                }
            
                .w-50-l {
                    width: 50%;
                }
            
                .pl0-l {
                    padding-left: 0;
                }
            
                .pl2-l {
                    padding-left: .5rem;
                }
            
                .pr0-l {
                    padding-right: 0;
                }
            
                .pr2-l {
                    padding-right: .5rem;
                }
            
                .pr3-l {
                    padding-right: 1rem;
                }
            
                .pv4-l {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }
            
                .ph5-l {
                    padding-left: 4rem;
                    padding-right: 4rem;
                }
            
                .f-subheadline-l {
                    font-size: 5rem;
                }
            
                .f1-l {
                    font-size: 3rem;
                }
            
                .f4-l {
                    font-size: 1.25rem;
                }
            
                .measure-l {
                    max-width: 30em;
                }
            }
            </style>
            <article class="helvetica pb5">
        <div class="serif ph3 ph4-m ph5-l">
            <p class="lh-copy f5 f3-m f1-l measure center pv4">
            On <time>March 14, 1896</time>, the Sutro Baths were opened to the public as the world's largest indoor swimming pool establishment. </p>
            <div class="f5 f3-m lh-copy">
            <div class="cf dt-l w-100 bt b--black-10 pv4">
                <div class="dtc-l v-mid mw6 pr3-l">
                <img class="w-100" src="http://mrmrs.github.io/photos/009.jpg" alt=""/>
                </div>
                <div class="dtc-l v-mid f6 f5-m f4-l measure-l">
                <p class="measure pv4-l center">
                    <span class="fw9 fw4-ns">
                    Before it burned to the ground, the structure</span> filled a small beach
                    inlet below the Cliff House, also owned by Adolph Sutro at the time.
                    Shortly after closing, a fire in 1966 destroyed the building
                    while it was in the process of being demolished. All that remains
                    of the site are concrete walls, blocked off stairs and
                    passageways, and a tunnel with a deep crevice in the middle. The
                    cause of the fire was arson. Shortly afterwards, the developer
                    left San Francisco and claimed insurance money.
                </p>
                </div>
            </div>
            <div class="cf dn">
                <div class="fl w-100 w-50-l">
                <p class="f5 pv4">
                    During high tides, water would flow directly into the pools from the
                    nearby ocean, recycling the <span class="db f4 f3-m f1-l fw6 measure lh-title">two million US gallons of water in about an hour. </span>
                </p>
                </div>
                <div class="fl w-100 w-50-l">
                <p class="f5">
                    During low tides, a powerful turbine water pump,
                    built inside a cave at sea level, could be switched on from a control
                    room and could fill the tanks at a rate of 6,000 US gallons a minute,
                    recycling all the water in five hours.
                </p>
                </div>
            </div>
            <div class="cf">
                <div class="fl w-100 w-50-l pr2-l pb3">
                <img class="db w-100" src="http://mrmrs.github.io/photos/010.jpg" alt="Photo of a dusk skyline above a grassy rockface covered in trees.">
                </div>
                <div class="fl w-50 w-50-l pr1 pr0-l pl2-l pb3">
                <img class="db w-100" src="http://mrmrs.github.io/photos/011.jpg" alt="Photo of the sea and sky on the horizon with the foundations of a demolished house.">
                </div>
                <div class="fl w-50 w-50-l pl2 pl0-l pr2-l pb3">
                <img class="db w-100" src="http://mrmrs.github.io/photos/012.jpg" alt="Photo of the sea with mist covering the rocky formations near the shore.">
                </div>
                <div class="fl w-100 w-50-l pl2-l">
                <img class="db w-100" src="http://mrmrs.github.io/photos/013.jpg" alt="Photo of the foundations of a building on a cliff overlooking a lighthouse.">
                </div>
            </div>
            </div>
        </div>
        </article>

            
        `,
  },
  {
    id: "Articles-single-column-large-title",
    category: "Articles",
    label: "<h3>Single Column Large Title</h3>",
    media: `<img src="${ArticlesSingleColumnLargeTitle}" width="100%">`,
    activate: false,
    content: `
        
            <style>
            .db {
                display: block;
            }
            
            .dib {
                display: inline-block;
            }
            
            .avenir {
                font-family: 'avenir next', avenir, sans-serif;
            }
            
            .baskerville {
                font-family: baskerville, serif;
            }
            
            .fw1 {
                font-weight: 100;
            }
            
            .lh-title {
                line-height: 1.25;
            }
            
            .lh-copy {
                line-height: 1.5;
            }
            
            .w-100 {
                width: 100%;
            }
            
            .pb5 {
                padding-bottom: 4rem;
            }
            
            .pt4 {
                padding-top: 2rem;
            }
            
            .ph3 {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            
            .mb4 {
                margin-bottom: 2rem;
            }
            
            .mt0 {
                margin-top: 0;
            }
            
            .mt4 {
                margin-top: 2rem;
            }
            
            .f3 {
                font-size: 1.5rem;
            }
            
            .f4 {
                font-size: 1.25rem;
            }
            
            .f5 {
                font-size: 1rem;
            }
            
            .measure {
                max-width: 30em;
            }
            
            .center {
                margin-right: auto;
                margin-left: auto;
            }
            
            @media screen and (min-width: 30em) {
                .pt5-ns {
                    padding-top: 4rem;
                }
            
                .ph4-ns {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            
                .mt5-ns {
                    margin-top: 4rem;
                }
            
                .f4-ns {
                    font-size: 1.25rem;
                }
            }
            
            @media screen and (min-width: 30em) and (max-width: 60em) {
                .ph4-m {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            
                .f2-m {
                    font-size: 2.25rem;
                }
            }
            
            @media screen and (min-width: 60em) {
                .ph5-l {
                    padding-left: 4rem;
                    padding-right: 4rem;
                }
            
                .tc-l {
                    text-align: center;
                }
            
                .f-subheadline-l {
                    font-size: 5rem;
                }
            
                .f4-l {
                    font-size: 1.25rem;
                }
            }
            </style>
            <article class="baskerville pb5">
            <header class="avenir tc-l ph3 ph4-ns pt4 pt5-ns">
                <h1 class="f3 f2-m f-subheadline-l measure lh-title fw1 mt0">A Night of Taking Photos at the Spooky Ruins of the Sutro Baths</h1>
                <time class="f5 f4-l db fw1 baskerville mb4"> March 19th, 2015</time>
                <img class="w-100 dib measure f3" src="http://mrmrs.github.io/photos/009.jpg" alt="A photo of sutro baths by Adam Morse"/>
            </header>
            <div class="ph3 ph4-m ph5-l">
                <p class="f4 mb4 center measure lh-copy">
                On <time>March 14, 1896</time>, the Sutro Baths were opened to the public as the world's largest indoor swimming pool establishment. </p>
                <div class="measure db center f5 f4-ns lh-copy">
                <img class="db w-100 mt4 mt5-ns" src="http://mrmrs.github.io/photos/013.jpg" alt="Photo of the foundations of a building on a cliff overlooking a lighthouse.">
                <p>
                    Before it burned to the ground, the structure filled a small beach
                    inlet below the Cliff House, also owned by Adolph Sutro at the time.
                    Shortly after closing, a fire in 1966 destroyed the building
                    while it was in the process of being demolished.
                </p>
                <img class="db w-100 mt4 mt5-ns" src="http://mrmrs.github.io/photos/012.jpg" alt="Photo of the sea with mist covering the rocky formations near the shore.">
                <p>
                    During high tides, water would flow directly into the pools from the
                    nearby ocean, recycling the two million US gallons of water in about an hour.
                </p>
                <img class="db w-100 mt4 mt5-ns" src="http://mrmrs.github.io/photos/010.jpg" alt="Photo of a dusk skyline above a grassy rockface covered in trees.">
                <p>
                    All that remains
                    of the site are concrete walls, blocked off stairs and
                    passageways, and a tunnel with a deep crevice in the middle. The
                    cause of the fire was arson. Shortly afterwards, the developer
                    left San Francisco and claimed insurance money.
                </p>
                <img class="db w-100 mt4 mt5-ns" src="http://mrmrs.github.io/photos/011.jpg" alt="Photo of the sea and sky on the horizon with the foundations of a demolished house.">
                <p>
                    During low tides, a powerful turbine water pump,
                    built inside a cave at sea level, could be switched on from a control
                    room and could fill the tanks at a rate of 6,000 US gallons a minute,
                    recycling all the water in five hours.
                </p>
                </div>
            </div>
            </article>


            
        `,
  },
  {
    id: "Articles-title-highlight-header-cover",
    category: "Articles",
    label: "<h3>Title Highlight Header Cover</h3>",
    media: `<img src="${ArticlesTitleHighlightHeaderCover}" width="100%">`,
    activate: false,
    content: `
        
            <style>
            .bl {
                border-left-style: solid;
                border-left-width: 1px;
            }
            
            .b--gold {
                border-color: #ffb700;
            }
            
            .bw1 {
                border-width: .125rem;
            }
            
            .dib {
                display: inline-block;
            }
            
            .sans-serif {
                font-family: -apple-system,
                             BlinkMacSystemFont,
                             'avenir next',
                             avenir,
                             'helvetica neue',
                             helvetica,
                             ubuntu,
                             roboto,
                             noto,
                             'segoe ui',
                             arial,
                             sans-serif;
            }
            
            .georgia {
                font-family: georgia, serif;
            }
            
            .i {
                font-style: italic;
            }
            
            .fw1 {
                font-weight: 100;
            }
            
            .tracked {
                letter-spacing: .1em;
            }
            
            .tracked-tight {
                letter-spacing: -.05em;
            }
            
            .lh-title {
                line-height: 1.25;
            }
            
            .lh-copy {
                line-height: 1.5;
            }
            
            .mw9 {
                max-width: 96rem;
            }
            
            .black-80 {
                color: rgba(0, 0, 0, .8);
            }
            
            .white {
                color: #fff;
            }
            
            .bg-black-90 {
                background-color: rgba(0, 0, 0, .9);
            }
            
            .bg-gold {
                background-color: #ffb700;
            }
            
            .pa1 {
                padding: .25rem;
            }
            
            .pa4 {
                padding: 2rem;
            }
            
            .pl4 {
                padding-left: 2rem;
            }
            
            .mb2 {
                margin-bottom: .5rem;
            }
            
            .mb4 {
                margin-bottom: 2rem;
            }
            
            .mv0 {
                margin-top: 0;
                margin-bottom: 0;
            }
            
            .ttu {
                text-transform: uppercase;
            }
            
            .f2 {
                font-size: 2.25rem;
            }
            
            .f3 {
                font-size: 1.5rem;
            }
            
            .f5 {
                font-size: 1rem;
            }
            
            .f6 {
                font-size: .875rem;
            }
            
            .measure {
                max-width: 30em;
            }
            
            .measure-narrow {
                max-width: 20em;
            }
            
            .center {
                margin-right: auto;
                margin-left: auto;
            }
            
            @media screen and (min-width: 30em) {
                .pt5-ns {
                    padding-top: 4rem;
                }
            
                .f3-ns {
                    font-size: 1.5rem;
                }
            
                .f4-ns {
                    font-size: 1.25rem;
                }
            
                .f5-ns {
                    font-size: 1rem;
                }
            }
            
            @media screen and (min-width: 30em) and (max-width: 60em) {
                .f1-m {
                    font-size: 3rem;
                }
            }
            
            @media screen and (min-width: 60em) {
                .mw9-l {
                    max-width: 96rem;
                }
            
                .ph7-l {
                    padding-left: 16rem;
                    padding-right: 16rem;
                }
            
                .f-headline-l {
                    font-size: 6rem;
                }
            }
            </style>
            <article>
            <header class="bg-gold sans-serif">
                <div class="mw9 center pa4 pt5-ns ph7-l">
                <time class="f6 mb2 dib ttu tracked"><small>27 July, 2015</small></time>
                <h3 class="f2 f1-m f-headline-l measure-narrow lh-title mv0">
                    <span class="bg-black-90 lh-copy white pa1 tracked-tight">
                    Too many tools and frameworks
                    </span>
                </h3>
                <h4 class="f3 fw1 georgia i">The definitive guide to the javascript tooling landscape in 2015.</h4>
                <h5 class="f6 ttu tracked black-80">By Adam Morse</h5>
                </div>
            </header>
            <div class="pa4 ph7-l georgia mw9-l center">
                <p class="f5 f3-ns lh-copy measure georgia">
                    Theodore Sturgeon was a science fiction author, critic, and the basis for Kurt Vonnegut's recurring character Kilgore Trout. He was prolific, authoring over 200 pieces himself and critiquing around 400 others.
                </p>
                <p class="f5 f3-ns lh-copy measure georgia">
                    In 1958 he published a piece in Venture proclaiming what he called "Sturgeon's Revelation"
                </p>
                <p class="f6 f5-ns lh-copy measure i pl4 bl bw1 b--gold mb4">
                I repeat Sturgeon's Revelation, which was wrung out of me after twenty years of wearying defense of science fiction against attacks of people who used the worst examples of the field for ammunition, and whose conclusion was that ninety percent of SF is crud. Using the same standards that categorize 90% of science fiction as trash, crud, or crap, it can be argued that 90% of film, literature, consumer goods, etc. is crap. In other words, the claim (or fact) that 90% of science fiction is crap is ultimately uninformative, because science fiction conforms to the same trends of quality as all other artforms.
                </p>
                <p class="f5 f4-ns lh-copy measure mb4">
                You don't have to bounce a tennis ball very far in San Francisco before it will hit two developers complaining about how many js tools/frameworks there are for development in 2015 and how much unneccessary complexity they add. Doing a search on twitter for 'too many js tools' or 'yet another js framework' returns... a lot of people lamenting the current state of affairs.
            </p>

                <p class="f5 f4-ns lh-copy measure">
                This is most likely, the wrong conversation for us as a community, to be having. The presence of bad tools - shouldn't discourage us from wanting more tools or frameworks. There are more books published in a single day than I will ever be able to read in my lifetime. But this does not make me sad. Or overwhelm me. Mostly I think about how excited I am to read the best books that are being published. And this is where we should push the conversation. How do we build better tools? What does that look like?
            </p>
            </div>
            </article>
            
        `,
  },
  {
    id: "Articles-title-text-with-image",
    category: "Articles",
    label: "<h3>Title Text with Image</h3>",
    media: `<img src="${ArticlesTitleTextwithImage}" width="100%">`,
    activate: false,
    content: `
        
           <style>
           .lh-copy {
            line-height: 1.5;
            }
            
            .w-100 {
                width: 100%;
            }
            
            .pa3 {
                padding: 1rem;
            }
            
            .f2 {
                font-size: 2.25rem;
            }
            
            .f5 {
                font-size: 1rem;
            }
            
            .measure {
                max-width: 30em;
            }
            
            @media screen and (min-width: 30em) {
                .pa5-ns {
                    padding: 4rem;
                }
            }
        
           </style>
           <article class="pa3 pa5-ns">
            <h1 class="f2">Title Text with Image</h1>
            <img src="http://tachyons.io/img/space.jpg" class="w-100 f5 measure" alt="Photo of outer space">
            <p class="measure lh-copy">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
            <p class="measure lh-copy">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
            </article>

            
        `,
  },
];

export default Articles;
