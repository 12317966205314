import config from "../../config";
import axios from "axios";

export async function getLockdownStatus(accessToken: string): Promise<boolean> {
  {
    const url = new URL(
      `/lockdown`,
      config.api.billing_endpoint
    );
    const response = await axios.get(url.href, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response.data.lockdown;
  }
}
