import { createSlice } from "@reduxjs/toolkit";

export interface IStorage {
  size: number | undefined;
}

const initialState: IStorage = {
  size: undefined,
};

const storageSlice = createSlice({
  name: "storage",
  initialState,
  reducers: {
    setStorage(state, action) {
      state.size = action.payload.size;
    },
  },
});

export const storageActions = storageSlice.actions;
export default storageSlice;
