import { Slider } from "@mui/material";
import { time } from "console";
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import useWindowDimensions from "../Hook/useWindowDimensions";
import server from "../Static/server.jpg";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { IProduct } from "../api/billing/product/getProducts";
import { creatStamp } from "../api/stamp/create-stamp";
import { getStamp, IGetStamp } from "../api/stamp/get-stamp";
import { getStampUsage, IGetStampUsage } from "../api/stamp/get-stamp-usage";
import { Icon } from "@iconify/react";
import { depthToSize } from "../util/storage";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

interface BoxProps {
  usage: any;
  day: number;
  storage: number;
  name: string | undefined;
}

export const Box = (props: BoxProps) => {
  return (
    <div className="bg-main fade-in border-gray main-box-shadow min-w-[210px] rounded-xl p-4">
      <div className="flex select-none justify-between">
        <div
          className={`grid place-items-center rounded-md p-2 ${
            props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red billing-icon-red" : "billing-icon-color billing-usage-bg-blue"
          }`}
        >
          <Icon icon="fluent:storage-20-regular" className=" h-10 w-auto" />
        </div>
        <div
          className={`h-fit rounded-lg p-3 py-2 text-xs ${
            props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red billing-icon-red" : "billing-icon-color billing-usage-bg-blue"
          }`}
        >
          {props.day} Day Left
        </div>
      </div>
      <div className="title-white mt-6 truncate text-xl font-medium">{props.name}</div>
      <div className={`relative mt-4 h-[7px] w-full rounded-full ${props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red" : "billing-usage-bg-blue"}`}>
        <div
          className={`${props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-red" : "billing-usage-blue"} absolute top-0 left-0 h-[7px] rounded-full`}
          style={{ width: (props.usage / props.storage) * 100 + "%" }}
        ></div>
      </div>
      <div className="mt-3 flex select-none justify-between text-sm text-[#aaabaf]">
        <p>{isNaN(props.usage) ? "Stamp Error" : `${props.usage} GB`}</p>
        <p>{isNaN(props.storage) ? "" : `${props.storage} GB`} </p>
      </div>
    </div>
  );
};

const Popup = () => {
  const productsList = useSelector(authActions.login).payload.auth.productsList;
  const accessToken = useSelector(authActions.login).payload.auth.accessToken;
  const user = useSelector(authActions.login).payload.auth.user;
  const [targetProduct, setTargetProduct] = useState<null | IProduct>(null);
  const { width, height } = useWindowDimensions();
  const location = useLocation();
  const [stampName, setStampName] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const lockdown: boolean = useSelector(authActions.login).payload.auth.lockdown;

  // Handle Popup Animation
  useEffect(() => {
    const btnAddStamp = document.querySelector(".btn-add-stamp") as HTMLDivElement;
    const popup = document.querySelector(".billing-popup") as HTMLDivElement;
    const stampCon = document.querySelector("html") as HTMLElement;
    const inner = document.querySelector(".billing-popup-inner") as HTMLDivElement;
    const wrapper = document.querySelector(".billing-popup-wrapper") as HTMLDivElement;
    const handlePopupHeight = () => {
      if (window.innerHeight * 0.9 >= 786) {
        window.innerWidth > 768 ? (popup.style.height = "978px") : (popup.style.height = "962px");
      } else {
        popup.style.height = (window.innerHeight * 90) / 100 + "px";
      }
    };
    // scroll popup to top and smooth
    const handlePopupScroll = () => {
      popup.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Handle popup height
    const handleResize = () => {
      if (location.pathname.split("/")[3] !== "new") {
        const btnAddStampRect = btnAddStamp.getBoundingClientRect();
        popup.style.transition = "none";
        popup.style.top = btnAddStampRect.top + "px";
        popup.style.left = btnAddStampRect.left + "px";
        popup.style.transition = "all 0.45s";
      } else {
        handlePopupHeight();
      }
    };
    handleResize();
    handlePopupScroll();
    if (width > 768) {
      const fadeOut = () => {
        stampCon.style.overflow = "auto";
        const popupTop = btnAddStamp?.getBoundingClientRect().top;
        const popupLeft = btnAddStamp?.getBoundingClientRect().left;
        popup.style.top = popupTop + "px";
        popup.style.left = popupLeft + "px";
        inner.style.opacity = "0";
        inner.style.transform = "translateY(20px) scale(0.95)";
        popup.style.transform = "";
        popup.style.width = btnAddStamp.offsetWidth + "px";
        popup.style.height = btnAddStamp.offsetHeight + "px";
        popup.style.visibility = "hidden";
        popup.style.opacity = "0";
        wrapper.style.visibility = "hidden";
        wrapper.style.opacity = "0";
      };

      if (!initialized) {
        popup.style.transition = "";
        fadeOut();
        popup.style.width = btnAddStamp.offsetWidth + "px";
        popup.style.height = btnAddStamp.offsetHeight + "px";
        setInitialized(true);
        popup.style.transition = "all 0.45s";
      }

      // Handle popup animation
      if (location.pathname.split("/")[3] === "new" && initialized) {
        // desktop
        stampCon.style.overflow = "hidden";
        popup.style.top = "50%";
        popup.style.left = "50%";
        popup.style.transform = "translate(-50%, -50%)";
        popup.style.visibility = "visible";
        popup.style.opacity = "1";
        popup.style.width = "385px";
        handlePopupHeight();
        wrapper.style.visibility = "visible";
        wrapper.style.opacity = "1";
        setTimeout(() => {
          inner.style.opacity = "1";
          inner.style.transform = "translateY(0) scale(1)";
        }, 260);
      } else {
        fadeOut();
      }
    } else {
      // mobile
      popup.style.transform = "";
      const fadeOut = () => {
        stampCon.style.overflow = "auto";
        popup.style.bottom = "-100%";
        popup.style.top = "initial";
        popup.style.left = "0";
        inner.style.opacity = "0";
        popup.style.transform = "";
        popup.style.width = "100%";
        popup.style.height = "auto";
        popup.style.visibility = "hidden";
        popup.style.opacity = "0";
        wrapper.style.visibility = "hidden";
        wrapper.style.opacity = "0";
        inner.style.transform = "scale(1)";
      };
      // Initialize popup animationi
      if (!initialized) {
        setInitialized(true);
        fadeOut();
      }
      // Handle popup animation
      if (location.pathname.split("/")[3] === "new") {
        stampCon.style.overflow = "hidden";
        popup.style.width = "100%";
        popup.style.top = "initial";
        popup.style.left = "0";
        popup.style.bottom = "0%";
        popup.style.visibility = "visible";
        popup.style.opacity = "1";
        wrapper.style.visibility = "visible";
        wrapper.style.opacity = "1";
        setTimeout(() => {
          inner.style.opacity = "1";
        }, 150);
      } else {
        fadeOut();
      }
    }
  }, [location, width, height, initialized]);

  const size = [
    {
      value: 20,
    },
    // {
    //   value: 21,
    // },
    // {
    //   value: 22,
    // },
    // {
    //   value: 23,
    // },
    // {
    //   value: 24,
    // },
    // {
    //   value: 25,
    // },
    // {
    //   value: 26,
    // },
    // {
    //   value: 27,
    // },
    // {
    //   value: 28,
    // },
    // {
    //   value: 29,
    // },
    // {
    //   value: 30,
    // },
  ];

  const [value, setValue] = useState<number>(size[0].value);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const val = newValue as number;
    setValue(val);
  };

  const yearToAmount = (value: number) => {
    return (value * 365 * 24000 * 86400) / 5;
  };

  const [yearValue, setYearValue] = useState(0);

  useEffect(() => {
    // if yearValue change then scroll popup to bottom
    const scrollBottom = () => {
      const popup = document.querySelector(".billing-popup") as HTMLDivElement;
      popup.scrollTo({
        top: popup.scrollHeight,
        behavior: "smooth",
      });
    };
    scrollBottom();
  }, [yearValue]);

  const verifyOption = async () => {
    setIsLoading(true);
    const option = document.querySelector(".sec-option") as HTMLDivElement;
    if (yearValue === 0) {
      option.classList.add("error");
      setTimeout(() => {
        option.classList.remove("error");
      }, 550);
    } else {
      option.classList.contains("error") && option.classList.remove("error");
    }
    if (lockdown) {
      return;
    }
    await createBzzStamp();
  };

  const createBzzStamp = async () => {
    const depth = value;
    const amount = yearToAmount(yearValue);
    const productId = targetProduct?.id;
    const productAmount = targetProduct?.price;

    if (productId && productAmount) {
      try {
        const result = await creatStamp(accessToken, stampName, amount, depth, productId, productAmount);

        if (result) {
          console.log(result);
          setIsLoading(false);
          window.location.href = `${window.location.origin}/billing/stamp`;
        }
      } catch (error: any) {
        if (error.response.status === 504) {
          toast.error("Server is down", {
            style: {
              background: "var(--bg-sec)",
              color: "var(--title-white)",
            },
          });
          setIsLoading(false);
        }
        console.log(error.response.status);
      }
    }
  };

  const queryStamp = () => {
    if (yearValue !== 0) {
      const depth = value;
      const amount = yearToAmount(yearValue);
      let list: IProduct[] = productsList;
      list = list.filter((x) => x.name == `BZZ_STAMP_${depth}-${amount}`);
      setTargetProduct(list[0]);
    }
  };

  useEffect(() => {
    queryStamp();
  }, [yearValue, value]);

  return (
    <>
      <Link to="/billing/stamp" className="billing-popup-wrapper backdrop-blue-[2px] fixed top-0 left-0 z-[999] h-full w-full cursor-default bg-black/10 dark:bg-black/30"></Link>
      <div className="billing-popup scrollbar-hidden border-gray fixed max-h-[95vh] overflow-auto rounded-3xl rounded-b-none border bg-white p-6 shadow-lg duration-200 dark:bg-[#16181b] md:max-w-[95vw] md:rounded-b-3xl md:p-6">
        {/* <Link to="/billing/stamp" className="text-main absolute right-8 top-8 z-[999] block text-lg font-medium md:hidden">
          Back
        </Link> */}
        <Link
          to="/billing/stamp"
          className="text-main absolute right-6 top-6 z-[900] grid cursor-pointer place-items-center rounded-full bg-[#e1e7e9] text-lg font-medium dark:bg-gray-800/70"
        >
          <Icon icon="basil:cross-outline" className="h-auto w-8 text-[#7c7e84] dark:text-gray-500" />
        </Link>
        <div className="billing-popup-inner relative">
          <div className="title-white text-2xl font-bold">New Stamp</div>
          <div className="billing-new-option title-white billing-option-border-color mt-8 rounded-xl border-2 p-6 pt-6 pb-5 shadow-sm hover:shadow-lg hover:shadow-blue-100/80 dark:shadow-none md:mt-6">
            <div className="title-white text-lg font-medium md:text-xl">Stamp Name</div>
            <input
              placeholder="Stamp Name"
              className="setting-input add mt-2 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
              value={stampName}
              onChange={(e) => {
                setStampName(e.currentTarget.value);
              }}
              disabled={isloading}
            ></input>
          </div>
          <div className="billing-new-option title-white billing-option-border-color mt-8 rounded-xl border-2 p-6 pt-6 pb-5 shadow-sm hover:shadow-lg hover:shadow-blue-100/80 dark:shadow-none md:mt-6">
            <div className="title-white text-lg font-medium md:text-xl">Storage</div>
            <p className="mt-3 text-2xl font-semibold">{depthToSize(value)}</p>
            <div className="mt-1">
              <Slider
                defaultValue={size[0].value}
                step={null}
                marks={size}
                min={20}
                max={20}
                onChange={handleChange}
                disabled={isloading}
                className="[&>.MuiSlider-rail]:bg-green-500 [&>.MuiSlider-thumb]:bg-green-500"
              />
            </div>
          </div>
          <div className="billing-new-option sec-option title-white billing-option-border-color mt-8 rounded-xl border-2 p-6 pt-6 shadow-sm hover:shadow-lg hover:shadow-blue-100/80 dark:shadow-none">
            <div className="title-white text-lg font-medium md:text-xl">Year</div>
            <div className="mt-4 flex flex-col gap-4">
              <div
                onClick={() => {
                  setYearValue(1);
                }}
                className={
                  !isloading
                    ? `${
                        yearValue === 1 ? "hover-color border-[#32cbae]" : "border-gray"
                      } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`
                    : `border-gray pointer-events-none flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`
                }
              >
                <div
                  className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-[#32cbae] outline-none ${
                    yearValue === 1 ? "border-0 bg-[#32cbae]/70" : "bg-color"
                  } `}
                >
                  {yearValue === 1 && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
                </div>{" "}
                <div data-on={true} className="chunk text-sm font-light">
                  1 Year
                </div>
              </div>
              <div
                onClick={() => {
                  setYearValue(3);
                }}
                className={
                  !isloading
                    ? `${
                        yearValue === 3 ? "hover-color border-[#32cbae]" : "border-gray"
                      } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`
                    : `border-gray pointer-events-none flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`
                }
              >
                <div
                  className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-[#32cbae] outline-none ${
                    yearValue === 3 ? "border-0 bg-[#32cbae]/70" : "bg-color"
                  } `}
                >
                  {yearValue === 3 && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
                </div>{" "}
                <div data-on={true} className="chunk text-sm font-light">
                  3 Year
                </div>
              </div>
              <div
                onClick={() => {
                  setYearValue(5);
                }}
                className={
                  !isloading
                    ? `${
                        yearValue === 5 ? "hover-color border-[#32cbae]" : "border-gray"
                      } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`
                    : `border-gray pointer-events-none flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`
                }
              >
                <div
                  className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-[#32cbae] outline-none ${
                    yearValue === 5 ? "border-0 bg-[#32cbae]/70" : "bg-color"
                  } `}
                >
                  {yearValue === 5 && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
                </div>{" "}
                <div data-on={true} className="chunk text-sm font-light">
                  5 Year
                </div>
              </div>
            </div>
          </div>

          <div className="title-white mt-6 flex items-end justify-between text-base font-semibold">
            <p>Bzz Token :</p>
            <p className="text-1xl">{!targetProduct ? `0` : `${targetProduct.price.toFixed(5)} Bzz`}</p>
          </div>

          <div className="title-white mt-6 flex items-end justify-between text-base font-semibold">
            <p>Bzz Token Price :</p>
            <p className="text-1xl">~0.5754 USD/ Bzz</p>
          </div>

          <div className="title-white mt-4 flex items-end justify-between text-base font-semibold">
            <p>Total</p>
            <p className="text-2xl">{!targetProduct ? `0` : `$${(targetProduct.price * 0.5754).toFixed(5)} USD`}</p>
          </div>

          {!isloading ? (
            <button onClick={verifyOption} className="bg-blue btn-billing-pay relative mt-5 w-full rounded-xl py-5 text-sm font-semibold text-white hover:bg-[#226ddd]">
              Proceed to payment
              <Icon className="btn-billing-pay-icon h-6 w-auto" icon="material-symbols:arrow-right-alt-rounded" />
            </button>
          ) : (
            <button className="bg-blue btn-billing-pay relative mt-5 w-full rounded-xl py-5 text-sm font-semibold text-white hover:bg-[#226ddd]">
              Loading ...
              <Icon className="btn-billing-pay-icon  h-6 w-auto" icon="line-md:loading-twotone-loop" width="30" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const Stamp = () => {
  const accessToken = useSelector(authActions.login).payload.auth.accessToken;
  const [_stamps, setStamps] = useState<IGetStamp[]>();
  const [usage, setUsage] = useState<IGetStampUsage[]>();
  // const [add, setAdd] = useState(false);
  // const location = useLocation();
  const _getStamp = async () => {
    const stamps = await getStamp(accessToken);
    // console.log({ stamps });
    setStamps(stamps);
    const _getStamp: IGetStampUsage = {
      usable: false,
      exists: false,
      expiredAt: 0,
      capacity: {
        total: "",
        used: "",
      },
    };
    const _usages: IGetStampUsage[] = await Promise.all([
      ...stamps.map((stamp_) => {
        return stamp_.batchId ? getStampUsage(accessToken, stamp_.batchId) : _getStamp;
      }),
    ]);
    setUsage(_usages);
  };

  useEffect(() => {
    _getStamp();
  }, []);

  useEffect(() => {
    const contentCon = document.querySelector(".content-con") as HTMLDivElement;
    if (_stamps === undefined || usage === null) {
      contentCon.style.overflow = "hidden";
    } else {
      contentCon.style.overflow = "auto";
    }
  }, [_stamps, usage]);

  // useEffect(() => {
  //   location.pathname.split("/")[3] === "new" ? setAdd(true) : setAdd(false);
  // }, [location]);
  // console.log(Math.floor(usage ? usage[0]?.expiredAt / (3600 * 24) : 0));

  return (
    <div className="stamp-con pb-mobile mt-6 md:pb-10">
      <Popup />
      <div className="billing-stamp-con gap-6">
        <Link to="/billing/stamp/new" className="btn-add-stamp bg-main border-gray main-box-shadow grid h-[197px] min-w-[210px] cursor-pointer place-items-center rounded-xl p-4">
          <div className="text-[#32cbae]">
            <Icon icon="ic:round-add" className="mx-auto h-16 w-auto" />
            <p className="mt-2 font-medium">New Stamp</p>
          </div>
        </Link>
        {_stamps === undefined || usage === null ? (
          [...Array(100)].map((e) => <div className="skeleton h-[197px] w-full"></div>)
        ) : usage === null || _stamps.length === 0 ? (
          <>{/* <div className="bg-white">you dont have any stamp</div> */}</>
        ) : (
          _stamps.map((e: IGetStamp, i: number) =>
            usage !== undefined && usage[i]?.capacity !== undefined && usage[i]?.capacity.used !== undefined ? (
              <Box
                key={i}
                usage={parseInt(usage[i]?.capacity.used ?? "0") / 1024 / 1024 / 1024}
                day={Math.floor(usage[i]?.expiredAt / (3600 * 24))}
                storage={parseInt(usage[i]?.capacity.total ?? "0") / 1024 / 1024 / 1024}
                // usage={1099511627776} day={365} storage={1099511627776}
                name={e.name || `BZZ stamp ${i + 1}`}
              />
            ) : (
              <></>
            ),
          )
        )}
      </div>
    </div>
  );
};

export default Stamp;
