import React, { useEffect, useState, useRef, useCallback } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import "./builder_style.css";
import devices from "../Components/builder-component/deviceManager/Device";
import blocks from "../Components/builder-component/blockManager/Block";
import storage from "../Components/builder-component/storageManager/Storage";
import panel from "../Components/builder-component/panelManager/Panel";
import command from "../Components/builder-component/commandManager/Command";
import DeDrivePlugin from "../Components/builder-component/plugin/dedrive-plugin";
import ZipPlugin from "../Components/builder-component/plugin/zip-plugin";
import ImportPlugin from "../Components/builder-component/plugin/custom-code-plugin";
import "grapesjs-plugin-ckeditor/dist/grapesjs-plugin-ckeditor.min.js";
import "grapesjs/dist/grapes.min.js";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { Iuser } from "../interface/get-user.interface";
import { IGetPods, IPod } from "../interface/get-pod-interface";
import { getLockdownStatus } from "../api/lockdown/getLockdownStatus";
import { useKeycloak } from "@react-keycloak/web";
import { getPod, getPodCount } from "../api/get-pod";
import Select from "react-select";
import { Icon } from "@iconify/react";
import ICreatePod, { IStamp, podType, provideType } from "../interface/create-pod.interface";
import { getStamp, IGetStamp } from "../api/stamp/get-stamp";
import { getStampUsage, IGetStampUsage } from "../api/stamp/get-stamp-usage";
import { creatPod } from "../api/create-pod";
import toast, { Toaster } from "react-hot-toast";
import { IPresignedUrlMulti, presignUrlMulti } from "../api/presigned-url-multi";
import { uploadFile } from "../api/upload-file";
import { ICreateItem } from "../interface/create-item-interface";
import { creatItem } from "../api/create-Item";
import CoverStyle from "../Components/builder-component/blockManager/template/testPage/styles/style1";
import CoverPage from "../Components/builder-component/blockManager/template/testPage/testpage7/testpage7";
import pageStyleEngine from "../Components/builder-component/styleManager/pageStyleEngine";
import { toEditorSettings } from "typescript";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import grapesjsCustomPageCounter from "../Components/builder-component/grapesjs-custom-page-counter";
import useWindowDimensions from "../Hook/useWindowDimensions";

interface IOutput {
  key: string;
  value: Buffer;
}
const options = [
  { value: provideType.IPFS, label: "IPFS" },
  // { value: provideType.ARWEAVE, label: "Arweave" },
  { value: provideType.BTFS, label: "BTFS" },
  { value: provideType.BZZ, label: "BZZ" },
  // { value: provideType.ETD, label: "ETD" },
  // { value: provideType.FILECOIN, label: "FileCoin" },
  // { value: provideType.SIA, label: "SIA" },
  // { value: provideType.STORJ, label: "STORJ" },
  // { value: provideType.ZEROCHAIN, label: "0Chain" },
];

const Builder = () => {
  const user: Iuser = useSelector(authActions.login).payload.auth.user;
  const [pods, setPods] = useState<IGetPods>();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const { width } = useWindowDimensions();
  const { keycloak } = useKeycloak();
  const [style, setStyle] = useState("");
  const [uploadData, setUploadData] = useState<[] | null>(null);
  const [useExistsPod, setUseExistsPod] = useState<boolean>(true);
  const [bzzStampList, setBzzStampList] = useState<{ value: IStamp; label: string }[]>([]);
  const [chunk, setChunk] = useState(false);
  const [access, setAccess] = useState(false);
  const _accessToken: string = useSelector(authActions.login).payload.auth.accessToken;
  const [targetPod, setTargetPod] = useState<IPod | null>(null);
  const [upLoading, setUploading] = useState(false);
  const userId = useSelector(authActions.login).payload.auth.user.sub;
  const [recoveryEnable, setRecoveryEnable] = useState(false);
  const [pages, setPages] = useState<any[]>([]);
  const [importing, setImporting] = useState(false);
  const [useEditor, setUseEditor] = useState<grapesjs.Editor | undefined>(undefined);
  type Editor = grapesjs.Editor;
  let editor: Editor;
  const [createPodInfo, setCreatePodInfo] = useState<{
    name: string;
    primaryProvider: provideType | undefined;
    providers: provideType[];
    publicAccessible: boolean;
    type: podType;
    address: string;
    owner: string;
    chunking: boolean;
    bzzStamp?: {
      defaultIndex: number;
      stamps: IStamp[];
    };
    recovery: {
      threshold: number;
      enable: boolean;
    };
  }>({
    name: "",
    primaryProvider: undefined,
    providers: [],
    publicAccessible: false,
    type: podType.FULLY_MANAGED,
    address: "",
    chunking: false,
    owner: user.sub,
    recovery: {
      threshold: 0,
      enable: false,
    },
  });
  useEffect(() => {
    async function exec() {
      if (keycloak.authenticated) {
        await keycloak.loadUserInfo();
        const _user: Iuser = keycloak.userInfo as Iuser;
        const _accessToken = keycloak.token;
        if (_accessToken == undefined) {
          return;
        }
        const lockdownStatus = await getLockdownStatus(_accessToken);
        setAccessToken(_accessToken);
      }
    }
    exec();
  }, [keycloak.authenticated]);
  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      setStyle("#fff");
    } else {
      setStyle("#000");
    }
  }, [localStorage.getItem("theme")]);

  useEffect(() => {
    window.analytics.page("Web Builder");
  }, []);

  useEffect(() => {
    const get_Pod = async () => {
      if (accessToken && user.sub) {
        const podCount = await getPodCount(accessToken, user.sub);
        if(podCount>0){
          const response = await getPod(accessToken, user.sub, "0", podCount);
          setPods(response);
        }
        _getStamp();
      }
    };
    get_Pod();
  }, [accessToken, user.sub]);
  useEffect(() => {
    if (uploadData !== null) {
      console.log(uploadData);
    }
  }, [uploadData]);
  function callbackSetData(data: any) {
    setUploadData(data);
  }
  const _getStamp = async () => {
    const stampArray: any = [];
    const stamps = await getStamp(_accessToken);
    stamps.map((e: IGetStamp, i) =>
      stampArray.push({
        value: {
          batchId: e.batchId,
          // apiUrl: e.node.apiUrl,
          // debugApiUrl: e.node.debugUrl,
        },
        label: e.name || `BZZ Stamp ${i + 1}`,
      }),
    );
    setBzzStampList(stampArray);
  };

  useEffect(() => {
    console.log("userId", userId);

    editor = grapesjs.init({
      container: "#gjs", // Create the tage and set the id value : gjs
      fromElement: true,
      pluginsOpts: { setData: callbackSetData, importEvent: importEvent },
      height: "100%", // Default height value
      width: "100%", // Default width +value
      showOffsets: true,
      storageManager: storage(user.sub),
      deviceManager: devices,
      blockManager: blocks,
      styleManager: pageStyleEngine, // You can enable this fuction, for reset the default settings styleManager
      commands: command, //  Edit the command for the device or other function (panels+commands)
      panels: panel, //  Change the editor tools navBar (panels+commands)
      dragMode: "translate", // 'absolute' | 'translate'
      plugins: [DeDrivePlugin, ZipPlugin, ImportPlugin, grapesjsCustomPageCounter],
    });
    editor.setStyle(CoverStyle);
    editor.setComponents(CoverPage); //Set the coverPage
  }, []);
  console.log(pages);
  function importEvent() {
    // console.log(editor)
    // setUseEditor(editor)
    setImporting(true);
  }
  function MyDropzone() {
    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
          const reader = new FileReader();

          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = () => {
            // Do whatever you want with the file contents
            const binaryStr = reader.result;
            const importJson: { "index.html": string; "style.css": string } = JSON.parse(binaryStr as string);
            if (useEditor) {
              useEditor.setComponents(importJson["index.html"]);
              useEditor.setStyle(importJson["style.css"]);
              useEditor.refresh();
              setImporting(false);
            } else {
              toast.error("Missing Editor, wait for fix.");
            }
          };
          reader.readAsText(file);
        });
      },
      [useEditor],
    );
    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({ onDrop });

    return (
      <div {...getRootProps()}>
        {/*<input className="x absolute top-0 left-0 z-[600] grid h-full w-full place-items-center rounded-2xl rounded-t-none border-2 border-dashed border-blue-500 bg-blue-500/30 text-2xl font-medium text-gray-200" {...getInputProps()} />*/}
        {/*<p className="title-white" >Drag 'n' drop some files here, or click to select files</p>*/}
        {isDragActive && (
          <div className="x absolute top-0 left-0 z-[600] grid h-full w-full place-items-center rounded-2xl rounded-t-none border-2 border-dashed border-blue-500 bg-blue-500/30 text-2xl font-medium text-gray-200"></div>
        )}
        <div onClick={open} className="title-white  top-0 left-0 z-[300] flex h-full w-full select-none flex-col items-center justify-center rounded-md">
          <Icon icon="icon-park-solid:upload-web" className="billing-icon-color h-auto w-16" />
          <p className="mt-4 text-base text-[#70757c]">{width > 768 ? "Click or Drag file here to upload" : "Click here to upload"}</p>
        </div>
      </div>
    );
  }

  const handleCreatePodStorage = async (e: any) => {
    let tmpArray = [...createPodInfo.providers];
    const _tmpArray: provideType[] = [];
    tmpArray = e;

    tmpArray.forEach((e: any) => {
      _tmpArray.push(e.value);
    });

    setCreatePodInfo({ ...createPodInfo, providers: _tmpArray });
  };

  const renderPod = () => {
    const renderlist: any[] = [];
    pods?.data.forEach((pod) => {
      renderlist.push({ value: pod, label: pod.name });
    });
    return renderlist;
  };

  const manualSave = async () => {
    const projectData = editor.getProjectData();
    await editor.store(projectData);
  };

  const handleSelectBzzStamp = async (e: any) => {
    const cache = createPodInfo;
    // if (e.length > 0) {
    if (e.value) {
      // const stampList: IStamp[] = [];
      // e.map((x: { label: string; value: IStamp }) => {
      //   stampList.push(x.value);
      // });
      cache.bzzStamp = {
        defaultIndex: 0,
        stamps: [e.value],
      };
      // console.log({ cache });
    } else {
      cache.bzzStamp = undefined;
    }
    setCreatePodInfo(cache);
  };
  const renderPrimaryOption = () => {
    const renderlist: any[] = [];
    createPodInfo.providers.forEach((element) => {
      renderlist.push({ value: element, label: element });
    });
    return renderlist;
  };
  const handleCreatePodPrimaryStorage = (e: any) => {
    setCreatePodInfo({
      ...createPodInfo,
      primaryProvider: e.value,
    });
  };

  const handleUpload = async (url: string, index: number, type: string) => {
    // const blob = new Blob()
    const file = new File([(uploadData![index] as { key: string; value: Buffer }).value], (uploadData![index] as { key: string; value: Buffer }).key);
    const response = await uploadFile(_accessToken, file, url, type, user.sub);
    return response;
  };

  const handleItemCreateMuti = async (
    name: string, // file name
    size: number,
    _targetPod: IPod,
  ) => {
    const input: ICreateItem = {
      podName: _targetPod!.name,
      prefix: _targetPod!.name,
      name: name,
      type: "File",
      size: size,
      eTag: " ",
    };

    const res = await creatItem(_accessToken, input, user.sub);
    if (res.status === 400) {
      toast.error(`${res.messages.toString()}`);
      throw new Error(res.messages.toString());
    }
  };

  const uploadWebsite = async (pod: IPod) => {
    // try {
    const input: IPresignedUrlMulti[] = [];
    (uploadData as { key: string; value: Buffer }[]).forEach((element: { key: string; value: Buffer }, i: any) => {
      input.push({
        id: `${i}`,
        key: `${pod?.name}/${element.key}`,
        size: element.value.byteLength,
        type: element.key === "index.html" ? "text/html" : element.key === "style.css" ? "text/css" : "text/javascript",
      });
    });
    const response = await presignUrlMulti(_accessToken, input, user.sub);
    for (const c of Object.keys(response.data.urls)) {
      const targetItem = uploadData![parseInt(c)] as { key: string; value: Buffer };
      const current = response.data.urls[parseInt(c)];
      // await new Promise(r => setTimeout(r, 1000));
      const res = await handleUpload(current, parseInt(c), targetItem.key === "index.html" ? "text/html" : targetItem.key === "style.css" ? "text/css" : "text/javascript");
      if (res !== null) {
        await handleItemCreateMuti(targetItem.key, targetItem.value.byteLength, pod);
      }
    }
    toast.success("Upload Website Success, \npage will be redirect after 5s");
    await new Promise((f) => setTimeout(f, 5000));
    window.location.pathname = `/pod/info/${pod.name}/items`;
    // }catch (e) {
    //   toast.error("error")
    // }
  };
  const handleCreatePod = async () => {
    if (accessToken && createPodInfo) {
      if (createPodInfo.providers.includes(provideType.BZZ) && !createPodInfo.bzzStamp) {
        alert("If use Bzz as provider, must select at least one bzz stamp");
        return;
      }

      const input: ICreatePod = createPodInfo;
      input.owner = user.sub;
      input.chunking = chunk;
      setCreatePodInfo(input);
      if (user) {
        const result = await creatPod(user.sub, accessToken, input);
        console.log({ result });
      } else {
        alert("Can not retreive your user, Please Login Again");
      }
      toast.success(`Success to Create Pod ${createPodInfo.name}`);
    } else {
      throw new Error("accessToken expired");
    }
  };

  return (
    <div className="content-con overflow-hidden p-0">
      {/* <div> */}
      <div>{/* <PageSection pages={pages} /> */}</div>
      {/* <div className="MainContent"> */}
      <Toaster />
      <div id="gjs"></div>
      {/* </div> */}
      {/* </div> */}
      <div
        data-show={uploadData !== null || importing}
        onClick={() => {
          setUploadData(null);
          setImporting(false);
        }}
        className="create-bucket-wrapper backdrop-blue-[2px] fixed left-0 top-0 z-[800] h-full w-full bg-black/10"
      ></div>

      <div
        data-show={importing}
        className="create-bucket-con website create-border-gray bg-color scrollbar-hidden z-[850] flex max-h-[80vh] flex-col justify-between overflow-auto rounded-3xl border-2 px-6 md:max-w-[95vw]"
      >
        <h3 className="title-white mb-4 mt-6 text-xl font-semibold">Import Html</h3>
        {MyDropzone()}
        <h3 className="title-white mb-4 mt-6 text-xl font-semibold"></h3>
      </div>
      <div
        data-option={useExistsPod}
        data-show={uploadData !== null}
        className="create-bucket-con website create-border-gray bg-color scrollbar-hidden z-[850] flex max-h-[80vh] flex-col justify-between overflow-auto rounded-3xl border-2 px-6 md:max-w-[95vw]"
      >
        <h3 className="title-white mb-4 mt-6 text-xl font-semibold">Upload website to pod</h3>
        <div className="border-gray relative flex rounded-lg border p-1">
          <div data-option={useExistsPod} className="upload-website-option-wrapper"></div>
          <div onClick={() => setUseExistsPod(true)} className={`upload-website-option ${useExistsPod && "text-white"}`}>
            Use existed pod
          </div>
          <div onClick={() => setUseExistsPod(false)} className={`upload-website-option ${!useExistsPod && "text-white"}`}>
            Create new pod
          </div>
        </div>
        {/* <div style={{ display: "flex" }} className="mb-2">
          <input
            type="radio"
            name="existedPod"
            defaultChecked={true}
            onChange={(e) => {
              setUseExistsPod(true);
            }}
          />{" "}
          <h6 className="title-white ml-2 text-[12px]"> Use Existed Pod</h6>
        </div>

        <div style={{ display: "flex" }} className="mb-2">
          <input
            type="radio"
            name="existedPod"
            onChange={(e) => {
              setUseExistsPod(false);
            }}
          />{" "}
          <h6 className="title-white ml-2 text-[12px]"> Create a new Pod</h6>
        </div> */}
        {useExistsPod && (
          <div className="fade-in">
            <h3 className="title-white mt-4 text-[13px] font-medium">Select pod</h3>
            <Select
              className="mt-2"
              options={renderPod()}
              onChange={(e) => setTargetPod(e.value)}
              styles={{
                container: (base) => ({
                  ...base,
                  fontSize: "12px",
                }),
                control: (base) => ({
                  ...base,
                  fontSize: "12px",
                  borderRadius: "0.5rem",
                  border: "2px solid var(--border-gray)",
                  backgroundColor: "var(--bg-sec)",
                  "&:hover": {
                    borderColor: "var(--blue)",
                  },
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: "0.75rem",
                  backgroundColor: "var(--bg-sec)",
                  borderTopLeftRadius: "0.5rem",
                  borderBottomLeftRadius: "0.5rem",
                  color: "var(--title-white)",
                }),

                menu: (base) => ({
                  ...base,
                  backgroundColor: "var(--bg-sec)",
                  color: "var(--title-white)",
                  overflow: "auto",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--bg-sec)",
                  border: "0px",
                  "&:hover": {
                    backgroundColor: "#2971db",
                    color: "#fff",
                  },
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: "#2971db",
                  borderRadius: "0.25rem",
                  "&:hover": {
                    backgroundColor: "#2971db",
                  },
                  color: "#fff",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: "#fff",
                }),
                input: (base, state) => ({
                  ...base,
                  color: `${style}`,
                }),
                singleValue: (base, state) => ({
                  ...base,
                  color: `${style}`,
                }),
              }}
            />
          </div>
        )}
        {useExistsPod === false && (
          <div className="fade-in mt-4">
            {/*<div className="bg-blue mb-6 grid aspect-square w-[64px] place-items-center rounded-full">*/}
            {/*  <Icon icon="tabler:bucket" className="h-auto w-8 text-white" />*/}
            {/*</div>*/}
            {/* <h1 className="title-white text-2xl font-semibold">Create Pod</h1> */}
            <h3 className="title-white text-[13px] font-medium">Pod Name</h3>
            <input
              placeholder="Bucket Name"
              className="setting-input border-gray mt-2 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
              value={createPodInfo?.name}
              onChange={(e) => {
                setCreatePodInfo({
                  ...createPodInfo,
                  name: e.currentTarget.value,
                });
              }}
            ></input>
            <h3 className="title-white mt-5 text-[13px] font-medium">Storage Provider</h3>
            <Select
              className="mt-2"
              isMulti
              options={options}
              isSearchable={true}
              onChange={(e) => {
                handleCreatePodStorage(e);
              }}
              styles={{
                container: (base) => ({
                  ...base,
                  fontSize: "12px",
                }),
                control: (base) => ({
                  ...base,
                  fontSize: "12px",
                  borderRadius: "0.5rem",
                  border: "2px solid var(--border-gray)",
                  backgroundColor: "var(--bg-sec)",
                  "&:hover": {
                    borderColor: "var(--blue)",
                  },
                  zIndex: 999,
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: "0.75rem",
                  backgroundColor: "var(--bg-sec)",
                  borderTopLeftRadius: "0.5rem",
                  borderBottomLeftRadius: "0.5rem",
                  color: "var(--title-white)",
                }),

                menu: (base) => ({
                  ...base,
                  backgroundColor: "var(--bg-sec)",
                  color: "var(--title-white)",
                  overflow: "auto",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--bg-sec)",
                  border: "0px",
                  "&:hover": {
                    backgroundColor: "#2971db",
                    color: "#fff",
                  },
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: "#2971db",
                  borderRadius: "0.25rem",
                  "&:hover": {
                    backgroundColor: "#2971db",
                  },
                  color: "#fff",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: "#fff",
                }),
                input: (base, state) => ({
                  ...base,
                  color: `${style}`,
                }),
                singleValue: (base, state) => ({
                  ...base,
                  color: `${style}`,
                }),
              }}
            ></Select>
            {createPodInfo.providers.includes(provideType.BZZ) ? (
              <>
                {" "}
                <h3 className="title-white mt-5 text-[13px] font-medium">Bzz Stamp</h3>
                <Select
                  className="mt-2"
                  options={bzzStampList}
                  isSearchable={true}
                  onChange={(e) => {
                    handleSelectBzzStamp(e);
                  }}
                  styles={{
                    container: (base) => ({
                      ...base,
                      fontSize: "12px",
                    }),
                    control: (base) => ({
                      ...base,
                      fontSize: "12px",
                      borderRadius: "0.5rem",
                      border: "2px solid var(--border-gray)",
                      backgroundColor: "var(--bg-sec)",
                      "&:hover": {
                        borderColor: "var(--blue)",
                      },
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      padding: "0.75rem",
                      backgroundColor: "var(--bg-sec)",
                      borderTopLeftRadius: "0.5rem",
                      borderBottomLeftRadius: "0.5rem",
                      color: "var(--title-white)",
                    }),

                    menu: (base) => ({
                      ...base,
                      backgroundColor: "var(--bg-sec)",
                      color: "var(--title-white)",
                      overflow: "auto",
                    }),
                    option: (base) => ({
                      ...base,
                      backgroundColor: "var(--bg-sec)",
                      border: "0px",
                      "&:hover": {
                        backgroundColor: "#2971db",
                        color: "#fff",
                      },
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: "#2971db",
                      borderRadius: "0.25rem",
                      "&:hover": {
                        backgroundColor: "#2971db",
                      },
                      color: "#fff",
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: "#fff",
                    }),
                    input: (base, state) => ({
                      ...base,
                      color: `${style}`,
                    }),
                    singleValue: (base, state) => ({
                      ...base,
                      color: `${style}`,
                    }),
                  }}
                ></Select>
              </>
            ) : (
              <></>
            )}

            <h3 className="title-white mt-5 text-[13px] font-medium">Primary Provider</h3>
            <Select
              className="mt-2"
              options={renderPrimaryOption()}
              onChange={(e) => handleCreatePodPrimaryStorage(e)}
              styles={{
                container: (base) => ({
                  ...base,
                  fontSize: "12px",
                }),
                control: (base) => ({
                  ...base,
                  fontSize: "12px",
                  borderRadius: "0.5rem",
                  border: "2px solid var(--border-gray)",
                  backgroundColor: "var(--bg-sec)",
                  "&:hover": {
                    borderColor: "var(--blue)",
                  },
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: "0.75rem",
                  backgroundColor: "var(--bg-sec)",
                  borderTopLeftRadius: "0.5rem",
                  borderBottomLeftRadius: "0.5rem",
                  color: "var(--title-white)",
                }),

                menu: (base) => ({
                  ...base,
                  backgroundColor: "var(--bg-sec)",
                  color: "var(--title-white)",
                  overflow: "auto",
                }),
                option: (base) => ({
                  ...base,
                  backgroundColor: "var(--bg-sec)",
                  border: "0px",
                  "&:hover": {
                    backgroundColor: "#2971db",
                    color: "#fff",
                  },
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: "#2971db",
                  borderRadius: "0.25rem",
                  "&:hover": {
                    backgroundColor: "#2971db",
                  },
                  color: "#fff",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: "#fff",
                }),
                input: (base, state) => ({
                  ...base,
                  color: `${style}`,
                }),
                singleValue: (base, state) => ({
                  ...base,
                  color: `${style}`,
                }),
              }}
            ></Select>

            <div className="mt-5 flex flex-col justify-between gap-2">
              <span className="title-white text-[13px] font-medium">Chunk Pod</span>
              <div
                onClick={() => {
                  setChunk(!chunk);
                }}
                className={`${
                  chunk ? "hover-color border-blue-500" : "border-gray"
                } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`}
              >
                <div
                  className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-blue-300 outline-none ${
                    chunk ? "bg-blue border-0" : "bg-color"
                  } `}
                >
                  {chunk && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
                </div>
                <div data-on={true} className="chunk font-light">
                  Chunk Items
                </div>
              </div>
              <span className="title-white mt-3 text-[13px] font-medium">Access Control</span>
              <div
                onClick={() => {
                  setAccess(!access);
                  setCreatePodInfo({
                    ...createPodInfo,
                    publicAccessible: !access,
                  });
                }}
                className={`${
                  access ? "hover-color border-blue-500" : "border-gray"
                } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`}
              >
                <div
                  className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-blue-300 outline-none ${
                    access ? "bg-blue border-0" : "bg-color"
                  } `}
                >
                  {access && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
                </div>
                <div data-on={true} className="chunk font-light">
                  Public Access
                </div>
              </div>
              <span className="title-white mt-3 text-[13px] font-medium">Data Recovery</span>
              <hr />
              <span className="title-white mt-3 text-[13px] font-medium">Enable</span>
              <div
                onClick={() => {
                  setRecoveryEnable(!recoveryEnable);
                  setCreatePodInfo({
                    ...createPodInfo,
                    recovery: {
                      enable: !recoveryEnable,
                      threshold: createPodInfo.recovery.threshold,
                    },
                  });
                }}
                className={`${
                  recoveryEnable ? "hover-color border-blue-500" : "border-gray"
                } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`}
              >
                <div
                  className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-blue-300 outline-none ${
                    recoveryEnable ? "bg-blue border-0" : "bg-color"
                  } `}
                >
                  {recoveryEnable && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
                </div>
                <div data-on={true} className="chunk font-light">
                  Enable Data Recovery
                </div>
              </div>
              {createPodInfo.recovery.enable ? (
                <>
                  <h3 className="title-white mt-4 text-[13px] font-medium">Threshold</h3>
                  <input
                    type="number"
                    placeholder="Recovery Threshold"
                    className="setting-input border-gray mt-2 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                    value={createPodInfo?.recovery.threshold}
                    onChange={(e) => {
                      setCreatePodInfo({
                        ...createPodInfo,
                        recovery: {
                          enable: createPodInfo.recovery.enable,
                          threshold: parseInt(e.currentTarget.value),
                        },
                      });
                    }}
                  ></input>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        <button
          onClick={async () => {
            try {
              if (useExistsPod && !upLoading) {
                setUploading(true);
                if (targetPod !== null) {
                  await uploadWebsite(targetPod);
                } else {
                  alert("Pod");
                }
              } else {
                setUploading(true);
                await handleCreatePod();
                const podCount = await getPodCount(_accessToken, user.sub);
                const response = await getPod(_accessToken, user.sub, "0", podCount);
                if (response) {
                  const catchPod = response.data.filter((x) => x.name === createPodInfo.name)[0];
                  await uploadWebsite(catchPod);
                }
              }
              setUploading(false);
            } catch (e) {
              setUploading(false);
              toast.error((e as Error).message);
            }

            // ;
          }}
          className="bg-blue mt-6 mb-6 w-full rounded-xl p-4 text-[13px] font-medium text-white hover:bg-[#226ddd] md:text-sm"
        >
          {upLoading ? (
            <div className="flex justify-center">
              <Icon icon="line-md:uploading-loop" className="h-auto w-5"></Icon>
            </div>
          ) : (
            "upload"
          )}
        </button>
      </div>
    </div>
  );
};

export default Builder;
