import axios from "axios";
import config from "../config";
import fs from "fs";
import path from "path";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { useEffect } from "react";
export async function downloadFiles(accessToken: string, fileName: string, file: string,filesize:number,uid:string,callback?:(name:string,percentCompleted:number,filesize:number,fileName:string)=>void): Promise<any> {
  const url = new URL(`/v1/access/${fileName}`, config.api.download_gateway);
  const res = await axios
    .get(url.href, {
      responseType: "blob",
      onDownloadProgress:function(progressEvent) {
        const percentCompleted = progressEvent.total!==0 && progressEvent.total?Math.round((progressEvent.loaded * 100) / progressEvent.total ):Math.round( (progressEvent.loaded * 100) / filesize);
        if (callback) {
          console.log({progressEvent})
          callback(`Download-${fileName}`, percentCompleted, filesize, fileName);
        }
      }
    })
    .then((response) => {
      const filename = file.split("/").pop();

      if (!filename) {
        throw new Error("File Name Error");
      }
      window.analytics.track('Download Public File - Success',{
        cid:fileName,
        userId:uid,
        date:new Date().toLocaleString()
      })
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });

  return res;
}

export function getDownloadFilesLink(accessToken: string, fileName: string): string {
  const link = `${config.api.download_gateway}/v1/access/${fileName}`;

  return link;
}

export function getPreviewFilesLinkVideo(accessToken: string, fileName: string): string {
  let prefix;
  if(config.env === 'production'){
    prefix= 'https://app.dedrive.io/preview/video/'
  }else{
    prefix= 'https://app.dev.dedrive.io/preview/video/'
  }
  const link = `${prefix}${fileName}`;

  return link;
}
export function getPreviewFilesLinkImage(accessToken: string, fileName: string): string {
  let prefix;
  if(config.env === 'production'){
    prefix= 'https://app.dedrive.io/preview/image/'
  }else{
    prefix= 'https://app.dev.dedrive.io/preview/image/'
  }
  const link = `${prefix}${fileName}`;

  return link;
}
