import axios from "axios";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useParams } from "react-router-dom";
import TopNav from "../Components/TopNav";
import logo from "../Static/logo.webp";
import { Helmet } from "react-helmet";
import config from "../config";

const VideoPlayer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [url,setUrl]=useState<string|undefined>(undefined)
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  useEffect(() => {
    console.log(config.env)
    if(config.env === 'production'){
      setUrl(`https://gateway.dedrive.io/v1/access/${id}`)
    }else{
      setUrl(`https://dev.gateway.dedrive.io/v1/access/${id}`)
    }




  }, [id]);
  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div style={{display:'flex',justifyContent:'center'}}>
      {url ? (
          // <ReactPlayer url={url} controls={true} width="80%" height="80%" />
        <video controls={true} autoPlay={true} style={{width:"auto", height:size.height-100}} >
          <source src={url} type="video/mp4"/>
        </video>
      ) : (
        <p>Loading video...</p>
      )}
    </div>
  );
};


const PreviewPage: React.FC = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  useEffect(() => {
    if (theme === null) {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    }
  });

  useEffect(() => {
    theme === "dark" ? (document.body.style.background = "#111315") : (document.body.style.background = "#ffffff");
  });
  return (
    <>
    <Helmet>{theme === "dark" ? <meta name="theme-color" content="#111315"></meta> : <meta name="theme-color" content="#ffffff"></meta>}</Helmet>
      <div data-theme={theme} className={`app ${theme === "dark" && "dark"}`}>
      <nav className="top-nav main-theme gap-6">
        <div className="flex h-full items-center gap-6">
          <Link to="/pod" className="flex select-none items-center gap-3">
            <img src={logo} className="logo-img max-w-[35px] md:max-w-[37px]" />
            <p className="text-lg font-[500] text-[#4883e2] dark:text-white md:text-[20px]">DeDrive</p>
          </Link>
          <div className="hidden h-[18px] w-[2px] rounded-full bg-[#e1e0e3] md:block"></div>
        </div>
      </nav>
      <VideoPlayer />
    </div>
      </>
  );
};

export default PreviewPage;
