import axios from "axios";
import config from "../config";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { errorHandler } from "./presigned-url-multi";

export interface IPresignedUrl {
  key: string;
  size: number;
  type: string;
}

export interface IPresignedUrlRes {
  status: number;
  messages: string[];
  time: Date;
  data: {
    url: string;
  };
}

export async function presignUrl(accessToken: string, input: IPresignedUrl, userId: string): Promise<IPresignedUrlRes> {
  const url = new URL(`/v1/storage/upload/url`, config.api.endpoint);
  const response = await axios.post(url.href, input, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  errorHandler(response.data.status, response.data.messages[0], "presignUrl", userId);
  return response.data;
}
