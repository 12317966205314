import { Icon } from "@iconify/react";
import React, { ReactNode, useEffect, useState, useRef } from "react";
import PodCon from "../Components/PodCon";
import Select from "react-select";
import useWindowDimensions from "../Hook/useWindowDimensions";
import { creatPod } from "../api/create-pod";
import ICreatePod, { IStamp, podType, provideType } from "../interface/create-pod.interface";
import { IGetPods, IPod } from "../interface/get-pod-interface";
import { getPod, getPodCount } from "../api/get-pod";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { IUpdateChunk, updateChunkPod } from "../api/chunk-pod";
import { deletePod } from "../api/delete-pod";
import config from "../config";
import { getStamp, IGetStamp } from "../api/stamp/get-stamp";
import { getLockdownStatus } from "../api/lockdown/getLockdownStatus";
import { updateStamp } from "../api/stamp/update-stamp-usable";
import server from "../Static/server.svg";
import { getStampUsage, IGetStampUsage } from "../api/stamp/get-stamp-usage";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../Static/logo.webp";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import { deleteItem } from "../api/item/deleteItem";
import { podListActions } from "../store/pod-list-slice";
interface Iuser {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  preferred_username: string;
  sub: string;
}

interface BoxProps {
  name: string;
  usage: any;
  day: number;
  storage: number;
}

const Box = (props: BoxProps) => {
  return (
    <div className="bg-main border-gray-sec fade-in min-w-[250px] shrink-0 rounded-xl border p-4">
      <div className="flex justify-between">
        <div
          className={`grid place-items-center rounded-md p-2 ${
            props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red billing-icon-red" : "billing-icon-color billing-usage-bg-blue"
          }`}
        >
          <Icon icon="fluent:storage-20-regular" className=" h-10 w-auto" />
        </div>
        <div
          className={`h-fit rounded-lg p-3 py-2 text-xs ${
            props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red billing-icon-red" : "billing-icon-color billing-usage-bg-blue"
          }`}
        >
          {props.day} Day Left
        </div>
      </div>
      <div className="title-white mt-4 truncate text-xl font-semibold">{props.name}</div>
      <div className={`relative mt-4 h-[7px] w-full rounded-full ${props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red" : "billing-usage-bg-blue"}`}>
        <div
          className={`${props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-red" : "billing-usage-blue"} absolute top-0 left-0 h-[7px] rounded-full`}
          style={{ width: (props.usage / props.storage) * 100 + "%" }}
        ></div>
      </div>
      <div className="mt-3 flex select-none justify-between text-sm font-medium text-[#aaabaf]">
        <p>{props.usage} GB</p>
        <p>{props.storage} GB</p>
      </div>
    </div>
  );
};

const provider = Object.keys(provideType);
const Pod = () => {
  const { height, width } = useWindowDimensions();
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [overflowTop, setOverflowTop] = useState(false);
  const [overflowBottom, setOverflowBottom] = useState(false);
  const [click, setClick] = useState(-1);
  const [editItem, setEditItem] = useState(false);
  const [chunk, setChunk] = useState(false);
  const [recoveryEnable, setRecoveryEnable] = useState(false);
  const confirmDeleteRef = useRef<HTMLInputElement>(null);
  const [access, setAccess] = useState(false);
  const [create, setCreate] = useState(false);
  const [pods, setPods] = useState<IGetPods>();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const { keycloak, initialized } = useKeycloak();
  const [seletedProvider, setSeletedProvider] = useState<provideType[]>([]);
  const [style, setStyle] = useState("");
  const [podLoading, setPodLoading] = useState(true);
  const [lockdownLoad, setLockDownLoad] = useState(true);
  const [userPodCount, setUserPodCount] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [lockdown, setLockdown] = useState<boolean | null>(null);
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [stamps, setStamps] = useState([]);
  const [handlePodNum, setHandlePodNum] = useState<number>(width > 768 ? (height > 1475 ? 10 : config.listPodNumber) : 100);
  const [listPodNumber, setListPodNumber] = useState<number>(width > 768 ? (height > 1475 ? 10 : config.listPodNumber) : 100);
  const [bzzStampList, setBzzStampList] = useState<{ value: IStamp; label: string; status: string }[]>([]);
  const [isStampFetched, setIsStampFetched] = useState<boolean>(false);
  const dispatch = useDispatch();
  const user: Iuser = useSelector(authActions.login).payload.auth.user;
  const _accessToken: string = useSelector(authActions.login).payload.auth.accessToken;
  const [reload, setReload] = useState(false);
  const [usage, setUsage] = useState<IGetStampUsage[] | null>(null);
  const [orderBy, setOrderBy] = useState<boolean>(true);
  const location = useLocation();
  const [orderType, setOrderType] = useState<string>("createdAt");
  const [ipfsWarning, setipfsWarning] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isCreatePod, setIsCreatePod] = useState(false);

  const updateLockDown = (packages: { lockdown: null | boolean }) => {
    dispatch(authActions.updateLockDown(packages));
  };

  const _getStamp = async () => {
    const stampArray: any = [];
    const stamps = await getStamp(_accessToken);
    stamps.map((e: IGetStamp, i) =>
      stampArray.push({
        value: {
          batchId: e.batchId,
          // apiUrl: e.node.apiUrl,
          // debugApiUrl: e.node.debugUrl,
        },
        label: e.name || `BZZ Stamp ${i + 1}`,
        status: e.status,
      }),
    );
    setBzzStampList(stampArray);
    // setUsage
    const _usages: IGetStampUsage[] = await Promise.all([...stamps.map((stamp_) => getStampUsage(accessToken!, stamp_.batchId))]);
    // console.log({_usages})
    setUsage(_usages);
    setIsStampFetched(true);
  };

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      setStyle("#fff");
    } else {
      setStyle("#000");
    }
  }, [localStorage.getItem("theme")]);

  useEffect(() => {
    window.analytics.page("Home Page - Pod");
  }, []);

  const [createPodInfo, setCreatePodInfo] = useState<{
    name: string;
    primaryProvider: provideType | undefined;
    providers: provideType[];
    publicAccessible: boolean;
    type: podType;
    address: string;
    owner: string;
    chunking: boolean;
    bzzStamp?: {
      defaultIndex: number;
      stamps: IStamp[];
    };
    recovery: {
      enable: boolean;
      threshold: number;
    };
  }>({
    name: "",
    primaryProvider: undefined,
    providers: [],
    publicAccessible: false,
    type: podType.FULLY_MANAGED,
    address: "",
    chunking: false,
    owner: user.sub,
    recovery: {
      enable: false,
      threshold: 0,
    },
  });

  // const avaliableProviders = () => {
  //   let providers = [];
  //   const list = async getProvider();
  //   providers.push(list.filter((e) => e.avaliable === true)[0]);
  //   return providers
  // }

  const options = [
    { value: provideType.IPFS, label: "IPFS" },
    // { value: provideType.ARWEAVE, label: "Arweave" },
    { value: provideType.BTFS, label: "BTFS" },
    { value: provideType.BZZ, label: "BZZ" },
    // { value: provideType.ETD, label: "ETD" },
    // { value: provideType.FILECOIN, label: "FileCoin" },
    // { value: provideType.SIA, label: "SIA" },
    // { value: provideType.STORJ, label: "STORJ" },
    // { value: provideType.ZEROCHAIN, label: "0Chain" },
  ];
  const select_page = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
  ];

  const generatePageList = () => {
    const page_list = [];
    const totalPage = userPodCount % listPodNumber === 0 ? userPodCount / listPodNumber : Math.floor(userPodCount / listPodNumber) + 1;
    for (let i = 0; i < totalPage; i++) {
      page_list.push({
        value: i,
        label: `${i + 1}`,
      });
    }
    return page_list;
  };

  useEffect(() => {
    async function exec() {
      if (keycloak.authenticated) {
        await keycloak.loadUserInfo();
        const _user: Iuser = keycloak.userInfo as Iuser;
        const _accessToken = keycloak.token;

        if (_accessToken == undefined) {
          return;
        }
        const lockdownStatus = await getLockdownStatus(_accessToken);
        setLockdown(lockdownStatus);
        setLockDownLoad(false);
        updateLockDown({ lockdown: lockdownStatus });
        setAccessToken(_accessToken);
      } else {
        setAccessToken(keycloak.refreshToken);
      }
    }
    exec();
  }, [keycloak.authenticated]);

  useEffect(() => {
    if (keycloak.authenticated && user.sub) {
      setCreatePodInfo({
        ...createPodInfo,
        owner: user?.sub,
      });
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    const get_Pod = async () => {
      if (lockdown === false) {
        if (accessToken && user.sub) {
          const response = await getPod(accessToken, user.sub, "0", listPodNumber);
          const podCount = await getPodCount(accessToken, user.sub);
          setUserPodCount(podCount);
          _getStamp();
          setPods(response);
          setPodLoading(false);
        } else {
          setPodLoading(false);
        }
      }
    };
    get_Pod();
  }, [accessToken, user.sub, lockdown, reload]);

  const get_Pod = async () => {
    if (accessToken && user.sub) {
      setPodLoading(true);
      const response = await getPod(accessToken, user.sub, `${currentPageIndex * listPodNumber}`, listPodNumber);
      setPods(response);
      setPodLoading(false);
    }
  };

  useEffect(() => {
    get_Pod();
  }, [currentPageIndex]);

  useEffect(() => {
    setCurrentPageIndex(0);
    if (pods?.data.length === listPodNumber) {
      return;
    }
    get_Pod();
  }, [listPodNumber]);

  useEffect(() => {
    width > 768 ? setListPodNumber(handlePodNum) : setListPodNumber(50);
  }, [width]);

  const render_page_index = () => {
    const pageIndexlist = [];
    const totalPage = userPodCount % listPodNumber === 0 ? userPodCount / listPodNumber : Math.floor(userPodCount / listPodNumber) + 1;
    for (let i = 0; i < totalPage; i++) {
      if (currentPageIndex === i) {
        pageIndexlist.push(
          <div
            key={i}
            className="title-white w-12 select-none text-center"
            style={{
              width: "3rem",
              textDecoration: "underline",
            }}
          >
            <b>{i + 1}</b>{" "}
          </div>,
        );
      } else {
        pageIndexlist.push(
          <div
            key={i}
            className="title-white text-center"
            style={{ width: "3rem", cursor: "pointer", opacity: "0.2" }}
            onClick={(x) => {
              setCurrentPageIndex(i);
            }}
          >
            {" "}
            {i + 1}{" "}
          </div>,
        );
      }
    }

    return pageIndexlist;
  };
  const render_pod = (order?: boolean, type?: string) => {
    const pods_list: any[] = [];
    let arr: IPod[] | undefined = [];

    if (pods) {
      // const sortPodsList = pods.data.sort((pod1, pod2) => new Date(pod2.createdAt).getTime() - new Date(pod1.createdAt).getTime());
      // console.log(new Date( sortPodsList[0].UpdatedAt).getTime())
      switch (type) {
        case "name":
          arr = order ? _.orderBy(pods.data, ["name"], ["desc"]) : _.orderBy(pods.data, ["name"], ["asc"]);
          break;
        case "primaryProvider":
          arr = order ? _.orderBy(pods.data, ["primaryProvider"], ["desc"]) : _.orderBy(pods.data, ["primaryProvider"], ["asc"]);
          break;
        case "publicAccessible":
          arr = order ? _.orderBy(pods.data, ["publicAccessible"], ["desc"]) : _.orderBy(pods.data, ["publicAccessible"], ["asc"]);
          break;
        case "createdAt":
          arr = order ? _.orderBy(pods.data, ["createdAt"], ["desc"]) : _.orderBy(pods.data, ["createdAt"], ["asc"]);
          break;
      }
      //beginning rollback
      // for (let i = 0; i < pods.data.length; i++) {
      //   let provider = "";
      //   for (let k = 0; k < sortPodsList[i].providers.length; k++) {
      //     provider += sortPodsList[i].providers[k] + " ";
      //   }
      //   const tagList = [];
      //   sortPodsList[i].publicAccessible ? tagList.push("public") : tagList.push("private");
      //   sortPodsList[i].isChunking && tagList.push("chunk");
      //   const isBottom = width > 768 ? i + 1 === listPodNumber : i === pods.data.length - 1;

      arr.forEach((element: IPod, index: number) => {
        let provider = "";
        for (let k = 0; k < element.providers.length; k++) {
          provider += element.providers[k] + " ";
        }
        const tagList = [];
        element.publicAccessible ? tagList.push("public") : tagList.push("private");
        element.isChunking && tagList.push("chunk");
        const isBottom = width > 768 ? index + 1 === listPodNumber : index === pods.data.length - 1;
        pods_list.push(
          <PodCon
            pod={element}
            key={index}
            delete={editItem}
            length={arr!.length}
            index={index}
            click={click}
            setClick={setClick}
            name={element.name}
            primary={element.primaryProvider}
            storage={provider}
            access={element.publicAccessible ? "public" : "private"}
            label={tagList}
            date={`${element.createdAt}`}
            edit={setEditItem}
            isBottom={isBottom}
          ></PodCon>,
        );
      });

      //   pods_list.push(
      //     <PodCon
      //       pod={sortPodsList[i]}
      //       key={i}
      //       delete={editItem}
      //       length={pods.data.length}
      //       index={i}
      //       click={click}
      //       setClick={setClick}
      //       name={sortPodsList[i].name}
      //       primary={sortPodsList[i].primaryProvider}
      //       storage={provider}
      //       access={sortPodsList[i].publicAccessible ? "public" : "private"}
      //       label={tagList}
      //       date={`${sortPodsList[i].createdAt}`}
      //       edit={setEditItem}
      //       isBottom={isBottom}
      //     ></PodCon>,
      //   );
      // }
    }

    return pods_list;
  };

  const handleCreatePodStorage = async (e: any) => {
    let tmpArray = [...createPodInfo.providers];
    const _tmpArray: provideType[] = [];
    tmpArray = e;
    tmpArray.forEach((e: any) => {
      _tmpArray.push(e.value);
    });

    setCreatePodInfo({ ...createPodInfo, providers: _tmpArray });
    createPodInfo.providers.indexOf(provideType.IPFS) === 1 ? setipfsWarning(true) : setipfsWarning(false);
  };

  const handleSelectBzzStamp = async (e: any) => {
    const cache = createPodInfo;
    console.log({ e });
    // if (e.length > 0) {
    if (e.value) {
      // const stampList: IStamp[] = [];
      // e.map((x: { label: string; value: IStamp }) => {
      //   stampList.push(x.value);
      // });
      cache.bzzStamp = {
        defaultIndex: 0,
        stamps: [e.value],
      };
      // console.log({ cache });
    } else {
      cache.bzzStamp = undefined;
    }
    setCreatePodInfo(cache);
  };

  const handleCreatePodPrimaryStorage = (e: any) => {
    setCreatePodInfo({
      ...createPodInfo,
      primaryProvider: e.value,
    });
  };
  // render primary option
  const renderPrimaryOption = () => {
    const renderlist: any[] = [];
    createPodInfo.providers.forEach((element) => {
      renderlist.push({ value: element, label: element });
    });
    return renderlist;
  };

  //Create Pod Handler
  const handleCreatePod = async () => {
    try {
      if (accessToken && createPodInfo) {
        console.log({ createPodInfo });
        if (createPodInfo.providers.includes(provideType.BZZ) && !createPodInfo.bzzStamp) {
          alert("If use Bzz as provider, must select at least one bzz stamp");
          return;
        }

        const input: ICreatePod = createPodInfo;
        input.owner = user.sub;
        input.chunking = chunk;
        setCreatePodInfo(input);
        if (user) {
          const result = await creatPod(user.sub, accessToken, input);
          // if (input.bzzStamp) {
          //   const _updateStamp = await updateStamp(accessToken, input.bzzStamp?.stamps[0].batchId);
          // if (result && _updateStamp) {
          //   window.location.reload();
          // }
          // }
        } else {
          alert("Can not retreive your user, Please Login Again");
        }
        setReload(!reload);
        setCreate(false);
      }
      setIsCreatePod(false);
      toast.success(`Success to Create Pod ${createPodInfo.name}`);
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const handleSetChunkPod = async (podId: string, enable: boolean) => {
    const input: IUpdateChunk = {
      pod: podId,
      enable: enable,
    };
    const response = await updateChunkPod(accessToken, input, user.sub);
  };

  const handleCheckAll = (e: any) => {
    const checkAll = e;
    document.querySelectorAll("#check").forEach((e) => {
      const input = e as HTMLInputElement;
      input.checked = checkAll.target.checked;
    });
    let isCheck = false;
    document.querySelectorAll("#check").forEach((e) => {
      const check = e as HTMLInputElement;
      if (check.checked) {
        isCheck = true;
      }
    });
    setEditItem(isCheck);
  };

  const handleDeletePod = async () => {
    document.querySelectorAll("#check").forEach(async (e) => {
      const box = e as HTMLInputElement;

      if (box.checked) {
        deleteList.push(box.dataset.index as string);
      }
    });
    deleteList.forEach(async (e) => {
      const podName = document.querySelector('#podname[data-index="' + e + '"')?.textContent;

      const response = await deletePod(_accessToken, user.sub, `${podName}`);
      if (response !== undefined) {
        document.querySelector('div[data-index="' + e + '"')?.classList.add("remove");
        setTimeout(() => {
          document.querySelector('div[data-index="' + e + '"')?.remove();
          // update();
        }, 250);
      }
    });
    // if all checkbox is not checked, set editItem to false
    const res = await getPod(_accessToken, user.sub, "0", listPodNumber);
    dispatch(podListActions.podList(res));
    let isCheck = false;
    document.querySelectorAll("#check").forEach((e) => {
      const check = e as HTMLInputElement;
      if (check.checked) {
        isCheck = true;
      } else {
        isCheck = false;
      }
    });
    setEditItem(isCheck);
    setReload(!reload);
  };

  const getDeleteList = () => {
    document.querySelectorAll("#check").forEach(async (e) => {
      const box = e as HTMLInputElement;
      if (box.checked) {
        deleteList.push(box.dataset.index as string);
      }
      setDeleteList(deleteList);
      console.log(deleteList);
    });
  };

  const [isDeletePod, setIsDeletePod] = useState(false);

  return (
    <div className="content-con flex min-w-full gap-8 overflow-hidden p-0 md:min-w-0">
      <Toaster />
      <div
        id="upload-pop-up-box-bg"
        data-show={isDeletePod}
        onClick={() => setIsDeletePod(true)}
        className="create-bucket-wrapper fixed left-0 top-0 z-[998] h-full w-full bg-black/10 backdrop-blur-[2px] dark:bg-black/30"
      ></div>
      <div
        id="upload-pop-up-box"
        data-show={isDeletePod}
        className="create-bucket-con upload create-border-gray bg-color z-[999] flex max-h-[80vh] flex-col overflow-auto rounded-2xl border-2 py-6 md:max-w-[95vw]"
      >
        <div id="DELETEBOX">
          <h1 id="DELETEBOX" className="title-white px-6 text-2xl font-semibold">
            Delete Pod
          </h1>
          <div id="DELETEBOX" className={"title-white mt-2 px-6 text-sm "}>
            <div id="DELETEBOX" style={{ display: "flex", padding: "6px" }} className="border-gray mt-4 max-w-[550px] rounded-lg border-2">
              <div id="DELETEBOX">
                <Icon id="DELETEBOX" icon="material-symbols:warning" className="h-auto w-5 text-yellow-300 dark:text-yellow-500" />
              </div>
              <ul id="DELETEBOX" className="font-medium" style={{ marginLeft: "2rem", fontSize: "10px" }}>
                <li id="DELETEBOX" style={{ listStyle: "disc" }}>
                  If a folder is selected for deletion, all objects in the folder will be deleted, and any new objects added while the delete action is in progress might also be
                  deleted. If an object is selected for deletion, any new objects with the same name that are uploaded before the delete action is completed will also be deleted.
                </li>
                <li id="DELETEBOX" style={{ listStyle: "disc" }}>
                  Deleting the specified objects can't be undone.
                </li>
              </ul>
            </div>
          </div>
          <div id="DELETEBOX" className="title-white mt-4 px-6 text-sm">
            To confirm deletion, type{" "}
            <b id="DELETEBOX" className="text-lg text-red-400 dark:text-red-500">
              Permanently Delete
            </b>{" "}
            in the text input field.
          </div>

          {/*<p id="DELETEBOX" className="mt-2 px-6 title-white text-sm">{checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"}</p>*/}
          <div className="mt-2 px-6">
            <input
              id="DELETEBOX"
              // placeholder={checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"}
              placeholder={"Type Here"}
              className="setting-input border-gray w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
              ref={confirmDeleteRef}
            ></input>
          </div>

          <div id="DELETEBOX" className="border-gray-sec mt-6 flex gap-5 border-t px-6 pt-6">
            <button
              onClick={() => {
                setTimeout(() => {
                  // setFiles([]);
                  // setDeleteing(false);
                }, 500);

                setIsDeletePod(false);
                confirmDeleteRef.current!.value = "";
              }}
              className="border-gray-sec title-white h-[55px] flex-1 rounded-lg border text-sm font-semibold hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                // const confirmText = checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"
                const confirmText = "Permanently Delete";
                if (confirmDeleteRef.current!.value === confirmText) {
                  try {
                    handleDeletePod();

                    toast.success(`${deleteList.length === 1 ? `${deleteList[0]}` : "Files"} were deleted`, {
                      style: {
                        background: "var(--bg-sec)",
                        color: "var(--title-white)",
                      },
                    });
                    setIsDeletePod(false);
                  } catch (e) {
                    toast.error((e as Error).message, {
                      style: {
                        background: "var(--bg-sec)",
                        color: "var(--title-white)",
                      },
                    });
                  }
                } else {
                  toast.error("Confirm Text Not Correct" + confirmText, {
                    style: {
                      background: "var(--bg-sec)",
                      color: "var(--title-white)",
                    },
                  });
                }
              }}
              className="w-full flex-1 rounded-lg bg-red-500 text-[13px] font-semibold text-white hover:bg-red-500/80 dark:hover:bg-red-600 2xl:text-[14px]"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div
        className={`relative mx-auto flex w-full flex-col px-[1.25rem] pt-[1.5rem] md:min-w-[980px] md:px-[2rem] md:pt-[2rem] ${
          lockdown === null || lockdown === true || lockdown === undefined ? "max-h-[calc(100vh-70px)] overflow-hidden" : "overflow-auto"
        }`}
      >
        {lockdown === null && (
          <div className="rounded-4xl absolute top-0 left-0 z-[300] grid h-full w-full place-items-center bg-white/10 backdrop-blur-[2px] dark:bg-black/30">
            <img src={Logo} className="logo-img logo-loading w-[50vw] max-w-[5rem]" alt="" />
            {/* <span className="loader text-main mt-[-2rem]"></span> */}
          </div>
        )}
        {lockdown === true && (
          <div className="rounded-4xl fade-in absolute top-0 left-0 z-[300] grid h-full w-full place-items-center bg-white/30 backdrop-blur-[2px] dark:bg-black/10">
            <div className="bg-main border-gray title-white min-w-[90%] rounded-3xl py-20 px-4 text-center text-2xl font-medium shadow-lg md:min-w-0 md:p-20">
              <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" className="mx-auto" width="80%" height={200} viewBox="0 0 900 800">
                <title>{"server_cluster"}</title>
                <path
                  d="M444.936 604.807a260.064 260.064 0 1 1 183.893-76.17 258.365 258.365 0 0 1-183.893 76.17Zm0-518.129c-142.297 0-258.065 115.768-258.065 258.065S302.64 602.807 444.936 602.807 703 487.04 703 344.743 587.232 86.678 444.936 86.678Z"
                  fill="#ccc"
                />
                <path fill="#2f2e41" d="M541.5 381.807v262h-195v-262z" />
                <path fill="#3f3d56" d="M313 373.807h262v104H313zM313 489.807h262v104H313zM313 605.807h262v104H313z" />
                <path fill="#3e84ec" d="M313 417.904h262v16H313zM313 533.904h262v16H313zM313 649.904h262v16H313z" />
                <circle cx={524} cy={388.807} r={6} fill="#3e84ec" />
                <circle cx={545} cy={388.807} r={6} fill="#3e84ec" />
                <circle cx={566} cy={388.807} r={6} fill="#3e84ec" />
                <circle cx={524} cy={502.807} r={6} fill="#3e84ec" />
                <circle cx={545} cy={502.807} r={6} fill="#3e84ec" />
                <circle cx={566} cy={502.807} r={6} fill="#3e84ec" />
                <circle cx={524} cy={616.807} r={6} fill="#3e84ec" />
                <circle cx={545} cy={616.807} r={6} fill="#3e84ec" />
                <circle cx={566} cy={616.807} r={6} fill="#3e84ec" />
                <path fill="#3f3d56" d="M0 708.807h888v2H0z" />
                <path
                  d="M241.879 271.065v185.991a14.675 14.675 0 0 1-14.673 14.673h-72.278a14.677 14.677 0 0 1-14.678-14.673V271.065a14.677 14.677 0 0 1 14.678-14.673h8.768A6.978 6.978 0 0 0 170.15 266h41.208a6.978 6.978 0 0 0 6.455-9.607h9.392a14.675 14.675 0 0 1 14.673 14.673Z"
                  fill="#e6e6e6"
                />
                <path fill="#fff" d="M145.064 291.91h92v128h-92z" />
                <path fill="#e6e6e6" d="M171.186 379.927h36.72v2.098h-36.72z" />
                <path fill="#3e84ec" d="M171.149 387.632h56.817v2.098h-56.817z" />
                <path
                  fill="#3e84ec"
                  d="M171.149 394.89h44.588v2.098h-44.588zM171.149 402.149h26.228v2.098h-26.228zM171.149 409.407h39.343v2.098h-39.343zM154.533 379.324h4.197v4.197h-4.197z"
                />
                <path fill="#3e84ec" d="M154.533 386.582h4.197v4.197h-4.197z" />
                <path
                  fill="#e6e6e6"
                  d="M154.533 393.841h4.197v4.197h-4.197zM154.533 401.1h4.197v4.197h-4.197zM154.533 408.358h4.197v4.197h-4.197zM172.751 307.91h36.998v36.998h-36.998zM321.111.139h245.884v157.475H321.111z"
                />
                <path fill="#fff" d="M339.647 44.195h203.861v77.035H339.647z" />
                <path fill="#e6e6e6" d="M376.789 57.542h48.927v2.796h-48.927z" />
                <path fill="#3e84ec" d="M376.74 67.807h75.705v2.796H376.74z" />
                <path
                  fill="#e6e6e6"
                  d="M376.74 77.479h59.411v2.796H376.74zM376.74 87.151h34.948v2.796H376.74zM376.74 96.822h52.422v2.796H376.74zM354.601 56.738h5.592v5.592h-5.592z"
                />
                <path fill="#3e84ec" d="M354.601 66.409h5.592v5.592h-5.592z" />
                <path
                  fill="#e6e6e6"
                  d="M354.601 76.081h5.592v5.592h-5.592zM354.601 85.753h5.592v5.592h-5.592zM354.601 95.424h5.592v5.592h-5.592zM483.445 53.847h50.059v50.059h-50.059z"
                />
                <path fill="#3e84ec" d="M321.006 0H566.89v10.446H321.006z" />
                <circle cx={328.768} cy={5.341} r={2.097} fill="#fff" />
                <circle cx={336.117} cy={5.341} r={2.097} fill="#fff" />
                <circle cx={343.466} cy={5.341} r={2.097} fill="#fff" />
                <path fill="#e6e6e6" d="M671.648 294.807h60v31h-60zM671.648 405.807h60v31h-60z" />
                <circle cx={701.648} cy={365.807} r={50} fill="#e6e6e6" />
                <circle cx={701.648} cy={365.807} r={39} fill="#fff" />
                <path fill="#e2e2e2" d="M688.877 355.122h30.247v1.728h-30.247z" />
                <path fill="#3e84ec" d="M688.846 361.468h46.802v1.728h-46.802z" />
                <path
                  fill="#e2e2e2"
                  d="M688.846 367.447h36.728v1.728h-36.728zM688.846 373.426h21.605v1.728h-21.605zM688.846 379.405h32.407v1.728h-32.407zM675.16 354.625h3.457v3.457h-3.457z"
                />
                <path fill="#3e84ec" d="M675.16 360.604h3.457v3.457h-3.457z" />
                <path fill="#e2e2e2" d="M675.16 366.583h3.457v3.457h-3.457zM675.16 372.562h3.457v3.457h-3.457zM675.16 378.541h3.457v3.457h-3.457z" />
              </svg>
              <p className="text-2xl font-bold">Oh no!</p>
              <p className="mt-2 text-sm text-[#a19da8]">Account Locked : (Balance not Enought)</p>
            </div>
          </div>
        )}
        {deleting === true && (
          <div className="relative flex w-full flex-1 flex-col xl:w-auto">
            <div
              id="upload-pop-up-box-bg"
              data-show={deleting}
              onClick={() => setDeleting(false)}
              className="create-bucket-wrapper fixed left-0 top-0 z-[998] h-full w-full bg-black/10 backdrop-blur-[2px] dark:bg-black/30"
            ></div>
            <div
              id="upload-pop-up-box"
              data-show={deleting}
              className="create-bucket-con upload create-border-gray bg-color z-[999] flex max-h-[80vh] flex-col overflow-auto rounded-2xl border-2 py-6 md:max-w-[95vw]"
            >
              <div id="DELETEBOX">
                <h1 id="DELETEBOX" className="title-white px-6 text-2xl font-semibold">
                  Delete {deleteList.length === 1 ? "Pod" : "Pods"}
                </h1>
                <div id="DELETEBOX" className={"title-white mt-2 px-6 text-sm "}>
                  <div id="DELETEBOX" style={{ display: "flex", padding: "6px" }} className="border-gray mt-4 max-w-[550px] rounded-lg border-2">
                    <div id="DELETEBOX">
                      <Icon id="DELETEBOX" icon="material-symbols:warning" className="h-auto w-5 text-yellow-300 dark:text-yellow-500" />
                    </div>
                    <ul id="DELETEBOX" className="font-medium" style={{ marginLeft: "2rem", fontSize: "10px" }}>
                      <li id="DELETEBOX" style={{ listStyle: "disc" }}>
                        If a folder is selected for deletion, all objects in the folder will be deleted, and any new objects added while the delete action is in progress might also
                        be deleted. If an object is selected for deletion, any new objects with the same name that are uploaded before the delete action is completed will also be
                        deleted.
                      </li>
                      <li id="DELETEBOX" style={{ listStyle: "disc" }}>
                        Deleting the specified objects can't be undone.
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="DELETEBOX" className="title-white mt-4 px-6 text-sm">
                  To confirm deletion, type{" "}
                  <b id="DELETEBOX" className="text-lg text-red-400 dark:text-red-500">
                    Permanently Delete
                  </b>{" "}
                  in the text input field.
                </div>

                {/*<p id="DELETEBOX" className="mt-2 px-6 title-white text-sm">{checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"}</p>*/}
                <div className="mt-2 px-6">
                  <input
                    id="DELETEBOX"
                    // placeholder={checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"}
                    placeholder={"Type Here"}
                    className="setting-input border-gray w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                    ref={confirmDeleteRef}
                  ></input>
                </div>

                <div id="DELETEBOX" className="border-gray-sec mt-6 flex gap-5 border-t px-6 pt-6">
                  <button
                    onClick={() => {
                      setTimeout(() => {
                        setDeleteList([]);
                        setDeleting(false);
                      }, 500);
                      confirmDeleteRef.current!.value = "";
                    }}
                    className="border-gray-sec title-white h-[55px] flex-1 rounded-lg border text-sm font-semibold hover:bg-gray-100 dark:hover:bg-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={async () => {
                      // const confirmText = checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"
                      const confirmText = "Permanently Delete";
                      if (confirmDeleteRef.current!.value === confirmText) {
                        try {
                          handleDeletePod();
                          setDeleting(false);
                          toast.success(`Pods were deleted`, {
                            style: {
                              background: "var(--bg-sec)",
                              color: "var(--title-white)",
                            },
                          });
                        } catch (e) {
                          toast.error((e as Error).message, {
                            style: {
                              background: "var(--bg-sec)",
                              color: "var(--title-white)",
                            },
                          });
                        }
                      } else {
                        toast.error("Confirm Text Not Correct" + confirmText, {
                          style: {
                            background: "var(--bg-sec)",
                            color: "var(--title-white)",
                          },
                        });
                      }
                    }}
                    className="w-full flex-1 rounded-lg bg-red-500 text-[13px] font-semibold text-white hover:bg-red-500/80 dark:hover:bg-red-600 2xl:text-[14px]"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          data-show={create}
          onClick={() => setCreate(false)}
          className="create-bucket-wrapper fixed left-0 top-0 z-[999] h-full w-full bg-black/10 backdrop-blur-[2px] dark:bg-black/30"
        ></div>
        <div
          data-show={create}
          className="create-bucket-con create create-border-gray bg-color scrollbar-hidden z-[999] overflow-y-auto rounded-3xl border-2 p-6 md:max-h-[85vh] md:rounded-3xl md:p-6"
        >
          <div
            className="text-main absolute right-6 top-6 grid cursor-pointer place-items-center rounded-full bg-[#e1e7e9] text-lg font-medium dark:bg-gray-800/70"
            onClick={() => setCreate(false)}
          >
            <Icon icon="basil:cross-outline" className="h-auto w-8 text-[#7c7e84] dark:text-gray-500" />
          </div>
          {/* <div className="bg-blue mb-6 grid aspect-square w-[64px] place-items-center rounded-full">
            <Icon icon="tabler:bucket" className="h-auto w-8 text-white" />
          </div> */}
          <h1 className="title-white text-2xl font-semibold">Create Pod</h1>
          <h3 className="title-white mt-6 text-[13px] font-medium">Pod Name</h3>
          <input
            placeholder="Bucket Name"
            className={` mt-2 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px] ${
              createPodInfo.name !== "" && createPodInfo.name.match("(?!.*[-]{2})[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$") == null ? "setting-input-error" : "setting-input border-gray"
            } `}
            title="Can only contain letters and numbers"
            value={createPodInfo?.name}
            onChange={(e) => {
              setCreatePodInfo({
                ...createPodInfo,
                name: e.currentTarget.value,
              });
            }}
            required
            pattern="(?!.*[-.]{2})[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$"
          ></input>
          {createPodInfo.name !== "" && createPodInfo.name.match("(?!.*[-]{2})[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$") == null && (
            <a className="mt-3 text-xs text-red-400">
              <b>Invalid Pod name</b>( Pod name can consist only of lowercase letters, numbers, and hyphens (-) but must not end with hyphens (-).)
            </a>
          )}
          <h3 className="title-white mt-5 text-[13px] font-medium">Storage Provider</h3>
          <Select
            className="z-[999] mt-2"
            isMulti
            options={options}
            isSearchable={true}
            onChange={(e) => {
              handleCreatePodStorage(e);
            }}
            styles={{
              container: (base) => ({
                ...base,
                fontSize: "12px",
                border: "0px",
              }),
              control: (base) => ({
                ...base,
                fontSize: "12px",
                borderRadius: "0.5rem",
                border: "2px solid var(--border-gray)",
                backgroundColor: "var(--bg-sec)",
                "&:hover": {
                  borderColor: "var(--text-main)",
                },
                "&:focus": {
                  border: "0px",
                },
                zIndex: 999,
              }),
              valueContainer: (base) => ({
                ...base,
                padding: "0.75rem",
                backgroundColor: "var(--bg-sec)",
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                color: "var(--title-white)",
              }),

              menu: (base) => ({
                ...base,
                backgroundColor: "var(--bg-sec)",
                color: "var(--title-white)",
                overflow: "auto",
              }),
              option: (base) => ({
                ...base,
                backgroundColor: "var(--bg-sec)",
                border: "0px",
                "&:hover": {
                  backgroundColor: "#32cbae",
                  color: "#fff",
                },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: "#32cbae",
                borderRadius: "0.25rem",
                "&:hover": {
                  backgroundColor: "#32cbae",
                },
                color: "#fff",
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: "#fff",
              }),
              input: (base, state) => ({
                ...base,
                color: `${style}`,
              }),
              singleValue: (base, state) => ({
                ...base,
                color: `${style}`,
              }),
            }}
          ></Select>
          {createPodInfo.providers.includes(provideType.IPFS) ? (
            <div className="">
              <p className="mt-3 text-xs text-red-400">
                <b>Caution</b>: Using IPFS as storage may make retrieving items difficult. Network availability, reliance on pinning services, and content-addressing issues can
                hinder access. Backup files, verify accessibility and understand pinning services—contact support for assistance.
              </p>
            </div>
          ) : (
            <></>
          )}
          {createPodInfo.providers.includes(provideType.BZZ) ? (
            <>
              {" "}
              <h3 className="title-white mt-5 text-[13px] font-medium">Bzz Stamp</h3>
              <Select
                className="mt-2"
                options={bzzStampList.filter((e) => e.status === "SUCCESS")}
                isSearchable={true}
                onChange={(e) => {
                  handleSelectBzzStamp(e);
                }}
                styles={{
                  container: (base) => ({
                    ...base,
                    fontSize: "12px",
                    border: "0px",
                    zIndex: 11,
                  }),
                  control: (base) => ({
                    ...base,
                    fontSize: "12px",
                    borderRadius: "0.5rem",
                    border: "2px solid var(--border-gray)",
                    backgroundColor: "var(--bg-sec)",
                    "&:hover": {
                      borderColor: "var(--text-main)",
                    },
                    "&:focus": {
                      border: "0px",
                    },
                    zIndex: 999,
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: "0.75rem",
                    backgroundColor: "var(--bg-sec)",
                    borderTopLeftRadius: "0.5rem",
                    borderBottomLeftRadius: "0.5rem",
                    color: "var(--title-white)",
                  }),

                  menu: (base) => ({
                    ...base,
                    backgroundColor: "var(--bg-sec)",
                    color: "var(--title-white)",
                    overflow: "auto",
                  }),
                  option: (base) => ({
                    ...base,
                    backgroundColor: "var(--bg-sec)",
                    border: "0px",
                    "&:hover": {
                      backgroundColor: "#32cbae",
                      color: "#fff",
                    },
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: "#32cbae",
                    borderRadius: "0.25rem",
                    "&:hover": {
                      backgroundColor: "#32cbae",
                    },
                    color: "#fff",
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: "#fff",
                  }),
                  input: (base, state) => ({
                    ...base,
                    color: `${style}`,
                  }),
                  singleValue: (base, state) => ({
                    ...base,
                    color: `${style}`,
                  }),
                }}
              ></Select>
            </>
          ) : (
            <></>
          )}

          <h3 className="title-white mt-5 text-[13px] font-medium">Primary Provider</h3>
          <Select
            className="mt-2"
            options={renderPrimaryOption()}
            onChange={(e) => handleCreatePodPrimaryStorage(e)}
            styles={{
              container: (base) => ({
                ...base,
                fontSize: "12px",
                border: "0px",
                zIndex: 10,
              }),
              control: (base) => ({
                ...base,
                fontSize: "12px",
                borderRadius: "0.5rem",
                border: "2px solid var(--border-gray)",
                backgroundColor: "var(--bg-sec)",
                "&:hover": {
                  borderColor: "var(--text-main)",
                },
                "&:focus": {
                  border: "0px",
                },
                zIndex: 999,
              }),
              valueContainer: (base) => ({
                ...base,
                padding: "0.75rem",
                backgroundColor: "var(--bg-sec)",
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
                color: "var(--title-white)",
              }),

              menu: (base) => ({
                ...base,
                backgroundColor: "var(--bg-sec)",
                color: "var(--title-white)",
                overflow: "auto",
              }),
              option: (base) => ({
                ...base,
                backgroundColor: "var(--bg-sec)",
                border: "0px",
                "&:hover": {
                  backgroundColor: "#32cbae",
                  color: "#fff",
                },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: "#32cbae",
                borderRadius: "0.25rem",
                "&:hover": {
                  backgroundColor: "#32cbae",
                },
                color: "#fff",
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: "#fff",
              }),
              input: (base, state) => ({
                ...base,
                color: `${style}`,
              }),
              singleValue: (base, state) => ({
                ...base,
                color: `${style}`,
              }),
            }}
          ></Select>

          <div className="mt-2 flex flex-col justify-between gap-2">
            {/* <span className="title-white text-[13px] font-medium">Chunk Pod</span>
            <div
              onClick={() => {
                setChunk(!chunk);
              }}
              className={`${
                chunk ? "hover-color border-blue-500" : "border-gray"
              } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`}
            >
              <div
                className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-blue-300 outline-none ${
                  chunk ? "bg-blue border-0" : "bg-color"
                } `}
              >
                {chunk && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
              </div>
              <div data-on={true} className="chunk font-light">
                Chunk Items
              </div>
            </div> */}
            <span className="title-white mt-3 text-[13px] font-medium">Access Control</span>
            <div
              onClick={() => {
                setAccess(!access);
                setCreatePodInfo({
                  ...createPodInfo,
                  publicAccessible: !access,
                });
              }}
              className={`${
                access ? "hover-color border-[#32cbae]" : "border-gray"
              } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`}
            >
              <div
                className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-[#32cbae] outline-none ${
                  access ? "border-0 bg-[#32cbae]/70" : "bg-color"
                } `}
              >
                {access && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
              </div>
              <div data-on={true} className="chunk font-light">
                Public Access
              </div>
            </div>
            {/* <span className="title-white mt-3 text-[13px] font-medium">Data Recovery</span>
            <hr /> */}
            <span className="title-white mt-3 text-[13px] font-medium">Enable</span>
            <div
              onClick={() => {
                setRecoveryEnable(!recoveryEnable);
                setCreatePodInfo({
                  ...createPodInfo,
                  recovery: {
                    enable: !recoveryEnable,
                    threshold: createPodInfo.recovery.threshold,
                  },
                });
              }}
              className={`${
                recoveryEnable ? "hover-color border-[#32cbae]" : "border-gray"
              } flex cursor-pointer select-none items-center gap-5 rounded-xl border-2 p-3 px-4 text-xs text-[#A4A4A4] 2xl:text-[13px]`}
            >
              <div
                className={`relative grid aspect-square h-[28px] shrink-0 cursor-pointer place-items-center rounded-full border-2 border-[#32cbae] outline-none ${
                  recoveryEnable ? "border-0 bg-[#32cbae]/70" : "bg-color"
                } `}
              >
                {recoveryEnable && <Icon icon="charm:tick" className="h-auto w-4 text-white" />}
              </div>
              <div data-on={true} className="chunk font-light">
                Enable Data Recovery
              </div>
            </div>
            {createPodInfo.recovery.enable ? (
              <>
                <span className="mt-2 flex flex-row items-center space-x-2">
                  <h3 className="title-white mt-0  text-[13px] font-medium">Threshold</h3>
                  <Tooltip title="Threshold is the tryout time before fetching the file from Storage provider again" placement="right" arrow>
                    <IconButton>
                      <Icon className="h-4 w-4" icon="memory:tooltip-end-alert" color="white" />
                    </IconButton>
                  </Tooltip>
                </span>
                <input
                  type="number"
                  placeholder="Recovery Threshold"
                  className="setting-input border-gray w-full rounded-lg border-2 p-4 text-xs outline-none [appearance:textfield] 2xl:text-[13px] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  value={createPodInfo?.recovery.threshold}
                  min={0}
                  onWheelCapture={(e) => {
                    e.target.removeEventListener;
                  }}
                  onChange={(e) => {
                    setCreatePodInfo({
                      ...createPodInfo,
                      recovery: {
                        enable: createPodInfo.recovery.enable,
                        threshold: parseInt(e.currentTarget.value),
                      },
                    });
                  }}
                ></input>
              </>
            ) : (
              <></>
            )}
          </div>
          <button
            onClick={() => {
              setIsCreatePod(true);
              handleCreatePod();
            }}
            disabled={isCreatePod}
            className="bg-blue md:text-smfirst-letter: mt-6 flex w-full justify-center rounded-xl p-4 text-center text-[13px] font-medium text-white hover:bg-[#1fae94]"
          >
            {isCreatePod ? <Icon icon="line-md:loading-twotone-loop" height={20} /> : "Create Pod"}
          </button>
        </div>
        {/* <div className="mt-2 flex items-center gap-[30px] md:mt-0 xl:gap-[60px]">
          <h1 className="title-white text-[20px] font-medium md:text-2xl ">
            Pod
          </h1>
          <button
            onClick={() => setCreate(!create)}
            className="bg-blue rounded-md px-12 py-3 text-xs font-medium text-white duration-100 hover:bg-[#226ddd] md:text-[13px]"
          >
            Create pod
          </button>
        </div> */}

        {width > 768 && <p className="title-white mb-4 text-xl font-semibold md:mb-6 md:text-[26px]">Pods</p>}
        {!(lockdown === null || lockdown === true || lockdown === undefined) ? (
          <div className="fade-in bg-main main-box-shadow min-h-[291px] w-full max-w-full shrink-0 overflow-x-hidden overflow-y-hidden rounded-2xl py-6">
            <h2 className="title-white px-6 text-xl font-semibold">Quick Access</h2>

            <div className="quick-text-sec scrollbar-hidden mt-6 flex h-fit min-h-[170px] gap-4 overflow-x-auto px-6">
              {isStampFetched ? (
                bzzStampList.length !== 0 && usage !== null ? (
                  bzzStampList.map((e, i) =>
                    i < 3 && usage !== undefined && usage[i]?.capacity !== undefined && usage[i]?.capacity.used !== undefined ? (
                      <Box
                        key={i}
                        name={e.label}
                        usage={parseInt(usage[i].capacity.used) / 1024 / 1024 / 1024}
                        day={Math.floor(usage[i].expiredAt / (3600 * 24))}
                        storage={parseInt(usage[i].capacity.total) / 1024 / 1024 / 1024}
                      />
                    ) : (
                      <></>
                    ),
                  )
                ) : (
                  <Link className="border-gray-sec fade-in quick-access-con stamp-card h-[170px]" to={"/billing/stamp/new"}>
                    You don't have any stamps
                    <span className="grid h-full w-full place-content-center transition duration-150 ease-out hover:scale-125">
                      <Icon icon="icon-park-outline:add" fontSize={50} />
                    </span>
                  </Link>
                )
              ) : (
                [...Array(10)].map((e, i) => <div key={i} className="skeleton quick-access-skeleton" style={{ animationDelay: 0.1 * i + "s" }}></div>)
              )}
            </div>
          </div>
        ) : (
          // <div className="skeleton h-[299px] w-full shrink-0 rounded-2xl"></div>
          <></>
        )}

        <div className={`pb-mobile relative mt-6 flex md:mt-8 md:pb-6 ${lockdown === false ? "flex-0" : "flex-1"}`}>
          <div className={`flex flex-1 flex-col`}>
            <div className="flex flex-col items-start justify-between gap-4 md:flex-row md:items-center md:justify-between">
              {lockdown === null || lockdown === true || lockdown === undefined ? (
                <div className="skeleton h-[28px] w-[120px]" style={{ animationDelay: "0.2s" }}></div>
              ) : (
                <div className="title-white fade-in flex items-center gap-3 text-base font-medium text-[#484652]">
                  <p className="title-white text-xl font-semibold">Pods</p>{" "}
                  <div className="border-gray h-full rounded-full border bg-[#32cbae] py-0.5 px-3 text-sm font-semibold dark:text-[#eceae5]">{userPodCount}</div>
                </div>
              )}

              <div className={`flex items-center gap-4 overflow-x-auto md:overflow-x-visible md:overflow-y-visible ${lockdown === false && "fade-in"}`}>
                {lockdown === null || lockdown === true || lockdown === undefined ? (
                  <div className="skeleton h-[42px] w-[103px]" style={{ animationDelay: "0.3s" }}></div>
                ) : (
                  <button
                    onClick={() => {
                      setIsDeletePod(true);
                      // setDeleting(true);
                    }}
                    className={`btn-delete quick-text-sec flex h-[42px] items-center gap-2 rounded-lg border border-gray-300 px-4 py-2 text-xs font-medium opacity-40 transition duration-150 dark:border-gray-600 md:text-sm ${
                      editItem && "active"
                    }`}
                  >
                    <Icon icon="majesticons:delete-bin-line" className="ml-[-3px] h-auto w-5" />
                    <p>Delete</p>
                  </button>
                )}
                {lockdown === null || lockdown === true || lockdown === undefined ? (
                  <div className="skeleton h-[42px] w-[133px]" style={{ animationDelay: "0.4s" }}></div>
                ) : (
                  <button
                    onClick={() => setCreate(!create)}
                    className="bg-blue md:btn-hover relative flex h-[42px] shrink-0 items-center gap-2 rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 px-4 py-3 text-xs font-medium text-white hover:bg-[#226ddd] dark:from-[#32cbae] dark:to-[#2d9b87] md:text-sm"
                  >
                    <Icon icon="tabler:bucket" className="h-auto w-5 text-white" />
                    Create pod
                  </button>
                )}
              </div>
            </div>
            {!(lockdown === null || lockdown === true || lockdown === undefined) ? (
              <div className="fade-in bg-main main-box-shadow mt-4 flex flex-col gap-4 overflow-hidden rounded-2xl">
                <div className="flex flex-1 flex-col justify-between">
                  <div>
                    <div className="border-gray flex h-[70px] items-center border-b px-4 text-[13px] font-semibold text-[#ababb3] 2xl:text-sm">
                      <div className="flex flex-[1] items-center gap-1 overflow-hidden text-[#a2a2a7]">
                        <input className="ml-2" type="checkbox" onChange={handleCheckAll} name="" id="checkAll" />
                      </div>
                      <div
                        onClick={() => {
                          setOrderBy(pods !== null ? !orderBy : orderBy);
                          setOrderType("name");
                        }}
                        className="flex flex-[5.7] items-center gap-1 md:flex-[4]"
                      >
                        <span>Pods</span>
                        <span data-sort={orderBy} data-type={orderType} className="btn-sort name cursor-pointer">
                          <Icon icon="material-symbols:arrow-drop-down-rounded" className="h-5 w-auto"></Icon>
                        </span>
                        {/* <Icon
                          icon="material-symbols:keyboard-arrow-up-rounded"
                          className="h-4 w-auto"
                        ></Icon> */}
                      </div>
                      {width > 768 && (
                        <>
                          <div
                            onClick={() => {
                              setOrderBy(orderType === "primaryProvider" ? (pods !== null ? !orderBy : orderBy) : true);
                              setOrderType("primaryProvider");
                            }}
                            className="flex flex-[3] items-center gap-1"
                          >
                            <span>Primary Provider</span>
                            <span data-sort={orderBy} data-type={orderType} className="btn-sort name cursor-pointer">
                              <Icon icon="material-symbols:arrow-drop-down-rounded" className="h-5 w-auto"></Icon>
                            </span>
                          </div>
                          <div className="flex-[3] items-center gap-1">Storage Provider</div>
                          {/*<div className="flex-[2.5] items-center gap-1">Access</div>*/}
                          <div className="podss-center flex-[3] gap-1">
                            <span>Label</span>
                          </div>
                          <div
                            onClick={() => {
                              setOrderBy(orderType === "createdAt" ? (pods !== null ? !orderBy : orderBy) : true);
                              setOrderType("createdAt");
                            }}
                            className="flex flex-[2] items-center gap-1"
                          >
                            <span>CreatedAt</span>
                            <span data-sort={orderBy} data-type={orderType} className="btn-sort name cursor-pointer">
                              <Icon icon="material-symbols:arrow-drop-down-rounded" className="h-5 w-auto"></Icon>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={`relative shrink-0 overflow-y-auto md:min-w-[820px]`} style={{ minHeight: width > 768 ? listPodNumber * 75 + "px" : "fit-content" }}>
                    <div
                      data-loading={podLoading}
                      className="props-loading-con pod absolute top-0 left-0 z-[999] grid h-full w-full place-items-center overflow-hidden rounded-lg backdrop-blur-[2px]"
                    >
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    {render_pod(orderBy, orderType)}
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4 flex-1">
                <div className="skeleton h-full min-h-[445px] rounded-2xl" style={{ animationDelay: "0.4s" }}></div>
              </div>
            )}
          </div>
        </div>
        {width > 768 && (
          <div className="pb-8">
            <div className="relative flex flex-col items-center justify-between gap-4 md:flex-row md:gap-6">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="title-white flex items-center gap-4"
              >
                {lockdown === null || lockdown === true || lockdown === undefined ? (
                  <div className="skeleton h-[38px] w-[183px]" style={{ animationDelay: "0.5s" }}></div>
                ) : (
                  <>
                    <Select
                      value={select_page.find((obj) => {
                        return obj.value === listPodNumber;
                      })}
                      menuPlacement="top"
                      options={select_page}
                      onChange={(e: any) => {
                        setListPodNumber(e.value);
                        setHandlePodNum(e.value);
                      }}
                      styles={{
                        container: (base) => ({
                          ...base,
                          fontSize: "14px",
                        }),
                        control: (base) => ({
                          ...base,
                          fontSize: "14px",
                          borderRadius: "0.5rem",
                          border: "1px solid var(--border-gray)",
                          backgroundColor: "var(--bg-main)",
                          "&:hover": {
                            borderColor: "var(--blue)",
                          },
                          zIndex: 600,
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          padding: "0 0.75rem",
                          backgroundColor: "var(--bg-main)",
                          borderTopLeftRadius: "0.5rem",
                          borderBottomLeftRadius: "0.5rem",
                          color: "var(--title-white)",
                        }),
                        menu: (base) => ({
                          ...base,
                          backgroundColor: "var(--bg-main)",
                          color: "var(--title-white)",
                          overflow: "auto",
                        }),
                        option: (base) => ({
                          ...base,
                          backgroundColor: "var(--bg-main)",
                          border: "0px",
                          color: "var(--title-white)",
                          "&:hover": {
                            backgroundColor: "#2971db",
                            color: "#fff",
                          },
                        }),
                        input: (base, state) => ({
                          ...base,
                          color: "var(--title-white)",
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          color: "var(--title-white)",
                        }),
                      }}
                    ></Select>
                    <div className="select-none text-sm">Pods per page</div>
                  </>
                )}
              </div>
              <div className="transform-center flex h-[38px] items-center gap-2">
                {lockdown === null || lockdown === true || lockdown === undefined ? (
                  <div className="skeleton h-[38px] w-[140px]" style={{ animationDelay: "0.6s" }}></div>
                ) : (
                  <>
                    <div
                      className={`title-white bg-main main-box-shadow border-gray grid aspect-square h-full place-items-center rounded-md border px-2 ${
                        currentPageIndex === 0 && `pointer-events-none opacity-50`
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (currentPageIndex !== 0) {
                          setCurrentPageIndex(currentPageIndex - 1);
                        }
                      }}
                    >
                      <Icon className="h-6 w-auto text-[#cccbce]" icon="material-symbols:chevron-left-rounded" />
                    </div>
                    {render_page_index()}
                    {/*<div className="title-white w-12 select-none text-center">{currentPageIndex + 1} </div>*/}
                    <div
                      className={`title-white bg-main border-gray main-box-shadow grid aspect-square h-full place-items-center rounded-md border px-2 ${
                        currentPageIndex + 1 === (userPodCount % listPodNumber === 0 ? userPodCount / listPodNumber : Math.floor(userPodCount / listPodNumber) + 1) &&
                        `pointer-events-none opacity-50`
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const totalPage = userPodCount % listPodNumber === 0 ? userPodCount / listPodNumber : Math.floor(userPodCount / listPodNumber) + 1;
                        if (currentPageIndex + 1 < totalPage) {
                          setCurrentPageIndex(currentPageIndex + 1);
                        }
                      }}
                    >
                      <Icon className="h-6 w-auto text-[#cccbce]" icon="material-symbols:chevron-right-rounded" />
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="title-white flex items-center gap-4"
              >
                {lockdown === null || lockdown === true || lockdown === undefined ? (
                  <div className="skeleton h-[38px] w-[120px]" style={{ animationDelay: "0.7s" }}></div>
                ) : (
                  <>
                    <div className="select-none text-sm">Page</div>
                    <Select
                      value={generatePageList()[0] ? generatePageList()[currentPageIndex] : { value: 1, label: 1 }}
                      menuPlacement="top"
                      options={generatePageList()}
                      onChange={(e: any) => {
                        setCurrentPageIndex(e.value);
                      }}
                      styles={{
                        container: (base) => ({
                          ...base,
                          fontSize: "14px",
                        }),
                        control: (base) => ({
                          ...base,
                          fontSize: "14px",
                          borderRadius: "0.5rem",
                          border: "1px solid var(--border-gray)",
                          backgroundColor: "var(--bg-main)",
                          "&:hover": {
                            borderColor: "var(--blue)",
                          },
                          zIndex: 600,
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          padding: "0 0.75rem",
                          backgroundColor: "var(--bg-main)",
                          borderTopLeftRadius: "0.5rem",
                          borderBottomLeftRadius: "0.5rem",
                          color: "var(--title-white)",
                        }),
                        menu: (base) => ({
                          ...base,
                          backgroundColor: "var(--bg-main)",
                          color: "var(--title-white)",
                          overflow: "auto",
                        }),
                        option: (base) => ({
                          ...base,
                          backgroundColor: "var(--bg-main)",
                          border: "0px",
                          color: "var(--title-white)",
                          "&:hover": {
                            backgroundColor: "#2971db",
                            color: "#fff",
                          },
                        }),
                        input: (base, state) => ({
                          ...base,
                          color: "var(--title-white)",
                        }),
                        singleValue: (base, state) => ({
                          ...base,
                          color: "var(--title-white)",
                        }),
                      }}
                    ></Select>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pod;
