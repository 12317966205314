import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../Hook/useWindowDimensions";
import { Icon } from "@iconify/react";
import { podActions } from "../store/pod-info-slice";
import { useDispatch } from "react-redux";
import { IPod } from "../interface/get-pod-interface";
import { StateFromReducersMapObject } from "redux";

interface Props {
  pod: IPod;
  delete: boolean;
  length: number;
  index: number;
  click: number;
  setClick: any;
  name: string;
  primary: string;
  storage: string;
  access: string;
  label: string[];
  date: string;
  edit: any;
  isBottom: boolean;
}

const renderTag = (tags: string[]) => {
  const tag_list: JSX.Element[] = [];
  const tagBlock: { [key: string]: JSX.Element } = {
    chunk: (
      <div key="ChunkTage" className="flex items-center gap-3 rounded-md bg-orange-100 px-3 py-1 text-[13px] text-orange-500 dark:bg-orange-700 dark:text-orange-300">
        Chunk
      </div>
    ),
    public: (
      <div key="publicTag" className="text-blue-300 dark:text-main-300 flex items-center gap-3 rounded-md bg-blue-100 px-3 py-1 text-[13px] dark:bg-blue-700">
        Public
      </div>
    ),
    private: (
      <div key="privateTag" className="flex items-center gap-3 rounded-md bg-red-100 px-3 py-1 text-[13px] text-red-500 dark:bg-red-700 dark:text-red-300">
        Private
      </div>
    ),
  };

  tags.map((x) => {
    if (Object.keys(tagBlock).includes(x)) {
      tag_list.push(tagBlock[x]);
    } else {
      tag_list.push(<div key={x}>{x}</div>);
    }

    tag_list.push();
  });
  return tag_list;
};

const PodCon = (props: Props) => {
  const { height, width } = useWindowDimensions();
  // const dispatch = useDispatch();
  const [select, setSelect] = useState(false);
  // const currentPod = (info: IPod) => {
  //   dispatch(podActions.currentPod(info));
  // };
  const navigate = useNavigate();
  const handleClick = (e: any) => {
    console.log(e.target);
    switch (e.detail) {
      case 1:
        width < 768 && e.target.id !== "check" && navigate(`/pod/info/${props.name}/items`);
        props.setClick(props.index);
        break;
      case 2:
        // currentPod(props.pod);
        // console.log(props.pod);
        navigate(`/pod/info/${props.name}/items`);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  const handleClickOutside = (event: any) => {
    if (!(event.target as HTMLElement).closest(".bucket-item") && props.index === props.click) {
      props.setClick(-1);
    }
  };

  // Delete handler
  // const handleClickMain = (e: any) => {
  //   handleClick(e);
  //   props.delete && setSelect(!select);
  // };

  // useEffect(() => {
  //   !props.delete && setSelect(false);
  // }, [props.delete]);

  const handleCheck = (e: any) => {
    const checkAll = document.querySelector("#checkAll") as HTMLInputElement;
    let isCheckAll = true;
    let isCheck = false;
    document.querySelectorAll("#check").forEach((e) => {
      const check = e as HTMLInputElement;
      if (!check.checked) {
        isCheckAll = false;
      } else {
        isCheck = true;
      }
    });
    checkAll.checked = isCheckAll;
    props.edit(isCheck);
  };

  const convertDate = (date: string) => {
    const dateArr = date.split("T")[0].split("-");
    const month = dateArr[1];
    const day = dateArr[2];
    const year = dateArr[0];
    const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthName[parseInt(month) - 1] + " " + day + ", " + year;
  };

  return (
    <div className="pod-con-outer h-[75px]" data-index={props.index}>
      <div
        data-click={props.index === props.click}
        onClick={handleClick}
        className={`bucket-info-text-color bucket-item item border-gray flex h-full select-none px-4 py-4 text-[13px] hover:text-[#1C5CBC] md:text-sm ${
          props.isBottom ? "border-b-0" : "border-b"
        }`}
      >
        <div className="flex flex-[1] items-center gap-1 text-[#888E99]">
          <input className="ml-2" data-index={props.index} type="checkbox" name="" onChange={handleCheck} id="check" />
        </div>
        <div id="podname" data-index={props.index} className="name flex flex-[5.36] items-center gap-1 font-semibold md:flex-[4]">
          {props.name}
        </div>
        {width > 768 ? (
          <>
            <div className="flex flex-[3] items-center gap-1 font-semibold">{props.primary}</div>
            <div className="flex flex-[3] items-center gap-1 font-semibold">{props.storage}</div>
            {/*<div className="flex flex-[2.5] items-center gap-1 font-semibold">{props.access}</div>*/}
            <div className="flex flex-[3] items-center gap-2 font-semibold">{renderTag(props.label)}</div>
            <div className="flex flex-[2] items-center gap-1 font-semibold">{convertDate(props.date)}</div>
          </>
        ) : (
          <Icon icon="simple-line-icons:options" className="h-auto w-4" />
        )}
      </div>
    </div>
  );
};

export default PodCon;
