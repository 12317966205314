import axios from "axios";
import config from "../../config";

enum IEStamp {
  pending = "PENDING",
  success = "SUCCESS",
  failed = "FAILED",
}
export interface IGetStamp {
  node: string;
  _id: string;
  user: string;
  name?: string;
  batchId: string;
  usable: boolean;
  updatedAt: Date;
  createdAt: Date;
  __v: number;
  status: IEStamp;
}

export async function getStamp(accessToken: string): Promise<IGetStamp[]> {
  const url = new URL("/storage/bzz/stamps", config.api.endpoint);
  const response = await axios.get(url.href, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
