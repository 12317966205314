// import edns_logo from "./logo-icon/logo.png";

// const crc = 'create-comp';
// const mvc = 'move-comp';
const swv = "sw-visibility";
const expt = "export-template";
const osm = "open-sm";
const otm = "open-tm";
const ola = "open-layers";
const obl = "open-blocks";
const ful = "fullscreen";
const prv = "preview";
const udo = "undo";
const rdo = "redo";
// const info = 'info';
// const imageEditor = 'tui-image-editor';

// const cmdImport = 'custom-code:open-modal';
const cmdDeviceDesktop = "set-device-desktop";
const cmdDeviceTablet = "set-device-tablet";
const cmdDeviceMobile = "set-device-mobile";
const cmdClear = "canvas-clear";

/*
    Note:
    -----------------------------------------
    buttons : [
        {
            label : ( HTML Tag | String )
            active: true
            id : HTML tag id value
            command : ( Function )
            context : ???
            className : set the i-con image
        }
    ]
*/

const Panel = {
  stylePrefix: "pn-",
  defaults: [
    {
      id: "options",
      buttons: [
        {
          id: "manuel-save",
          className: "fa fa-floppy-o",
          command: "manuel-save",
          togglable: 0,
          attributes: { title: "Save" },
        },
        {
          id: "show-box-fol",
          className: "fa fa-folder",
          command: "show-box-fol",
          attributes: { title: "File Folder" },
        },
        {
          id: "show-box-edit-page",
          className: "fa fa-pencil-square-o",
          command: "show-box-edit-page",
          attributes: { title: "Create & Update Page" },
        },
        {
          //active: true,
          id: swv,
          className: "fa fa-square-o",
          command: swv,
          // active: 1,
          //label: '<input type= "text " style= " "/>'
          //context: swv,
          //attributes: { title: 'View components' },
        },
        {
          id: prv,
          className: "fa fa-eye",
          command: prv,
          context: prv,
          attributes: { title: "Preview" },
        },

        {
          id: ful,
          className: "fa fa-arrows-alt",
          command: ful,
          attributes: { title: "Full screen" },
        },
        {
          id: udo,
          className: "fa fa-undo",
          command: udo,
          attributes: { title: "Undo" },
        },
        {
          id: rdo,
          className: "fa fa-repeat",
          command: rdo,
          attributes: { title: "Redo" },
        },
        {
          id: cmdClear,
          className: "fa fa-trash",
          command: cmdClear,
          attributes: { title: "Delete all component" },
        },
      ],
    },
    {
      id: "views",
      buttons: [
        {
          id: osm,
          className: "fa fa-paint-brush",
          command: osm,
          togglable: 0,
          attributes: { title: "Open Style Manager" },
        },
        {
          id: otm,
          className: "fa fa-cog",
          command: otm,
          togglable: 0,
          attributes: { title: "Settings" },
        },
        {
          id: ola,
          className: "fa fa-bars",
          command: ola,
          togglable: 0,
          attributes: { "data-title": "Open Layer Manager" },
        },
        {
          id: obl,
          className: "fa fa-th-large",
          command: obl,
          active: true,
          togglable: 0,
          attributes: { title: "Open Blocks" },
        },
        // {
        //   id: "show_page",
        //   className: "fa fa-file-text-o",
        //   command: "show_page",
        //   togglable: 0,
        //   attributes: { title: "Pages" },
        // },
      ],
    },
    {
      id: "devices-c",
      buttons: [
        {
          id: cmdDeviceDesktop,
          command: cmdDeviceDesktop,
          className: "fa fa-desktop",
          active: 1,
        },
        {
          id: cmdDeviceTablet,
          command: cmdDeviceTablet,
          className: "fa fa-tablet",
        },
        {
          id: cmdDeviceMobile,
          command: cmdDeviceMobile,
          className: "fa fa-mobile",
        },
        {
          //active: true,
          //id: swv,
          //className: 'fa fa-square-o',
          //command: swv,
          label:
            '<select id="domains" name= "domains " style= "padding:1px;background-color:#F9F5F4;color:#000;font-size:15px;border-radius:10px; ">' +
            '<option value= "meta ">https://edns.meta</option>' +
            '<option value= "404 ">https://web345.404</option>' +
            '<option value= "sandbox ">https://blockchain.sandbox</option>' +
            '<option value= "music ">https://money.music</option>' +
            "</select>",
          //context: swv,
          attributes: { title: "NFT Domains" },
        },
        {
          id: expt,
          className: "fa fa-cloud-upload",
          command: expt,
          attributes: { title: "View code" },
        },
        // {
        //   id: 'show-box-tem',
        //   className: 'fa fa-archive',
        //   command: 'show-box-tem',
        //   attributes: { title: 'Template Box'},
        // },
        // {
        //   id: 'show-cam-pdf',
        //   className: 'fa fa-archive',
        //   command: 'show-cam-pdf',
        //   attributes: { title: 'Download PDF' },
        // },
        // {
        //   id: 'show-youtube',
        //   className: 'fa fa-youtube',
        //   command: 'show-youtube',
        //   attributes: { title: 'EDNS Youtube' },
        // },
        // {
        //   id: 'show-edns',
        //   className: `fa fa-shopping-cart`,
        //   command: 'show-edns',
        //   attributes: { title: 'EDNS' },
        // },
        {
          id: "show-help",
          className: `fa fa-question-circle`,
          command: "show-help",
          attributes: { title: "Help" },
        },
        {
          id: "show-import",
          className: `fa fa-upload`,
          command: "show-import",
          attributes: { title: "Import" },
        },
      ],
    },
  ],
};

export default Panel;
