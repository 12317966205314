import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Image from "../Static/invis-user.png";
import useWindowDimensions from "../Hook/useWindowDimensions";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { podListActions } from "../store/pod-list-slice";
import { authActions } from "../store/auth-slice";
import { Iuser } from "../interface/get-user.interface";
import { getUser, IUser } from "../api/billing/user/getUser";
import { getProducts, IProduct } from "../api/billing/product/getProducts";
import logo from "../Static/logo.webp";
import { getPod } from "../api/get-pod";
import { FiArrowRight } from "react-icons/fi";
import { RootState } from "../store";
import { useAppSelector } from "../Hook/redux";
import { getItemsByUid } from "../api/get-item";
import podInfoSlice, { IPod, podActions } from "../store/pod-info-slice";

interface IProps {
  theme: string | null;
  setTheme: (value: string) => void;
  show: boolean;
  setShow: (value: boolean) => void;
  search?: any;
}

const TopNav = (props: IProps) => {
  const navigate = useNavigate();
  const [optionShow, setOptionShow] = useState(false);
  const { width, height } = useWindowDimensions();
  const { keycloak, initialized } = useKeycloak();
  const [user, setUser] = useState<Iuser>();
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const ref_userEmail = useRef<HTMLInputElement>(null);
  const ref_search = useRef<HTMLInputElement>(null);
  const [isInputtingChinese, setIsInputtingChinese] = useState(false);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [uidfile, setUidFile] = useState<any>(null);
  const [podList, setPodList] = useState<any>(null);
  const [resultConX, setResultConX] = useState(0);
  const [enableSearchCon, setEnableSearchCon] = useState(false);
  const searchCon = useRef<HTMLDivElement>(null);
  const pod = useSelector(podActions.currentPod).payload.pod;
  const handleCompositionStart = () => {
    setIsInputtingChinese(true);
  };

  const handleCompositionEnd = () => {
    setIsInputtingChinese(false);
  };

  const login = (packages: { productsList: IProduct[]; auth: boolean; billingUser: IUser; accessToken: string; user: Iuser }) => {
    dispatch(authActions.login(packages));
  };

  const logout = () => {
    dispatch(authActions.logout());
  };

  const auth = useSelector(authActions.login);
  const accessToken = auth.payload.auth.accessToken;
  const getFileByUid = async (uid: string) => {
    const res = await getItemsByUid(accessToken, `${user?.sub}`, uid);
    console.log(res.data[pod.pod.PrimaryProvider.toLowerCase()]);
  };
  const podListState = useAppSelector((state) => state.podList);

  useEffect(() => {
    console.log(podListState);
  }, [podListState]);

  const handleClickOutside = (event: any) => {
    if (!(event.target as HTMLElement).closest(".option-con") && !(event.target as HTMLElement).closest(".btn-option") && optionShow) {
      setOptionShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  useEffect(() => {
    const exec = async () => {
      if (keycloak.authenticated) {
        await keycloak.loadUserInfo();
        const _user: Iuser = keycloak.userInfo as Iuser;
        setUser(_user);
        const _accessToken = keycloak.token as string;

        const billingUser = await getUser(_accessToken);
        const productList = await getProducts(_accessToken);
        login({
          auth: true,
          accessToken: _accessToken,
          user: _user,
          billingUser: billingUser,
          productsList: productList,
        });
      }
    };
    exec();
  }, [keycloak.authenticated]);

  useEffect(() => {
    props.search(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (ref_userEmail.current && user) ref_userEmail.current.value = user.email!;
    handleGetPod();
  }, [user]);

  const handleGetPod = async () => {
    if (user) {
      const _podList = await getPod(keycloak.token as string, user.sub, "0", 100);
      // console.log(_podList.data);
      setPodList(_podList.data);
      setSearchResult(_podList.data);
    }
  };

  //Handle Search View Coordination
  useEffect(() => {
    if (width > 768) {
      const searchCon = document.querySelector(".search-con") as HTMLDivElement;
      const searchConX = searchCon.getBoundingClientRect().x;
      // console.log(searchConX);
      setResultConX(searchConX);
    }
  }, [width, searchValue, enableSearchCon]);

  const handleNavPod = (name: string) => {
    //navigate with redirect
    navigate(`/pod/info/${name}/items`);
    // navigate(0);
    setSearchResult(null);
  };

  const matchPod = async (e: any) => {
    await setTimeout(() => {
      // if (e.target.value.length === 0) {
      //   setSearchResult(null);
      //   return;
      // }
      if (user) {
        const arr_podList = [];
        for (let i = 0; i < podList.length; i++) {
          const regex = new RegExp(e.target.value);
          const match = podList[i].name.match(regex);
          if (match) {
            arr_podList.push(podList[i]);
          }
        }
        console.log(searchValue, arr_podList);
        if (arr_podList.length == 0 && e.target.value.length === 0) {
          setSearchResult(podList);
        } else {
          setSearchResult(arr_podList);
        }
      }
    }, 200);
  };

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (searchCon.current && !searchCon.current.contains(e.target) && enableSearchCon) {
        setEnableSearchCon(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchCon, enableSearchCon]);

  return (
    <>
      <nav className="top-nav main-theme gap-6">
        {searchResult && searchResult.length > 0 && enableSearchCon && (
          <div
            ref={searchCon}
            className="fade-in-from-b absolute top-[90%] left-0 flex max-h-[calc(100vh-100px)] min-w-[375px] flex-col overflow-y-auto rounded-xl border dark:border-[#232426] dark:bg-[#111315] dark:text-white"
            style={{ left: resultConX }}
          >
            {searchResult.map((pod: any) => {
              return (
                <div className="flex items-center justify-between p-4 hover:bg-[#16191c]">
                  <div className="flex items-center gap-3">
                    <div className="rounded-full bg-blue-100 px-2 text-sm font-medium dark:bg-[#27a48d]">{pod.primaryProvider}</div>
                    <div className="font-medium">{pod.name}</div>
                  </div>
                  <div
                    onClick={() => {
                      handleNavPod(pod.name);
                      setSearchValue("");
                    }}
                    className="cursor-pointer rounded-full bg-[#212425] p-2 transition-colors hover:bg-[#32cbae]"
                  >
                    <FiArrowRight />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="flex h-full items-center gap-6">
          <Link to="/pod" className="flex select-none items-center gap-3">
            <img src={logo} className="logo-img max-w-[35px] md:max-w-[37px]" />
            <p className="text-lg font-[500] text-[#4883e2] dark:text-white md:text-[20px]">DeDrive</p>
          </Link>
          <div className="hidden h-[18px] w-[2px] rounded-full bg-[#e1e0e3] md:block"></div>
        </div>
        {width > 768 && (
          <>
            <div className="search-con relative h-full w-full max-w-[375px] flex-1">
              <input
                ref={ref_search}
                className="title-white search-input sec bg-stamp h-full rounded-full border border-[#f6f8fa] bg-[#f6f8fa] text-xs outline-none focus:border-[#32cbae] dark:border-[#181a1b] dark:bg-[#1b1e1f] dark:focus:border-[#32cbae] md:w-full md:pl-[42px] md:text-sm"
                placeholder="Search in DeDrive"
                value={searchValue}
                onClick={(e) => {
                  setEnableSearchCon(true);
                  matchPod(e);
                }}
                onChange={(e) => {
                  setSearchValue(e.currentTarget.value);
                }}
                onKeyDown={(e) => {
                  matchPod(e);
                }}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
              ></input>
              <Icon icon="uil:search" className="icon-search h-auto w-5 cursor-pointer text-[#929198]" />
              <div
                onClick={() => {
                  const input = document.querySelector(".search-input") as HTMLInputElement;
                  input?.focus();
                }}
                className="bg-blue icon-search phone grid aspect-square h-auto w-8 place-items-center rounded-full text-white"
              >
                <Icon icon="entypo:drive" className="h-3 w-auto" />
              </div>
            </div>
          </>
        )}

        <div className="flex h-full flex-1 shrink-0 items-center justify-end gap-4">
          {keycloak.authenticated ? (
            <>
              {width < 768 && (
                <button
                  onClick={() => props.setShow(!props.show)}
                  className="grid aspect-square h-full place-items-center rounded-full text-[#636363] hover:bg-blue-500 hover:text-white dark:hover:bg-[#1b1f24]"
                >
                  <Icon icon="system-uicons:menu-hamburger" className="border-gray h-7 w-auto" />
                </button>
              )}
              <div className="relative flex h-full items-center">
                <div onClick={() => setOptionShow(!optionShow)} className="btn-option flex h-full cursor-pointer items-center gap-3 md:mr-[-5px]">
                  <div className=" relative flex h-full w-auto shrink-0 cursor-pointer select-none items-center gap-4">
                    <div className="border-gray grid aspect-square h-full place-items-center rounded-full border bg-white dark:bg-gray-600 md:h-[40px]">
                      <img src={Image} className="aspect-square h-[80%] w-[80%] object-cover" alt="user" />
                    </div>
                  </div>
                </div>
                <div
                  data-show={optionShow}
                  className="option-con border-gray bucket-info-border-color !z-[999] flex flex-col overflow-hidden rounded-3xl rounded-b-3xl border bg-white/80 text-xs font-medium backdrop-blur-[8px] dark:bg-[#111315]/80 2xl:text-[13px]"
                >
                  <div className="mx-auto mt-2 w-[calc(100%-1rem)] overflow-hidden rounded-2xl bg-[#f3f6fc] dark:bg-[#191b1c]">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-3 p-3 dark:text-[#bdc7d5]">
                        <div className="border-gray grid aspect-square h-[58px] place-items-center rounded-full border bg-white dark:bg-gray-600">
                          <img src={Image} className="aspect-square h-[80%] w-[80%] object-cover" alt="user" />
                        </div>
                        <div>
                          <p className="text-base font-medium dark:text-white">{user?.family_name}</p>
                          <p className="text-xs text-[#999b9e]">{user?.email}</p>
                        </div>
                      </div>
                      <Link
                        to="/billing"
                        onClick={() => setOptionShow(false)}
                        className="flex cursor-pointer items-center gap-6 p-6 text-[13px] font-medium text-[#565b5f] hover:bg-[#e2ebf9] dark:text-[#bdc7d5] dark:hover:bg-[#242728]"
                      >
                        <div className="border-gray bg-color rounded-lg">
                          <Icon icon="icon-park-outline:bill" className="h-auto w-5" />
                        </div>
                        <div className="">Billing</div>
                      </Link>
                    </div>
                  </div>
                  <hr className="mt-5 border-[#e1e3e1] dark:border-[#2d2f31]" />
                  <Link
                    to=""
                    onClick={() => {
                      keycloak.logout();
                      logout();
                      // window.location.reload();
                    }}
                    className="flex cursor-pointer items-center gap-6 px-8 py-6 text-[13px] font-semibold text-[#565b5f] hover:bg-[#e0e9f8] dark:text-[#bdc7d5] dark:hover:bg-[#252829]"
                  >
                    <div className="border-gray bg-color rounded-lg">
                      <Icon icon="material-symbols:logout-rounded" className="h-auto w-5" />
                    </div>
                    <div className="">Logout</div>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </nav>
    </>
  );
};
export default TopNav;
