import { Icon } from "@iconify/react";
import { useState } from "react";
import RightInfo from "../Components/RightInfo";
import Image from "../Static/invis-user.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { Iuser } from "../interface/get-user.interface";
import { updateUser } from "../api/keycloak-app/updateUserInfo";

const Setting = () => {
  const user: Iuser = useSelector(authActions.login).payload.auth.user;
  const accessToken: string = useSelector(authActions.login).payload.auth.accessToken;
  const [email, setEmail] = useState(user.email);

  const handleChange = (e: any) => {
    setEmail(e.currentTarget.value);
  };

  const _updateUser = async () => {
    await updateUser(accessToken, { email });
  };

  return (
    <div className="content-con overflow-y-auto md:px-24">
      <div className="mx-auto mb-8 max-w-[1400px] items-center justify-between md:mt-6 md:mb-16">
        <h1 className="title-white text-lg font-semibold md:text-[24px]">Genaral Settings</h1>
      </div>
      <div className="mx-auto flex max-w-[1400px] gap-5 pb-mobile md:pb-16 md:mt-6">
        <div className="flex flex-1 flex-col">
          <div className="flex w-full flex-col justify-between gap-8 rounded-xl md:flex-row md:gap-12">
            <div className="title-white min-w-[183px] text-lg font-semibold md:text-xl">
              <p>User Info</p>
              <p className="mt-2 text-sm text-[#a9a8b3]">Provide your personal info</p>
            </div>
            <div className="max-w-[800px] flex-1">
              <div className="btn-option relative flex select-none flex-wrap items-center gap-5">
                <div className="aspect-square h-10 rounded-full bg-white dark:bg-gray-600 md:h-[42px]">
                  <img src={Image} className="h-full w-full" alt="user" />
                </div>
                <div className="shrink-0">
                  <h3 className="title-white text-sm font-semibold">{user.name}</h3>
                  {/* <h3 className="text-xs text-[#A4A4A4]">Lite Plan</h3> */}
                </div>
                {/* <button className="bg-blue mt-[-5px] rounded-md px-10 py-3 text-xs font-medium text-white duration-100 hover:bg-[#226ddd] md:ml-4 2xl:text-[13px]">Update</button> */}
              </div>
              <div className="mt-6 flex flex-col justify-between gap-4 md:flex-row">
                <div className="flex-1">
                  <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">Email</h3>
                  <input
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => handleChange(e)}
                    className="bg-main setting-input add mt-3 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                  ></input>
                </div>
                <div className="flex-1">
                  <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">Password</h3>
                  <input
                    className="bg-main setting-input add mt-3 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                    disabled={true}
                    value="* * * * * * * *"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-10 md:mt-16" />
          {/* <div className="mt-12 flex flex-1 flex-col justify-between gap-10 rounded-xl md:flex-row md:gap-12">
            <div className="title-white shrink-0 text-xl font-semibold">
              <p>Billing Address</p>
              <p className="mt-2 text-sm text-[#a9a8b3]">Provide your billing address</p>
            </div>
            <div className="setting-grid billing max-w-[800px]">
              <div>
                <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">Address 1</h3>
                <input
                  placeholder="Enter your address 1"
                  className="bg-main border-gray setting-input add mt-3 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                ></input>
              </div>
              <div>
                <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">Address 2</h3>
                <input
                  placeholder="Enter your address 2"
                  className="bg-main border-gray setting-input add mt-3 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                ></input>
              </div>
              <div>
                <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">City</h3>
                <input
                  placeholder="Enter your city"
                  className="bg-main border-gray setting-input add mt-3 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                ></input>
              </div>
              <div>
                <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">State</h3>
                <input
                  placeholder="Enter your state"
                  className="bg-main border-gray setting-input add mt-3 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                ></input>
              </div>
              <div>
                <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">Country</h3>
                <input
                  placeholder="Enter your country"
                  className="bg-main border-gray setting-input add mt-3 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                ></input>
              </div>
              <div>
                <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">Post Code</h3>
                <input
                  placeholder="Enter your post code"
                  className="bg-main border-gray setting-input add mt-3 w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                ></input>
              </div>
            </div>
          </div> */}
          <div className="mt-8 flex justify-between gap-12">
            <div className="hidden min-w-[183px] md:block"></div>

            <button
              onClick={() => _updateUser()}
              className="w-full max-w-[800px] flex-1 items-center rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 py-4 px-6 text-center text-[15px] font-medium text-white dark:from-blue-800 dark:via-blue-700 dark:to-indigo-600"
            >
              Save
            </button>
          </div>
        </div>

        {/* <div className="flex h-fit min-w-[260px] flex-1 flex-col md:min-w-[400px] 2xl:max-w-[450px]">
          <div className="border-gray flex-1 rounded-xl border-2">
            <h2 className="border-gray border-b-2 p-5 px-6 text-[18px] font-medium text-[#788296]  ">
              Two Factor Authentication
            </h2>
            <div className="verify flex h-full flex-col justify-between gap-5 p-6">
              <div>
                <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">
                  Phone
                </h3>
                <div className="relative mt-3 flex flex-col items-center gap-5">
                  <PhoneInput
                    country="hk"
                    placeholder="Enter phone number"
                    // value={phoneNumber}
                    // onChange={(e, country) => {
                    //   setCountry(country);
                    //   setPhoneNumber(e);
                    // }}
                  />

                  <button
                    name="sendVerifyCode"
                    // onClick={sendVerifyCodeHandle}
                    className={`bg-blue bg theme absolute top-[6px] right-[6px] hidden h-[38px] rounded-[5px] px-6 text-xs font-medium text-white outline-none duration-200 hover:bg-[#226ddd] md:block 2xl:text-[13px]`}
                  >
                    Send Code
                  </button>
                </div>
                <button
                  name="sendVerifyCode"
                  // onClick={sendVerifyCodeHandle}
                  className={`bg-blue bg theme mt-5 block w-full rounded-[5px] py-4 px-4 text-sm font-medium text-white outline-none duration-200 hover:bg-[#226ddd] md:hidden 2xl:text-[13px]`}
                >
                  Send Code
                </button>
              </div>
              <div>
                <h3 className="text-xs text-[#A4A4A4] 2xl:text-[13px]">Code</h3>
                <input className="setting-input mt-3 w-full rounded-md border p-4 text-xs outline-none 2xl:text-[13px]"></input>
              </div>
              <button
                name="sendVerifyCode"
                // onClick={sendVerifyCodeHandle}
                className={`bg-blue bg theme top-[6px] right-[6px] h-[50px] rounded-[5px] px-4 text-sm font-medium text-white outline-none duration-200 hover:bg-[#226ddd] 2xl:text-[13px]`}
              >
                Verify
              </button>
            </div>
          </div>
        </div> */}
      </div>
      {/* <RightInfo /> */}
    </div>
  );
};

export default Setting;
