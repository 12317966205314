const pageStyleEngine = {
  sectors: [
    {
      name: "General",
      open: false,
      properties: ["display", "float", "position", "top", "right", "left", "bottom"],
    },
    {
      name: "Flex",
      open: false,
      properties: ["flex-direction", "flex-wrap", "justify-content", "align-items", "align-content", "order", "flex-basis", "flex-grow", "flex-shrink", "align-self"],
    },
    {
      name: "Dimension",
      open: false,
      properties: ["width", "height", "max-width", "min-height", "margin", "padding"],
    },
    {
      name: "Typography",
      open: false,
      properties: [
        "font-family",
        "font-weight",
        {
          // Default options
          // id: 'padding', // The id of the property, if missing, will be the same as `property` value
          type: "number",
          label: "Font Size", // Label for the property
          property: "font-size", // CSS property to change
          default: "20", // Default value to display
          // Additonal `number` options
          units: ["px", "%", "em", "rem", "vh", "vw"], // Units (available only for the 'number' type)
          min: 0, // Min value (available only for the 'number' type)
        },
        "letter-spacing",
        "color",
        "line-height",
        "text-align",
        "text-shadow",
      ],
    },
    {
      name: "Decorations",
      open: false,
      properties: ["background-color", "border-radius", "border", "box-shadow", "background"],
    },
    {
      name: "Extra",
      open: false,
      properties: ["opacity", "transition", "transform"],
    },
  ],
  appendTo: "",
  stylePrefix: "sm-",
  custom: false,
  hideNotStylable: true,
  highlightChanged: true,
  highlightComputed: true,
  showComputed: true,
  clearProperties: true,
  avoidComputed: ["width", "height"],
};

export default pageStyleEngine;
