// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import type grapesjs from "grapesjs";
import importfile from "./import.json";
// import { toEditorSettings } from 'typescript';
// import testCom from "../blockManager/template/testComponent/testComponent"
// import temPage from "../blockManager/template/testComponent/testComponent3"
import com3css from "../blockManager/template/testComponent/com3text";
// import { ImageEditor } from '@syncfusion/ej2-image-editor';
// import { Browser, getComponent } from '@syncfusion/ej2-base';
// import { DropDownList } from '@syncfusion/ej2-dropdowns';

import page from "../blockManager/template/testPage/testpage1/testpage1";
import page2 from "../blockManager/template/testPage/testpage2/testpage2";
import page3 from "../blockManager/template/testPage/testpage3/testpage3";
import page4 from "../blockManager/template/testPage/testpage4/testpage4";
import page5 from "../blockManager/template/testPage/testpage5/testpage5";
import page6 from "../blockManager/template/testPage/testpage6/testpage6";
import CoverStyle from "../blockManager/template/testPage/styles/style1";
import CoverPage from "../blockManager/template/testPage/testpage7/testpage7";
import axios from "axios";
type Editor = grapesjs.Editor;

const Command = {
  defaults: [
    {
      id: "set-device-desktop", //Follow the deviceManger/Device , device's attribute "name"
      run(editor: Editor) {
        editor.setDevice("Desktop");
      },
    },
    {
      id: "set-device-mobile",
      run(editor: Editor) {
        editor.setDevice("Mobile");
      },
    },
    {
      id: "set-device-tablet",
      run(editor: Editor) {
        editor.setDevice("Tablet");
      },
    },
    {
      id: "set-device-tablet",
      run(editor: Editor) {
        editor.setDevice("Tablet");
      },
    },
    {
      id: "undo",
      run(editor: Editor) {
        editor.UndoManager.undo();
      },
    },
    {
      id: "redo",
      run(editor: Editor) {
        editor.UndoManager.redo();
      },
    },
    {
      id: "show-json",
      run(editor: Editor) {
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">Components JSON</b>")
          .setContent(`<textarea style="width:100%; height: 500px;">${JSON.stringify(editor.getComponents())}</textarea>`)
          .open();
      },
    },
    {
      id: "show-box-tem",
      run(editor: Editor) {
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">Template Box</b>")
          .setContent(
            `<p>Resize the browser window to see the effect.</p>
          <style>
              ${com3css}
          </style>
          <div class="row">
            <div class="column">
              <div class="card">
              <img style="border-radius:5px; width:100%;" src="https://i.im.ge/2022/08/26/OwaYGY.Screenshot-2022-08-26-at-6-01-45-PM.th.png" alt="Screenshot 2022-08-26 at 6.01.45 PM" border="0">
              <button id="edns_1" class="btn-use" type="button" >Use</button>
              </div>
            </div>
          
            <div class="column">
              <div class="card">
              <img style="border-radius:5px; width:100%;" src="https://i.im.ge/2022/09/06/ORn1La.Screenshot-2022-09-06-at-11-05-06-AM.th.png" alt="Screenshot 2022-09-06 at 11.05.06 AM" border="0">
              <button id="edns_2" class="btn-use" type="button" >Use</button>
              </div>
            </div>
            
            <div class="column">
              <div class="card">
              <img style="border-radius:5px; width:100%;" src="https://i.im.ge/2022/09/06/ORJBIf.Screenshot-2022-09-06-at-11-14-32-AM.th.png" alt="Screenshot 2022-09-06 at 11.14.32 AM" border="0">
              <button id="edns_3" class="btn-use" type="button" >Use</button>
              </div>
            </div>
            
            <div class="column">
              <div class="card">
              <img style="border-radius:5px; width:100%;" src="https://i.im.ge/2022/09/06/ORn2xJ.Screenshot-2022-09-06-at-11-25-26-AM.th.png" alt="Screenshot 2022-09-06 at 11.25.26 AM" border="0">
              <button id="edns_4" class="btn-use" type="button">Use</button>
              </div>
            </div>
            
            <div class="column">
              <div class="card">
              <img style="border-radius:5px; width:100%;" src="https://i.im.ge/2022/09/06/ORnsPz.Screenshot-2022-09-06-at-11-27-41-AM.th.png" alt="Screenshot 2022-09-06 at 11.27.41 AM" border="0">
              <button id="edns_5" class="btn-use" type="button" >Use</button>
              </div>
            </div>
            
            <div class="column">
              <div class="card">
              <img style="border-radius:5px; width:100%;" src="https://i.im.ge/2022/09/06/ORnHsy.Screenshot-2022-09-06-at-11-36-46-AM.th.png" alt="Screenshot 2022-09-06 at 11.36.46 AM" border="0">
              <button id="edns_6" class="btn-use" type="button" >Use</button>
              </div>
            </div>
          </div>`,
          )
          .open();
        const btnExp1 = document.getElementById("edns_1");
        btnExp1.onclick = () => {
          editor.setComponents(page);
        };
        const btnExp2 = document.getElementById("edns_2");
        btnExp2.onclick = () => {
          editor.setComponents(page2);
        };
        const btnExp3 = document.getElementById("edns_3");
        btnExp3.onclick = () => {
          editor.setComponents(page3);
        };
        const btnExp4 = document.getElementById("edns_4");
        btnExp4.onclick = () => {
          editor.setComponents(page4);
        };
        const btnExp5 = document.getElementById("edns_5");
        btnExp5.onclick = () => {
          editor.setComponents(page5);
        };
        const btnExp6 = document.getElementById("edns_6");
        btnExp6.onclick = () => {
          editor.setComponents(page6);
        };
      },
    },
    {
      id: "manuel-save",
      run(editor: Editor) {
        editor.StorageManager.store();
        alert("Saved");
      },
    },
    {
      id: "show-box-fol",
      run(editor: Editor) {
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">File Folder</b>")
          .setContent(
            `<p>Resize the browser window to see the effect.</p>
          <style>
              ${com3css}
          </style>
          ${listPage(editor)} 

          `,
          ) //Mult File Structure
          .open();
        addEditBtn(editor);

        //Show mult pages item
        function listPage(editor: Editor): string {
          const PageManager = editor.Pages;
          const arrayPages = PageManager.getAll();
          const pagef = [];
          const pageCom = [];
          let result = `<div class="row">`;

          // First Page == Main Page
          // Loop skip the index[0]
          // bug: cannot get first page in loop ,because coverpage is first(main) page

          for (let i = 1; i < arrayPages.length; i++) {
            pagef[i] = PageManager.get(arrayPages[i].id);
            // console.log(pagef[i])
            pageCom[i] = pagef[i].getMainComponent();
            // console.log(pageCom[i])
            result += `
                <div class="column">
                  <div id="${pagef[i].getId()}-card" class="card">
                    <i class="fa fa-files-o" aria-hidden="true" style="font-size:100px;"></i>
                    <p style="font-size:15px;">${pagef[i].getId()}</p>
                    <p><small id="${pagef[i].getId()}-respon"></small></p>
                    <button onclick="console.log('edit')" id="${pagef[i].getId()}-edit" style="color:green;">Edit</button>
                    <button onclick="console.log('delete')" id="${pagef[i].getId()}-del" style="color:red">Delete</button>
                  </div>
                </div>`;
          }
          result += `</div>`;
          return result;
        }

        //Add the button fun for each page in page list
        function addEditBtn(editor: Editor): void {
          const PageManager = editor.Pages;
          const arrayPages = PageManager.getAll();

          const pagef = [];
          const pageCom = [];
          const btn_edit = [];
          const btn_del = [];
          const label_card = [];
          const lable_response = [];

          // First Page == Main Page
          // Loop skip the index[0]
          // bug: cannot get first page in loop ,because coverpage is first(main) page
          for (let i = 1; i < arrayPages.length; i++) {
            pagef[i] = PageManager.get(arrayPages[i].id);
            pageCom[i] = pagef[i].getMainComponent();
            btn_edit[i] = document.getElementById(`${pagef[i].getId()}-edit`);
            btn_del[i] = document.getElementById(`${pagef[i].getId()}-del`);
            label_card[i] = document.getElementById(`${pagef[i].getId()}-card`);
            lable_response[i] = document.getElementById(`${pagef[i].getId()}-respon`);

            btn_edit[i].style.backgroundColor = "green";
            btn_edit[i].style.padding = "10px";
            btn_edit[i].style.width = "60px";
            btn_edit[i].style.borderRadius = "5px";
            btn_edit[i].style.color = "#FFF";

            btn_del[i].style.backgroundColor = "red";
            btn_del[i].style.padding = "10px";
            btn_del[i].style.width = "60px";
            btn_del[i].style.borderRadius = "5px";
            btn_del[i].style.color = "#FFF";

            // console.log("array index no:",i)
            // console.log("page components:",pageCom[i]);
            // console.log("Page id:",arrayPages[i].id)
            // console.log("Page content:",pageCom[i].toHTML());

            //Add the onclick and onmousedown function in loop
            btn_edit[i].onclick = () => editor.setComponents(pageCom[i].toHTML());
            btn_del[i].onmousedown = () => PageManager.remove(pagef[i].getId());
            btn_del[i].onclick = () => (label_card[i].style.display = "none");
          }
        }
      },
    },
    {
      id: "show_page",
      run(editor: Editor) {
        function listPage(editor: Editor): string {
          const PageManager = editor.Pages;
          const arrayPages = PageManager.getAll();
          const pagef = [];
          const pageCom = [];
          let result = `<div class="row">`;

          // First Page == Main Page
          // Loop skip the index[0]
          // bug: cannot get first page in loop ,because coverpage is first(main) page

          for (let i = 1; i < arrayPages.length; i++) {
            pagef[i] = PageManager.get(arrayPages[i].id);
            // console.log(pagef[i])
            pageCom[i] = pagef[i].getMainComponent();
            // console.log(pageCom[i])
            result += `
                <div class="column">
                  <div id="${pagef[i].getId()}-card" class="card">
                    <p style="font-size:15px;">${pagef[i].getId()}</p>
                    <p><small id="${pagef[i].getId()}-respon"></small></p>
                    <button onclick="console.log('edit')" id="${pagef[i].getId()}-edit" style="color:green;">Edit</button>
                    <button onclick="console.log('delete')" id="${pagef[i].getId()}-del" style="color:red">Delete</button>
                  </div>
                </div>`;
          }
          result += `</div>`;
          return result;
        }

        //Add the button fun for each page in page list
        function addEditBtn(editor: Editor): void {
          const PageManager = editor.Pages;
          const arrayPages = PageManager.getAll();

          const pagef = [];
          const pageCom = [];
          const btn_edit = [];
          const btn_del = [];
          const label_card = [];
          const lable_response = [];

          // First Page == Main Page
          // Loop skip the index[0]
          // bug: cannot get first page in loop ,because coverpage is first(main) page
          for (let i = 1; i < arrayPages.length; i++) {
            pagef[i] = PageManager.get(arrayPages[i].id);
            pageCom[i] = pagef[i].getMainComponent();
            btn_edit[i] = document.getElementById(`${pagef[i].getId()}-edit`);
            btn_del[i] = document.getElementById(`${pagef[i].getId()}-del`);
            label_card[i] = document.getElementById(`${pagef[i].getId()}-card`);
            lable_response[i] = document.getElementById(`${pagef[i].getId()}-respon`);

            btn_edit[i].style.backgroundColor = "green";
            btn_edit[i].style.padding = "10px";
            btn_edit[i].style.width = "60px";
            btn_edit[i].style.borderRadius = "5px";
            btn_edit[i].style.color = "#FFF";

            btn_del[i].style.backgroundColor = "red";
            btn_del[i].style.padding = "10px";
            btn_del[i].style.width = "60px";
            btn_del[i].style.borderRadius = "5px";
            btn_del[i].style.color = "#FFF";

            // console.log("array index no:",i)
            // console.log("page components:",pageCom[i]);
            // console.log("Page id:",arrayPages[i].id)
            // console.log("Page content:",pageCom[i].toHTML());

            //Add the onclick and onmousedown function in loop
            btn_edit[i].onclick = () => editor.setComponents(pageCom[i].toHTML());
            btn_del[i].onmousedown = () => PageManager.remove(pagef[i].getId());
            btn_del[i].onclick = () => (label_card[i].style.display = "none");
          }
        }
        const componentCount = editor.getComponents().filter((c: any) => c.is("page")).length;
        pageCounterPanel.set("label", `Pages: ${componentCount}`);
      },
    },
    {
      id: "canvas-clear",
      run(editor: Editor) {
        //editor.DomComponents.clear();
        editor.setComponents("");
      },
    },
    {
      id: "show-box-edit-page",
      run(editor: Editor) {
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">Create, update & save current page</b>")
          .setContent(
            `
            File name : <input type="text" id="mint-new-page" style="margin:10px;text-align:center;padding:5px;color:#000"/>
            <button id="mint-page-btn" style="padding:5px;background-color:blue;color:#FFF;border-radius:10px;" >Submit</button>
            <p id="respon"></p>
          `,
          )
          .open();

        const btn = document.getElementById("mint-page-btn");

        // When the user click "submit" button (update function)
        btn.onclick = () => {
          const respon: string = document.getElementById("respon");
          const fileName: string = document.getElementById("mint-new-page").value;
          const fileStyle: string = editor.getCss();
          const fileHtml: string = editor.getHtml();
          const pageManager = editor.Pages;
          const arrayPages = pageManager.getAll();
          const pagef: array = [];
          const pageName: array = [];

          // Check if the page has been save in PageManager,
          // then remove and overwrite it
          for (let i = 0; i < arrayPages.length; i++) {
            pagef[i] = pageManager.get(arrayPages[i].id); // what page did user have ?
            pageName[i] = pagef[i].getId();

            if (pageName[i] === fileName) {
              // console.log("The page has been update");
              pageManager.remove(pageName[i]);
              pageManager.add({ id: fileName, styles: fileStyle, component: fileHtml });
              respon.innerHTML = `Update the ${fileName} content in your folder.`;
            } else {
              // console.log("The page has not create in pageManager");
              // console.log("Creating now....");
              pageManager.add({ id: fileName, styles: fileStyle, component: fileHtml });
              respon.innerHTML = `Create the ${fileName} in your folder.`;
            }
          }
        };
      },
    },
    // {
    //   id: 'show-cam-pdf',
    //   run(editor:Editor) {
    //     let canvas = document.getElementById('gjs-frame-wrapper');
    //     console.log(canvas)
    //     // only jpeg is supported by jsPDF
    //     let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //     console.log(imgData)
    //     let pdf = new jsPDF();
    //     console.log(pdf)

    //   },
    // },
    {
      id: "show-youtube",
      run(editor: Editor) {
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">EDNS Youtube - Why DeDrive ?</b>")
          .setContent(
            `<iframe width="100%" height="600px" src="https://www.youtube.com/embed/NXedyHoOzx8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
          )
          .open();
      },
    },
    {
      id: "show-edns",
      run(editor: Editor) {
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">EDNS Domain - Buy Web3.0 Domain</b>")
          .setContent(`<iframe width="100%" height="700px" src="https://app.edns.domains/"></iframe>`)
          .open();
      },
    },
    {
      id: "show-help",
      run(editor: Editor) {
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">EDNS - FAQ</b>")
          .setContent(`<iframe width="100%" height="700px" src="https://faq.edns.domains/"></iframe>`)
          .open();
      },
    },
    {
      id: "show-import",
      run(editor: Editor) {
        editor.getConfig().pluginsOpts["importEvent"]();
        // const file:{"index.html":string,"style.css":string} = importfile
        // editor.setStyle(file["style.css"]);
        // editor.setComponents(file["index.html"]); //Set the coverPage
      },
    },
  ],
};

export default Command;
