import axios from "axios";
import config from "../../config";

export interface IUpdateUser {
  email?: string;
  family_name?: string;
  given_name?: string;
  name?: string;
}

export async function updateUser(accessToken: string, input: IUpdateUser) {
  const url = new URL("/auth/realms/test_dedrive/account", config.auth0.url);
  const response = await axios.post(url.href, input, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
