import config from "../../../config";
import axios from "axios";
import { IProduct } from "../product/getProducts";
import { IPaymentRecord } from "../payment/getPaymentRecord";

export interface ITransactionRecord{
  id:string;
  subtotal:number;
  total:number;
  type:string;
  createdAt:string;
  product?:IProduct
  payment?:IPaymentRecord
}

export async function getTransactionRecords(accessToken: string): Promise<ITransactionRecord[]> {
  {
    const url = new URL(
      `/billing/transactions`,
      config.api.billing_endpoint
    );
    const response = await axios.get(url.href, {
      withCredentials: false,
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    });
    return response.data;
  }
}
