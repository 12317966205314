import config from "../../../config";
import axios from "axios";
import { IWallet } from "../wallet/wallet";

enum PaymentType {
  COINGATE = "COINGATE",
}

interface IcreateCoingatePaymentLink {
  type: string;
  total: number;
  wallet: IWallet;
  link: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

export async function createCoingatePaymentLink(accessToken: string, total: number): Promise<IcreateCoingatePaymentLink> {
  const url = new URL(`/billing/payment`, config.api.billing_endpoint);
  const data = {
    type: PaymentType.COINGATE,
    total: total,
  };
  const response = await axios.post(url.href, data, {
    withCredentials: false,
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
