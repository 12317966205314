import { configureStore, createSlice } from "@reduxjs/toolkit";
import authSlice from "./auth-slice";
import fileSlice from "./file-slice";
import podInfoSlice from "./pod-info-slice";
import storageSlice from "./storage-slice";
import podListSlice from "./pod-list-slice";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const peristedState = loadState();

const store = configureStore({
  reducer: {
    file: fileSlice.reducer,
    auth: authSlice.reducer,
    storage: storageSlice.reducer,
    pod: podInfoSlice.reducer,
    podList: podListSlice.reducer,
  },
  preloadedState: peristedState,
});

store.subscribe(() => {
  saveState(store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
