import { useEffect } from "react";

/* Web Builder Set the Panel Layout for each devices mode */
const devices = {
    devices:
    [
        {
            id: 'desktop',
            name: 'Desktop',
            width: '',
        },
        {
            id: 'tablet',
            name: 'Tablet',
            width: '768px',
            widthMedia: '992px',
        },
        {
            id: 'mobilePortrait',
            name: 'Mobile',
            width: '320px',
            widthMedia: '575px',
        },
    ]
}

export default devices;