import config from "../../../config";
import axios from "axios";

export interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  createdAt: string;
  updatedAt: string;
}

export async function getProducts(accessToken: string): Promise<IProduct[]> {
  const url = new URL(`/billing/products`, config.api.billing_endpoint);
  const response = await axios.get(url.href, {
    withCredentials: false,
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
