import axios from "axios";
import config from "../config";
import ICreatePod from "../interface/create-pod.interface";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { errorHandler } from "./presigned-url-multi";

export async function creatPod(userid: string, accessToken: string, input: ICreatePod) {
  if (input.name.toLowerCase() !== input.name) {
    throw new Error("Pod name must be lower case");
  }
  const url = new URL("/v1/http-api/pods", config.api.endpoint);
  const response = await axios.post(url.href, input, {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${accessToken}`,
      "user-id": userid,
    },
  });
  errorHandler(response.data.status, response.data.messages, "creatPod", userid);
  window.analytics.track("Create Pod - Success", {
    podInfo: { ...input },
    userId: userid,
    date: new Date().toLocaleString(),
  });
  return response.data;
}
