import QuotesLeftBorder from "./QuotesContent/QuotesImg/QuotesLeftBorder.png";
import QuotesPullQuote from "./QuotesContent/QuotesImg/QuotesPullQuote.png";

const Quotes = [
  {
    id: "Quotes-left-border",
    category: "Quotes",
    label: "<h3>Left Border</h3>",
    media: `<img src="${QuotesLeftBorder}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .bl {
                    border-left-style: solid;
                    border-left-width: 1px;
                }
                
                .b--blue {
                    border-color: #408bc9;
                }
                
                .bw2 {
                    border-width: .25rem;
                }
                
                .athelas {
                    font-family: athelas, georgia, serif;
                }
                
                .fs-normal {
                    font-style: normal;
                }
                
                .tracked {
                    letter-spacing: .1em;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .black-90 {
                    color: rgba(0, 0, 0, .9);
                }
                
                .pa4 {
                    padding: 2rem;
                }
                
                .pl4 {
                    padding-left: 2rem;
                }
                
                .ml0 {
                    margin-left: 0;
                }
                
                .mt0 {
                    margin-top: 0;
                }
                
                .ttu {
                    text-transform: uppercase;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                @media screen and (min-width: 30em) and (max-width: 60em) {
                    .f4-m {
                        font-size: 1.25rem;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .f3-l {
                        font-size: 1.5rem;
                    }
                }
                </style>
                <div class="pa4">
                <blockquote class="athelas ml0 mt0 pl4 black-90 bl bw2 b--blue">
                    <p class="f5 f4-m f3-l lh-copy measure mt0">
                    Discipline in typography is a prime virtue. Individuality
                    must be secured by means that are rational. Distinction
                    needs to be won by simplicity and restraint. It is equally
                    true that these qualities need to be infused wiht a
                    certain spirit and vitality, or they degenerate into
                    dullness and mediocrity.
                    </p>
                    <cite class="f6 ttu tracked fs-normal">―Stanley Morison</cite>
                </blockquote>
                </div>
            `,
  },
  {
    id: "Quotes-pull-quote",
    category: "Quotes",
    label: "<h3>Pull Quote</h3>",
    media: `<img src="${QuotesPullQuote}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .athelas {
                    font-family: athelas, georgia, serif;
                }
                
                .fw9 {
                    font-weight: 900;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .pa4 {
                    padding: 2rem;
                }
                
                .ph0 {
                    padding-left: 0;
                    padding-right: 0;
                }
                
                .f4 {
                    font-size: 1.25rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                .measure-narrow {
                    max-width: 20em;
                }
                
                .center {
                    margin-right: auto;
                    margin-left: auto;
                }
                
                @media screen and (min-width: 30em) {
                    .lh-title-ns {
                        line-height: 1.25;
                    }
                
                    .f1-ns {
                        font-size: 3rem;
                    }
                
                    .f4-ns {
                        font-size: 1.25rem;
                    }
                }
                </style>
                <article class="athelas pa4">
                <p class="f6 f4-ns lh-copy measure center">
                    The form of our letters, the older handwriting and inscriptions as much as
                    the cuttings in use today, reflects a convention that has slowly solidified,
                    an agreement hardened in many battles. Even after the Renaissance several
                    European countries retained broken, blackletter national scripts in opposition to roman, the obligatory type for all Latin material; yet even today, I hope, the last word about Fraktur has not been spoken.
                </p>
                <blockquote class="ph0 f4 f1-ns measure-narrow center">
                    <p class="fw9 lh-copy lh-title-ns">The punches of Claude Garamond, cut around 1530 in Paris, are simply unsurpassed in their clarity, readability and beauty.
                    </p>
                </blockquote>
                <p class="f6 f4-ns lh-copy measure center">
                    Apart from that, the roman minuscule has been our way of writing for hundreds
                    of years. What followed were merely fashionable variations, here and there even
                    deformations, of the noble basic form, but no improvement whatever. The
                    punches of Claude Garamond, cut around 1530 in Paris, are simply unsurpassed in
                    their clarity, readability and beauty. Garamond appeared on the scene at a
                    time when the occidental book, as an object, cast off its medieval
                    ponderousness and took up the form which today is still the best: the slender
                    and upright rectangular body, comprising folded sheets stitched or sewn at
                    the back, in a cover whose protruding edges protect the trimmed pages.
                </p>

                </article>
            `,
  },
];

export default Quotes;
