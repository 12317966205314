export enum provideType {
  IPFS = "IPFS",
  BZZ = "BZZ",
  BTFS = "BTFS",
  ETD = "ETD",
  ARWEAVE = "ARWEAVE",
  STORJ = "STORJ",
  FILECOIN = "FILECOIN",
  SIA = "SIA",
  ZEROCHAIN = "0CHAIN",
}
export enum podType {
  MANAGED_PUBLIC_CONTRACT = "MANAGED_PUBLIC_CONTRACT",
  MANAGED_PRIVATE_CONTRACT = "MANAGED_PRIVATE_CONTRACT",
  IMPORTED_PUBLIC_CONTRACT = "IMPORTED_PUBLIC_CONTRACT",
  FULLY_MANAGED = "FULLY_MANAGED",
}

export interface IStamp {
  batchId: string;
  // apiUrl: string;
  // debugApiUrl: string;
}
export interface IStampUp {
  BatchId: string;
  ApiUrl: string;
  DebugApiUrl: string;
}

export interface IBzzStamp {
  defaultIndex: number;
  stamps: [];
}
export default interface ICreatePod {
  name: string;
  primaryProvider: provideType | undefined;
  providers: provideType[];
  publicAccessible: boolean;
  type: podType;
  address: string;
  owner: string;
  chunking: boolean;
  bzzStamp?: {
    defaultIndex: number;
    stamps: IStamp[];
  };
  recovery: {
    enable: boolean;
    threshold: number;
  };
}
