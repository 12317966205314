import { NavLink } from "react-router-dom";
import useWindowDimensions from "../Hook/useWindowDimensions";
import { Icon } from "@iconify/react";

const BottomNav = () => {
  const { height, width } = useWindowDimensions();
  if (width > 768) {
    return null;
  }
  return (
    <div className="bg-main fixed bottom-0 left-0 z-[500] flex w-screen border-t border-gray-100 dark:border-[#2d2f31]">
      <NavLink
        to="/pod"
        className={({ isActive }) =>
          isActive ? "text-main flex flex-1 flex-col items-center gap-1 py-3 text-xs" : "flex flex-1 flex-col items-center gap-1 py-3 text-xs text-[#c2c2c2] dark:text-white"
        }
      >
        <Icon icon="tabler:bucket" className="h-auto w-5 shrink-0" />
        <p>Pod</p>
      </NavLink>
      {/* <NavLink
        to="/billing"
        className={({ isActive }) =>
          isActive ? "text-main flex flex-1 flex-col items-center gap-1 py-3 text-xs" : "flex flex-1 flex-col items-center gap-1 py-3 text-xs text-[#c2c2c2] dark:text-white"
        }
      >
        <Icon icon="icon-park-outline:bill" className="h-auto w-5 shrink-0" />
        <p>Billing</p>
      </NavLink> */}
      <NavLink
        to="/setting"
        className={({ isActive }) =>
          isActive ? "text-main flex flex-1 flex-col items-center gap-1 py-3 text-xs" : "flex flex-1 flex-col items-center gap-1 py-3 text-xs text-[#c2c2c2] dark:text-white"
        }
      >
        <Icon icon="akar-icons:gear" className="h-auto w-5 shrink-0" />
        <p>Setting</p>
      </NavLink>
    </div>
  );
};

export default BottomNav;
