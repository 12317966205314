// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type grapesjs from "grapesjs";
import { Buffer } from "buffer";
import { Data } from "../../../../interface/websiteData.interface";
import { useKeycloak } from "@react-keycloak/web";

type Editor = grapesjs.Editor;

export type PluginOptions = {
  /**
   * Type id used to register the new storage.
   * You can use this option in case you want to replace the already available storages (eg. `local`).
   * @default 'indexeddb'
   */
  type?: string;

  /**
   * Add a button inside the export dialog
   * @default true
   */
  addExportBtn?: boolean;

  /**
   * Label of the export button
   * @default 'Export to ZIP'
   */
  btnLabel?: string;

  /**
   * ZIP filename prefix
   * @default 'grapesjs_template'
   */
  filenamePfx?: string;

  /**
   * Use a function to generate the filename, eg. `filename: editor => 'my-file.zip',`
   */
  filename?: (editor: Editor) => string;

  /**
   * Callback to execute once the export is completed
   */
  done?: () => void;

  /**
   * Callback to execute on export error
   */
  onError?: (error: Error) => void;

  /**
   * Use the root object to create the folder structure of your zip (async functions are supported)
   * @example
   * root: {
   *   css: {
   *     'style.css': ed => ed.getCss(),
   *     'some-file.txt': 'My custom content',
   *   },
   *   img: async ed => {
   *     const images = await fetchImagesByStructue(ed.getComponents());
   *     return images;
   *     // Where `images` is an object like this:
   *     // { 'img1.png': '...png content', 'img2.jpg': '...jpg content' }
   *   },
   *   'index.html': ed => `<body>${ed.getHtml()}</body>`
   * }
   */
  root?: Record<string, unknown>;

  /**
   * Custom function for checking if the file content is binary
   */
  isBinary?: (content: string, name: string) => boolean;
};

// You can edit the plugin option status for the function
const plugin: grapesjs.Plugin<PluginOptions> = (editor, opts = {}) => {
  const pfx = editor.getConfig("stylePrefix");
  const configgg = editor.getConfig();
  console.log({ configgg });
  const commandName = "dedrive-connect";

  const config: PluginOptions = {
    addExportBtn: true,
    btnLabel: "DeDrive Upload",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    done: () => {},
    onError: console.error,
    // css: {
    "style.css": (editor: Editor) => editor.getCss(),
    // },
    // html:{
    "index.html": (editor: Editor) =>
      `<!doctype html>
      <html lang="en">
        <head>
          <meta charset="utf-8">
          
        </head>
        <body>${editor.getHtml()}</body>
      </html>`,
    // },
    // js:{
    "index.js": (editor: Editor) => editor.getJs(),
    // }
  };

  // Add command -> what will happen when user click the button
  editor.Commands.add(commandName, {
    run(editor, sender: any) {
      const data: Data = {
        "index.html": `<!doctype html>
        <html lang="en">
          <head>
            <meta charset="utf-8">
            
            <script src="../js/index.js"></script>
          </head>
          <body>${editor.getHtml()}</body>
        </html>`,

        "style.css": editor.getCss(),
        "index.js": editor.getJs(),
      };
      console.log("Save to DeDrive");
      console.log(data);

      const output = Object.entries(data).map(([key, value]) => ({ key, value: Buffer.from(value, "utf-8") }));

      configgg.pluginsOpts["setData"](output.filter((x) => x.value.byteLength !== 0));
    },
  });

  // Create the button for the command function
  editor.onReady(async () => {
    // Add button inside export dialog
    if (config.addExportBtn) {
      const btnExp = document.createElement("select");
      // await keycloak.loadUserInfo();
      btnExp.innerHTML = config.btnLabel!;
      btnExp.className = `mt-2`;

      editor.on("run:export-template", () => {
        const el = editor.Modal.getContentEl();
        el.appendChild(btnExp); //bug
        btnExp.onclick = () => {
          editor.runCommand(commandName);
        };
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">Upload to DeDrive Storage</b>");
      });
    }
  });

  editor.onReady(() => {
    // Add button inside export dialog
    if (config.addExportBtn) {
      const btnExp = document.createElement("button");

      btnExp.innerHTML = config.btnLabel!;
      btnExp.className = `${pfx}btn-prim`;

      editor.on("run:export-template", () => {
        const el = editor.Modal.getContentEl();
        el.appendChild(btnExp); //bug
        btnExp.onclick = () => {
          editor.runCommand(commandName);
        };
        editor.Modal.setTitle("<b style=\"font-family: 'Changa', sans-serif;font-size:20px;\">Upload to DeDrive Storage</b>");
      });
    }
  });
};

export default plugin;
