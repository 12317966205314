import axios from "axios";
import config from "../config";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";

export interface IPresignedUrlMulti {
  id: string;
  key: string;
  size: number;
  type: string;
}

export interface IPresignedUrlResMulti {
  status: number;
  messages: string[];
  time: Date;
  data: {
    urls: { [index: number]: string };
  };
}
export function errorHandler(status:number,message:string,functionName:string,userId:string){
  if(status!==200){
    window.analytics.track(`${functionName} - Fail`,{
      status:status,
      errorMessage:message,
      userId:userId,
      date:new Date().toLocaleString()
    })
    throw new Error(message)
  }
}

export async function presignUrlMulti(accessToken: string, input: IPresignedUrlMulti[],userId:string): Promise<IPresignedUrlResMulti> {
  const url = new URL(`/v1/storage/upload/urls`, config.api.endpoint);
  const response = await axios.post(url.href, input, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  errorHandler(response.data.status,response.data.messages[0],"presignUrlMulti",userId)
  return response.data;
}
