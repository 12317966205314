import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IFile {
  file: {
    name: string | undefined;
    type: string | undefined;
    location: string | undefined;
    lastModified: string | undefined;
    size: string | undefined;
  };
}

const initialState: IFile = {
  file: {
    name: undefined,
    type: undefined,
    location: undefined,
    lastModified: undefined,
    size: undefined,
  },
};

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    preview(state, action: any) {
      state.file = {
        name: action.payload.name,
        type: action.payload.type,
        location: action.payload.location,
        lastModified: action.payload.lastModified,
        size: action.payload.size,
      };
    },
    reset(state) {
      state.file = {
        name: undefined,
        type: undefined,
        location: undefined,
        lastModified: undefined,
        size: undefined,
      };
    },
  },
});

export const fileActions = fileSlice.actions;

export default fileSlice;
