import axios from "axios";
import config from "../config";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";

export async function uploadFile(accessToken: string, input: any | any[], url: string, fileType: string,userId:string,callback?:(name:string,percentCompleted:number,filesize:number,fileName:string)=> Promise<void> ): Promise<void> {
  const response = await axios.put(url, input, {
    headers: {
      "Content-Type": `${fileType}`,
      "access-control-allow-origin": "*",
    },
    onUploadProgress:function(progressEvent){
      const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      console.log({ input })
      if(callback){
        callback(`Upload-${input.name}`,percentCompleted,input.size,input.name)
      }

    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });

  window.analytics.track('uploadFile - Success',{
    file: {
      name:input.name,
      size:input.size,
      type:fileType
    },
    userId:userId,
    date:new Date().toLocaleString()
  })
  return response.data;
}
