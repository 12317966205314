import React, { useEffect, useState, useRef, useCallback } from "react";
import PreviewItem from "../Components/PreviewItem";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { Iuser } from "../interface/get-user.interface";
import { getProducts } from "../api/billing/product/getProducts";
import { getTransactionRecords } from "../api/billing/transaction/getTransactionRecords";
import { Outlet } from "react-router-dom";

const Billing = () => {
  const location = useLocation();
  const user: Iuser = useSelector(authActions.login).payload.auth.user;
  const accessToken = useSelector(authActions.login).payload.auth.accessToken;

  const getProduct = async () => {
    try {
      await getProducts(accessToken);
    } catch (error) {
      console.error(error);
    }
  };
  // const getTransaction = async () => {
  //   try {
  //     await getTransactionRecords(accessToken);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  //get wallet balance and pass to balance

  //get Product and transaction
  useEffect(() => {
    getProduct();
    //
  }, []);
  return (
    <div className="content-con flex-1 gap-12">
      <div className="relative mx-auto flex w-full flex-1 flex-col xl:w-auto xl:min-w-[900px]">
        {/* <div className="upload-con box-shadow"></div> */}
        {/* <div className="flex items-center justify-between">
          <h1 className="title-white text-[20px] font-medium md:text-2xl">
            Billing
          </h1>
        </div> */}
        <p className="title-white mb-4 text-xl font-semibold md:mb-6 md:text-[26px]">Billing</p>
        <div className="billing-option-con main-box-shadow flex flex-wrap gap-5 rounded-2xl bg-[#ffffff] p-4 dark:bg-[#1b1d1e]">
          <Link
            to="/billing"
            className={`bg-color btn-billing bg-stamp w-full rounded-md border p-3 px-10 text-center text-xs md:w-auto 2xl:text-[13px] ${
              location.pathname === "/billing" && "active"
            }`}
          >
            Overview
          </Link>
          <Link
            to="/billing/stamp"
            className={`bg-color btn-billing bg-stamp w-full rounded-md border p-3 px-10 text-center text-xs md:w-auto 2xl:text-[13px] ${
              location.pathname === "/billing/stamp" && "active"
            }`}
          >
            Stamp (BZZ)
          </Link>
          <Link
            to="/billing/month"
            className={`bg-color btn-billing bg-stamp w-full rounded-md border p-3 px-10 text-center text-xs md:w-auto 2xl:text-[13px] ${
              location.pathname === "/billing/month" && "active"
            }`}
          >
            Month Billing
          </Link>
        </div>
        <Outlet />
      </div>
      {/* <RightInfo /> */}
    </div>
  );
};

export default Billing;
