import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: false,
    accessToken: "",
    user: {},
    billingUser: {},
    productsList: [],
    lockdown: null,
  },
  reducers: {
    login(state, action) {
      state.auth = true;
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.billingUser = action.payload.billingUser;
      state.productsList = action.payload.productsList;
      state.lockdown = action.payload.lockdown;
    },
    logout(state) {
      state.auth = false;
      state.accessToken = "";
      state.user = {};
      state.billingUser = {};
      state.productsList = [];
      state.lockdown = null;
    },
    updateLockDown(state, action) {
      state.lockdown = action.payload.lockdown;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
