import { createSlice } from "@reduxjs/toolkit";
import { IStamp } from "../interface/create-pod.interface";

export interface PodList {
  arr_pod: [] | undefined;
}

const initialState: PodList = {
  arr_pod: [],
};

const podListSlice = createSlice({
  name: "podInfo",
  initialState,
  reducers: {
    podList(state: PodList, action) {
      state.arr_pod = action.payload;
    },
  },
});

export const podListActions = podListSlice.actions;
export default podListSlice;
