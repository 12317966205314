import axios from "axios";
import config from "../../config";
import { useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";

export async function creatStamp(accessToken: string, name: string, amount: number, depth: number, productId: string, productAmount: number) {
  const url = new URL("/storage/bzz/stamp", config.api.endpoint);
  const response = await axios.post(
    url.href,
    {
      amount,
      depth,
      productId,
      productAmount,
      name,
    },
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return response;
}
