/* eslint-disable @typescript-eslint/ban-types */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { authActions } from "../../../store/auth-slice";

interface StorageManagerConfig {
  id?: string;
  autosave?: boolean;
  autoload?: boolean;
  type?: string;
  stepsBeforeSave?: number;
  recovery?: boolean | Function;
  onStore?: (data: any, editor: any) => any;
  onLoad?: (data: any, editor: any) => any;
  options?: {
    local?: any;
    remote?: any;
    [key: string]: any;
  };
}
/* Set the storage value for user. e.g web builder auto save when they are create the website */
// const userId = useSelector(authActions.login).payload.auth.user.sub;
const storage = (userId: string): StorageManagerConfig => {
  return {
    id: "gjs-",
    type: "local",
    autosave: true,
    stepsBeforeSave: 1,
    options: {
      // key is equal to gjs- prefix
      local: { key: "gjs-" + userId },
    },
  };
};

export default storage;
