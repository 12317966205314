import axios from "axios";
import config from "../config";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";

export async function deletePod(accessToken: string, userid: string, podName: string): Promise<void> {
  const url = new URL(`/v1/http-api/pods/${podName}`, config.api.endpoint);

  const response = await axios.delete(url.href, {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${accessToken}`,
      "user-id": userid,
    },
  });
  return response.data;
}
