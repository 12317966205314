import "./App.scss";
import "./index.scss";
import { Icon } from "@iconify/react";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Image from "./Static/Ellipse.png";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import LeftNav from "./Components/LeftNav";
import TopNav from "./Components/TopNav";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/auth-slice";
import { useKeycloak } from "@react-keycloak/web";
import space from "./Static/space.jpg";
import logo from "./Static/logo.webp";
import useWindowDimensions from "./Hook/useWindowDimensions";
import BottomNav from "./Components/BottomNav";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const { width, height } = useWindowDimensions();
  // const [theme, setTheme] = useState("light");
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const { keycloak, initialized } = useKeycloak();
  // const [optionShow, setOptionShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [searchWord, setSearchWord] = useState("");


  useEffect(()=>{
    if(location.pathname === "/reviewCampaign"){
       window.analytics.track("ReviewCampaign - Access", {
        date: new Date().toLocaleString(),
      });
    }

  },[])

  useEffect(() => {
    if (theme === null) {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    }
  });

  useEffect(() => {
    theme === "dark" ? (document.body.style.background = "#111315") : (document.body.style.background = "#ffffff");
  });

  useEffect(() => {
    initialized && keycloak.authenticated && location.pathname === "/" && navigate("/pod");
  }, [location, initialized]);

  return initialized ? (
    <>
      <Helmet>{theme === "dark" ? <meta name="theme-color" content="#111315"></meta> : <meta name="theme-color" content="#ffffff"></meta>}</Helmet>
      <div data-theme={theme} className={`app ${theme === "dark" && "dark"}`}>
        <TopNav show={show} setShow={setShow} setTheme={setTheme} theme={theme} search={setSearchWord} />
        <div className="right-con overflow-x-auto bg-white dark:bg-[#111315]">
          <LeftNav show={show} setShow={setShow} />
          <BottomNav />
          {/* <div className="absolute top-0 left-0 z-[-1] h-full w-full bg-white dark:bg-[#111315] md:h-[100vh]"></div> */}
          {keycloak.authenticated ? (
            <Outlet context={[searchWord, setSearchWord]} />
          ) : (
            <>
              <div className="webkit-fill-h fixed left-0 top-0 z-[999] grid h-screen w-screen place-items-center backdrop-blur">

                <div className="flex w-[85vw] max-w-[330px] flex-col overflow-hidden rounded-3xl bg-white shadow-lg dark:bg-[#111315] md:w-[90vw] md:max-w-[650px] md:flex-row">
                  <div className="title-white relative order-2 flex flex-1 flex-col items-start justify-between gap-6 p-8 px-6 md:order-1 md:min-w-[50%] md:gap-12 md:px-12">
                    <div className="flex w-full flex-1 flex-col justify-between gap-3 font-bold md:mt-3 md:gap-4">
                      <p className="flex items-center gap-2 text-[1.35rem] font-normal md:text-2xl">
                        <img src={logo} className="logo-img h-[21px] max-w-[35px] shrink-0 md:max-w-[40px]" />
                        DeDrive
                      </p>
                      <div className="relative flex flex-row gap-1 max-md:h-[40px] md:flex-col md:gap-4">
                        <p className="max-md:landing-text-animation text-4xl max-md:absolute max-md:left-0 max-md:top-0">Efficient</p>
                        <p className="max-md:landing-text-animation text-4xl max-md:absolute max-md:left-0 max-md:top-0 max-md:opacity-0" style={{ animationDelay: 8 / 3 + "s" }}>
                          Secure
                        </p>
                        <p
                          className="max-md:landing-text-animation text-4xl max-md:absolute max-md:left-0 max-md:top-0 max-md:opacity-0"
                          style={{ animationDelay: (8 / 3) * 2 + "s" }}
                        >
                          Flexible
                        </p>
                      </div>

                    </div>
                    <div className="flex w-full flex-col justify-center md:mb-2">
                      <button
                        onClick={() => {
                          keycloak.login();
                        }}
                        className="h-[48px] w-full rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 text-base font-semibold text-white"
                      >
                        Login
                      </button>
                      {/* <button
                        onClick={() => {
                          keycloak.register();
                        }}
                        className="mx-auto mt-4 w-fit text-sm hover:underline"
                      >
                        Register
                      </button> */}
                    </div>
                  </div>
                  <img src={space} alt="" className="order-1 h-[40vh] max-h-[500px] object-cover md:order-2 md:h-[400px] md:max-h-[80vh] md:flex-1" />
                </div>
              </div>
              <div className="content-con mt-0 flex h-screen min-w-full gap-8 overflow-auto md:min-w-0"></div>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

export default App;
