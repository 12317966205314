import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import RightInfo from "../Components/RightInfo";
import { useDropzone } from "react-dropzone";
import useWindowDimensions from "../Hook/useWindowDimensions";
import File from "../Components/File";
import { useSelector } from "react-redux";
import { creatItem } from "../api/create-Item";
import { authActions } from "../store/auth-slice";
import { ICreateItem } from "../interface/create-item-interface";
import { getItems } from "../api/get-item";
import { Iuser } from "../interface/get-user.interface";
import { IPresignedUrl, presignUrl } from "../api/presigned-url";
import { uploadFile } from "../api/upload-file";
import { IPresignedUrlMulti, presignUrlMulti } from "../api/presigned-url-multi";
import { IItem } from "../interface/get-item.interface";
import { downloadFiles, getDownloadFilesLink, getPreviewFilesLinkImage, getPreviewFilesLinkVideo } from "../api/download-file";
import { isImage, isVideo, isText, isExcel } from "../Hook/isType";
import toast, { Toaster } from "react-hot-toast";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { deleteItem } from "../api/item/deleteItem";
import _, { isBoolean } from "lodash";
import config from "../config";
import { getPod, getPodByPodName, getPodCount } from "../api/get-pod";
import { IPod } from "../interface/get-pod-interface";
import { downloadFilesPrivate } from "../api/download-file-private";
import { getStamp, IGetStamp } from "../api/stamp/get-stamp";
import { getStampUsage } from "../api/stamp/get-stamp-usage";
import UploadFiles from "../Components/UploadFiles";
import { useDispatch } from "react-redux";
import { fileActions } from "../store/file-slice";
import EDNSICON from "../Static/ednsicon_white.svg";
import getWeb3WalletAddress from "../util/web3";
import { isDomainOwner } from "@edns/sdk";
import { podListActions } from "../store/pod-list-slice";
import { podActions } from "../store/pod-info-slice";
import { getFqdnByPodName } from "../api/resolver/getFqdnByPodName";

interface Props {
  type: string;
}

interface BoxProps {
  usage: any;
  day: number;
  storage: number;
  name: string | undefined;
}

interface IUploadingItem {
  fileName: string;
  uploadedSize: number;
  totalSize: number;
  uploadSpeed: number;
}

interface hashMapUploadingItem {
  [key: string]: IUploadingItem;
}

const Box = (props: BoxProps) => {
  return (
    <div className="bg-main border-gray-sec fade-in main-box-shadow min-w-[235px] shrink-0 rounded-xl border p-4">
      <div className="flex justify-between">
        <div
          className={`grid place-items-center rounded-md p-2 ${
            props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red billing-icon-red" : "billing-icon-color billing-usage-bg-blue"
          }`}
        >
          <Icon icon="fluent:storage-20-regular" className=" h-10 w-auto" />
        </div>
        <div
          className={`h-fit rounded-lg p-3 py-2 text-xs ${
            props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red billing-icon-red" : "billing-icon-color billing-usage-bg-blue"
          }`}
        >
          {props.day} Day Left
        </div>
      </div>
      <div className="title-white mt-3 truncate text-xl font-semibold">{props.name}</div>
      <div className={`relative mt-2 h-[7px] w-full rounded-full ${props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-bg-red" : "billing-usage-bg-blue"}`}>
        <div
          className={`${props.usage / props.storage > 0.85 || props.day <= 30 ? "billing-usage-red" : "billing-usage-blue"} absolute top-0 left-0 h-[7px] rounded-full`}
          style={{ width: (props.usage / props.storage) * 100 + "%" }}
        ></div>
      </div>
      <div className="mt-3 flex select-none justify-between text-sm font-medium text-[#aaabaf]">
        <p>{props.usage} GB</p>
        <p>{props.storage} GB</p>
      </div>
    </div>
  );
};
const BucketInfo = (props: Props) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [files, setFiles] = useState<any>([]);
  const [overflowBottom, setOverflowBottom] = useState(false);
  const [upload, setUpload] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [bottomHandler, setBottomHandler] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [preheight, setPreheight] = useState<number>(0);
  // const pod: IPod = useSelector(podActions.currentPod).payload.pod;
  const [pod, setPod] = useState<IPod | undefined>(undefined);
  const user: Iuser = useSelector(authActions.login).payload.auth.user;
  const accessToken = useSelector(authActions.login).payload.auth.accessToken;
  const [item, setItem] = useState<IItem[]>();
  const [breadCumb, setBreadCumb] = useState<any[]>([]);
  const [currLocation, setCurrLocation] = useState("");
  const [reloadHome, setReloadHome] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<boolean>(false);
  // const [download, setDownload] = useState(false);
  const lockdown: boolean = useSelector(authActions.login).payload.auth.lockdown;
  const [uploadingFile, setUploading] = useState(false);
  const [deleteingFile, setDeleteing] = useState(false);
  const { podName } = useParams();
  const [selectedFile, setSelectedFile] = useState<IItem | null>(null);
  const [orderType, setOrderType] = useState<string>("name");
  const [podNotFound, setPodNotFound] = useState<boolean | null>(null);
  const [checkedFileList, setCheckedFileList] = useState<IItem[]>([]);
  const [optionShow, setOptionShow] = useState(false);
  const [optionShowStatic, setOptionShowStatic] = useState(false);
  const [createIngFolder, setcreateingFolder] = useState(false);
  const folderNameRef = useRef<HTMLInputElement>(null);
  const confirmDeleteRef = useRef<HTMLInputElement>(null);
  const [currentStamp, setCurrentStamp] = useState<{ name: string; usage: string; total: string; expiredAt: number } | null>(null);
  const [showUploadingCon, setShowUploadingCon] = useState(true);
  const [uploadingFileObject, setUploadingFileObject] = useState<hashMapUploadingItem>({});
  const [handleDisplayMode, setHandleDisplayMode] = useState<boolean>(true);
  const navigate = useNavigate();
  const [bindDomainAction, setBindDomainAction] = useState<boolean>(false);
  const [domainName, setDomainName] = useState("");
  const [checkDomainValid, setCheckDomainValid] = useState<{ domain: string; address: string; result: boolean } | undefined>(undefined);
  const [disableStaticWeb, setDisableStaticWeb] = useState(true);
  const [ednsDomain,setEDNSDomain] = useState<null|undefined|string>(undefined)
  // const [sleepForUploader, setSleepForUploader] = useState(false)
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  useEffect(() => {
    dispatch(fileActions.reset());
  }, []);
  // useEffect(()=>{
  //
  //   const change100Pencent = async ()=>{
  //     let updated = false
  //     const obj = {...uploadingFileObject}
  //     for (const [key, value] of Object.entries(uploadingFileObject)) {
  //       if(value.uploadedSize === value.totalSize){
  //         updated = true
  //         delete obj[key]
  //       }
  //     }
  //
  //     if(updated){
  //       await new Promise(f => setTimeout(f, 5000));
  //       setUploadingFileObject(obj)
  //     }
  //   }
  //   change100Pencent()
  // },[uploadingFileObject])
  useEffect(() => {
    const initPopup = async () => {
      await new Promise((x) => setTimeout(x, 500));
      if (upload === false) {
        setDomainName("");
        setUploading(false);
        setDeleteing(false);
        setcreateingFolder(false);
        setBindDomainAction(false);
      }
    };
    initPopup();
  }, [upload]);

  useEffect(() => {
    const get_Pod = async () => {
      if (accessToken && user.sub) {
        const podCount = await getPodCount(accessToken, user.sub);
        const response = await getPod(accessToken, user.sub, `0`, podCount);
        // await getPodByPodName(accessToken,user.sub,podName!!)
        const targetPodList: IPod[] = response.data.filter((x) => x.name === podName);

        if (targetPodList.length == 1) {
          const targetPod = targetPodList[0];
          setPod(targetPod);
          const fqdn = await getFqdnByPodName(targetPod.name)
          setEDNSDomain(fqdn)
          dispatch(podActions.currentPod(targetPod));
          setPodNotFound(false);
        } else {
          navigate("/404");
          setPodNotFound(true);
        }
      }
    };
    get_Pod();
    checkstatic();
  }, [accessToken, user, podName, item]);

  useEffect(() => {
    if (lockdown) {
      window.location.pathname = "";
    }
  }, [lockdown]);

  useEffect(() => {
    if (selectedFile !== null) setSelectedFile(null);
  }, [breadCumb]);

  useEffect(() => {
    const update = () => {
      const preview = document.querySelector(".preview-con") as HTMLDivElement;
      preview?.scrollHeight > preview?.clientHeight ? setOverflowBottom(true) : setOverflowBottom(false);
    };
    window.addEventListener("resize", update);
    update();
    return () => {
      window.removeEventListener("resize", update);
    };
  });

  useEffect(() => {
    const loadingCon = document.querySelector(".props-loading-con") as HTMLDivElement;
    if (loading === false) {
      // setTimeout(() => {
      //   // loadingCon?.style.display= "none";
      //   // Object.assign(loadingCon.style,{display:'none'})
      // }, 2000);
    }
  }, [loading]);

  const thumbs = files.map((file: any, index: number) => (
    <div key={index} className="thumb border-gray mt-4 flex max-w-[100%] items-center justify-between gap-5 overflow-y-hidden rounded-xl border-2 p-3">
      <div className="flex max-w-[80%] shrink-0 items-center gap-5">
        {props.type !== "Folder" && isText(file.name?.split(".")[file.name?.split(".").length - 1]) && (
          <div className="border-gray grid aspect-square h-[48px] w-[48px] shrink-0 place-items-center rounded-md border-2 p-2">
            <Icon icon="ant-design:file-text-filled" className="h-7 w-auto shrink-0 text-[#588EDF]"></Icon>
          </div>
        )}
        {props.type === "Folder" && (
          <div className="border-gray grid aspect-square h-[48px] w-[48px] shrink-0 place-items-center rounded-md border-2 p-2">
            <Icon icon="bxs:folder" className="h-7 w-auto shrink-0 text-[#EFA72A]"></Icon>
          </div>
        )}
        {props.type !== "Folder" && isVideo(file.name?.split(".")[file.name?.split(".").length - 1]) && (
          <div className="border-gray grid aspect-square h-[48px] w-[48px] shrink-0 place-items-center rounded-md border-2 p-2">
            <Icon icon="ic:baseline-video-file" className="h-7 w-auto shrink-0 text-[#D4594A]"></Icon>
          </div>
        )}
        {props.type !== "Folder" && isImage(file.name?.split(".")[file.name?.split(".").length - 1]) && (
          <div className="border-gray grid aspect-square h-[48px] w-[48px] shrink-0 place-items-center rounded-md border-2 p-2">
            <Icon icon="ic:baseline-insert-photo" className="h-7 w-auto shrink-0 text-[#D4594A]"></Icon>
          </div>
        )}
        {props.type !== "Folder" && isExcel(file.name?.split(".")[file.name?.split(".").length - 1]) && (
          <div className="border-gray grid aspect-square h-[48px] w-[48px] shrink-0 place-items-center rounded-md border-2 p-2">
            <Icon icon="vscode-icons:file-type-excel" className="h-7 w-auto shrink-0 text-[#33c481]"></Icon>
          </div>
        )}
        <div className="flex-1 overflow-hidden">
          <h3 className="title-white truncate font-medium">{file.name}</h3>
          <h3 className="title-white shrink-0 text-xs 2xl:text-sm">{(file.size / 1024).toString().slice(0, 4) + " KB"}</h3>
        </div>
      </div>
      <Icon
        onClick={() => {
          const thumb_con = document.querySelectorAll(".thumb") as any;
          const thumb = thumb_con[index];
          thumb.style.opacity = "0";
          thumb.style.maxHeight = "0px";
          thumb.style.paddingTop = "0px";
          thumb.style.paddingBottom = "0px";
          thumb.style.border = "none";
          thumb.style.marginTop = "0px";
          console.log(files.length);
          files.length === 1 && setUpload(false);
          setTimeout(() => {
            files.splice(index, 1);
          }, 250);
        }}
        icon="charm:cross"
        className="title-white h-6 w-auto shrink-0 cursor-pointer"
      />
    </div>
  ));

  const isCheckedFileListCanDownload = (): boolean => {
    let result = true;
    if (checkedFileList.length === 0 || !isCheckedFilePendingAndFail()) {
      result = false;
    }

    return result;
  };

  const isCheckedFileListCanDelete = (): boolean => {
    let result = true;
    if (checkedFileList.length === 0 || isCheckedFileIncludsFolder() || !isCheckedFilePending()) {
      result = false;
    }

    return result;
  };
  const isCheckedFileIncludsFolder = (): boolean => {
    let result = true;
    checkedFileList.forEach((x) => {
      if (x.type === "Folder") {
        result = false;
      }
    });

    return result;
  };
  const isCheckedFilePending = (): boolean => {
    let result = true;
    checkedFileList.forEach((x) => {
      if (x.status === "PENDING") {
        result = false;
      }
    });

    return result;
  };
  const isCheckedFilePendingAndFail = (): boolean => {
    let result = true;
    checkedFileList.forEach((x) => {
      if (x.status === "PENDING" || x.status === "FAILED") {
        result = false;
      }
    });

    return result;
  };
  const updateCheckFileList = (uid: string, action: boolean) => {
    if (item) {
      const targetItem = item.find((x) => {
        return x.uid === uid;
      });
      if (targetItem) {
        if (action) {
          setCheckedFileList([...checkedFileList, targetItem]);
        } else {
          setCheckedFileList(checkedFileList.filter((obj) => targetItem !== obj));
        }
      }
    }
  };
  const selectFile = (uid: string | null) => {
    if (item && uid !== null) {
      const targetItem = item.find((x) => {
        return x.uid === uid;
      });
      if (targetItem) {
        setCheckedFileList([targetItem]);

        document.querySelectorAll("#fileCheck").forEach((e) => {
          const input = e as HTMLInputElement;
          input.checked = false;
        });

        const itemName = `[name=\'checkBox-${uid}\']`; // eslint-disable-line
        const checkbox = document.querySelector(itemName);
        if (checkbox) (checkbox as HTMLInputElement).checked = true;

        document.querySelectorAll("#checkAll").forEach((e) => {
          const input = e as HTMLInputElement;
          input.checked = false;
        });
      }
    } else {
      setSelectedFile(null);
      return;
    }
  };
  const unCheckFile = (uid: string) => {
    if (item) {
      const targetItem = item.find((x) => {
        return x.uid === uid;
      });
      const itemList = item.filter((x) => x !== targetItem);
      setCheckedFileList(itemList);
    }
  };
  const cancelUpload = () => {
    setTimeout(() => {
      setFiles([]);
    }, 500);
    setUpload(false);
  };

  useEffect(() => {
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      setUpload(true);
    }
  }, [files]);

  useEffect(() => {
    window.analytics.page("BucketInfo Page");
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  });

  const handleClickOutside = (event: any) => {
    if (!(event.target as HTMLElement).closest(".btn-add-inner") && !(event.target as HTMLElement).closest(".btn-upload") && uploadShow) {
      setUploadShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScrollBottom, false);
    return () => {
      document.removeEventListener("scroll", handleScrollBottom, false);
    };
  });

  const handleScrollBottom = (event: any) => {
    if (document.body.scrollHeight - document.documentElement.scrollTop < document.documentElement.clientHeight + 20) {
      setBottomHandler(true);
    } else {
      setBottomHandler(false);
    }
    console.log(preheight, document.body.scrollHeight - document.documentElement.clientHeight - 10);
    if (document.documentElement.scrollTop < preheight) {
      if (preheight < document.body.scrollHeight - document.documentElement.clientHeight - 10) {
        setHidden(false);
      }
    } else {
      if (preheight > 10) {
        setHidden(true);
        setUploadShow(false);
      }
    }
    setPreheight(document.documentElement.scrollTop);
  };

  const getPodData = async (): Promise<IItem[]> => {
    let resposnse;
    let items: IItem[] = [];
    if (user && pod) {
      if (currLocation === "") {
        resposnse = await getItems(accessToken, user.sub, pod.name);
      } else {
        resposnse = await getItems(accessToken, user.sub, `${currLocation.replaceAll("/", "%2F")}`);
      }
      items = resposnse.data;
    }
    checkstatic();
    return items;
  };

  // useEffect(() => {
  //   const handleGetPodItem = async () => {

  //   };
  //   handleGetPodItem();
  //   console.log("pod", pod);
  //   console.log("user", user);
  // }, [user, pod, reloadHome]);

  useEffect(() => {
    const handleCheckStamp = async () => {
      if (pod) {
        if (pod.bzzStamp && currentStamp === null) {
          const stamps = await getStamp(accessToken);
          if (pod.bzzStamp?.stamps) {
            const filtedStamp = stamps.filter((x) => x.batchId === pod.bzzStamp?.stamps[0].batchId);
            if (filtedStamp.length > 0) {
              const usage = await getStampUsage(accessToken, filtedStamp[0].batchId);
              console.log({ usage });
              setCurrentStamp({
                name: filtedStamp[0].name || `BZZ Stamp`,
                usage: usage.capacity.used,
                total: usage.capacity.total,
                expiredAt: usage.expiredAt,
              });
            }
          } else if (pod.bzzStamp?.Stamps) {
            const filtedStamp = stamps.filter((x) => x.batchId === pod.bzzStamp?.Stamps[0].BatchId);
            if (filtedStamp.length > 0) {
              const usage = await getStampUsage(accessToken, filtedStamp[0].batchId);
              console.log({ usage });
              setCurrentStamp({
                name: filtedStamp[0].name || `BZZ Stamp`,
                usage: usage.capacity.used,
                total: usage.capacity.total,
                expiredAt: usage.expiredAt,
              });
            }
          }
        }
      }
    };
    handleCheckStamp();
  }, [pod]);

  useEffect(() => {
    const handleGetPodItem = async () => {
      // const resposnse = { data: item };
      if (user && pod && loading) {
        let resposnse;

        if (currLocation === "") {
          resposnse = await getItems(accessToken, user.sub, pod.name);
        } else {
          console.log("hi", currLocation);

          resposnse = await getItems(accessToken, user.sub, `${currLocation.replaceAll("/", "%2F")}`);
        }
        setItem(resposnse.data);
        checkstatic();
        setLoading(false);
      }
    };
    handleGetPodItem();
  }, [loading, pod]);

  const uploadingStatusCallback = async (keyname: string, percentCompleted: number, filesize: number, fileName: string) => {
    const uploadedSizeHere = Math.round(filesize * (percentCompleted / 100));
    const obj: hashMapUploadingItem = {
      ...uploadingFileObject,
      [keyname]: {
        fileName: fileName,
        uploadSpeed: 100,
        totalSize: filesize,
        uploadedSize: uploadedSizeHere,
      },
    };
    if (uploadedSizeHere === filesize) {
      uploadingFileObject[keyname] = obj[keyname];
    }
    setUploadingFileObject(obj);

    // }
  };

  const handlePresignedURL = async () => {
    if (!pod) {
      return;
    }
    console.log(files);

    console.log(files[0].type === "" ? "File" : files[0].type);

    try {
      if (files.length > 0 && files.length === 1) {
        const input: IPresignedUrl = {
          key: `${currLocation === "" ? pod?.name : currLocation}${files[0].path.split("")[0] === "/" ? files[0].path : "/".concat(files[0].path)}`,

          //   `${currLocation === "" ? pod.pod.Name : currLocation}/${
          //   files[0].path
          // }`

          size: files[0].size,
          type: files[0].type === "" ? "File" : files[0].type,
        };
        setUploading(true);
        const response = await presignUrl(accessToken, input, user.sub);
        // if(uploadingFileObject === null){
        //   const keyName = `upload-${files[0].name}`
        //   const object:hashMapUploadingItem = {}
        //   object[keyName] = {
        //     fileName:files[0].name,
        //     uploadSpeed:47,
        //     totalSize:100,
        //     uploadedSize:0,
        //   }
        //   setUploadingFileObject(object)
        // }

        await handleUpload(response.data.url, 0);

        await handleItemCreate(files[0].path, files[0].name, files[0].type === "" ? "File" : files[0].type, files[0].size);
        setUploading(false);
        // eslint-disable-next-line no-constant-condition
        // while (true) {
        //   await new Promise((resolve) => setTimeout(resolve, config.uploadItemListenerSleepTime));
        //   const items = await getPodData();
        //
        //   if (items === null) {
        //     alert("Create Item Fail");
        //     break;
        //   }
        //
        //   const targetItem = items.filter((item) => {
        //     return item.name === files[0].name;`
        //   });
        //
        //   if (targetItem.length > 0) {
        //     if (targetItem[0].status != "PENDING") break;
        //   } else {
        //     alert("Create Item Fail");
        //     break;
        //   }
        // }
      } else if (files.length > 0 && files.length > 1) {
        const input: IPresignedUrlMulti[] = [];

        files.forEach((element: any, i: any) => {
          input.push({
            id: `${i}`,
            key: `${currLocation === "" ? pod?.name : currLocation}${element.path.split("")[0] === "/" ? element.path : "/".concat(element.path)}`,
            size: element.size,
            type: element.type === "" ? "File" : element.type,
          });
        });
        setUploading(true);
        const response = await presignUrlMulti(accessToken, input, user.sub);
        for (const c of Object.keys(response.data.urls)) {
          const current = response.data.urls[parseInt(c)];
          // await new Promise(r => setTimeout(r, 1000));
          const res = await handleUpload(current, parseInt(c));
          if (res !== null) {
            await handleItemCreateMuti(
              files[parseInt(c)].path,
              files[parseInt(c)].name,
              files[parseInt(c)].type === "" ? "File" : files[parseInt(c)].type,
              files[parseInt(c)].size,
            );
          }
        }
        // alert("pending")
        setUploading(false);
        // eslint-disable-next-line no-constant-condition
        // while (true) {
        //   await new Promise((resolve) => setTimeout(resolve, config.uploadItemListenerSleepTime));
        //   const items = await getPodData();
        //   if (items === null) {
        //     alert("Create Item Fail");
        //     break;
        //   }
        //   let status = true;
        //
        //   for (const c of Object.keys(response.data.urls)) {
        //     const targetItem = items.filter((item) => {
        //       return item.name === files[parseInt(c)].name;
        //     });
        //
        //     if (
        //       targetItem.length > 0
        //       // items.filter((item) => {
        //       //   return item.Name === files[parseInt(c)].name && item.Status === "PENDING";
        //       // }).length > 0
        //     ) {
        //       if (targetItem[0].status === "PENDING") status = false;
        //     } else {
        //       alert(`Create Item Fail ${files[parseInt(c)].name}`);
        //     }
        //   }
        //   if (status) {
        //     break;
        //   }
        // }
      }
      setReloadHome(!reloadHome);
    } catch (e) {
      setUploading(false);
      setUpload(false);
      setLoading(false);
      toast.error((e as Error).message, {
        style: {
          background: "var(--bg-sec)",
          color: "var(--title-white)",
        },
      });
    }
  };

  const handleUpload = async (url: string, index: number) => {
    const reader = new FileReader();
    let filestream;
    let fixarray;
    const fileByteArray: any[] = [];
    // const file = files[index];

    // reader.readAsArrayBuffer(files[index]);
    // reader.onload = function () {
    //   filestream = reader.result;
    //   fixarray = new Uint8Array(filestream as ArrayBufferLike);
    //   for (let i = 0; i < fixarray.length; i++) {
    //     fileByteArray.push(fixarray[i]);
    //   }
    // };

    const response = await uploadFile(accessToken, files[index], url, files[index].type, user.sub, uploadingStatusCallback);
    return response;
  };

  const handleItemCreate = async (prefix: string, name: string, type: "File" | "Folder", size: number) => {
    let _prefix = `${pod?.name}`;
    const _prefixLas = prefix.split(`/${name}`);
    if (_prefixLas.length !== 1) {
      _prefix = _prefix.concat(_prefixLas[0]);
    }
    const input: ICreateItem = {
      podName: pod!.name!,
      prefix: `${currLocation !== "" ? `${currLocation}` : `${_prefix}`}`,
      name: name,
      type: "File",
      size: size,
      eTag: " ",
    };

    await creatItem(accessToken, input, user.sub);
    setItem(await getPodData());
    // setReloadHome(!reloadHome);
  };

  const handleItemCreateMuti = async (
    prefix: string, // path
    name: string, // file name
    type: "File" | "Folder",
    size: number,
  ) => {
    let _prefix = `${pod?.name}`;
    const _prefixLas = prefix.split(`/${name}`);
    if (_prefixLas.length !== 1) {
      _prefix = _prefix.concat(_prefixLas[0]);
    }
    const input: ICreateItem = {
      podName: pod!.name!,
      prefix: `${currLocation !== "" ? `${currLocation}` : _prefix}`,
      name: name,
      type: "File",
      size: size,
      eTag: " ",
    };

    const res = await creatItem(accessToken, input, user.sub);
    if (res.status === 400) {
      toast.error(`${res.messages.toString()}`, {
        style: {
          background: "var(--bg-sec)",
          color: "var(--title-white)",
        },
      });
      setReloadHome(!reloadHome);
      throw new Error(res.messages.toString());
    }
    setItem(await getPodData());
    // await new Promise(f => setTimeout(f, 10000));
  };

  const handleClickFolder = async (folder: string, location: string) => {
    setLoading(true);
    const response = await getItems(accessToken, user.sub, `${encodeURIComponent(location)}%2F${folder}`);
    setItem(response.data);
    setLoading(false);
  };

  //TODO Bad Logic !!
  const _setBreadCumb = (folder: string, location: string) => {
    setBreadCumb([...breadCumb, { folder: folder, location: location }]);
    return breadCumb;
  };

  function removeBreadCumb(folder: string, location: string) {
    // _setBreadCumb(folder, location);
    handleClickFolder(folder, location);
  }

  const renderUploadingTask = (): JSX.Element[] => {
    const taskItem: JSX.Element[] = [];
    for (const [key, value] of Object.entries(uploadingFileObject!)) {
      taskItem.push(
        <UploadFiles
          key={key}
          fileName={value.fileName}
          uploadedSize={value.uploadedSize}
          totalSize={value.totalSize}
          uploadSpeed={value.uploadSpeed}
          keyname={key}
          removeBtn={removeLoadingFileFromObject}
        />,
      );
    }
    return taskItem;
  };

  const renderFile = (order: boolean, type: string) => {
    const renderlist: any[] = [];
    let arr: IItem[] | undefined = [];
    if (item) {
      switch (type) {
        case "name":
          arr = order ? _.orderBy(item, ["name"], ["desc"]) : _.orderBy(item, ["name"], ["asc"]);
          break;
        case "type":
          arr = order ? _.orderBy(item, ["type"], ["desc"]) : _.orderBy(item, ["type"], ["asc"]);
          break;
        case "lastModified":
          arr = order ? _.orderBy(item, ["updatedAt"], ["desc"]) : _.orderBy(item, ["updatedAt"], ["asc"]);
          break;
        case "size":
          arr = order ? _.orderBy(item, ["size"], ["desc"]) : _.orderBy(item, ["size"], ["asc"]);
          break;
        case "status":
          arr = order ? _.orderBy(item, ["status"], ["desc"]) : _.orderBy(item, ["status"], ["asc"]);
          break;
      }

      arr.forEach((element: IItem, index: number) => {
        renderlist.push(
          <File
            key={element.uid}
            Uid={element.uid}
            name={element.name}
            // type={element.mimeType !== "" ? element.mimeType.split("/")[1].toUpperCase().split(";")[0] : "File"}
            type={element.type !== "Folder" ? element.name.split(".")[element.name.split(".").length - 1].toUpperCase() : element.type}
            lastModified={`${element.updatedAt}`}
            size={`${element.size}`}
            status={element.status}
            location={element.prefix}
            handleClickFolder={handleClickFolder}
            setLocation={setCurrLocation}
            setBreadCumb={_setBreadCumb}
            accessToken={accessToken}
            unCheckAction={unCheckFile}
            index={index}
            selectFile={selectFile}
            clicked={checkedFileList.find((file: IItem) => file.uid === element.uid) ? true : false}
            length={arr!.length}
            updateToList={updateCheckFileList}
            handleDisplayMode={handleDisplayMode}
          />,
        );
      });
    }
    return renderlist;
  };

  const checkstatic = () => {
    item?.forEach((element: IItem) => {
      if (element.name == "index.html") {
        setOptionShowStatic(true);
      }
    });
  };

  useEffect(() => {
    console.log(checkedFileList);
  }, [checkedFileList]);

  const createFolder = async (): Promise<void> => {
    setUpload(false);
    // setUploading(true);
    const _prefix = `${pod?.name}`;

    setcreateingFolder(false);
    const input: ICreateItem = {
      podName: pod!.name!,
      prefix: `${currLocation !== "" ? `${currLocation}` : `${_prefix}`}`,
      name: folderNameRef.current!.value,
      type: "Folder",
      size: 0,
      eTag: " ",
    };
    await creatItem(accessToken, input, user.sub);

    setReloadHome(!reloadHome);
  };
  const handleCheckAll = (e: any) => {
    const checkAll = e;
    document.querySelectorAll("#fileCheck").forEach((e) => {
      const input = e as HTMLInputElement;
      input.checked = checkAll.target.checked;
    });

    if (checkAll.target.checked) {
      if (item) setCheckedFileList(item);
    } else {
      if (item) setCheckedFileList([]);
    }
  };

  const handleNotFile = (e: any) => {
    if (!e.target.closest(".bucket-item")) {
      setSelectedFile(null);
    }
  };
  const calTotalPercent = (): number => {
    let allPencentage = 0;
    for (const [key, value] of Object.entries(uploadingFileObject)) {
      const itemPencentage = value.uploadedSize / value.totalSize;
      allPencentage += itemPencentage;
    }
    //if value is NaN return 0
    if (isNaN(allPencentage)) return 0;

    return Math.round((allPencentage / Object.keys(uploadingFileObject).length) * 100);
  };

  useEffect(() => {
    document.addEventListener("click", handleNotFile, false);
    return () => {
      document.removeEventListener("click", handleNotFile, false);
    };
  });
  const downCheckedFile = async () => {
    if (pod?.publicAccessible) {
      await Promise.all(
        checkedFileList.map(async (item) => {
          try {
            await toast.promise(
              downloadFiles(accessToken, item.uid, item.name, item.size, user.sub, uploadingStatusCallback),
              {
                success: `Success to download ${item.name}`,
                loading: "Loading",
                error: "Fail to download",
              },
              {
                style: {
                  background: "var(--bg-sec)",
                  color: "var(--title-white)",
                },
              },
            );
          } catch (e) {
            window.analytics.track("Download Public File - Failed", {
              cid: item.uid,
              userId: user.sub,
              primaryProvider: pod?.primaryProvider,
              providers: pod?.providers,
              error: (e as Error).message,
            });
            delete uploadingFileObject[`Download-${item.uid}`];
            setUploadingFileObject(uploadingFileObject);
          }
        }),
      );
    } else {
      await Promise.all(
        checkedFileList.map(async (item) => {
          try {
            await toast.promise(
              downloadFilesPrivate(accessToken, item.uid, item.name, user.sub, item.size, uploadingStatusCallback),
              {
                success: `Success to download ${item.name}`,
                loading: "Loading",
                error: "Fail to download",
              },
              {
                style: {
                  background: "var(--bg-sec)",
                  color: "var(--title-white)",
                },
              },
            );
          } catch (e) {
            window.analytics.track("Download Private File - Failed", {
              cid: item.uid,
              userId: user.sub,
              primaryProvider: pod?.primaryProvider,
              providers: pod?.providers,
              error: (e as Error).message,
              date: new Date().toLocaleString(),
            });
            delete uploadingFileObject[`Download-${item.uid}`];
            setUploadingFileObject(uploadingFileObject);
          }
        }),
      );
    }
  };
  const deleteCheckedFile = async () => {
    await Promise.all(
      checkedFileList.map(async (item) => {
        await deleteItem(accessToken, item.prefix, item.name, item.type);
      }),
    );
    document.querySelectorAll("#checkAll").forEach((e) => {
      const input = e as HTMLInputElement;
      input.checked = false;
    });
    setCheckedFileList([]);
    // setDownload(false);
  };
  const removeLoadingFileFromObject = (keyname: string) => {
    const obj = { ...uploadingFileObject };
    delete obj[keyname];
    setUploadingFileObject(obj);
  };
  useEffect(() => {
    if (checkedFileList.length === 1) {
      if (checkedFileList[0].type === "File") {
        setSelectedFile(checkedFileList[0]);
      } else {
        setSelectedFile(null);
      }
    } else {
      setSelectedFile(null);
    }
  }, [checkedFileList]);
  return (
    <>
      <div
        className="content-con mt-[55px] flex gap-8 !px-0 pt-[57px] md:mt-0 md:max-h-screen md:pt-0"
        onClick={(e) => {
          const onclickId = (e.target as any).id.toUpperCase();
          const onClickType = (e.target as any).nodeName.toUpperCase();

          if (
            onclickId !== "FILECHECK" &&
            onclickId !== "ITEMROW" &&
            onclickId !== "ITEMPROP" &&
            onclickId !== "CHECKALL" &&
            onclickId !== "DELETEBOX" &&
            onClickType !== "BUTTON" &&
            onClickType !== "SVG" &&
            onClickType !== "PATH"
          ) {
            // alert(onClickType)
            document.querySelectorAll("#fileCheck").forEach((e) => {
              const input = e as HTMLInputElement;
              input.checked = false;
            });

            document.querySelectorAll("#checkAll").forEach((e) => {
              const input = e as HTMLInputElement;
              input.checked = false;
            });

            setCheckedFileList([]);
          }
        }}
      >
        {podNotFound === false ? (
          <>
            <>
              <div
                className={`uploading-con bg-main border-gray custom-shadow fixed right-0 z-[950] h-auto max-h-[80vh] w-full overflow-hidden border-t pb-1 md:max-w-[550px] md:rounded-tl-xl md:border-l ${
                  Object.keys(uploadingFileObject).length > 0 && (showUploadingCon ? "active" : "fold md:max-w-[85px]")
                }`}
              >
                <div data-show={showUploadingCon} className="flex items-center justify-between gap-4 px-5 py-4 text-sm text-black md:px-6 [&[data-show=false]]:justify-end">
                  {showUploadingCon && (
                    <div className="flex flex-1 items-center justify-between text-[#5b5d60] dark:text-white">
                      <p className="text-lg font-semibold">Files Processor</p>
                      <p className="text-lg font-semibold">{calTotalPercent()}%</p>
                    </div>
                  )}

                  <div
                    data-show={showUploadingCon}
                    onClick={() => setShowUploadingCon(!showUploadingCon)}
                    className="grid h-[34px] w-[34px] place-items-center self-end rounded-full bg-[#e8eff1] text-[#2d353c] hover:bg-[#d2dde0] dark:bg-[#272929] dark:text-[#e8eff1] hover:dark:bg-[#2e3030]"
                  >
                    {showUploadingCon ? (
                      <Icon icon="material-symbols:keyboard-arrow-down-rounded" className="mb-[-0.1rem] h-auto w-6 cursor-pointer" />
                    ) : (
                      <Icon icon="material-symbols:keyboard-arrow-up-rounded" className="h-auto w-6 cursor-pointer" />
                    )}
                  </div>
                </div>
                <div className="h-fit max-h-[calc(80vh-66px)] overflow-y-auto">{renderUploadingTask()}</div>
              </div>
              <div className={`btn-upload-con ${Object.keys(uploadingFileObject).length > 0 && "uploading"}`}>
                <div
                  // data-bottom={bottomHandler}
                  data-hidden={hidden}
                  data-rotate={uploadShow}
                  onClick={() => setUploadShow(!uploadShow)}
                  className={`btn-upload`}
                >
                  <Icon icon="akar-icons:plus" className="h-auto w-6 text-white" />
                </div>
                <div
                  // data-bottom={bottomHandler}
                  data-hidden={hidden}
                  data-show={uploadShow}
                  onClick={open}
                  className="btn-add-inner box-shadow z-[200] cursor-pointer border bg-white"
                >
                  <Icon icon="eva:cloud-upload-outline" className="text-main transform-center h-auto w-6" />
                </div>
              </div>
            </>

            <div className="relative flex w-full flex-1 flex-col xl:w-auto">
              <div
                id="upload-pop-up-box-bg"
                data-show={upload}
                onClick={() => setUpload(false)}
                className="create-bucket-wrapper fixed left-0 top-0 z-[998] h-full w-full bg-black/10 backdrop-blur-[2px] dark:bg-black/30"
              ></div>
              <div
                id="upload-pop-up-box"
                data-show={upload}
                className="create-bucket-con upload create-border-gray bg-color z-[999] flex max-h-[80vh] flex-col overflow-auto rounded-2xl border-2 py-6 md:max-w-[95vw]"
              >
                {uploadingFile ? (
                  <div className="top-0 left-0 z-[999] flex h-full w-full flex-col items-center justify-center rounded-lg p-10 backdrop-blur-[2px]">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <h1 className="mt-8 text-center text-[#70757c]">Uploading your files. Please Don't Close and reload.</h1>
                  </div>
                ) : (
                  <>
                    {createIngFolder ? (
                      <div>
                        <h1 className="title-white px-6 text-2xl font-semibold">Create Folder</h1>
                        <p className="mt-2 px-6 text-[#aaabaf]">
                          Create folder in <b>{pod?.name}</b> pod
                        </p>
                        <div className="mt-6 px-6">
                          <input
                            placeholder="Folder Name"
                            className="setting-input border-gray w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                            ref={folderNameRef}
                          ></input>
                        </div>

                        <div className="border-gray-sec mt-6 flex gap-5 border-t px-6 pt-6">
                          <button
                            onClick={() => {
                              setTimeout(() => {
                                setFiles([]);
                                setcreateingFolder(false);
                              }, 500);

                              setUpload(false);
                              folderNameRef.current!.value = "";
                            }}
                            className="border-gray-sec title-white h-[55px] flex-1 rounded-lg border text-sm font-semibold hover:bg-gray-100 dark:hover:bg-red-800"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={async () => {
                              try {
                                const folderName = folderNameRef.current!.value;
                                await createFolder();
                                toast.success(`Success to create Folder ${folderName}`, {
                                  style: {
                                    background: "var(--bg-sec)",
                                    color: "var(--title-white)",
                                  },
                                });
                                folderNameRef.current!.value = "";
                              } catch (e) {
                                toast.error(`Failed to create Folder ${folderNameRef.current!.value}`, {
                                  style: {
                                    background: "var(--bg-sec)",
                                    color: "var(--title-white)",
                                  },
                                });
                              }
                            }}
                            className="bg-blue w-full flex-1 rounded-lg text-[13px] font-semibold text-white hover:bg-[#226ddd] 2xl:text-[14px]"
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {deleteingFile ? (
                          <div id="DELETEBOX">
                            <h1 id="DELETEBOX" className="title-white px-6 text-2xl font-semibold">
                              Delete {checkedFileList.length === 1 ? "File" : "Files"}
                            </h1>
                            <div id="DELETEBOX" className={"title-white mt-2 px-6 text-sm "}>
                              <div id="DELETEBOX" style={{ display: "flex", padding: "6px" }} className="border-gray mt-4 max-w-[550px] rounded-lg border-2">
                                <div id="DELETEBOX">
                                  <Icon id="DELETEBOX" icon="material-symbols:warning" className="h-auto w-5 text-yellow-300 dark:text-yellow-500" />
                                </div>
                                <ul id="DELETEBOX" className="font-medium" style={{ marginLeft: "2rem", fontSize: "10px" }}>
                                  <li id="DELETEBOX" style={{ listStyle: "disc" }}>
                                    If a folder is selected for deletion, all objects in the folder will be deleted, and any new objects added while the delete action is in
                                    progress might also be deleted. If an object is selected for deletion, any new objects with the same name that are uploaded before the delete
                                    action is completed will also be deleted.
                                  </li>
                                  <li id="DELETEBOX" style={{ listStyle: "disc" }}>
                                    Deleting the specified objects can't be undone.
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div id="DELETEBOX" className="title-white mt-4 px-6 text-sm">
                              To confirm deletion, type{" "}
                              <b id="DELETEBOX" className="text-lg text-red-400 dark:text-red-500">
                                Permanently Delete
                              </b>{" "}
                              in the text input field.
                            </div>

                            {/*<p id="DELETEBOX" className="mt-2 px-6 title-white text-sm">{checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"}</p>*/}
                            <div className="mt-2 px-6">
                              <input
                                id="DELETEBOX"
                                // placeholder={checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"}
                                placeholder={"Type Here"}
                                className="setting-input border-gray w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                                ref={confirmDeleteRef}
                              ></input>
                            </div>

                            <div id="DELETEBOX" className="border-gray-sec mt-6 flex gap-5 border-t px-6 pt-6">
                              <button
                                onClick={() => {
                                  setTimeout(() => {
                                    setFiles([]);
                                    setDeleteing(false);
                                  }, 500);

                                  setUpload(false);
                                  confirmDeleteRef.current!.value = "";
                                }}
                                className="border-gray-sec title-white h-[55px] flex-1 rounded-lg border text-sm font-semibold hover:bg-gray-100 dark:hover:bg-red-800"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={async () => {
                                  // const confirmText = checkedFileList.length===1?`${checkedFileList[0]!.Name}`:"Permanently Delete"
                                  const confirmText = "Permanently Delete";
                                  if (confirmDeleteRef.current!.value === confirmText) {
                                    try {
                                      if (selectedFile || checkedFileList.length > 0)
                                        if (selectedFile) {
                                          await deleteItem(accessToken, selectedFile.prefix, selectedFile.name, selectedFile.type);
                                        } else {
                                          await deleteCheckedFile();
                                        }
                                      setReloadHome(!reloadHome);

                                      toast.success(`${checkedFileList.length === 1 ? `${checkedFileList[0]!.name}` : "Files"} were deleted`, {
                                        style: {
                                          background: "var(--bg-sec)",
                                          color: "var(--title-white)",
                                        },
                                      });
                                      setUpload(false);
                                    } catch (e) {
                                      toast.error((e as Error).message, {
                                        style: {
                                          background: "var(--bg-sec)",
                                          color: "var(--title-white)",
                                        },
                                      });
                                    }
                                  } else {
                                    toast.error("Confirm Text Not Correct" + confirmText, {
                                      style: {
                                        background: "var(--bg-sec)",
                                        color: "var(--title-white)",
                                      },
                                    });
                                  }
                                }}
                                className="w-full flex-1 rounded-lg bg-red-500 text-[13px] font-semibold text-white hover:bg-red-500/80 dark:hover:bg-red-600 2xl:text-[14px]"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {bindDomainAction ? (
                              <div>
                                <h1 className="title-white px-6 text-2xl font-semibold">Bind EDNS Domain</h1>
                                <div className="mt-6 flex justify-between px-6">
                                  <input
                                    placeholder="EDNS Domain Name"
                                    className="setting-input border-gray w-full rounded-lg border-2 p-4 text-xs outline-none 2xl:text-[13px]"
                                    onChange={(e) => {
                                      setDomainName(e.target.value);
                                    }}
                                  ></input>

                                  {checkDomainValid !== undefined && checkDomainValid.domain === domainName && checkDomainValid.result ? (
                                    <div className="flex w-10" style={{ justifyContent: "end", alignItems: "center" }}>
                                      <Icon icon="icon-park:correct" className="h-5 w-5 md:h-[1.3rem]" />
                                    </div>
                                  ) : (
                                    <div
                                      className="flex w-10"
                                      style={{ justifyContent: "end", alignItems: "center", cursor: "pointer" }}
                                      onClick={async () => {
                                        if (domainName !== "") {
                                          const popupbox = document.getElementById("upload-pop-up-box") as HTMLElement;
                                          const popupbox_bg = document.getElementById("upload-pop-up-box-bg") as HTMLElement;
                                          popupbox_bg.style.display = "none";
                                          popupbox.style.display = "none";
                                          const address = await getWeb3WalletAddress();
                                          console.log({ address });

                                          popupbox_bg.style.display = "block";
                                          popupbox.style.display = "block";
                                          if (address) {
                                            const isOwner = await isDomainOwner(address, domainName);
                                            console.log({ isOwner });

                                            if (isBoolean(isOwner)) {
                                              const vaildcheck = setCheckDomainValid({ domain: domainName, address: address, result: isOwner });
                                              console.log({ vaildcheck });
                                              isOwner
                                                ? toast.success(`${isOwner}`, {
                                                    style: {
                                                      background: "var(--bg-sec)",
                                                      color: "var(--title-white)",
                                                    },
                                                  })
                                                : toast.error(`${isOwner}`, {
                                                    style: {
                                                      background: "var(--bg-sec)",
                                                      color: "var(--title-white)",
                                                    },
                                                  });
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      <Icon icon="carbon:query" className="title-white h-5 w-5 md:h-[1.3rem]" />
                                    </div>
                                  )}
                                </div>
                                <div className="border-gray-sec mt-6 flex gap-5 border-t px-6 pt-6">
                                  <button
                                    onClick={() => {
                                      // domainName === "";
                                      setUpload(false);
                                    }}
                                    className="border-gray-sec title-white h-[55px] flex-1 rounded-lg border text-sm font-semibold hover:bg-gray-100 dark:hover:bg-red-800"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() => {
                                      console.log({ checkDomainValid });
                                    }}
                                    disabled={!(checkDomainValid !== undefined && checkDomainValid.domain === domainName && checkDomainValid.result)}
                                    className={
                                      checkDomainValid !== undefined && checkDomainValid.domain === domainName && checkDomainValid.result
                                        ? "bg-blue w-full flex-1 rounded-lg text-[13px] font-semibold text-white hover:bg-[#226ddd] 2xl:text-[14px]"
                                        : "border-gray-sec title-white disabled h-[55px] flex-1 rounded-lg border text-sm font-semibold"
                                    }
                                  >
                                    Bind
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <h1 className="title-white px-6 text-2xl font-semibold">Upload files</h1>
                                <p className="mt-2 px-6 text-[#aaabaf]">
                                  Upload thie file to <b>{pod?.name}</b> pod
                                </p>
                                <div className="thumb-con mt-2 flex h-auto flex-col overflow-y-auto px-6">{thumbs}</div>

                                <div className="border-gray-sec mt-6 flex gap-5 border-t px-6 pt-6">
                                  <button
                                    onClick={() => cancelUpload()}
                                    className="border-gray-sec title-white h-[55px] flex-1 rounded-lg border text-sm font-semibold hover:bg-gray-100 dark:hover:bg-red-800"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() => {
                                      setUpload(false);
                                      // setUploading(true);
                                      handlePresignedURL();
                                    }}
                                    className="bg-blue w-full flex-1 rounded-lg text-[13px] font-semibold text-white hover:bg-[#226ddd] 2xl:text-[14px]"
                                  >
                                    Upload
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="bg-main border-gray fixed top-[57px] z-[699] flex w-full items-center justify-between gap-2 border-b px-5 py-3 text-base text-[#9ea4a9] md:sticky md:top-0 md:w-auto md:py-4 md:px-8 2xl:text-base">
                <div className="shrink-1 flex items-center gap-1 overflow-auto text-base">
                  <Link to="/" className="cursor-pointer hover:underline">
                    <Icon icon="mingcute:home-1-line" className="hover:text-main-500 h-5 w-auto transition duration-150 dark:hover:text-white md:h-[1.3rem]" />
                  </Link>
                  <Icon icon="gg:format-slash" className="h-auto w-5 shrink-0 text-[#d3dbdf] md:w-6"></Icon>
                  <span
                    className={`text-sm font-semibold md:text-base ${breadCumb.length !== 0 ? "cursor-pointer hover:underline" : "text-[#5c5d61] dark:text-white"}`}
                    onClick={(x) => {
                      setBreadCumb([]);
                      setCurrLocation("");
                      setReloadHome(!reloadHome);
                    }}
                  >
                    {pod?.name}
                  </span>
                  <>
                    {breadCumb.map((item, i) => {
                      return (
                        <div key={i} className="flex items-center text-sm md:text-base">
                          <Icon icon="gg:format-slash" className="h-auto w-5 text-[#d3dbdf] md:w-6"></Icon>
                          <span
                            className={`font-semibold ${breadCumb.length === i + 1 ? "text-[#5c5d61] dark:text-white" : "cursor-pointer hover:underline"}`}
                            onClick={() => {
                              breadCumb.splice(i + 1, breadCumb.length - (i + 1));
                              removeBreadCumb(item.folder, item.location);
                            }}
                          >
                            {item.folder}
                          </span>
                        </div>
                      );
                    })}
                  </>
                </div>
                <div className="flex items-center gap-2 md:gap-4">
                  <button
                    onClick={async () => {
                      setLoading(true);
                      await new Promise((r) => setTimeout(r, 2000));
                      setReloadHome(!reloadHome);
                    }}
                    className={` border-gray group relative grid aspect-square h-[32px] place-items-center rounded-md border hover:bg-gray-50 hover:dark:bg-gray-800 ${
                      !loading ? "opacity-100" : "pointer-events-none opacity-60"
                    }`}
                  >
                    <Icon icon="mdi:refresh" className="group-hover:text-main-500 h-auto w-5 text-[#6f7175] transition duration-200 dark:group-hover:text-white" />
                    <div className="pointer-events-none absolute left-[50%] top-[calc(100%+10px)] w-fit translate-x-[-50%] scale-95 whitespace-nowrap rounded-md bg-gray-700 p-3 py-2 text-sm text-white opacity-0 transition duration-150 group-hover:scale-100 group-hover:opacity-100 group-hover:delay-200 dark:bg-gray-800">
                      Refresh folder
                    </div>
                  </button>
                  <button
                    onClick={() => setHandleDisplayMode(!handleDisplayMode)}
                    className="border-gray group relative grid aspect-square h-[32px] place-items-center rounded-md border hover:bg-gray-50 hover:dark:bg-gray-800"
                  >
                    {handleDisplayMode ? (
                      <Icon
                        icon="mingcute:rows-4-line"
                        className="group-hover:text-main-500 h-auto w-5 text-[#5c5d61] transition duration-200 dark:text-gray-500 dark:group-hover:text-white"
                      />
                    ) : (
                      <Icon
                        icon="material-symbols:grid-view-outline-rounded"
                        className="group-hover:text-main-500 h-auto w-5 text-[#5c5d61] transition duration-200 dark:text-gray-500 dark:group-hover:text-white"
                      />
                    )}

                    <div className="pointer-events-none absolute left-[50%] top-[calc(100%+10px)] w-fit translate-x-[-50%] scale-95 whitespace-nowrap rounded-md bg-gray-700 p-3 py-2 text-sm text-white opacity-0 transition duration-150 group-hover:scale-100 group-hover:opacity-100 group-hover:delay-200 dark:bg-gray-800">
                      Change display mode
                    </div>
                  </button>

                  <div
                    className={
                      pod?.publicAccessible
                        ? "bg-blue grid h-fit place-items-center rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 px-5 py-2 text-xs font-medium text-white dark:from-cyan-800 dark:to-blue-800 md:text-sm"
                        : "bg-red grid h-fit place-items-center rounded-full bg-gradient-to-r from-red-400 to-red-500 px-5 py-2 text-xs font-medium text-white dark:from-red-700 dark:to-red-900 md:text-sm"
                    }
                  >
                    {pod?.publicAccessible ? `Public` : `Private`}
                  </div>
                </div>
              </div>
              <div className="max-w-full flex-1 overflow-auto px-5 pt-6 md:px-8 md:pt-8">
                {/* <div className="mb-6 flex items-center justify-between gap-6">
                  <div className="flex items-center gap-4">
                    <Link to="/" className="border-gray bg-main rounded-lg border p-1">
                      <Icon className="title-white h-7 w-auto" icon="material-symbols:chevron-left-rounded" />
                    </Link>
                    <p className="title-white text-2xl font-semibold">{pod?.name}</p>
                  </div>
                  <div
                    className={
                      pod?.publicAccessible
                        ? "bg-blue grid h-fit place-items-center rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 px-6 py-2 text-sm font-medium text-white dark:from-cyan-800 dark:to-blue-800"
                        : "bg-red grid h-fit place-items-center rounded-full bg-gradient-to-r from-red-300 to-red-500 px-6 py-2 text-sm font-medium text-white dark:from-red-700 dark:to-red-900"
                    }
                  >
                    {pod?.publicAccessible ? `Public` : `Private`}
                  </div>
                </div> */}

                <section className="bg-main main-box-shadow shrink-0 overflow-x-hidden rounded-2xl p-6 px-0 pt-5">
                  <div className="flex items-center gap-6 px-6 font-medium">
                    {/* <h1 className="title-white text-xl font-medium">{pod.pod.Name}</h1> */}
                    <h1 className="title-white text-xl font-semibold">Overview</h1>
                  </div>

                  {/* <div className="bg-gradient"></div> */}
                  <div className="quick-text-sec scrollbar-hidden mt-6 flex gap-4 overflow-x-auto px-6">
                    <div className="border-gray flex h-[177px] w-fit min-w-[235px] max-w-[350px] shrink-0 flex-col justify-between rounded-xl border p-4">
                      <div className={`billing-icon-color billing-usage-bg-blue grid h-[56px] w-[56px] place-items-center rounded-md`}>
                        <Icon icon="healthicons:provider-fst" className="h-9 w-auto text-[#32cbae]" />
                      </div>
                      <div>
                        <div className="title-white mt-4 text-[27px] font-semibold">{pod?.primaryProvider}</div>
                        <div className="mt-2.5 truncate text-sm text-[#aaabaf]">Primary Provider</div>
                      </div>
                    </div>
                    <div className="border-gray flex h-[177px] w-fit min-w-[235px] max-w-[350px] shrink-0 flex-col justify-between rounded-xl border p-4">
                      <div className={`billing-icon-color billing-usage-bg-blue grid h-[56px] w-[56px] place-items-center rounded-md`}>
                        <Icon icon="fluent:storage-24-regular" className="h-9 w-auto text-[#32cbae]" />
                      </div>
                      <div>
                        <div className="title-white mt-4 text-[27px] font-semibold">{pod?.providers}</div>
                        <div className="mt-2.5 truncate text-sm text-[#aaabaf]">Storage Provider</div>
                      </div>
                    </div>

                    {/* <div
                  className="border-gray flex h-[177px] w-fit min-w-[235px] max-w-[350px] shrink-0 flex-col justify-between rounded-xl border p-4">
                  <Icon icon="bx:code-curly" className="h-9 w-auto billing-icon-color" />
                  <div className="title-white mt-4 truncate text-lg font-semibold">Bucket URL</div>
                  <div
                    className="mt-2.5 cursor-pointer text-[14px] font-semibold billing-icon-color hover:underline">dedrive://bzz-public-website
                  </div>
                </div> */}
                    <div className="border-gray flex h-[177px] w-fit min-w-[235px] max-w-[350px] shrink-0 flex-col justify-between rounded-xl border p-4">
                      <div className={`billing-icon-color billing-usage-bg-blue grid h-[56px] w-[56px] place-items-center rounded-md`}>
                        <Icon icon="gg:calendar-dates" className="h-9 w-auto text-[#32cbae]" />
                      </div>
                      <div>
                        <div className="title-white mt-4 text-[27px] font-semibold">{`${new Date(`${pod?.createdAt}`).toLocaleDateString()}`}</div>
                        <div className="mt-2.5 truncate text-sm text-[#aaabaf]">Created Date</div>
                      </div>
                    </div>

                    <div className="border-gray flex h-[177px] w-fit min-w-[235px] max-w-[350px] shrink-0 flex-col justify-between rounded-xl border p-4">
                      <div className={`billing-icon-color billing-usage-bg-blue grid h-[56px] w-[56px] place-items-center rounded-md`}>
                        {/*<Icon icon="gg:calendar-dates" className="" />*/}
                        <img src={EDNSICON} style={{height:"45px"}} className="h-10 w-auto text-[#32cbae]"/>
                      </div>
                      <div>
                        {ednsDomain === undefined ? <>
                          Loading
                        </>:<>
                          {ednsDomain === null ? <>
                            <button onClick={()=>{window.open(
                              `https://app.edns.domains/`,
                              '_blank'
                            )}}>Link Now</button>
                          </>:<>
                            {<div className="title-white mt-4 text-[27px] font-semibold hover:bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 inline-block hover:text-transparent bg-clip-text"
                            style={{cursor:"pointer"}}
                            onClick={()=>{window.open(
                              `https://${ednsDomain}.edns.link/`,
                              '_blank'
                            )}}
                            >{`${ednsDomain}`}</div>}
                          </>}
                        </>}
                        <div className="mt-2.5 truncate text-sm text-[#aaabaf]">EDNS Domain</div>
                      </div>
                    </div>
                    {/* {config.env === "develop" && (
                      <div className="border-gray flex h-[177px] w-fit min-w-[235px] max-w-[350px] shrink-0 flex-col justify-between rounded-xl border p-4">
                        <div className={`billing-icon-color billing-usage-bg-blue grid h-[56px] w-[56px] shrink-0 place-items-center rounded-md`}>
                          <img src={EDNSICON} className="billing-icon-color h-9 w-auto rounded-md bg-white" />
                        </div>
                        <div>
                          <div className="title-white mt-4 text-[27px] font-semibold">EDNS Domain</div>
                          <div className="mt-1 flex" style={{ justifyContent: "space-between", alignItems: "baseline" }}>
                            <div className="truncate text-sm text-[#aaabaf]">No Domain Bound</div>
                            <button
                              className="border-gray-sec title-white rounded-lg border p-1 px-2 text-sm font-semibold  hover:bg-gray-100 dark:bg-blue-500 dark:hover:bg-blue-800"
                              onClick={(e) => {
                                setBindDomainAction(true);
                                setUpload(true);
                              }}
                            >
                              Bind
                            </button>
                          </div>
                        </div>
                      </div>
                    )} */}

                    {currentStamp !== null && (
                      <>
                        <Box
                          name={currentStamp.name}
                          usage={parseInt(currentStamp.usage) / 1024 / 1024 / 1024}
                          day={Math.floor(currentStamp.expiredAt / (3600 * 24))}
                          storage={parseInt(currentStamp.total) / 1024 / 1024 / 1024}
                        />
                      </>
                    )}
                  </div>
                </section>

                <section className="relative mt-4 flex py-4 text-sm">
                  <div className="absolute bottom-0 left-0 h-[4px] w-full">
                    <div className="billing-usage-bg-blue absolute left-0 bottom-0 h-full w-full rounded-full"></div>
                    <div data-type={props.type} className={`bg-blue info-border absolute bottom-0 h-full w-[130px] rounded-full`}></div>
                  </div>
                  <Link
                    to={`/pod/info/${pod?.name}/items`}
                    className={`w-[130px] cursor-pointer text-center ${props.type === "Items" ? "text-[#32cbae]" : "text-[#888E99] hover:text-[#1c947e]"}`}
                  >
                    Files
                  </Link>
                  <Link
                    to={`/pod/info/${pod?.name}/properties`}
                    className={`w-[130px] cursor-pointer text-center ${props.type === "Properties" ? "text-[#32cbae]" : "text-[#888E99] hover:text-[#1c947e]"}`}
                  >
                    Properties
                  </Link>
                  {/* <div
            className={`border-gray tips-con bg-main bg-color bucket-info-text-color absolute right-0 bottom-5 hidden w-fit rounded-xl border px-8 py-5 text-sm shadow-sm md:block`}
          >
            Upload by Dropping a File Here
            <div data-loading={loading}></div>
          </div> */}
                </section>
                {props.type === "Items" && (
                  <div
                    className="bg-main main-box-shadow mt-4 shrink-0 overflow-x-hidden rounded-2xl p-4 px-0"
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  >
                    {props.type === "Items" && (
                      <div className="text-color btn-container scrollbar-hidden flex w-full items-center gap-4 overflow-x-auto px-4 text-base 2xl:text-base">
                        <button
                          onClick={async () => {
                            setcreateingFolder(true);
                            setUpload(true);
                          }}
                          className={`hover:text-main-500 dark:hover:text-main-highlight flex h-[42px] shrink-0 items-center gap-3 rounded-lg border border-gray-300 px-4 py-3 text-xs font-medium text-gray-400 transition hover:border-blue-500 hover:bg-[#f9fbff] dark:border-gray-600 dark:text-gray-400 dark:hover:border-teal-400 dark:hover:bg-teal-900/50 md:w-auto md:text-sm
`}
                        >
                          <Icon icon="material-symbols:create-new-folder" className="h-auto w-5" />
                          Create Folder
                        </button>
                        <button
                          onClick={async () => {
                            downCheckedFile();
                          }}
                          className={`hover:text-main-500 dark:hover:text-main-highlight flex h-[42px] shrink-0 items-center gap-3 rounded-lg border border-gray-300 px-4 py-3 text-xs font-medium text-gray-400 transition hover:border-blue-500 hover:bg-[#f9fbff] dark:border-gray-600 dark:text-gray-400 dark:hover:border-teal-400 dark:hover:bg-teal-900/50 md:w-auto md:text-sm
                  ${(selectedFile?.status === "AVAILABLE" && selectedFile?.type === "File") || isCheckedFileListCanDownload() ? "opacity-100" : "pointer-events-none opacity-40"}`}
                        >
                          <Icon icon="material-symbols:arrow-circle-down-outline-rounded" className="h-auto w-5" />
                          Download
                        </button>
                        <button
                          onClick={async () => {
                            setDeleteing(true);
                            setUpload(true);
                          }}
                          className={`flex h-[42px] shrink-0 items-center gap-3 rounded-lg border border-gray-300 px-4 py-3 text-xs font-medium text-gray-400 transition hover:border-red-500 hover:bg-red-100 hover:text-red-500 dark:border-gray-600 dark:text-gray-400 dark:hover:border-[#7d291e] dark:hover:bg-[#410d04] dark:hover:text-red-400 md:w-auto md:text-sm ${
                            selectedFile?.status === "AVAILABLE" || isCheckedFileListCanDownload() ? "opacity-100" : "pointer-events-none opacity-40"
                          }`}
                        >
                          <Icon icon="material-symbols:delete-forever-outline" className="h-auto w-5" />
                          Delete
                        </button>
                        <div className="flex shrink-0">
                          {optionShowStatic && (
                            <button
                              id="ITEMPROP"
                              className={`btn-website hover:text-main-500 dark:hover:text-main-highlight relative flex h-[42px] shrink-0 items-center gap-3 rounded-lg border border-gray-300 px-4 py-3 text-xs font-medium text-gray-400 hover:border-blue-500 hover:bg-[#f9fbff] dark:border-gray-600 dark:text-gray-400 dark:hover:border-teal-400 dark:hover:bg-teal-900/50 md:w-auto md:text-sm ${
                                optionShowStatic && "active"
                              } ${pod?.publicAccessible ? "" : "hidden"}`}
                              onClick={async () => {
                                await toast.promise(
                                  navigator.clipboard.writeText(`http://${pod?.name}${config.api.website_gateway}`),
                                  {
                                    success: `Copied to clipboard.`,
                                    loading: "Loading",
                                    error: "Fail to Delete",
                                  },
                                  {
                                    style: {
                                      background: "var(--bg-sec)",
                                      color: "var(--title-white)",
                                    },
                                  },
                                );
                                setOptionShow(false);
                              }}
                            >
                              <Icon icon="nimbus:browser" className="h-auto w-5" />
                              Static Website
                            </button>
                          )}
                          <button
                            style={{ zIndex: 1 }}
                            className={`relative z-[500] flex h-[42px] shrink-0 items-center gap-3 rounded-lg border border-gray-300 px-4 py-3 text-xs font-medium text-gray-400 opacity-100 transition hover:border-blue-500 hover:bg-[#f9fbff] hover:text-blue-500 dark:border-gray-600 dark:text-gray-400 dark:hover:border-blue-400 dark:hover:bg-blue-900/50 dark:hover:text-blue-400 md:w-auto md:text-sm ${
                              pod?.publicAccessible ? "" : "hidden"
                            }`}
                            onClick={() => {
                              setOptionShow(!optionShow);
                              const con = document.querySelector(".btn-container") as HTMLDivElement;
                              setTimeout(() => {
                                con.scrollTo(1000, 0);
                              }, 300);
                            }}
                          >
                            <Icon icon="material-symbols:content-copy-outline" className="h-auto w-5" />
                            <div className="flex items-center" id="ITEMPROP">
                              Copy Link
                              {optionShow ? (
                                <Icon icon="ri:arrow-drop-right-fill" className="mr-[-0.5rem] h-auto w-6" />
                              ) : (
                                <Icon icon="ri:arrow-drop-down-fill" className="mr-[-0.5rem] h-auto w-6" />
                              )}
                            </div>
                          </button>

                          <button
                            id="ITEMPROP"
                            className={`btn-file hover:text-main-500 dark:hover:text-main-highlight relative flex h-[42px] shrink-0 items-center gap-3 rounded-lg border border-gray-300 px-4 py-3 text-xs font-medium text-gray-400 hover:border-blue-500 hover:bg-[#f9fbff] dark:border-gray-600 dark:text-gray-400 dark:hover:border-teal-400 dark:hover:bg-teal-900/50 md:w-auto md:text-sm ${
                              optionShow && "active"
                            } ${checkedFileList.length === 1 && checkedFileList[0].type === "File" && checkedFileList[0].status === "AVAILABLE" ? "" : "!pointer-events-none"} `}
                            style={{
                              opacity: optionShow ? (checkedFileList.length === 1 && checkedFileList[0].type === "File" && checkedFileList[0].status === "AVAILABLE" ? 1 : 0.4) : 0,
                            }}
                            onClick={async () => {
                              if (checkedFileList.length === 1 && checkedFileList[0].type === "File" && checkedFileList[0].status === "AVAILABLE") {
                                await toast.promise(
                                  navigator.clipboard.writeText(await getDownloadFilesLink(accessToken, checkedFileList[0].uid)),
                                  {
                                    success: `Success to Copy File Link`,
                                    loading: "Loading",
                                    error: "Fail to Get File Link",
                                  },
                                  {
                                    style: {
                                      background: "var(--bg-sec)",
                                      color: "var(--title-white)",
                                    },
                                  },
                                );
                              }

                              setOptionShow(false);
                            }}
                          >
                            <Icon icon="material-symbols:attach-file" className="h-auto w-5" />
                            File
                          </button>
                          <button
                            id="ITEMPROP"
                            className={`btn-file hover:text-main-500 dark:hover:text-main-highlight relative flex h-[42px] shrink-0 items-center gap-3 rounded-lg border border-gray-300 px-4 py-3 text-xs font-medium text-gray-400 hover:border-blue-500 hover:bg-[#f9fbff] dark:border-gray-600 dark:text-gray-400 dark:hover:border-teal-400 dark:hover:bg-teal-900/50 md:w-auto md:text-sm ${
                              optionShow && "active"
                            } ${checkedFileList.length === 1 && checkedFileList[0].type === "File" && checkedFileList[0].status === "AVAILABLE" ? "" : "!pointer-events-none"} `}
                            style={{
                              opacity: optionShow ? (checkedFileList.length === 1 && checkedFileList[0].type === "File" && checkedFileList[0].status === "AVAILABLE" ? 1 : 0.4) : 0,
                            }}
                            onClick={async () => {
                              if (checkedFileList.length === 1 && checkedFileList[0].type === "File" && checkedFileList[0].status === "AVAILABLE") {
                                const type = checkedFileList[0]?.name.split(".")[checkedFileList[0]?.name.split(".").length - 1];
                                if (isVideo(type)) {
                                  await toast.promise(
                                    navigator.clipboard.writeText(await getPreviewFilesLinkVideo(accessToken, checkedFileList[0].uid)),
                                    {
                                      success: `Success to Copy PreView Link`,
                                      loading: "Loading",
                                      error: "Fail to Get File Link",
                                    },
                                    {
                                      style: {
                                        background: "var(--bg-sec)",
                                        color: "var(--title-white)",
                                      },
                                    },
                                  );
                                } else if (isImage(type)) {
                                  await toast.promise(
                                    navigator.clipboard.writeText(await getPreviewFilesLinkImage(accessToken, checkedFileList[0].uid)),
                                    {
                                      success: `Success to Copy PreView Link`,
                                      loading: "Loading",
                                      error: "Fail to Get File Link",
                                    },
                                    {
                                      style: {
                                        background: "var(--bg-sec)",
                                        color: "var(--title-white)",
                                      },
                                    },
                                  );
                                } else {
                                  toast.error("File Type Not Found :" + type);
                                }
                              }

                              setOptionShow(false);
                            }}
                          >
                            <Icon icon="material-symbols:attach-file" className="h-auto w-5" />
                            Preview
                          </button>
                          {/* <div
                      data-show={optionShow}
                      className="border-gray bg-main absolute top-[unset] left-[unset] z-[999] flex w-[145px] flex-col overflow-hidden rounded-b-xl border px-4 py-4 text-xs font-medium md:rounded-xl 2xl:text-[13px]"
                    >
                      <div className={`flex flex-col gap-6`}>
                        <div
                          onClick={async () => {
                            await navigator.clipboard.writeText(`http://${pod?.name}.pod.gateway.dev.dedrive.io`);
                            toast.success("Copied to clipboard.", {
                              style: {
                                background: "var(--bg-sec)",
                                color: "var(--title-white)",
                              },
                            });
                          }}
                          className={`option-color border-gray flex cursor-pointer items-center gap-4 rounded-xl text-[13px] font-medium ${
                            pod ? "opacity-100" : "pointer-events-none opacity-40"
                          }`}
                        >
                          <Icon icon="bi:browser-chrome" className="h-auto w-5" />
                          Static Website
                        </div>

                        <div
                          onClick={async () => {
                            if (selectedFile) await navigator.clipboard.writeText(getDownloadFilesLink(accessToken, selectedFile.UID));
                            toast.success("Copied to clipboard.", {
                              style: {
                                background: "var(--bg-sec)",
                                color: "var(--title-white)",
                              },
                            });
                          }}
                          className={`option-color flex cursor-pointer items-center gap-4 rounded-xl text-[13px] font-medium ${
                            selectedFile?.Status === "AVAILABLE" && selectedFile?.Type === "File" ? "opacity-100" : "pointer-events-none opacity-40"
                          }`}
                        >
                          <Icon icon="material-symbols:attach-file" className="h-auto w-5" />
                          File
                        </div>
                      </div>
                    </div> */}
                        </div>
                      </div>
                    )}
                    {/* {!isCheckedFileIncludsFolder() && <div className="text-color px-4">Folder Can't Download.</div>} */}
                  </div>
                )}

                <section className="pb-mobile flex md:pb-10">
                  <div className="bg-main main-box-shadow mt-4 flex flex-1 flex-col overflow-hidden rounded-2xl">
                    {props.type === "Items" ? (
                      <div className="border-gray flex h-[70px] select-none gap-2 border-b px-6 text-[13px] font-semibold text-[#ababb3] md:gap-4 2xl:text-sm">
                        <div className="flex flex-[0.5] items-center gap-1 text-[#888E99]">
                          <input type="checkbox" defaultChecked={false} onChange={handleCheckAll} name="" id="checkAll" />
                        </div>
                        <div
                          onClick={() => {
                            setOrderBy(item !== null ? !orderBy : orderBy);
                            setOrderType("name");
                          }}
                          className={`flex ${handleDisplayMode ? "flex-[4]" : width > 768 ? "flex-[26]" : "flex-[4]"} cursor-pointer items-center`}
                        >
                          <span>Name</span>
                          <span data-sort={orderBy} data-type={orderType} className="btn-sort name cursor-pointer">
                            <Icon icon="material-symbols:arrow-drop-down-rounded" className="h-5 w-auto"></Icon>
                          </span>
                        </div>
                        {width > 768 && handleDisplayMode && (
                          <>
                            <div
                              onClick={() => {
                                setOrderBy(orderType === "type" ? (item !== null ? !orderBy : orderBy) : true);
                                setOrderType("type");
                              }}
                              className="flex flex-[3] cursor-pointer items-center"
                            >
                              <span>Type</span>
                              <span data-sort={orderBy} data-type={orderType} className="btn-sort type cursor-pointer">
                                <Icon icon="material-symbols:arrow-drop-down-rounded" className="h-5 w-auto"></Icon>
                              </span>
                            </div>
                            <div
                              onClick={() => {
                                setOrderBy(orderType === "lastModified" ? (item !== null ? !orderBy : orderBy) : true);
                                setOrderType("lastModified");
                              }}
                              className="flex flex-[3] cursor-pointer items-center"
                            >
                              Last modified
                              <span data-sort={orderBy} data-type={orderType} className="btn-sort lastModified cursor-pointer">
                                <Icon icon="material-symbols:arrow-drop-down-rounded" className="h-5 w-auto"></Icon>
                              </span>
                            </div>
                            <div
                              onClick={() => {
                                setOrderBy(orderType === "size" ? (item !== null ? !orderBy : orderBy) : true);
                                setOrderType("size");
                              }}
                              className="flex flex-[2.5] cursor-pointer items-center"
                            >
                              Size
                              <span data-sort={orderBy} data-type={orderType} className="btn-sort size cursor-pointer">
                                <Icon icon="material-symbols:arrow-drop-down-rounded" className="h-5 w-auto"></Icon>
                              </span>
                            </div>
                            <div
                              onClick={() => {
                                setOrderBy(orderType === "status" ? (item !== null ? !orderBy : orderBy) : true);
                                setOrderType("status");
                              }}
                              className="flex flex-[2] cursor-pointer items-center gap-1"
                            >
                              Status
                              <span data-sort={orderBy} data-type={orderType} className="btn-sort status cursor-pointer">
                                <Icon icon="material-symbols:arrow-drop-down-rounded" className="h-5 w-auto"></Icon>
                              </span>
                            </div>
                            <div className="flex flex-[1] items-center gap-1 text-[#888E99]">
                              <span>Cid</span>
                              <Tooltip title="CID can be use to get file from the chosen storage provide public gateway" placement="right" arrow>
                                <IconButton>
                                  <Icon className="h-4 w-4" icon="memory:tooltip-end-alert" color="white" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}

                    {props.type === "Items" ? (
                      <div {...getRootProps()} className={`relative shrink-0${item === null ? ` min-h-[500px]` : ``}${!handleDisplayMode ? ` test` : ``}`}>
                        <div
                          data-loading={loading}
                          className="props-loading-con absolute top-0 left-0 z-[450] grid h-full w-full place-items-center rounded-lg backdrop-blur-[2px]"
                        >
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                        <input {...getInputProps()} />
                        {isDragActive && (
                          <div className="x absolute top-0 left-0 z-[450] grid h-full w-full place-items-center rounded-2xl rounded-t-none border-2 border-dashed border-blue-500 bg-blue-500/30 text-2xl font-medium text-gray-200"></div>
                        )}
                        {item === null && (
                          <div onClick={open} className="title-white absolute top-0 left-0 z-[300] flex h-full w-full select-none flex-col items-center justify-center rounded-md">
                            <Icon icon="icon-park-solid:upload-web" className="text-main h-auto w-16" />
                            <p className="mt-4 text-base text-[#70757c]">{width > 768 ? "Click or Drag file here to upload" : "Click here to upload"}</p>
                          </div>
                        )}
                        {/* {acceptedFiles.map((file, index) => (
              <File
                name={file.name}
                type={file.name.split(".")[1]}
                lastModified="23 Jan 2022"
                size={(file.size / 1024).toString().slice(0, 4) + " KB"}
                status="Pending"
                location="Pod-1-example"
              />
            ))} */}

                        <>
                          {renderFile(orderBy, orderType)}
                          {/* <div className="dragText text-[#888E99]">
                Drag and Drop File Here!
              </div> */}
                        </>
                      </div>
                    ) : (
                      <Properties pod={pod} />
                    )}
                  </div>
                </section>
              </div>
            </div>
            <Toaster />
          </>
        ) : (
          <></>
        )}
      </div>
      {/* <RightInfo /> */}
    </>
  );
};

const Properties = (props: { pod: IPod | undefined }) => {
  return (
    <div className="mt-6 flex flex-1 flex-col gap-2 px-6 pb-6 text-xs 2xl:gap-4 2xl:text-[13px]">
      <div className="border-gray block overflow-hidden rounded-xl border">
        <div className="border-gray title-white w-full border-b p-6 text-lg font-medium">Overview of storage</div>
        <div className="flex flex-wrap justify-between py-4">
          <div className="border-gray w-[50%] min-w-[250px] p-6 text-base sm:border-r md:w-[33.3%]">
            <p className="title-white font-medium">Static Web Hosting</p>
            <p className="bucket-info-text-color mt-2 text-sm">Enabled</p>
          </div>
          <div className="border-gray w-[50%] min-w-[250px] p-6 text-base sm:border-r md:w-[33.3%]">
            <p className="title-white font-medium">Host Type</p>
            <p className="bucket-info-text-color mt-2 text-sm">Pod Hosting</p>
          </div>
          <div className="border-gray w-[50%] min-w-[250px] p-6 text-base sm:border-r md:w-[33.3%]">
            <p className="title-white font-medium">Index Document</p>
            <p className="bucket-info-text-color mt-2 text-sm">index.html</p>
          </div>
          <div className="border-gray w-[50%] min-w-[250px] p-6 text-base sm:border-r md:w-[33.3%]">
            <p className="title-white font-medium">Error Document</p>
            <p className="bucket-info-text-color mt-2 text-sm">error.html</p>
          </div>
          <div className="border-gray w-[50%] min-w-[250px] p-6 text-base sm:border-r md:w-[33.3%]">
            <p className="title-white font-medium">Website Endpoint</p>
            <p className="bucket-info-text-color mt-2 cursor-pointer truncate text-sm text-[#588EDF] hover:underline">
              http://{props.pod?.name}
              {config.api.website_gateway}
            </p>
          </div>
          <div className="border-gray w-[50%] min-w-[250px] p-6 text-base sm:border-r md:w-[33.3%]">
            <p className="title-white font-medium"></p>
            <p className="bucket-info-text-color mt-2 cursor-pointer truncate text-sm text-[#588EDF] hover:underline"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BucketInfo;
