import axios from "axios";
import config from "../../config";

export interface IGetStampUsage {
  usable: boolean;
  exists: boolean;
  expiredAt: number;
  capacity: {
    total: string;
    used: string;
  };
}

export async function getStampUsage(
  accessToken: string,
  batchId: string
): Promise<IGetStampUsage> {
  const url = new URL(`/storage/bzz/usage/${batchId}`, config.api.endpoint);
  const response = await axios.get(url.href, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
