import axios from "axios";
import config from "../../config";


interface IDeleteItem{
  status:number,
  message:[]
  time:Date
  data:{
    DeletedCount:number
  }
}

export async function deleteItem(
  accessToken: string,
  prefix: string,
  name:string,
  type:string,
): Promise<IDeleteItem> {

    const url = new URL("/v1/http-api/items", config.api.endpoint);
     const response = await axios.delete(
      url.href,
      {
        data:{
          "prefix": prefix,
          "name": name,
          "type": type
        },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;

}
