import { useState, useEffect, useCallback } from "react";
import { Icon } from "@iconify/react";
import { useLocation, NavLink, Link } from "react-router-dom";
import useWindowDimensions from "../Hook/useWindowDimensions";
import logo from "../Static/logo.webp";
import config from "../config";

interface IProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

const LeftNav = (props: IProps) => {
  const location = useLocation();
  const [prePath, setPrePath] = useState("/");
  const { height, width } = useWindowDimensions();
  const [show, setShow] = useState<boolean>(props.show);
  const [handleHover, setHandleHover] = useState<number>(0);
  useEffect(() => {
    if (location.pathname !== prePath && width <= 768) {
      props.setShow(false);
      setPrePath(location.pathname);
    }
  }, [location]);

  // const handleMenuOptionMouseOver = (e: any) => {
  //   const top = e.target.getBoundingClientRect().top;
  //   const left = e.target.getBoundingClientRect().right;
  //   const location = e.target.dataset.location;
  //   const div = document.createElement("div");
  //   div.classList.add(
  //     "bg-white",
  //     "dark:bg-[#111315]",
  //     "title-white",
  //     "rounded-lg",
  //     "text-base",
  //     "border",
  //     "border-gray",
  //     "option-hover-con",
  //     "font-medium",
  //     "pointer-events-none",
  //     "text-[#a6a6a6]",
  //     `${!props.show ? "block" : "hidden"}`
  //   );
  //   div.style.position = "fixed";
  //   div.style.padding = "0.5rem 1.5rem";
  //   div.style.top = `${top - 6}px`;
  //   div.style.left = `${left + 15}px`;
  //   div.innerText = location + props.show;
  //   e.target.appendChild(div);
  // };

  // const handleMenuOptionMouseLeave = (e: any) => {
  //   e.target.querySelector(".option-hover-con").style.animation =
  //     "option-fade-out 0.25s forwards";
  //   setTimeout(() => {
  //     e.target.querySelectorAll(".option-hover-con").forEach((con:any) => {
  //       con.remove();
  //     });
  //   }, 250);
  // };

  // useEffect(() => {
  //   const options = document.querySelectorAll(".options");
  //   options.forEach((option) => {
  //     if (!props.show) {
  //       console.log("asd");
  //       option.addEventListener("mouseenter", handleMenuOptionMouseOver);
  //       option.addEventListener("mouseleave", handleMenuOptionMouseLeave);
  //     }
  //     return () => {
  //       option.removeEventListener("mouseenter", handleMenuOptionMouseOver);
  //       option.removeEventListener("mouseleave", handleMenuOptionMouseLeave);
  //     };
  //   });
  //   console.log(props.show);
  // }, [props.show]);

  return (
    <>
      {width <= 700 && (
        <>
          <div
            data-show={props.show}
            onClick={() => props.setShow(false)}
            className="left-nav-bg-wrapper fixed top-0 left-0 z-[800] h-full w-full bg-black/10 backdrop-blur-[2px]"
          ></div>
        </>
      )}
      <div className="left-nav-wrapper z-[800]">
        <nav
          data-show={width > 768 ? show : props.show}
          onMouseEnter={() => {
            setTimeout(() => {
              setShow(true);
            }, 300);
          }}
          onMouseLeave={() => {
            setShow(false);
            setTimeout(() => {
              setShow(false);
            }, 300);
          }}
          className="left-con dark:bg-[#111315]"
        >
          {width < 768 ? (
            <div className="logo-con select-none items-end">
              <img src={logo} className="logo-img left w-[65%] max-w-[60px]"></img>
              {/* <h3 className="logo-white text-lg font-medium">dedrive</h3> */}
            </div>
          ) : (
            <></>
            // <div data-show={props.show} className="btn-expand cursor-pointer" onClick={() => props.setShow(!props.show)}>
            //   <Icon icon="akar-icons:text-align-right" className="h-auto w-5 text-white" />
            // </div>
          )}

          <div className="flex flex-1 flex-col justify-between gap-6">
            <div className="nav-option mt-[68px] md:mt-5">
              {width > 768 && <div data-tab={location.pathname.split("/")[1]} className="tab"></div>}
              <NavLink
                to="/pod"
                data-location="Pods"
                // onMouseEnter={(e:any) => {
                //   handleMenuOptionMouseOver(e);
                // }}
                // onMouseLeave={(e:any) => {
                //   handleMenuOptionMouseLeave(e);
                // }}
                className={`pod-option options relative flex cursor-pointer select-none items-center gap-4 overflow-hidden text-xs duration-100 md:text-[14px] ${
                  location.pathname.split("/")[1] === "pod" || location.pathname.split("/")[1] === "setting"
                    ? "text-main font-semibold"
                    : "hover:text-main-highlight font-light text-[#a6a6a6]"
                }`}
              >
                <Icon icon="tabler:bucket" className="h-auto w-7 shrink-0" />
                <h3 className="title">Pods</h3>
                {/* <div className="nav-hint p-2 bg-black"></div> */}
              </NavLink>
              {/* <NavLink
              to="/"
              className={`flex cursor-pointer select-none items-center gap-4 overflow-hidden text-xs font-light duration-100 hover:text-[#588EDF] 2xl:text-[13px] ${
                location.pathname === "/" ? "text-[#588EDF]" : "text-[#a6a6a6]"
              }`}
            >
              <div className="grid h-7 w-7 shrink-0 place-items-center">
                <Icon icon="ph:monitor-bold" className="h-auto w-6" />
              </div>
              <h3 className="title">Monitoring</h3>
            </NavLink> */}
              <NavLink
                to="/billing"
                data-location="Billing"
                className={`options flex cursor-pointer select-none items-center gap-4 overflow-hidden text-xs duration-100 md:text-[14px] ${
                  location.pathname.split("/")[1] === "billing" ? "text-main font-semibold" : "hover:text-main-highlight font-light text-[#a6a6a6]"
                }`}
              >
                <div className="grid h-7 w-7 shrink-0 place-items-center">
                  <Icon icon="icon-park-outline:bill" className="h-auto w-6" />
                </div>
                <h3 className="title">Billing</h3>
              </NavLink>

              <NavLink
                to="/builder"
                data-location="Builder"
                className={`options flex cursor-pointer select-none items-center gap-4 overflow-hidden text-xs duration-100 md:text-[14px] ${
                  location.pathname.split("/")[1] === "builder" ? "text-main font-semibold" : "hover:text-main-highlight font-light text-[#a6a6a6]"
                }`}
              >
                <div className="grid h-7 w-7 shrink-0 place-items-center">
                  <Icon icon="icon-park-outline:add-web" className="h-auto w-6" />
                </div>
                <h3 className="title whitespace-nowrap">Builder (Beta) </h3>
              </NavLink>

              <div
                onClick={() => {
                  window.open("https://faq.dedrive.io");
                }}
                className={`options hover:text-main-highlight flex cursor-pointer select-none items-center gap-4 overflow-hidden text-xs text-[#a5a5a5] duration-100 md:text-[14px]`}
              >
                <div className="grid h-7 w-7 shrink-0 place-items-center">
                  <Icon icon="eva:question-mark-circle-fill" className="h-auto w-6 " />
                </div>
                <h3 className="title whitespace-nowrap">Help Center</h3>
              </div>
            </div>
            {/* <div className="mb-6 ml-[-1rem] hidden w-full pr-2 2xl:block">
              <h2 className="bucket-info-text-color text-sm font-medium text-[#788296] 2xl:text-base">
                Storage
              </h2>
              <div className="bucket-info-text-color mt-2 text-xs font-light text-[#A4A4A4] 2xl:text-[13px]">
                629 MB / 2 GB
              </div>
              <div className="relative mt-[12px] h-[4px] w-full">
                <div className="billing-usage-bg-blue h-full w-full rounded-full"></div>
                <div
                  className={`absolute top-0 left-0 h-full ${"w-[30%]"} rounded-full bg-[#428DFD]`}
                ></div>
              </div>
              <Link to="/billing/stamp/new">
                <button className="bg-blue mt-4 w-full rounded-lg py-3 text-xs text-white hover:bg-[#226ddd] 2xl:text-[13px]">
                  Upgrade
                </button>
              </Link>
            </div> */}
          </div>

          <div
            className={`mb-8 overflow-hidden transition delay-300 duration-150 ${
              (width > 768 ? show : props.show) ? "opacity-1 pointer-event-auto" : "pointer-events-none opacity-0"
            }`}
          >
            <div
              className="mb-4 cursor-pointer whitespace-nowrap text-xs text-black hover:text-[#3f80e0] dark:text-white dark:hover:text-[#3677d8]"
              onClick={() => (window.location.href = "https://www.dedrive.io/term-of-use")}
            >
              Terms of Use
            </div>
            <div
              className="cursor-pointer whitespace-nowrap text-xs text-black hover:text-[#3f80e0] dark:text-white dark:hover:text-[#3677d8]"
              onClick={() => (window.location.href = "https://www.dedrive.io/privacy-policy")}
            >
              Privacy Policy
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default LeftNav;
