import Text from "./template/Text/Text";
import ArticleList from "./template/ArticleList/ArticleList";
import Articles from "./template/Articles/Articles";
import Footers from "./template/Footers/Footers";
import Nav from "./template/Nav/Nav";
import Cards from "./template/Cards/Cards";
import Quotes from "./template/Quotes/Quotes";
import Page from "./template/Page/Page";
import ErrorPage from "./template/ErrorPage/ErrorPage";
import Collections from "./template/Collections/Collections";
import Forms from "./template/Forms/Forms";
import Lists from "./template/Lists/Lists";
import Basic from "./template/Basic/Basic";
// import Marketing from "./template/Marketing/Marketing";
// import Tables from "./template/Tables/Tables"; cannot edit content in grapesjs

const blocksConfig = [
  ...Basic,
  // ...Page,
  ...Collections,
  ...Nav,
  ...Lists,
  // ...Forms,
  ...Cards,
  ...Articles,
  ...ArticleList,
  ...Text,
  ...ErrorPage,
  ...Quotes,
  ...Footers,
];

const block = {
  blocks: blocksConfig,
};

export default block;
