// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type grapesjs from "grapesjs";
import FileSaver from "file-saver";
import JSZip from "jszip";
import { Buffer } from "buffer";
import { Data } from "../../../interface/websiteData.interface";

type Editor = grapesjs.Editor;

export type PluginOptions = {
  /**
   * Type id used to register the new storage.
   * You can use this option in case you want to replace the already available storages (eg. `local`).
   * @default 'indexeddb'
   */
  type?: string;

  /**
   * Add a button inside the export dialog
   * @default true
   */
  addExportBtn?: boolean;

  /**
   * Label of the export button
   * @default 'Export to ZIP'
   */
  btnLabel?: string;

  /**
   * ZIP filename prefix
   * @default 'grapesjs_template'
   */
  filenamePfx?: string;

  /**
   * Use a function to generate the filename, eg. `filename: editor => 'my-file.zip',`
   */
  filename?: (editor: Editor) => string;

  /**
   * Callback to execute once the export is completed
   */
  done?: () => void;

  /**
   * Callback to execute on export error
   */
  onError?: (error: Error) => void;

  /**
   * Use the root object to create the folder structure of your zip (async functions are supported)
   * @example
   * root: {
   *   css: {
   *     'style.css': ed => ed.getCss(),
   *     'some-file.txt': 'My custom content',
   *   },
   *   img: async ed => {
   *     const images = await fetchImagesByStructue(ed.getComponents());
   *     return images;
   *     // Where `images` is an object like this:
   *     // { 'img1.png': '...png content', 'img2.jpg': '...jpg content' }
   *   },
   *   'index.html': ed => `<body>${ed.getHtml()}</body>`
   * }
   */
  root?: Record<string, unknown>;

  /**
   * Custom function for checking if the file content is binary
   */
  isBinary?: (content: string, name: string) => boolean;
};

/* You can set the plugin option to edit the file structure (html,css and js) */
const zipplugin: grapesjs.Plugin<PluginOptions> = (editor, opts = {}) => {
  const pfx = editor.getConfig("stylePrefix");
  const commandName = "gjs-export-zip";
  const commandName2 = "dedrive-connect";

  const config: PluginOptions = {
    addExportBtn: true,
    btnLabel: "Export to ZIP (EDNS)",
    filenamePfx: "edns_template",
    filename: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    done: () => {},
    onError: console.error,
    root: {
      // css: {
      "style.css": (editor: Editor) => editor.getCss(),
      //  },
      // html:{
      "index.html": (editor: Editor) =>
        `<!doctype html>
        <html lang="en">
          <head>
            <meta charset="utf-8">
            
            <script src="index.js"></script>
          </head>
          ${editor.getHtml()}
        </html>`,
      // },
      // js:{
      "index.js": (editor: Editor) => editor.getJs(),
      // }
      "import.json":{
        "index.html":``,
        "style.css":``
      }
    },
    isBinary: undefined,
    ...opts,
  };

  /* What will happend when user click the button */
  editor.Commands.add(commandName, {
    run(editor, sender: any) {
      const zip = new JSZip();
      const onError = opts.onError || config.onError;
      const root = opts.root || config.root;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.createDirectory(zip, root)
        .then(async () => {
          const content = await zip.generateAsync({ type: "blob" });
          const filenameFn = opts.filename || config.filename;
          const done = opts.done || config.done;
          const filenamePfx = opts.filenamePfx || config.filenamePfx;
          const filename = filenameFn ? filenameFn(editor) : `${filenamePfx}_${Date.now()}.zip`;
          FileSaver.saveAs(content, filename);
          //done?.();
        })
        .catch(onError);
    },

    createFile(zip: JSZip, name: string, content: string) {
      const opts: JSZip.JSZipFileOptions = {};
      const ext = name.split(".")[1];
      const isBinary = config.isBinary
        ? config.isBinary(content, name)
        : !(ext && ["html", "css","json"].indexOf(ext) >= 0) &&
          // eslint-disable-next-line no-control-regex
          !/^[\x00-\x7F]*$/.test(content);
      // console.log(name, isBinary)
      if (isBinary) {
        opts.binary = true;
      }

      editor.log(["Create file", { name, content, opts }], { ns: "plugin-export" });
      zip.file(name, content, opts);
    },

    async createDirectory(zip: JSZip, root: PluginOptions["root"]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      root = typeof root === "function" ? await root(editor) : root;

      for (const name in root) {

        // eslint-disable-next-line no-prototype-builtins
        if (root.hasOwnProperty(name)) {

          let content = root[name];
          content = typeof content === "function" ? await content(editor) : content;
          const typeOf = typeof content;
          console.log(name, typeOf)
          if (typeOf === "string") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.createFile(zip, name, content);
          } else if (typeOf === "object") {
            if(name.split(".")[1] === "json"){
              console.log(root[name])
              root[name]["index.html"]=`${editor.getHtml()}`
              root[name]["style.css"]=`${editor.getCss()}`
              this.createFile(zip, name, JSON.stringify(root[name]));
            }else{
              const dirRoot = zip.folder(name);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              await this.createDirectory(dirRoot, content);
            }
          }
        }
      }
    },
  });

  /* Create the button in this page */
  editor.onReady(() => {
    // Add button inside export dialog
    if (config.addExportBtn) {
      const btnExp = document.createElement("button");
      btnExp.innerHTML = config.btnLabel!;
      btnExp.className = `${pfx}btn-prim`;

      editor.on("run:export-template", () => {
        // Add a new button (custom format)
        const el = editor.Modal.getContentEl();
        el.appendChild(btnExp); //bug
        btnExp.onclick = () => {
          editor.runCommand(commandName);
        };
      });
    }
  });
};

export default zipplugin;
