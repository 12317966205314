import axios from "axios";
import config from "../config";
import { IGetItems } from "../interface/get-item.interface";
import { useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";

export async function getItems(accessToken: string, userid: string, podName: string): Promise<IGetItems> {
  const url = new URL(`/v1/http-api/items/${podName}`, config.api.endpoint);
  const response = await axios.get(url.href, {
    headers: { authorization: `Bearer ${accessToken}`, "user-id": `${userid}` },
  });
  return response.data;
}

export async function getItemsByUid(accessToken: string, userid: string, uid: string): Promise<IGetItems> {
  const url = new URL(`/v1/http-api/items/did/${uid}`, config.api.endpoint);
  const response = await axios.get(url.href, {
    headers: { authorization: `Bearer ${accessToken}`, "user-id": `${userid}` },
  });
  return response.data;
}
