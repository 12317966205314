import axios from "axios";

interface IRes{
  "status":number,
  "success":boolean,
  "data"?:string,
  //https://polygonns.meta.edns.link/
  "onchain":boolean
  ,"empty":boolean}

export const getFqdnByPodName = async (podName:string):Promise<string|null>=>{
  try {
    const fqdnData = await axios.get(`https://api.resolver.gdn/pod/${podName}`)
    const res:IRes = fqdnData.data
    if(res.data){
      const fqdn = res.data?.replace('https://','').replace('.edns.link/','')
      return fqdn
    }else{
      return null
    }
  }catch (e) {
    return null
  }
}
