import TextLargeParagraph from "./TextContent/TextImg/TextLargeParagraph.png";
import TextNarrowParagraph from "./TextContent/TextImg/TextNarrowParagraph.png";
import TextParagraph from "./TextContent/TextImg/TextParagraph.png";
import TextSmallNarrowParagraph from "./TextContent/TextImg/TextSmallNarrowParagraph.png";
import TextSmallParagraph from "./TextContent/TextImg/TextSmallParagraph.png";
import TextTitleSubtitleCentered from "./TextContent/TextImg/TextTitleSubtitleCentered.png";
import TextWideParagraph from "./TextContent/TextImg/TextWideParagraph.png";

const Text = [
  {
    id: "Text-large-paragraph",
    category: "Text",
    label: "<h3>Large Paragraph</h3>",
    media: `<img src="${TextLargeParagraph}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .lh-copy {
                    line-height: 1.5;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .f4 {
                    font-size: 1.25rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                </style>
                <div class="pa3 pa5-ns">
                    <p class="f4 lh-copy measure">
                        Typography has one plain duty before it and that is to convey information
                        in writing. No argument or consideration can absolve typography from this
                        duty. A printed work which cannot be read becomes a product without
                        purpose.
                    </p>
                </div>
            `,
  },
  {
    id: "Text-narrow-paragraph",
    category: "Text",
    label: "<h3>Narrow Paragraph</h3>",
    media: `<img src="${TextNarrowParagraph}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .lh-copy {
                    line-height: 1.5;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .measure-narrow {
                    max-width: 20em;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                </style>
                <div class="pa3 pa5-ns">
                    <p class="f5 lh-copy measure-narrow">
                        Typography has one plain duty before it and that is to convey information
                        in writing. No argument or consideration can absolve typography from this
                        duty. A printed work which cannot be read becomes a product without
                        purpose.
                    </p>
                </div>
            `,
  },
  {
    id: "Text-paragraph",
    category: "Text",
    label: "<h3>Paragraph</h3>",
    media: `<img src="${TextParagraph}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .lh-copy {
                    line-height: 1.5;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                </style>
                <main class="pa3 pa5-ns">
                    <p class="f5 lh-copy measure">
                        Typography has one plain duty before it and that is to convey information
                        in writing. No argument or consideration can absolve typography from this
                        duty. A printed work which cannot be read becomes a product without
                        purpose.
                    </p>
                </main>
                
            `,
  },
  {
    id: "Text-small-narrow-paragraph",
    category: "Text",
    label: "<h3>Small Narrow Paragraph</h3>",
    media: `<img src="${TextSmallNarrowParagraph}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .lh-copy {
                    line-height: 1.5;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .measure-narrow {
                    max-width: 20em;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                </style>
                <main class="pa3 pa5-ns">
                    <p class="f6 lh-copy measure-narrow">
                        Typography has one plain duty before it and that is to convey information
                        in writing. No argument or consideration can absolve typography from this
                        duty. A printed work which cannot be read becomes a product without
                        purpose.
                    </p>
                </main>

            `,
  },
  {
    id: "Text-small-paragraph",
    category: "Text",
    label: "<h3>Small Paragraph</h3>",
    media: `<img src="${TextSmallParagraph}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .lh-copy {
                    line-height: 1.5;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .f6 {
                    font-size: .875rem;
                }
                
                .measure {
                    max-width: 30em;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                </style>
                <main class="pa3 pa5-ns">
                    <p class="f6 lh-copy measure">
                        Typography has one plain duty before it and that is to convey information
                        in writing. No argument or consideration can absolve typography from this
                        duty. A printed work which cannot be read becomes a product without
                        purpose.
                    </p>
                </main>

            `,
  },
  {
    id: "Text-title-subtitle-centered",
    category: "Text",
    label: "<h3>Title Subtitle Centered</h3>",
    media: `<img src="${TextTitleSubtitleCentered}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .fw2 {
                    font-weight: 200;
                }
                
                .lh-copy {
                    line-height: 1.5;
                }
                
                .black-90 {
                    color: rgba(0, 0, 0, .9);
                }
                
                .black-50 {
                    color: rgba(0, 0, 0, .5);
                }
                
                .ph4 {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
                
                .mt0 {
                    margin-top: 0;
                }
                
                .mv3 {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
                
                .tc {
                    text-align: center;
                }
                
                .f3 {
                    font-size: 1.5rem;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                @media screen and (min-width: 30em) and (max-width: 60em) {
                    .f2-m {
                        font-size: 2.25rem;
                    }
                
                    .f4-m {
                        font-size: 1.25rem;
                    }
                }
                
                @media screen and (min-width: 60em) {
                    .f1-l {
                        font-size: 3rem;
                    }
                
                    .f3-l {
                        font-size: 1.5rem;
                    }
                }
                </style>
                <header class="tc ph4">
                    <h1 class="f3 f2-m f1-l fw2 black-90 mv3">
                        This is the title
                    </h1>
                    <h2 class="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                        This is the subtitle where additional information can go if you really need it.
                    </h2>
                </header>


            `,
  },
  {
    id: "Text-wide-paragraph",
    category: "Text",
    label: "<h3>Wide Paragraph</h3>",
    media: `<img src="${TextWideParagraph}" width="100%">`,
    activate: true,
    content: `
            
                <style>
                .lh-copy {
                    line-height: 1.5;
                }
                
                .pa3 {
                    padding: 1rem;
                }
                
                .f5 {
                    font-size: 1rem;
                }
                
                .measure-wide {
                    max-width: 34em;
                }
                
                @media screen and (min-width: 30em) {
                    .pa5-ns {
                        padding: 4rem;
                    }
                }
                </style>
                <div class="pa3 pa5-ns">
                    <p class="f5 lh-copy measure-wide">
                        Typography has one plain duty before it and that is to convey information
                        in writing. No argument or consideration can absolve typography from this
                        duty. A printed work which cannot be read becomes a product without
                        purpose.
                    </p>
                </div>
            `,
  },
];

export default Text;
