import { isImage, isVideo, isText, isExcel } from "../Hook/isType";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../Hook/useWindowDimensions";
import React from "react";

interface UploadingProps {
  fileName: string;
  uploadedSize: number;
  totalSize: number;
  uploadSpeed: number;
  type?: "Download" | "Upload";
  removeBtn: (keyname: string) => void;
  keyname: string;
}

const UploadFiles = (props: UploadingProps) => {
  const { width } = useWindowDimensions();
  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  const [removeItem, setRemoveItem] = React.useState(false);
  return (
    <div className={`uploading-item items-center overflow-hidden px-5 pb-4 md:px-6 ${removeItem && "upload-item-remove-ani"}`}>
      <div className="flex h-[82px] items-center rounded-lg border-2 border-[#e7e9ed] dark:border-[#252525] px-4 py-3">
        <div className="flex h-full flex-1 items-center gap-2 overflow-hidden">
          <div className="grid w-10 place-items-center">
            {isText(props.fileName?.split(".")[props.fileName?.split(".").length - 1]) && (
              <Icon icon="ant-design:file-text-filled" className="ml-[-4px] h-8 w-auto shrink-0 text-[#588EDF]" id="ItemProp"></Icon>
            )}
            {isVideo(props.fileName?.split(".")[props.fileName?.split(".").length - 1]) && (
              <Icon icon="ic:baseline-video-file" className="ml-[-4px] h-8 w-auto shrink-0 text-[#D4594A]" id="ItemProp"></Icon>
            )}
            {isImage(props.fileName?.split(".")[props.fileName?.split(".").length - 1]) && (
              <Icon icon="ic:baseline-insert-photo" className="ml-[-4px] h-8 w-auto shrink-0 text-[#D4594A]" id="ItemProp"></Icon>
            )}
            {isExcel(props.fileName?.split(".")[props.fileName?.split(".").length - 1]) && (
              <Icon icon="vscode-icons:file-type-excel" className="ml-[-4px] h-8 w-auto shrink-0 text-[#33c481]" id="ItemProp"></Icon>
            )}
          </div>
          <div className="flex h-full flex-1 flex-col justify-between overflow-hidden">
            <div className="title-white flex flex-col">
              {/* <div className="title-white md:flex-0 flex w-[20%] flex-1 shrink-0 items-center gap-2 md:w-[200px]"> */}
              {/* <div>
                  {props.keyname.split("-")[0] === "Download" ? <Icon icon="iconoir:download" className="h-auto w-5" /> : <Icon icon="iconoir:uploadd" className="h-auto w-5" />}
                </div> */}

              <div className="title-white flex flex-1 items-center text-sm font-semibold">
                <p className="truncate">{props.fileName.split(".")[0]}</p>
                <p className="shrink-0">.{props.fileName.split(".")[1]}</p>{" "}
              </div>
              {/* </div> */}

              {/* {props.uploadedSize === props.totalSize ? (
                <div className="mr-2 w-[100px] text-end text-sm font-semibold dark:text-[#1ea885]">
                  {props.keyname.split("-")[0] === "Download" ? (
                    <div className="mr-2 w-[100px] text-end text-sm font-semibold dark:text-[#1ea885]">Downloaded</div>
                  ) : (
                    <div className="mr-2 w-[100px] text-end text-sm font-semibold dark:text-[#1ea885]">Uploaded</div>
                  )}
                </div>
              ) : (
                <div className="mr-2 flex w-[100px] items-center justify-end gap-1 text-sm font-medium">
                  <p>{Math.round((props.uploadedSize / props.totalSize) * 100)} / 100%</p>

                  {props.keyname.split("-")[0] === "Download" ? <Icon icon="iconoir:download" className="h-auto w-5" /> : <Icon icon="iconoir:upload" className="h-auto w-5" />}
                </div>
              )} */}
            </div>
            <div>
              <div className="mb-1.5 flex items-center justify-between">
                {width > 768 && (
                  <div className="flex flex-1 text-sm font-medium text-[#aaaeb3]">
                    {props.uploadedSize < props.totalSize ? formatFileSize(props.uploadedSize) + " of " + formatFileSize(props.totalSize) : formatFileSize(props.totalSize)}
                  </div>
                )}
                <p className="text-sm font-medium text-[#8d9097]">{Math.ceil((props.uploadedSize / props.totalSize) * 100) + "%"}</p>
              </div>

              <div className={`billing-usage-bg-blue relative h-[5px] w-full rounded-full`}>
                <div
                  className={`${props.uploadedSize === props.totalSize ? "bg-upload-success" : "billing-usage-blue"} transition-width absolute top-0 left-0 h-[5px] rounded-full`}
                  style={{ width: (props.uploadedSize / props.totalSize) * 100 + "%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="group ml-4 rounded-full bg-[#f2f7fb] p-1 dark:bg-[#272929]"
          onClick={() => {
            setRemoveItem(true);
            setTimeout(() => {
              props.removeBtn(props.keyname);
            }, 510);
          }}
        >
          <Icon icon="charm:tick" className="text-upload-success h-auto w-5 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
